import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FinishRegister.css';

const FinishRegister = () => {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => setShowContent(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const handleStartClick = () => {
    navigate('/gestionarTickets');
  };

  return (
    <div className="fr-finish-register-container">
      <div className="fr-content-wrapper">
        <div className={`fr-content ${showContent ? 'fr-visible' : ''}`}>
          <h1 className="fr-title">Bienvenido a Planius</h1>
          <p className="fr-subtitle">Tu viaje hacia la planificación inteligente comienza aquí</p>
          <div className="fr-divider"></div>
          <p className="fr-message">Prepárate para experimentar una nueva forma de organizar tu vida,
           donde la calma y la productividad se encuentran en perfecto equilibrio.</p>
          <button className="fr-start-button" onClick={handleStartClick}>Comenzar mi experiencia</button>
        </div>
      </div>
    </div>
  );
};

export default FinishRegister;