import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import AudioRecorder from "../voiceToText/AudioRecoder";
import { massiveTaskJson } from "../IA/Gpt";
import moment from "moment";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ResumeMasiveTask from "./ResumeMassiveTask";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { createTicketNew } from "../Functions";
import { agregarEventoACalendario } from "../calendario/FunctionsCalendar";

function MassiveTask({ onClose, selectedDateTime, type, fullScreen }) {
  const [mensaje, setMensaje] = useState("");
  const [jsonResult, setJsonResult] = useState([]);
  const [confirmedTasks, setConfirmedTasks] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [areas, setAreas] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState({});
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const textAreaRef = useRef(null);
  const { currentUser, businessEmail, accessToken, refreshToken, tokenExpiry, firebaseAccesToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchAreas = async () => {
      if (businessEmail) {
        const q = query(collection(db, "business", businessEmail, "areas"));
        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
        });
        setAreas(items.map((item) => item.id));
      }
    };
    fetchAreas();
  }, [businessEmail]);

  const fechaActualChile = () => {
    const fechaChile = moment().tz("America/Santiago").format("DD-MM-YYYY");
    return fechaChile;
  };

  const hoy = fechaActualChile();
  const horaActual = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const handleMassiveAssign = async () => {
    if (!mensaje.trim() || isLoading) return; 
  
    setIsLoading(true);
    setError(null); 
  
    try {
      const response = await fetch('/.netlify/functions/massiveTask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mensaje, businessEmail }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { tasks } = await response.json();
      const sortedResponse = sortTasksByDateTime(tasks);

      setJsonResult(sortedResponse);
      setShowResumeModal(true);
    } catch (error) {
      console.error("Error al generar el JSON:", error);
      setError(
        "No hemos podido interpretar tu solicitud, por favor verifica que esté correcta y vuelve a intentar generarla."
      );
    } finally {
      setIsLoading(false);
    }
  };
  

  const getAreaDetails = async (areaId) => {
    try {
      const areaRef = collection(db, "business", businessEmail, "areas");
      const q = query(areaRef, where("name", "==", areaId));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const areaData = querySnapshot.docs[0].data();
        return areaData; // Devuelve los datos del área (color, icono, etc.)
      } else {
        console.warn("Área no encontrada:", areaId);
        return null;
      }
    } catch (error) {
      console.error("Error al obtener los detalles del área:", error);
      return null;
    }
  };

  const handleCreateTasks = async () => {
    const selectedTasks = jsonResult.filter((_, index) => confirmedTasks[index] === true);
    
    if (selectedTasks.length === 0) {
      alert("No se ha seleccionado ninguna tarea.");
      return;
    }
  
    setSuccessMessage(`Agregando 0 de ${selectedTasks.length} tareas seleccionadas...`);
  
    try {
      let addedTasksCount = 0;
      for (const task of selectedTasks) {
        const {
          fecha,
          horaInicio,
          horaFin,
          título,
          tipo,
          area,
          todoElDia,
          priority,
        } = task;
  
        // Determinar el estado basado en si tiene fecha asignada
        const status = fecha ? "Proceso" : "Pendiente";
  
        // Obtén el color y el ícono del área seleccionada
        const areaDetails = await getAreaDetails(area);
        const color = areaDetails?.color || "#000000";
        const icon = areaDetails?.icon || "📌";
  
        const formattedFechaCreacion = hoy;
        const formattedFechaVencimiento = fecha ? moment(fecha, "YYYY-MM-DD").format("DD-MM-YYYY") : null;
        const horaCreacion = horaActual;
        const fromEmail = currentUser.email;
        const descripcion = título;
        const tituloConIcono = `${icon} ${título}`;
        const businessName = "Proyecto de pruebas";
  
        // Manejar correctamente las tareas "Todo el día"
        const horaInicioFinal = todoElDia ? "Todo el día" : horaInicio || "09:00";
        const horaFinFinal = todoElDia ? "Todo el día" : (tipo === "evento" ? (horaFin || "10:00") : null);
  
        const refDoc = await createTicketNew(
          tituloConIcono,
          businessName,
          priority,
          formattedFechaVencimiento,
          formattedFechaCreacion,
          area,
          status,
          currentUser.email,
          businessEmail,
          descripcion,
          descripcion,
          "No aplica",
          descripcion,
          currentUser.displayName,
          [currentUser.email],
          fromEmail,
          formattedFechaCreacion,
          horaInicioFinal,
          horaFinFinal,
          tipo,
          color,
          todoElDia,
          formattedFechaVencimiento,
        );
  
        if (status === "Proceso") {
          const fechaInicioISO = moment.tz({ year: moment(fecha).year(), month: moment(fecha).month(), day: moment(fecha).date() }, "America/Santiago").toISOString();
          await agregarEventoACalendario(
            businessEmail, 
            refDoc.id, 
            tituloConIcono, 
            descripcion, 
            fechaInicioISO, 
            fechaInicioISO, 
            horaInicioFinal, 
            horaFinFinal, 
            color, 
            'primary',  
            currentUser, 
            accessToken, 
            refreshToken, 
            tokenExpiry,
            firebaseAccesToken
          );
        }
  
        addedTasksCount++;
        setSuccessMessage(`Agregando ${addedTasksCount} de ${selectedTasks.length} tareas seleccionadas...`);
      }
  
      setSuccessMessage("¡Agregadas todas las tareas y eventos satisfactoriamente!");
      setTimeout(() => {
        setSuccessMessage('');
        setConfirmedTasks({});
      }, 3000);
    } catch (error) {
      console.error("Error al crear los tickets:", error);
      setError("Ocurrió un error al crear las tareas.");
    }
  };
  
  
  const handleDateChange = (index, value) => {
    setJsonResult((prev) => {
      const newTasks = [...prev];
      newTasks[index].fecha = value;
      return newTasks;
    });
  };

  const handleTimeChange = (index, key, value) => {
    setJsonResult((prev) => {
      const newTasks = [...prev];
      newTasks[index][key] = value;
      return newTasks;
    });
  };

  const handleTypeChange = (index, value) => {
    setJsonResult((prev) => {
      const newTasks = [...prev];
      newTasks[index].tipo = value;
      if (value === "tarea") {
        newTasks[index].horaFin = null;
        newTasks[index].todoElDia = false;
      } else if (value === "evento" && !newTasks[index].horaFin) {
        const [hours, minutes] = newTasks[index].horaInicio.split(":");
        const newHours = (parseInt(hours, 10) + 1) % 24;
        newTasks[index].horaFin = `${newHours.toString().padStart(2, "0")}:${minutes}`;
        newTasks[index].todoElDia = false;
      }
      return newTasks;
    });
  };

  const handleAreaChange = (index, value) => {
    setJsonResult((prev) => {
      const newTasks = [...prev];
      newTasks[index].area = value;
      return newTasks;
    });
  };

  const handleTitleChange = (index, value) => {
    setJsonResult((prev) => {
      const newTasks = [...prev];
      newTasks[index].título = value;
      return newTasks;
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    textAreaRef.current?.addEventListener("keydown", handleKeyDown);
    textAreaRef.current?.focus();

    return () => {
      textAreaRef.current?.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, mensaje]);

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleCheckboxChange = (index) => {
    setConfirmedTasks(prev => {
      const newState = { ...prev };
      if (newState[index] === true) {
        delete newState[index]; // Eliminar la selección si ya estaba seleccionado
      } else {
        newState[index] = true; // Marcar como seleccionado si no lo estaba
      }
      return newState;
    });
  };

  const handleCloseResumeModal = () => {
    setShowResumeModal(false);
  };

  return (
    <>
      <Modal
        overlayClassName="popupBackground"
        className={`popupContainer ${fullScreen ? "fullscreen" : ""}`}
        isOpen={true}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        onClick={handleClickOutside}
      >
        <div className="popupHeader">
          <h2 className="popupTitle">Planificación automática</h2>
        </div>
        <div className="popupContent">
          <div className="popupField">
            <label htmlFor="description" className="popupLabel">
              Describe las distintas tareas que tienes pendientes:
            </label>
            <textarea
              id="description"
              ref={textAreaRef}
              placeholder="Describe tus tareas..."
              className="popupInput"
              value={mensaje}
              onChange={(e) => setMensaje(e.target.value)}
            />
          </div>
          <AudioRecorder onTranscription={(text) => setMensaje(text)} />

          <div className="popupActions" style={{display:"flex", flexDirection:"row"}}>
            <button
              onClick={handleMassiveAssign}
              className="popupButton"
              disabled={!mensaje.trim()}
              style={{margin:"auto", marginTop:"10px", textAlign:"center", width:"100%"}}
            >
              Asignar
            </button>
            <button onClick={onClose} className="popupButton" style={{margin:"auto", marginTop:"10px", textAlign:"center", width:"100%", marginLeft:"10px"}}>
              Cancelar
            </button>
          </div>
          {isLoading && (
            <div className="detalleTarea__loading" style={{margin:"auto", width:"90%"}}>
              <FontAwesomeIcon icon={faSpinner} spin className="icon" />
              <span>Cargando...</span>
            </div>
          )}
          {error && <div className="error-message">{error}</div>}
        </div>
      </Modal>
      <ResumeMasiveTask
        isOpen={showResumeModal}
        onClose={handleCloseResumeModal}
        tasks={jsonResult}
        confirmedTasks={confirmedTasks}
        onCheckboxChange={handleCheckboxChange}
        onTimeChange={handleTimeChange}
        onDateChange={handleDateChange}
        onTypeChange={handleTypeChange}
        onTitleChange={handleTitleChange}
        areas={areas}
        onAreaChange={handleAreaChange}
        handleCreateTasks={handleCreateTasks}
        successMessage={successMessage}
        setJsonResult={setJsonResult}
      />
    </>
  );
}

export default MassiveTask;

const sortTasksByDateTime = (tasks) => {
  return tasks.sort((a, b) => {
    const dateA = new Date(
      `${a.fecha}T${a.horaInicio !== "todoElDía" ? a.horaInicio : "00:00"}`
    );
    const dateB = new Date(
      `${b.fecha}T${b.horaInicio !== "todoElDía" ? b.horaInicio : "00:00"}`
    );
    return dateA - dateB;
  });
};
