import React from "react";

const clientId =
  "741637288756-sv9618j8erfuh46v2nh4ege4e2k01jhq.apps.googleusercontent.com";
const redirectUri = "http://localhost:8888/googleAuth";

const getAuthorizationCode = () => {
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${redirectUri}&client_id=${clientId}&prompt=consent&access_type=offline`;
  window.location.href = authUrl;
};

const SignInWithGoogle2 = () => {
  React.useEffect(() => {
    getAuthorizationCode();
  }, []);

  return <div>Redireccionando a Google...</div>;
};

export default SignInWithGoogle2;
