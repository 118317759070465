import React from "react";
import "./Boards.css";
import logo from "../img/logo-login.png";

function Spinner() {
  return (
    <div className="spinner-container">
      <img className="logo-init-container" src={logo} alt="" />
      <div className="spinner"></div>
    </div>
  );
}

export default Spinner;
