import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./ImageBox.css";

const ImageBox = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="image-box">
      {isLoading && (
        <div className="detalleTarea__loading">
          <FontAwesomeIcon icon={faSpinner} spin className="icon" />
        </div>
      )}
      <img
        src={src}
        alt="Uploaded"
        onLoad={handleImageLoad}
        className="board-icon_"
        style={{ display: isLoading ? "none" : "block" }}
      />
    </div>
  );
};

export default ImageBox;
