import React, { useState, useRef, useEffect } from "react";
import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faThumbtack, faVideo, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import TooltipPortal from './TooltipPortal';

const DraggableTicket = ({ ticket, onClick, style, currentUser }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const timeoutRef = useRef(null);
  const ticketRef = useRef(null);

  const [{ isDraggingItem }, drag, preview] = useDrag({
    type: "TICKET",
    item: { ...ticket },
    canDrag: ticket.canEdit,
    collect: (monitor) => ({
      isDraggingItem: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        ReactGA.event({
          category: "Interacción",
          action: "Arrastre Ticket Mensual",
          label: "Arrastre Ticket Mensual",
          value: 1,
        });
      }
    },
  });

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      const rect = ticketRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top,
        left: rect.left + rect.width / 2,
      });
      setShowTooltip(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setShowTooltip(false);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const hasItems = ticket.items && ticket.items.length > 0;

  return (
    <div
      ref={(node) => {
        preview(node);
        ticketRef.current = node;
      }}
      style={{ ...style, position: "relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={ticket.canEdit ? drag : null}
        className={`calendario__ticket-mensual ${isDraggingItem ? "dragging" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e, ticket);
        }}
        style={{
          opacity: isDraggingItem ? 0.5 : 1,
          cursor: ticket.canEdit ? "move" : "default",
          height: "auto",
          position: "relative",
        }}
      >
        <div className="ticket-content">
          <div className="ticket-title-mes" style={{fontWeight:"500"}}>{ticket.horaInicio === "(Día)"
              ? ticket.horaInicio
              : `${ticket.horaInicio}`} {ticket.title}</div>


        </div>
      </div>
      {showTooltip && (
        <TooltipPortal position={tooltipPosition}>
          <p><strong>Título:</strong> {ticket.title}</p>
          <p><strong>Hora:</strong>
            {ticket.horaInicio === "Todo el día"
              ? ticket.horaInicio
              : `${ticket.horaInicio} - ${ticket.horaTermino}`}
          </p>
          <p><strong>Editable:</strong> {ticket.canEdit ? "Sí" : "No"}</p>
          {hasItems && <p><strong>Tiene items adjuntos</strong></p>}
        </TooltipPortal>
      )}
    </div>
  );
};

export default DraggableTicket;