import React from 'react'
import { AuthContext } from '../context/AuthContext'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import RbClients from './registerBusiness/RbClients'

function Clientes() {

    
  return (
    <div>
    <Navbar/>
    <Sidebar />
    <RbClients />
    <Footer />
  </div>
  )
}

export default Clientes