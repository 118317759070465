import React, { useState } from 'react';
import TooltipPlanning from './TooltipPlanning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock, faSmile, faBullseye, faBalanceScale,
  faTasks, faDollarSign, faHeartbeat, faTrophy
} from '@fortawesome/free-solid-svg-icons';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './WhyPlan.css';

const WhyPlan = ({ nextStep, handleChange, formData, currentUser }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Estado para el loading

  const handleCheckboxChange = (value) => {
    let updatedOptions = [...selectedOptions];
    if (updatedOptions.includes(value)) {
      updatedOptions = updatedOptions.filter(option => option !== value);
    } else if (updatedOptions.length < 3) {
      updatedOptions.push(value);
    }
    setSelectedOptions(updatedOptions);
    handleChange('reason')(updatedOptions);
  };

  const handleAccept = async () => {
    setIsLoading(true); // Activar el estado de carga
    try {
      const userRef = doc(db, 'users', currentUser.email);
      await updateDoc(userRef, {
        whyPlansObjective: selectedOptions
      });
      nextStep(); // Procede al siguiente paso si la actualización es exitosa
    } catch (error) {
      console.error("Error updating document: ", error);
      // Aquí puedes manejar el error, como mostrar un mensaje al usuario
    } finally {
      setIsLoading(false); // Desactivar el estado de carga (esto puede no ser necesario si redireccionas)
    }
  };

  return (
    <div className="formStep">
      <h2 style={{ marginTop: "0px", fontSize: '18px', fontWeight: '400', letterSpacing: "0.5px" }}>
        ¿Qué objetivo deseas lograr al adoptar el hábito de planificación y la metodología Planius?
        <TooltipPlanning text="Esto es importante porque nos ayudará a entender las prioridades de tu planificación." />
      </h2>
      <div className="optionsContainer">
        {[
          { value: 'Controlar mejor mi tiempo y actividades.', icon: faClock, label: 'Controlar mejor mi tiempo y actividades.' },
          { value: 'Reducir el estrés y la ansiedad.', icon: faSmile, label: 'Reducir el estrés y la ansiedad.' },
          { value: 'Aumentar mi productividad y alcanzar mis metas.', icon: faBullseye, label: 'Aumentar mi productividad y alcanzar mis metas.' },
          { value: 'Equilibrar mi vida personal y laboral.', icon: faBalanceScale, label: 'Equilibrar mi vida personal y laboral.' },
          { value: 'Desarrollar habilidades de organización y gestión del tiempo.', icon: faTasks, label: 'Desarrollar habilidades de organización y gestión del tiempo.' },
          { value: 'Sentir satisfacción al completar tareas y proyectos.', icon: faSmile, label: 'Sentir satisfacción al completar tareas y proyectos.' },
          { value: 'Ganar más dinero siendo eficiente y productivo.', icon: faDollarSign, label: 'Ganar más dinero siendo eficiente y productivo.' },
          { value: 'Mejorar mi salud al gestionar mejor mi tiempo.', icon: faHeartbeat, label: 'Mejorar mi salud al gestionar mejor mi tiempo.' },
          { value: 'Ser reconocido por mi eficiencia y habilidades.', icon: faTrophy, label: 'Ser reconocido por mi eficiencia y habilidades.' }
        ].map((option, index) => (
          <label
            key={index}
            className={`optionLabel ${selectedOptions.includes(option.value) ? 'selectedOption' : ''}`}
          >
            <input
              type="checkbox"
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
            />
            <FontAwesomeIcon icon={option.icon} className="optionIcon" />
            {option.label}
          </label>
        ))}
      </div>
      <p className="selectionCounter">{`${selectedOptions.length}/3 seleccionados`}</p>
      <button
        className="formButton"
        onClick={handleAccept}
        disabled={selectedOptions.length !== 3 || isLoading}
      >
        {isLoading ? (
          <div className="spinnerAcept"></div> /* Spinner en lugar del texto "Aceptar" */
        ) : (
          "Aceptar"
        )}
      </button>

    </div>
  );
};

export default WhyPlan;
