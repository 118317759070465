import React from "react";
import Logo from "./img/logo-footer.png"

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo-footer">
          <img src={Logo} alt="Planius Logo" />
        </div>
        <div className="footer-text">
          <p>Planius.ai Todos los derechos reservados {currentYear}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
