import React, { useState, useEffect } from "react";
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";

export const VerificarEmail = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const enviarEmailDeVerificacion = async () => {
    setLoading(true);
    if (auth.currentUser) {
      await sendEmailVerification(auth.currentUser);
    }
    setLoading(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.emailVerified) {
        navigate("/");
      }
    });

    // Limpieza del efecto
    return () => unsubscribe();
  }, [navigate]);

  return (
    <div>
      <h2>Verificar Correo Electrónico</h2>
      <p>
        Necesitas verificar tu correo electrónico antes de poder continuar. Por
        favor, revisa tu bandeja de entrada.
      </p>
      <button
        onClick={enviarEmailDeVerificacion}
        disabled={loading}
      >
        Enviar Correo de Verificación
      </button>
      {loading && <div>Enviando correo de verificación...</div>}
      <hr />
      <div>Si ya lo verificaste, puedes iniciar sesión aquí <a href="/login">Iniciar Sesión</a> </div>
    </div>
  );
};
