import React from "react";
import Navbar from "../Navbar";
import RbIntro from "./RbIntro";
import RbDescription from "./RbDescription";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import RbAreas from "./RbAreas";
import RbAgents from "./RbAgents";
import RbClientsAgents from "./RbClientsAgents";
import RbClients from "./RbClients";
import AlertModal from "../AlertModal";
import { useRef } from "react";
import RbRewards from "./RbRewards";
import RbFinal from "./RbFinal";

function RegisterBusiness() {
  const [page, setPage] = useState(1);
  const [puedoAvanzar, setPuedoAvanzar] = useState(false);
  const alertModalRef = useRef(null);

  const handleOpenModal = () => {
    alertModalRef.current.handleOpen();
  };

  const handleClick = () => {
    setPage(page + 1);
    if (page === 2 && puedoAvanzar === false) {
      setPage(2);
      handleOpenModal();
    }
  };

  const handleAvanzar = (value) => {
    setPuedoAvanzar(value);
  };

  const handleBack = () => {
    setPage(page - 1);
    if (page < 1) {
      setPage(1);
    }
  };
  return (
    <div>
      <Navbar />

      <div className="container">
        {page === 1 && <RbIntro />}
        {page === 2 && <RbDescription handleAvanzar={handleAvanzar} />}
        {page === 3 && <RbAreas />}
        {page === 4 && <RbAgents />}
        {page === 5 && <RbClientsAgents />}
        {page === 6 && <RbFinal />}
        <div className="row">
          {page > 1 && (
            <button onClick={handleBack} className="btnNextBigCard">
              Atrás
            </button>
          )}

          {page < 6 && (
            <button onClick={handleClick} className="btnNextBigCard">
              Siguiente
            </button>
          )}
        </div>
      </div>
      <AlertModal
        ref={alertModalRef}
        modalDescription="Debe llenar todos los campos para poder avanzar"
      />
    </div>
  );
}

export default RegisterBusiness;
