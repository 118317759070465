import React from 'react';
import './WelcomeScreen.css';

const WelcomeScreen = ({ nextStep, currentUser }) => {
  return (
    <div className="welcomeStep">
      <h1>👋 Hola {currentUser.displayName}</h1>
      <h2>Soy Planibot 🤖</h2>
      <p>
       🎊 ¡Te felicito por dar el primer paso para poner en orden y aterrizar todas tus ideas, 
        actividades y proyectos! Con la metodología Planius, podrás gestionarlas para que se hagan realidad. 🚀
      </p>
      <p>
        Para convertirme en el mejor asistente, me encantaría conocer más sobre ti y tus ideas.
      </p>
      <button className="welcomeButton" onClick={nextStep}>
        Aceptar
      </button>
    </div>
  );
};

export default WelcomeScreen;
