import React, { useContext, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContext } from "../../context/AuthContext";
import { queryAgents, getAgents } from "./../Functions";
import { useState } from "react";
import { db } from "./../firebase";
import { doc, getDoc } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import Modal from "react-modal";
import { updateDoc } from "firebase/firestore";
import CloseTicket from "./CloseTicket";

Modal.setAppElement("#root");

function AddUserChat(props) {
  const [selectedUser, setSelectedUser] = React.useState(null); // Estado para guardar el valor del input
  const [empresas, setEmpresas] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null); // Estado para guardar el valor del input

  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "calc(100vh/70)",
            fontFamily: "Poppins",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "calc(100vh/70)",
            fontFamily: "Poppins",
          },
        },
      },
    },
  });

  

  useEffect(() => {
    if (businessEmail) {
      const q = queryAgents(businessEmail);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAgents(querySnapshot);
        setEmpresas(items);
      });
      setSelectedUser(empresas[1]);
      return unsubscribe;
    }
  }, [businessEmail, currentUser]);

  const handleAreaChange = (event, newValue) => {
    setSelectedUser(newValue);
    if (typeof usuarioSeleccionado === "function") {
      usuarioSeleccionado(newValue);
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const handleAddUser = async () => {
    if (selectedUser != null) {
      try {
        const ticketRef = doc(db, "business", businessEmail, "tickets", props.ticketId);
        const ticketSnap = await getDoc(ticketRef);
        
        if (ticketSnap.exists()) {
          let currentTo = ticketSnap.data().to;
          if (!currentTo.includes(selectedUser.email)) {
            currentTo.push(selectedUser.email);
            await updateDoc(ticketRef, {
              to: currentTo
            }).then(() => {
              alert("Ticket compartido con: " + selectedUser.name);
              setModalIsOpen(false);
            });
          } else {
            alert("El usuario ya tiene acceso al ticket");
          }
        } else {
          console.log("No existe ticket");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Seleccione un usuario");
    }
  }
  

  useEffect(() => {
    if (props.openModal) {
      handleOpenModal();
    }
  }, [props.openModal]);

  return (
    <Modal
      overlayClassName={"modal-overlay"}
      className={"modal-content"}
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
    >
      <h2 className="titleModal">Agregar Miembro</h2>

      <div className="descriptionModal">
        <p>Seleccione el usuario que desea agregar a la resolución del ticket</p>
      </div>

      <div className="autocomplete2">
        <ThemeProvider theme={theme}>
          <div className="autocomplete2">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={empresas}
              getOptionLabel={(option) => option.name}
              sx={{
                width: "100%",
                fontFamily: "Poppins",
                borderRadius: "10px",
                textAlign: "center",
              }}
              onChange={handleAreaChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%" }}
                  label="Usuarios?"
                />
              )}
            />
          </div>
        </ThemeProvider>
      </div>

      <div className="row">
        <button className="btnCloseModal" onClick={handleAddUser}>
          Sí
        </button>
        <button className="btnCloseModal" onClick={handleCloseModal}>
          No
        </button>
      </div>
    </Modal>
  );
}

export default AddUserChat;
