import { React, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { Tab, Tabs, Row, Col, Table, Form, Button } from "react-bootstrap";
import "./tabStyles.css";
import CambiarFotoModal from "./CambiarFotoModal";
import Perfil from "../Perfil/Perfil";
import { updateParameters } from "../Functions";
function MyProfile() {
  const { currentUser } = useContext(AuthContext);
  const {
    businessEmail,
    userType,
    category,
    status,
    department,
    businessName,
    businessAdminEmail,
    businessPhoto,
    businessDescription,
    numberOfTickets,
  } = useContext(AuthContext);
  const [isFotoModalOpen, setIsFotoModalOpen] = useState(false);
  const [initView, setInitView] = useState("calendario");
  const [workspaces, setWorkspaces] = useState("no");
  const [frequency, setFrequency] = useState("diario");

  const openFotoModal = () => {
    setIsFotoModalOpen(true);
  };

  const closeFotoModal = () => {
    setIsFotoModalOpen(false);
  };

  const handleSave = async () => {
    try {
      console.log();
      await updateParameters(
        currentUser.email,
        initView,
        workspaces,
        frequency
      ); // Usar currentUser.uid en lugar de currentUser.id
      alert("Parámetros guardados con éxito");
    } catch (error) {
      console.error("Error al guardar los parámetros: ", error);
      alert("Hubo un error al guardar los parámetros");
    }
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Mi Perfil</h2>
          </div>
        </div>
        <Tabs defaultActiveKey="personal" id="uncontrolled-tab-example">
          <Tab eventKey="personal" title="Personal">
            <div className="containerFicha">
              <Row className="row-cols-1 row-cols-md-2">
                <div className="columnaFicha">
                  <img
                    src={currentUser.photoURL}
                    alt="Foto de perfil"
                    className="imgFicha"
                    onClick={() => {
                      openFotoModal();
                    }}
                  />
                </div>

                <div className="columnaFicha">
                  <Table className="tableFicha">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Nombre:</strong>{" "}
                        </td>
                        <td>{currentUser.displayName}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email:</strong>
                        </td>
                        <td>{currentUser.email}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Teléfono:</strong>
                        </td>
                        <td>+56 xxxxxxx</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Privilegios:</strong>
                        </td>
                        <td>{userType}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Plan:</strong>
                        </td>
                        <td>Premium</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Tipo de usuario:</strong>
                        </td>
                        <td>{category ? category : "Administrador"}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Status:</strong>
                        </td>
                        <td>{status}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Contraseña:</strong>
                        </td>
                        <td>
                          <button className="standardButton">Cambiar</button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="empresa" title="Proyecto">
            <div className="containerFicha2">
              <Row className="row-cols-1 row-cols-md-2">
                <div className="columnaFicha">
                  <img
                    src={businessPhoto}
                    alt="Foto de perfil"
                    className="imgFicha"
                  />
                </div>

                <div className="columnaFicha">
                  <Table className="tableFicha">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Nombre:</strong>
                        </td>
                        <td>{businessName}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Administrador:</strong>
                        </td>
                        <td>{businessAdminEmail}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Descripción:</strong>
                        </td>
                        <td>{businessDescription}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Número de tareas</strong>
                        </td>
                        <td>{numberOfTickets}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Plan:</strong>
                        </td>
                        <td>Gratis</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="parámetros" title="Parámetros">
            <div className="containerFicha2">
              <Row className="row-cols-1 row-cols-md-2">
                <Col>
                  <Form className="parametrosForm">
                    <Form.Group controlId="initView" className="mb-4">
                      <Form.Label className="parametrosLabel">
                        Vista inicial predeterminada
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={initView}
                        onChange={(e) => setInitView(e.target.value)}
                        className="parametrosSelect"
                      >
                        <option value="timeLine">Timeline</option>
                        <option value="kanban">Kanban</option>
                        <option value="calendario">Calendario</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="workspaces" className="mb-4">
                      <Form.Label className="parametrosLabel">
                        Workspaces
                      </Form.Label>
                      <div className="parametrosRadio">
                        <Form.Check
                          type="radio"
                          label="Sí"
                          name="workspaces"
                          value="sí"
                          checked={workspaces === "sí"}
                          onChange={(e) => setWorkspaces(e.target.value)}
                          className="parametrosRadioItem"
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          name="workspaces"
                          value="no"
                          checked={workspaces === "no"}
                          onChange={(e) => setWorkspaces(e.target.value)}
                          className="parametrosRadioItem"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="frequency" className="mb-4">
                      <Form.Label className="parametrosLabel">
                        Frecuencia de informes
                      </Form.Label>
                      <div className="parametrosRadio">
                        <Form.Check
                          type="radio"
                          label="Diario"
                          name="frequency"
                          value="diario"
                          checked={frequency === "diario"}
                          onChange={(e) => setFrequency(e.target.value)}
                          className="parametrosRadioItem"
                        />
                        <Form.Check
                          type="radio"
                          label="Semanal"
                          name="frequency"
                          value="semanal"
                          checked={frequency === "semanal"}
                          onChange={(e) => setFrequency(e.target.value)}
                          className="parametrosRadioItem"
                        />
                        <Form.Check
                          type="radio"
                          label="Mensual"
                          name="frequency"
                          value="mensual"
                          checked={frequency === "mensual"}
                          onChange={(e) => setFrequency(e.target.value)}
                          className="parametrosRadioItem"
                        />
                      </div>
                    </Form.Group>
                    <Button
                      className="standardButton parametrosButton"
                      onClick={handleSave}
                    >
                      Guardar
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          </Tab>
        </Tabs>
      </div>

        <Perfil/>
      <CambiarFotoModal
        openModalTicket={isFotoModalOpen}
        handleCloseTicket={closeFotoModal}
      />

      <Footer />
    </div>
  );
}

export default MyProfile;
