import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

Modal.setAppElement("#root");

function DeleteProject(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isProjectNameValid, setIsProjectNameValid] = useState(false);

  useEffect(() => {
    if (props.selectedBoard && props.projectName.trim() === props.selectedBoard.businessName) {
      setIsProjectNameValid(true);
    } else {
      setIsProjectNameValid(false);
    }
  }, [props.projectName, props.selectedBoard]);

  const handleConfirmDelete = async () => {
    if (props.selectedBoard && props.projectName.trim() === props.selectedBoard.businessName) {
      setIsLoading(true);
      setErrorMessage("");
      try {
        await props.handleDeleteBoard();
        setIsLoading(false);
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          props.handleCloseModal();
        }, 2000);
      } catch (error) {
        console.error("Error al eliminar el proyecto: ", error);
        setIsLoading(false);
      }
    } else {
      setErrorMessage("El nombre del proyecto no coincide");
    }
  };

  return (
    <Modal
      overlayClassName={"popupBackground"}
      className={"popupContainer"}
      isOpen={props.isModalOpen}
      onRequestClose={props.handleCloseModal}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Confirmar eliminación</h2>
      </div>

      <div className="popupContent">
        {isLoading ? (
          <div className="detalleTarea__loading">
            <FontAwesomeIcon icon={faSpinner} spin className="icon" />
            <span>Cargando...</span>
          </div>
        ) : showConfirmation ? (
          <div className="detalleTarea__confirmation">
            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
            <span>Proyecto eliminado</span>
          </div>
        ) : (
          <>
            <p>¿Estás seguro de que deseas eliminar el siguiente proyecto?</p>
            <p>
              <strong>
                {props.selectedBoard ? props.selectedBoard.businessName : ""}
              </strong>
            </p>
            <p>Para confirmar, escribe el nombre del proyecto a continuación:</p>
            <div className="inputContainer1">
              <input
                type="text"
                value={props.projectName}
                className="inputBusqueda2"
                onChange={(e) => props.setProjectName(e.target.value)}
              />
              {props.projectName && (
                <FontAwesomeIcon
                  icon={isProjectNameValid ? faCheckCircle : faTimesCircle}
                  className={`validationIcon ${isProjectNameValid ? "valid" : "invalid"}`}
                />
              )}
            </div>
            {errorMessage && (
              <div className="errorMessage">
                {errorMessage}
              </div>
            )}
          </>
        )}
      </div>

      {!isLoading && !showConfirmation && (
        <div className="popupActions">
          <div className="row" style={{ margin: "10px", padding: "10px" }}>
            <button className="popupButton" onClick={handleConfirmDelete}>
              Aceptar
            </button>
            <button
              className="popupButton"
              style={{ marginLeft: "10px" }}
              onClick={props.handleCloseModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default DeleteProject;
