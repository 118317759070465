import { collection, query, where, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase"; // Asegúrate de tener configurada la conexión a Firebase

export const getPendingTasks = (businessEmail, setTasks, setError) => {
  const q = query(
    collection(db, "business", businessEmail, "tickets"),
    where("status", "==", "Pendiente")
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const tasks = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTasks(tasks);
  }, (error) => {
    setError(error);
  });

  return unsubscribe;
};

export const updateTaskStatus = async (businessEmail, taskId, updatedData) => {
  try {
    const taskRef = doc(db, "business", businessEmail, "tickets", taskId);
    await updateDoc(taskRef, updatedData);
    console.log("Estado de la tarea actualizado correctamente");
  } catch (error) {
    console.error("Error actualizando el estado de la tarea: ", error);
    throw new Error(error.message);
  }
};