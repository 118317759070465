import React, { useState, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./TextBox.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons';

const ItemType = "ITEM";

function TextBox({ item, index, setItems, items, initialSize = { width: 200, height: 100 } }) {
  const [text, setText] = useState(item.content || '');
  const [isEditing, setIsEditing] = useState(false);
  const [size, setSize] = useState({
    width: item.width || initialSize.width,
    height: item.height || initialSize.height
  });
  const [color, setColor] = useState(item.color || "#ffffff");
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const textRef = useRef(null);
  const ref = useRef(null);

  const colors = [
    "#ffffff", "#E0BBE4", "#D291BC", "#FEC8D8", "#FFDFD3", "#C4DFE6",
    "#FFB6C1", "#FF7F50", "#87CEFA", "#98FB98", "#FFD700", "#CD5C5C"
  ];

  const [{ isDragging: dragState }, drag] = useDrag({
    type: ItemType,
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    options: {
      begin: () => setIsDragging(true),
      end: () => setIsDragging(false),
    }
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        const newItems = Array.from(items);
        const [movedItem] = newItems.splice(draggedItem.index, 1);
        newItems.splice(index, 0, movedItem);
        setItems(newItems);
        draggedItem.index = index;
      }
    },
    drop: (draggedItem, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const newItems = Array.from(items);
      const movedItem = newItems.find((i) => i.id === draggedItem.id);
      if (movedItem) {
        movedItem.position = {
          x: Math.round(item.position.x + delta.x),
          y: Math.round(item.position.y + delta.y),
        };
        setItems(newItems);
      }
    },
  });

  drag(drop(ref));

  const handleChange = (event) => {
    setText(event.target.value);
    adjustHeight(event.target);
  };

  const handleBlur = () => {
    setIsEditing(false);
    const newItems = Array.from(items);
    const currentItem = newItems.find((i) => i.id === item.id);
    if (currentItem) {
      currentItem.content = text;
      currentItem.width = size.width;
      currentItem.height = size.height;
      currentItem.color = color;
      setItems(newItems);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleBlur(); // Sale de edición solo cuando se presiona "Escape"
    }
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleColorSelect = (selectedColor, event) => {
    event.preventDefault();
    event.stopPropagation();
    setColor(selectedColor);
    const newItems = Array.from(items);
    const currentItem = newItems.find((i) => i.id === item.id);
    if (currentItem) {
      currentItem.color = selectedColor;
      setItems(newItems);
    }
    setShowColorPalette(false); // Ocultar la paleta de colores después de seleccionar un color
  };

  useEffect(() => {
    if (isEditing && textRef.current) {
      textRef.current.focus();
    }
  }, [isEditing]);

  const adjustHeight = (element) => {
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
    setSize((prevSize) => ({
      ...prevSize,
      height: element.scrollHeight
    }));
  };

  useEffect(() => {
    if (textRef.current) {
      adjustHeight(textRef.current);
    }
  }, [text]);

  const handleIconClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowColorPalette(!showColorPalette);
  };

  return (
    <div
      ref={ref}
      className="element-container"
      style={{
        left: item.position.x,
        top: item.position.y,
        width: size.width,
        height: size.height,
        position: "absolute",
      }}
    >
      <div
        className="element"
        style={{
          opacity: dragState ? 0.5 : 1,
          width: "100%",
          height: "100%",
          position: "relative",
          resize: "both",
          overflow: "hidden",
          backgroundColor: color,
        }}
        onClick={handleClick}
      >
        {isEditing ? (
          <textarea
            ref={textRef}
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            style={{
              width: "100%",
              height: "100%",
              resize: "none",
              overflow: "hidden",
            }}
          />
        ) : (
          <label
            ref={textRef}
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              padding: "8px",
              boxSizing: "border-box"
            }}
          >
            {text}
          </label>
        )}
        <div
          className="paint-icon"
          onClick={handleIconClick}
        >
          <FontAwesomeIcon icon={faPaintBrush} />
        </div>
      </div>

      {showColorPalette && (
        <div className="colorPalettePopup">
          <div className="colorPaletteContainer">
            {colors.map((colorOption, index) => (
              <div
                key={index}
                className="colorOption"
                style={{ backgroundColor: colorOption }}
                onClick={(event) => handleColorSelect(colorOption, event)}
              ></div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default TextBox;
