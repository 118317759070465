import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Boards from "./Boards";
import { AuthContext } from "../../context/AuthContext";
import { getBoards, getBusinessDetails, deleteBoard } from "../Functions";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner"; // Asegúrate de importar tu componente Spinner

function SelectBusiness() {
  const { currentUser } = useContext(AuthContext);
  const [boards, setBoards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBoardData = async () => {
      setLoading(true);
      try {
        const boardsData = await getBoards(currentUser.email);
        setBoards(boardsData);

        // Obtener los IDs de los negocios
        const businessIds = boardsData.map((board) => board.id);

        // Obtener detalles de los negocios en lote, utilizando caché
        const boardsWithDetails = await getBusinessDetails(businessIds);

        // Combinar los detalles con los datos de los tableros
        const combinedBoards = boardsData.map((board) => ({
          ...board,
          ...boardsWithDetails.find((detail) => detail.id === board.id),
        }));

        setBoards(combinedBoards);
      } catch (error) {
        console.error("Error fetching boards data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBoardData();
  }, [currentUser.email]);

  const handleDeleteBoard = async (boardId) => {
    try {
      await deleteBoard(boardId);
      const updatedBoards = boards.filter((board) => board.id !== boardId);
      setBoards(updatedBoards);
    } catch (error) {
      console.error("Error al eliminar el tablero:", error);
    }
  };

  const filteredBoards = boards.filter((board) =>
    board.businessName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="selectBusinessContainer">
      <Navbar
        name={currentUser.displayName}
        photo={currentUser.photoURL}
        caption="NavBar"
      />
      <div className="selectBusinessContent">
        <div className="center">
          <div className="inputIconContainer">
            <div className="iconWrapperBlue"></div>
            <input
              type="text"
              placeholder="Buscar Proyecto"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="inputBusqueda2"
            />
          </div>
        </div>
        <h3 className="businessName">Selecciona un proyecto</h3>
        {loading || isGlobalLoading ? (
          <div className="center">
            <Spinner /> {/* Usar el componente Spinner aquí */}
          </div>
        ) : (
          <Boards
            boards={filteredBoards}
            onDeleteBoard={handleDeleteBoard}
            setBoards={setBoards}
            loading={isGlobalLoading}
            setIsGlobalLoading={setIsGlobalLoading}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default SelectBusiness;
