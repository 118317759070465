import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Modal from 'react-modal';
import './PopUp.css';
import moment from 'moment-timezone';
import { RefreshCcw } from 'lucide-react';
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { updateGoogleCalendarEvent } from '../calendario/FunctionsCalendar';

const ReprogFromPlani = ({ isOpen, onClose, detalle, businessEmail, fecha, onTaskUpdated }) => {
  const { currentUser, accessToken, refreshToken, tokenExpiry } = useContext(AuthContext);
  const [titulo, setTitulo] = useState('');
  const [horaInicio, setHoraInicio] = useState('');
  const [horaTermino, setHoraTermino] = useState('');
  const [fechaVencimiento, setFechaVencimiento] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingTasks, setLoadingTasks] = useState(false);

  useEffect(() => {
    if (detalle) {
      setTitulo(detalle.evento || '');
      setHoraInicio(detalle.horaSugerida || '');
      // Calcular hora término (1 hora después de la hora sugerida)
      const horaTerminoCalculada = detalle.horaSugerida ? 
        moment(detalle.horaSugerida, 'HH:mm').add(1, 'hour').format('HH:mm') : '';
      setHoraTermino(horaTerminoCalculada);
      setFechaVencimiento(moment(fecha).format('YYYY-MM-DD'));
    }
  }, [detalle, fecha]);

  const handleUpdateTask = async () => {
    if (!horaInicio || !horaTermino) {
      setErrorMessage('Por favor complete los horarios');
      return;
    }

    setLoadingTasks(true);
    setSuccessMessage('Actualizando tarea');
    setErrorMessage('');

    try {
      // Actualizar en Firestore
      const taskRef = doc(db, "business", businessEmail, "tickets", detalle.id);
      await updateDoc(taskRef, {
        horaInicio,
        horaTermino,
        lastUpdated: moment().tz("America/Santiago").format('DD-MM-YYYY HH:mm:ss')
      });

      // Actualizar en Google Calendar
      const fechaInicioISO = moment.tz(`${fechaVencimiento} ${horaInicio}`, "YYYY-MM-DD HH:mm", "America/Santiago").toISOString();
      const fechaTerminoISO = moment.tz(`${fechaVencimiento} ${horaTermino}`, "YYYY-MM-DD HH:mm", "America/Santiago").toISOString();

      await updateGoogleCalendarEvent(
        businessEmail,
        detalle.id,
        titulo,
        detalle.detalle || titulo,
        fechaInicioISO,
        fechaTerminoISO,
        horaInicio,
        horaTermino,
        detalle.color || '#000000',
        'primary',
        currentUser,
        accessToken,
        refreshToken,
        tokenExpiry
      );

      setLoadingTasks(false);
      console.log('Actualizando tarea:', detalle.id);
      if (onTaskUpdated) onTaskUpdated(detalle.id, horaInicio, horaTermino);
      onClose();

    } catch (error) {
      console.error('Error al actualizar la tarea:', error);
      setErrorMessage('Error al actualizar la tarea');
      setLoadingTasks(false);
    }
  };

  return (
    <Modal
      overlayClassName="popupBackground"
      className="popupContainer"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Reprogramar Tarea</h2>
      </div>

      <div className="popupContent">
        <div className="popupField">
          <label className="popupLabel">Título</label>
          <input
            type="text"
            value={titulo}
            disabled
            className="popupInput"
          />
        </div>

        <div className="popupField">
          <label className="popupLabel">Fecha</label>
          <input
            type="date"
            value={fechaVencimiento}
            disabled
            className="popupInput"
          />
        </div>

        <div className="popupField">
          <label className="popupLabel">Hora de Inicio</label>
          <input
            type="time"
            value={horaInicio}
            onChange={(e) => setHoraInicio(e.target.value)}
            className="popupInput"
          />
        </div>

        <div className="popupField">
          <label className="popupLabel">Hora de Término</label>
          <input
            type="time"
            value={horaTermino}
            onChange={(e) => setHoraTermino(e.target.value)}
            className="popupInput"
          />
        </div>

        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}

        {successMessage && (
          <div className="success-message">
            {successMessage}
            {loadingTasks && (
              <div className="loadingSpinner">
                <RefreshCcw size={24} className="spinner" />
              </div>
            )}
          </div>
        )}

        <div className="center" style={{display:"flex", flexDirection:"row", margin:"auto", padding:"10px", alignContent:"center"}}>
          <button 
            className="popupButton" 
            style={{marginRight:"10px"}} 
            onClick={handleUpdateTask} 
            disabled={loadingTasks}
          >
            Reprogramar
          </button>
          <button className="popupButton" onClick={onClose}>
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReprogFromPlani; 