import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Boards.css";
import { AuthContext } from "../../context/AuthContext";
import tinycolor from "tinycolor2";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteBoard } from "../Functions";
import EditBusiness from "./EditBusiness";
import InfoModal from "../ModalInformativo/InfoModal";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import DeleteProject from "./DeleteProject";
import Spinner from "./Spinner";
import ImageBox from "./ImageBox"; // Importa el nuevo componente
import subscribeToCalendar from "../subscribeToCalendar";

Modal.setAppElement("#root");

function Boards({ boards, onDeleteBoard, setBoards, loading, setIsGlobalLoading }) {
  const navigate = useNavigate();
  const { updateBusinessContext } = useContext(AuthContext);
  const [hoveredBoard, setHoveredBoard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const handleBoardClick = async (board) => {
    setIsGlobalLoading(true);
    sessionStorage.setItem("businessId", board.id);
    sessionStorage.setItem("userType", board.userType);

    let accessToken = sessionStorage.getItem("accessToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    const userId = sessionStorage.getItem("userId");
    const email = sessionStorage.getItem("email");

    const channelId = sessionStorage.getItem("channelId");
    if (channelId) {
      try {
        const docRef = doc(db, 'googleCalendarChannels', channelId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(doc(db, 'googleCalendarChannels', channelId), {
            idBusiness: board.id,
          });
        } else {
          console.error("Documento no encontrado:", channelId);
          const result = await subscribeToCalendar(accessToken, refreshToken, userId, email);
          if (!result.success) {
            throw new Error(result.error);
          }
        }
      } catch (error) {
        console.error("Error al actualizar idBusiness:", error);
        alert("Error al actualizar la sincronización. Por favor, inténtalo de nuevo más tarde.");
      }
    } else {
      console.error("Channel ID no encontrado en sessionStorage");
      const result = await subscribeToCalendar(accessToken, refreshToken, userId, email);
      if (!result.success) {
        alert("Error al suscribirse al calendario. Por favor, inténtalo de nuevo más tarde.");
        console.error(result.error);
      }
    }

    await updateBusinessContext(board.id, board.userType);
    navigate("/selectArea");
    setIsGlobalLoading(false);
  };

  const handleBoardMouseEnter = (board) => {
    setHoveredBoard(board);
  };

  const handleBoardMouseLeave = () => {
    setHoveredBoard(null);
  };

  const handleMenuItemClick = (item, board) => {
    if (item === "edit") {
      setSelectedBoard(board);
      setIsEditModalOpen(true);
    }
  };

  const handleDeleteBoard = async () => {
    if (selectedBoard) {
      if (projectName === selectedBoard.businessName) {
        setIsDeleting(true);
        try {
          await deleteBoard(selectedBoard.id);
          const updatedBoards = boards.filter(
            (board) => board.id !== selectedBoard.id
          );
          setBoards(updatedBoards);
          handleOpenMessageModal("Proyecto eliminado");
          setIsModalOpen(false);
          setProjectName("");
          setSelectedBoard(null);
        } catch (error) {
          console.error("Error al eliminar el tablero:", error);
        } finally {
          setIsDeleting(false);
        }
      } else {
        handleOpenMessageModal("El nombre del proyecto no coincide");
      }
    }
  };

  const handleOpenModal = (board) => {
    setSelectedBoard(board);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setProjectName("");
    setSelectedBoard(null);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedBoard(null);
  };

  const handleUpdateBoard = async (updatedBoard) => {
    try {
      const updatedBoards = boards.map((board) =>
        board.id === updatedBoard.id ? updatedBoard : board
      );
      setBoards(updatedBoards);
      handleOpenMessageModal("Proyecto actualizado");
    } catch (error) {
      console.error("Error al actualizar el tablero:", error);
    }
  };

  const handleNavigate = () => {
    navigate("/RegisterBusiness");
  };

  const handleOpenMessageModal = (message) => {
    setMessageModalContent(message);
    setIsMessageModalOpen(true);
  };

  const handleCloseMessageModal = () => {
    setIsMessageModalOpen(false);
    setMessageModalContent("");
  };

  const getContrastColor = (color) => {
    const tinyColor = tinycolor(color);
    return tinyColor.isDark() ? "white" : "black";
  };

  return (
    <div className="boardsContainer">
      {loading ? (
        <div className="spinner-container_">
          <Spinner />
        </div>
      ) : (
        boards.map((board) => (
          <div
            key={board.id}
            className="board_"
            style={{
              backgroundColor: board.color,
              color: getContrastColor(board.color),
              boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => handleBoardClick(board)}
            onMouseEnter={() => handleBoardMouseEnter(board)}
            onMouseLeave={handleBoardMouseLeave}
          >
            <div className="board-content_">
              <div
                className="board-icon-wrapper_"
                style={{
                  backgroundColor: tinycolor(board.color)
                    .lighten(25)
                    .setAlpha(0.46)
                    .toRgbString(),
                }}
              >
                <ImageBox src={board.img} /> {/* Usa el nuevo componente aquí */}
              </div>
              <div className="board-text_">
                <div className="board-title_">{board.businessName}</div>
                <div className="board-description_">{board.description}</div>
                <div className="userType_">{board.userType}</div>
              </div>
            </div>
            <div
              className="adminText_"
              style={{
                backgroundColor: tinycolor(board.color)
                  .lighten(25)
                  .setAlpha(0.46)
                  .toRgbString(),
              }}
            >
              <span className="left">Pendientes: {board.pendingTickets}</span>
              <span className="right">En Proceso: {board.processTickets}</span>
            </div>
            {hoveredBoard === board && board.userType === "admin" && (
              <div className="dropdown-menu-board_">
                <div
                  onClick={(e) => {
                    handleMenuItemClick("edit", board);
                    e.stopPropagation();
                  }}
                  className="dropdown-item2_"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenModal(board);
                  }}
                  className="dropdown-item2_"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </div>
            )}
          </div>
        ))
      )}

      <div className="board_ addNewBoard_" onClick={handleNavigate}>
        <div className="row">
          <div className="addBoardPlus_">+</div>
          <span className="addBoardText_">Nuevo proyecto</span>
        </div>
      </div>

      <DeleteProject
        isModalOpen={isModalOpen}
        projectName={projectName}
        setProjectName={setProjectName}
        selectedBoard={selectedBoard}
        isDeleting={isDeleting}
        handleDeleteBoard={handleDeleteBoard}
        handleCloseModal={handleCloseModal}
      />

      <EditBusiness
        board={selectedBoard}
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        onUpdate={handleUpdateBoard}
      />

      <InfoModal
        isOpen={isMessageModalOpen}
        message={messageModalContent}
        onClose={handleCloseMessageModal}
      />
    </div>
  );
}

export default Boards;
