import React from 'react';
import './TooltipPlanning.css';

const TooltipPlanning = ({ text }) => {
  return (
    <div className="tooltipPlanning-container">
      <span className="tooltipPlanning-icon">?</span>
      <span className="tooltipPlanning-text">{text}</span>
    </div>
  );
};

export default TooltipPlanning;
