import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "react-modal";
import { db } from "./firebase";
import { doc, setDoc } from "firebase/firestore";
import "./addAreasModal.css";

const colors = [
  '#7986CB', '#66C18C', '#B084C8', '#E67C73', '#F6BF26',
  '#F4511E', '#67C3EB', '#616161', '#3F51B5', '#4CAF50', '#EF5350'
];

const icons = [
  '😊', '🏡', '👨‍👩‍👧‍👦', '👶', '💼', '🎯', '📈', '💡', '🏋️‍♂️', '🧘‍♂️',
  '🍻', '❤️', '🤝', '🎉', '🎵', '✈️', '🍳', '📚', '🚗', '🐶', '🚀', '👨‍💻', '🎒',
  '⛪', '🎨', '🌱', '💰', '🏥', '🍕', '👥', '🎓', '🌍', '🥑'
];

const AddAreasModal = forwardRef((props, ref) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [input, setInput] = useState("");
  const [description, setDescription] = useState("");
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [errors, setErrors] = useState({});
  const [isFocused, setIsFocused] = useState({
    area: false,
    description: false
  });

  const idBusiness = sessionStorage.getItem("businessId");

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setError("");
    setValidationError("");
    setErrors({});
    setInput('');
    setDescription('');
    setSelectedColor('');
    setSelectedIcon('');
  };

  useImperativeHandle(ref, () => ({
    handleOpen: handleOpenModal,
  }));

  // Función de sanitización que remueve caracteres no permitidos
  const sanitizeInput = (input) => {
    const sanitizedInput = input.replace(/[\/<>\\;'"$`{}]/g, ''); // Remueve caracteres peligrosos
    return sanitizedInput.trim();
  };

  // Validación del nombre del área
  const validateAreaName = (name) => {
    const sanitizedInput = sanitizeInput(name);
    return sanitizedInput.length > 0 && sanitizedInput.length <= 200;
  };

  // Validación de la descripción
  const validateDescription = (description) => {
    const sanitizedDescription = sanitizeInput(description);
    return sanitizedDescription.length > 0 && sanitizedDescription.length <= 200;
  };

  const validateInputs = () => {
    const newErrors = {};
    
    if (!validateAreaName(input)) {
      newErrors.name = "Debe escribir un nombre válido (entre 1 y 200 caracteres).";
    }
    
    if (!validateDescription(description)) {
      newErrors.description = "Debe escribir una descripción válida (entre 1 y 200 caracteres).";
    }
    
    if (!selectedColor) {
      newErrors.color = "Debe seleccionar un color.";
    }
    
    if (!selectedIcon) {
      newErrors.icon = "Debe seleccionar un ícono.";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = async () => {
    if (!validateInputs()) {
      setValidationError(
        "Debes ingresar un nombre válido, una descripción válida, seleccionar un color y un ícono para poder avanzar."
      );
      return;
    }

    setLoading(true);
    try {
      await setDoc(doc(db, "business", idBusiness, "areas", sanitizeInput(input)), {
        name: sanitizeInput(input),
        icon: selectedIcon,
        color: selectedColor,
        description: sanitizeInput(description),
      });
      setLoading(false);
      handleCloseModal();
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError("Ocurrió un error al guardar el área. Inténtalo de nuevo.");
    }
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 200) {
      setDescription(value);
      setErrors((prev) => ({ ...prev, description: '' }));
    }
  };

  return (
    <div>
      <Modal
        overlayClassName={"modalAreas-popupBackground"}
        className={"modalAreas-popupContainer"}
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
      >
        <div className="modalAreas-popupHeader">
          <h2 className="modalAreas-popupTitle">Agregar área de vida</h2>
        </div>
        <div className="modalAreas-popupContent">
          <div className="modalAreas-popupInputContainer">
            <label className="modalAreas-popupInputLabel">
              Nombre del área
            </label>
            <input
              className={`modalAreas-popupInput ${errors.name ? 'modalAreas-popupInputError' : ''}`}
              value={input}
              placeholder="Ej: Desarrollo Personal"
              onChange={(e) => {
                setInput(e.target.value);
                setErrors((prev) => ({ ...prev, name: '' }));
                setValidationError("");
              }}
              onFocus={() => setIsFocused(prev => ({...prev, area: true}))}
              onBlur={() => setIsFocused(prev => ({...prev, area: false}))}
              maxLength={200}
            />
            {errors.name && <span className="modalAreas-popupInputHelperText">{errors.name}</span>}
            <span className="modalAreas-characterCount">{input.length}/200</span>
          </div>
          
          <div className="modalAreas-popupInputContainer">
            <label className="modalAreas-popupInputLabel">
              Descripción del área
            </label>
            <textarea
              className={`modalAreas-popupInput ${errors.description ? 'modalAreas-popupInputError' : ''}`}
              value={description}
              placeholder="Describe el propósito y objetivos de esta área en tu vida..."
              onChange={handleDescriptionChange}
              onFocus={() => setIsFocused(prev => ({...prev, description: true}))}
              onBlur={() => setIsFocused(prev => ({...prev, description: false}))}
              rows={3}
              maxLength={200}
            />
            {errors.description && <span className="modalAreas-popupInputHelperText">{errors.description}</span>}
            <span className="modalAreas-characterCount">{description.length}/200</span>
          </div>

          <div className="modalAreas-customizeContainer">
            <div className="modalAreas-colorSelection">
              <h3 style={{fontSize:"18px", fontWeight:"400", marginBottom:"10px"}}>Selecciona un color:</h3>
              <div className="modalAreas-colorOptions">
                {colors.map((color, index) => (
                  <div
                    key={index}
                    className={`modalAreas-colorCircle ${selectedColor === color ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      setSelectedColor(color);
                      setErrors((prev) => ({ ...prev, color: '' }));
                      setValidationError("");
                    }}
                  />
                ))}
              </div>
              {errors.color && <span className="modalAreas-popupInputHelperText">{errors.color}</span>}
            </div>

            <div className="modalAreas-iconSelection">
              <h3 style={{fontSize:"18px", fontWeight:"400", marginBottom:"10px"}}>Selecciona un ícono:</h3>
              <div className="modalAreas-iconOptions">
                {icons.map((icon, index) => (
                  <span
                    key={index}
                    className={`modalAreas-iconItem ${selectedIcon === icon ? 'selected' : ''}`}
                    onClick={() => {
                      setSelectedIcon(icon);
                      setErrors((prev) => ({ ...prev, icon: '' }));
                      setValidationError("");
                    }}
                  >
                    {icon}
                  </span>
                ))}
              </div>
              {errors.icon && <span className="modalAreas-popupInputHelperText">{errors.icon}</span>}
            </div>
          </div>

          {loading && (
            <div className="modalAreas-centerSpinner">
              <div className="modalAreas-spinner"></div>
              <p>Cargando...</p>
            </div>
          )}
        </div>
        <div className="modalAreas-center" style={{flexDirection:"row", display:"flex", marginTop:"10px"}}>
          <button className="modalAreas-popupButton" onClick={handleClick}>
            Aceptar
          </button>
          <button className="modalAreas-popupButton" style={{marginLeft:"20px"}} onClick={handleCloseModal}>
            Cancelar
          </button>
        </div>
      </Modal>
    </div>
  );
});

export default AddAreasModal;