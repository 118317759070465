import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { toast } from 'react-toastify';

const DeleteArea = ({ isOpen, onClose, idBusiness, area, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmDelete = async () => {
    setIsLoading(true);

    try {
      // Elimina el área de la base de datos
      await deleteDoc(doc(db, "business", idBusiness, "areas", area));
      setIsLoading(false);
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
        onDelete();
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error al eliminar el área: ", error);
      setIsLoading(false);
      toast.error(`Error al eliminar el área: ${error.message}`);
    }
  };

  return (
    <Modal
      overlayClassName={"popupBackground"}
      className={"popupContainer"}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Eliminar Área</h2>
      </div>

      <div className="popupContent">
        {isLoading ? (
          <div className="detalleTarea__loading">
            <FontAwesomeIcon icon={faSpinner} spin className="icon" />
            <span>Cargando...</span>
          </div>
        ) : showConfirmation ? (
          <div className="detalleTarea__confirmation">
            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
            <span>Área borrada</span>
          </div>
        ) : (
          <p>
            ¿Seguro que desea borrar el área? (esta acción no se puede deshacer)
          </p>
        )}
      </div>

      {!isLoading && !showConfirmation && (
        <div className="popupActions">
          <div className="row" style={{ margin: "10px", padding: "10px" }}>
            <button className="popupButton" onClick={handleConfirmDelete}>
              Aceptar
            </button>
            <button
              className="popupButton"
              style={{ marginLeft: "10px" }}
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DeleteArea;
