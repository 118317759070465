import React, { useState, useContext } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";
import { db, storage } from "./firebase";
import { doc, setDoc, addDoc, collection, updateDoc, arrayUnion } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { sendMail } from "./SendMail";
import { getObjectiveProject } from "./IA/Gpt";

const DEFAULT_IMAGE_URL = "https://firebasestorage.googleapis.com/v0/b/planiusai.appspot.com/o/business%2Fbusiness.png?alt=media&token=9aea5960-4d59-47b6-b522-b77f369e9f0d";

export default function BtnCargando(props) {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [subido, setSubido] = useState(false);

  const date = new Date().getTime();
  const storageRef = ref(storage, `${props.name + date}`);

  async function handleClick(e) {
    setLoading(true);
    const file = props.file;
  
    if (file instanceof File) {
      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          await createBusinessDocument(downloadURL);
        });
      }).catch(async () => {
        await createBusinessDocument(DEFAULT_IMAGE_URL);
      });
    } else {
      await createBusinessDocument(DEFAULT_IMAGE_URL);
    }
  }

  async function createBusinessDocument(downloadURL) {
    try {
      const objectiveDescription = await getObjectiveProject(props.description, props.objetivo, props.attributes);

      const businessDocRef = await addDoc(collection(db, "business"), {
        businessName: props.name,
        description: props.description,
        photoBusiness: downloadURL,
        admin: currentUser.email,
        color: props.color,
        img: downloadURL,
        objetivo: objectiveDescription,
      });

      const businessId = businessDocRef.id;
      sessionStorage.setItem("businessId", businessDocRef.id);
      sessionStorage.setItem("nameBusiness", props.name);

      await setDoc(doc(db, "business", businessId, "clients", "General"), {
        email: "General",
        name: "General",
        userType: "cliente",
        status: "activo",
      });

      await setDoc(doc(db, "business", businessId, "agents", currentUser.email), {
        email: currentUser.email,
        userType: "admin",
        name: `${currentUser.displayName} (Yo)`,
      }, { merge: true });

      setLoading(false);
      setSubido(true);

      // Llamar a onSave para notificar que el proyecto se ha guardado
      if (props.onSave) {
        props.onSave();
      }

      const newUserBusiness = {
        id: businessDocRef.id,
        userType: "admin",
        description: props.description,
        businessName: props.name,
        color: props.color,
        img: downloadURL,
        objetivo: objectiveDescription,
      };

      await updateDoc(doc(db, "users", currentUser.email), {
        business: arrayUnion(newUserBusiness),
      });
    } catch (err) {
      console.error("Error creating document: ", err);
    }
  }

  return (
    <Box>
      <Box sx={{ "& > button": { m: 1 } }}>
        {subido === false && (
          <LoadingButton
            size="small"
            onClick={handleClick}
            style={{ backgroundColor: "#240f5e", color: "white", borderRadius: "10px", fontSize: "1rem", padding:"10px", minWidth:"200px" }}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Guardar</span>
          </LoadingButton>
        )}

        {subido === true && (
          <h2 className="projectOk">¡Proyecto Creado!</h2>
        )}
      </Box>
    </Box>
  );
}
