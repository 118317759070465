import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";

function RbRewards() {
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();

  const handleStartClick = () => {
    setShowConfetti(true);
    setTimeout(() => {
      navigate("/selectBusiness");
    }, 2500);
  };

  return (
    <div>
      <div className="bigCard">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">¡Listo!🎉</h1>
            </div>
            <p>¡Hemos terminado de configurar tu proyecto!.</p>
            <button className="btnAI" onClick={handleStartClick}>
              Empezar
            </button>
          </div>
          <hr />
        </div>
      </div>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={300}
          recycle={false}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
    </div>
  );
}

export default RbRewards;