import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { getValidAccessToken } from '../Functions';
import { obtenerCalendariosDeUsuario, sincronizarDesdeGoogleCalendar } from '../calendario/FunctionsCalendar';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './SyncCalendar.css';


const SyncCalendar = ({ nextStep, prevStep }) => {
  const { currentUser, businessEmail, accessToken, refreshToken, tokenExpiry } = useContext(AuthContext);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSynced, setIsSynced] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [showCalendarSelection, setShowCalendarSelection] = useState(true);
  const [calendarios, setCalendarios] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [isLoadingCalendars, setIsLoadingCalendars] = useState(true);
  const [calendarColors, setCalendarColors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const esIdValido = (calendarId) => {
    return !calendarId.includes("weather") && !calendarId.includes("holiday") && calendarId.length > 0;
  };

  useEffect(() => {
    const fetchCalendars = async () => {
      try {
        const calendars = await obtenerCalendariosDeUsuario(currentUser, accessToken, refreshToken, tokenExpiry);
        
        const validCalendars = calendars.filter(calendar => esIdValido(calendar.id));

        setCalendarios(validCalendars);

        const initialColors = {};
        validCalendars.forEach(calendar => {
          initialColors[calendar.id] = '#4715d1';
        });
        setCalendarColors(initialColors);

        if (!selectedCalendars.includes(currentUser.email)) {
          setSelectedCalendars(prevSelected => [...prevSelected, currentUser.email]);
        }
      } catch (error) {
        console.error("Error al obtener los calendarios:", error);
        setCurrentMessage("Error al cargar los calendarios. Intenta nuevamente.");
      } finally {
        setIsLoadingCalendars(false);
      }
    };

    fetchCalendars();
  }, [currentUser]);

  const handleCheckboxChange = (calendarId) => {
    if (calendarId === currentUser.email) {
      return; 
    }

    setSelectedCalendars(prevSelected =>
      prevSelected.includes(calendarId)
        ? prevSelected.filter(id => id !== calendarId)
        : [...prevSelected, calendarId]
    );
  };

  const handleColorChange = (calendarId, color) => {
    setCalendarColors(prevColors => ({
      ...prevColors,
      [calendarId]: color
    }));
  };

  const handleCalendarsIds = async () => {
    try {
      const userRef = doc(db, 'users', currentUser.email);
      await updateDoc(userRef, {
        calendariosSeleccionados: selectedCalendars,
        calendarColors: calendarColors,
      });
      console.log('Calendarios y colores guardados:', selectedCalendars, calendarColors);
    } catch (error) {
      console.error('Error al actualizar los calendarios del usuario:', error);
      alert('Hubo un error al guardar los calendarios. Por favor, intenta de nuevo.');
    }
  };

  const handleSyncGoogleCalendar = async () => {
    setIsSyncing(true);
    setCurrentMessage("Guardando selección de calendarios...");
    await handleCalendarsIds();

    const messages = [
      "Espiando en el pasado🕵️",
      "Inspirándonos con tu presente🍃",
      "Leyendo el futuro🔮"
    ];

    for (let i = 0; i < messages.length; i++) {
      setCurrentMessage(messages[i]);
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    try {
      await sincronizarDesdeGoogleCalendar(businessEmail, currentUser, accessToken, refreshToken, tokenExpiry);
      setIsSyncing(false);
      setIsSynced(true);
      setCurrentMessage(
        <span>
          Calendarios sincronizados exitosamente. ¡Bienvenido a Planius!
        </span>
      );
    } catch (error) {
      console.error("Error durante la sincronización:", error);
      setIsSyncing(false);
      setIsSynced(false);
      setCurrentMessage("Error al sincronizar. Intenta de nuevo.");
    }
  };

  // Paleta de colores predefinida (solo los valores hexadecimales)
  const predefinedColors = [
    '#7986CB', // Lavanda
    '#66C18C', // Salvia
    '#B084C8', // Uva
    '#E67C73', // Flamingo
    '#F6BF26', // Banana
    '#F4511E', // Mandarina
    '#67C3EB', // Pavo Real
    '#616161', // Grafito
    '#3F51B5', // Arándano
    '#4CAF50', // Albahaca
    '#EF5350', // Tomate
    '#8BC34A', // Verde Manzana
    '#9C27B0', // Ciruela
    '#FF7F50', // Coral
    '#00BCD4', // Turquesa
    '#FBC02D', // Mostaza
    '#CE93D8', // Malva
    '#4DD0E1', // Aguamarina
    '#FFAB91', // Melocotón
    '#5C6BC0'  // Índigo
  ];

  return (
    <div className="formStep">
      <h2>Sincronizar Calendario</h2>
      <p>Conecta tu calendario de Google para una planificación más inteligente.</p>
      {showCalendarSelection && !isSynced ? (
        isLoadingCalendars ? (
          <div className="loadingContainer">
            <div className="loadingSpinner"></div>
            <p className="loadingMessage">Cargando calendarios...</p>
          </div>
        ) : (
          <div className="calendarSelection">
            <h3>Selecciona los calendarios que deseas sincronizar</h3>
            <p className="calendar-subtitle">Personaliza el color de cada calendario para distinguir sus eventos fácilmente</p>
            {calendarios.map((calendar) => (
              <div 
                key={calendar.id} 
                className={`calendarOption ${selectedCalendars.includes(calendar.id) ? 'selected' : ''}`}
              >
                <input
                  type="checkbox"
                  id={calendar.id}
                  value={calendar.id}
                  checked={selectedCalendars.includes(calendar.id)}
                  onChange={() => handleCheckboxChange(calendar.id)}
                  disabled={calendar.id === currentUser.email}
                />
                <div className="calendar-info">
                  <label htmlFor={calendar.id}>
                    {calendar.id === currentUser.email ? "Calendario Principal (obligatorio)" : calendar.summary}
                  </label>
                  <span className="color-hint">
                    Color predeterminado para eventos sin color definido
                  </span>
                </div>
                <div className="color-picker-container">
                  <div className="selected-color" style={{ backgroundColor: calendarColors[calendar.id] || '#4715d1' }}></div>
                  <div className="color-palette">
                    {predefinedColors.map((color) => (
                      <button
                        key={color}
                        className={`color-option ${calendarColors[calendar.id] === color ? 'selected' : ''}`}
                        style={{ backgroundColor: color }}
                        onClick={() => handleColorChange(calendar.id, color)}
                        title="Seleccionar color"
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <button className="syncButton" onClick={handleSyncGoogleCalendar} disabled={selectedCalendars.length === 0 || isSyncing}>
              {isSyncing ? <div className="spinnerAcept"></div> : "Sincronizar seleccionados"}
            </button>
          </div>
        )
      ) : (
        <>
          <div className="formActions">
            <button className="formButton" onClick={prevStep} disabled={isSyncing}>Volver</button>
            <button
              className="formButton"
              onClick={handleSyncGoogleCalendar}
              disabled={isSyncing || isSynced}
            >
              {isSyncing ? <div className="spinnerAcept"></div> : (isSynced ? "Sincronizado" : "Sincronizar con Google Calendar")}
            </button>
            <button
              className="formButton"
              onClick={nextStep}
              disabled={!isSynced}
            >
              Siguiente
            </button>
          </div>
          {isSyncing && (
            <div className="loadingContainer">
              <div className="loadingSpinner"></div>
              <p className="loadingMessage">{currentMessage}</p>
            </div>
          )}
          {isSynced && !isSyncing && (
            <div className="successMessage">
              {currentMessage}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SyncCalendar;
