import React, {
  useState,
  useEffect,
  useContext,
  useCallback
} from "react";
import { useDrop } from "react-dnd";
import { AuthContext } from "../../context/AuthContext";
import { getPendingTasks } from "./TodoGetTask";
import {
  doc,
  updateDoc,
  getFirestore,
  getDoc,
  writeBatch
} from 'firebase/firestore';
import TaskItem from "./TaskItem";
import DetalleTask from "./DetalleTask";
import { eliminarSoloDeCalendar } from "../calendario/FunctionsCalendar";
import { toast } from "react-toastify";
import "./TodoTask.css";
import ResumeTareasPendientes from "../addTarea/ResumeTareasPendientes";
import tinycolor from "tinycolor2";

const TodoTask = ({ onActualizarTareasPendientes = () => {} }) => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [showMassiveTask, setShowMassiveTask] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const {
    businessEmail,
    currentUser,
    accessToken,
    refreshToken,
    tokenExpiry,
    firebaseAccesToken
  } = useContext(AuthContext);

  const [searchTerm, setSearchTerm] = useState("");

  // Para mostrar el menú contextual
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  // Para almacenar los colores de las áreas
  const [areasColors, setAreasColors] = useState({});

  // Guardar el índice "hover" mientras se arrastra (resaltar slot en azul)
  const [dragOverIndex, setDragOverIndex] = useState(null);

  // Guardar el índice "dropeado" (resaltar slot en verde por unos segundos)
  const [dropIndex, setDropIndex] = useState(null);

  // Colores predeterminados si la tarea no tiene un área definida
  const defaultColors = {
    backgroundColor: '#f8faff',
    borderColor: '#e6e7eb',
    textColor: '#4a5568'
  };

  // 1. Cargar tareas inicialmente
  useEffect(() => {
    if (businessEmail) {
      const unsubscribe = getPendingTasks(
        businessEmail,
        (newTasks) => {
          setTasks(newTasks);
          setLoading(false);
        },
        setError
      );
      return () => unsubscribe();
    }
  }, [businessEmail]);

  // 2. Filtrado por searchTerm
  const filterTasks = useCallback(() => {
    let filtered = tasks;
    if (searchTerm) {
      filtered = filtered.filter((task) =>
        task.title?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredTasks(filtered);
    onActualizarTareasPendientes(filtered);
  }, [tasks, searchTerm, onActualizarTareasPendientes]);

  useEffect(() => {
    filterTasks();
  }, [filterTasks]);

  // 3. Handlers para buscador
  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  // 4. Manejo del menú contextual
  const handleOpenMenu = useCallback((event, task) => {
    event.stopPropagation();
    setMenuPosition({ x: event.clientX, y: event.clientY });
    setSelectedTask(task);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuPosition(null);
    setSelectedTask(null);
  }, []);

  // 5. Manejo de edición (borrado/actualizado) de la tarea
  const handleTaskEdit = useCallback(
    (updatedTask) => {
      if (updatedTask === null) {
        // Se borró la tarea
        setTasks((prev) =>
          prev.filter((task) => task.id !== selectedTask.id)
        );
        setSelectedTask(null);
        setMenuPosition(null);
      } else {
        // Se actualizó la tarea
        setTasks((prev) =>
          prev.map((task) =>
            task.id === updatedTask.id ? updatedTask : task
          )
        );
      }
    },
    [selectedTask]
  );

  // 6. React DnD: contenedor que recibe el drop
  const [{ isOver }, drop] = useDrop({
    accept: "TICKET",

    hover: (item, monitor) => {
      if (!monitor.isOver()) return;

      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;

      // Hallar el elemento .position-slot donde estamos "over"
      const dropTargetElement = document.elementFromPoint(
        clientOffset.x,
        clientOffset.y
      );
      if (!dropTargetElement) return;

      const slotElement = dropTargetElement.closest(".position-slot");
      if (!slotElement) {
        setDragOverIndex(null);
        return;
      }

      // Extraemos index desde data-index
      const hoverIndex = parseInt(slotElement.dataset.index, 10);
      if (isNaN(hoverIndex)) {
        setDragOverIndex(null);
      } else {
        setDragOverIndex(hoverIndex);
      }
    },

    drop: async (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;

      const dropTargetElement = document.elementFromPoint(
        clientOffset.x,
        clientOffset.y
      );
      if (!dropTargetElement) return;

      // Hallar el índice exacto de la posición en la lista
      const slotElement = dropTargetElement.closest(".position-slot");
      // Si no hay slot, colocamos al final
      let newIndex = filteredTasksSorted.length; 
      if (slotElement) {
        const parsed = parseInt(slotElement.dataset.index, 10);
        if (!isNaN(parsed)) {
          newIndex = parsed;
        }
      }

      try {
        // 1. Primero actualizamos el status a Pendiente y eliminamos datos de Calendar
        const db = getFirestore();
        const taskRef = doc(db, `business/${businessEmail}/tickets`, item.id);
        const taskDoc = await getDoc(taskRef);
        
        if (!taskDoc.exists()) {
          toast.warning("Espera a que el evento se agregue completamente antes de moverlo");
          return;
        }

        const taskData = taskDoc.data();
        
        // Verificar sincronización con Calendar
        if (taskData.status === "Proceso" && !taskData.idEventoCalendario) {
          toast.warning("El evento aún se está sincronizando con Google Calendar. Por favor, espera un momento.");
          return;
        }

        // 2. Reordenar todas las tareas existentes para hacer espacio
        let updatedTasks = [...filteredTasksSorted];
        const existingIndex = updatedTasks.findIndex(t => t.id === item.id);
        
        if (existingIndex === -1) {
          // Si la tarea no existía, la insertamos en la posición correcta
          updatedTasks.splice(newIndex, 0, { ...item, status: "Pendiente" });
        } else {
          // Si ya existía, la movemos a la nueva posición
          const [movedTask] = updatedTasks.splice(existingIndex, 1);
          updatedTasks.splice(newIndex, 0, movedTask);
        }

        // 3. Actualizar gradoImportancia para todas las tareas
        updatedTasks.forEach((task, index) => {
          task.gradoImportancia = index + 1;
        });

        // 4. Batch update para todas las tareas
        const batch = writeBatch(db);
        
        // Primero actualizamos la tarea arrastrada
        batch.update(taskRef, {
          status: "Pendiente",
          idEventoCalendario: null,
          googleCalendarEventId: null,
          eventId: null,
          enlaceEventoCalendario: null,
          gradoImportancia: newIndex + 1
        });

        // Luego actualizamos el gradoImportancia de las demás tareas
        updatedTasks.forEach((task) => {
          if (task.id !== item.id) {
            const ref = doc(db, `business/${businessEmail}/tickets`, task.id);
            batch.update(ref, { gradoImportancia: task.gradoImportancia });
          }
        });

        await batch.commit();

        // 5. Eliminar de Google Calendar si es necesario
        if (taskData.idEventoCalendario || taskData.googleCalendarEventId || taskData.eventId) {
          try {
            await eliminarSoloDeCalendar(
              {
                ...taskData,
                googleCalendarEventId: taskData.idEventoCalendario || taskData.googleCalendarEventId || taskData.eventId
              },
              currentUser,
              accessToken,
              refreshToken,
              tokenExpiry,
              firebaseAccesToken
            );
          } catch (calendarError) {
            console.error("Error al eliminar del calendario:", calendarError);
            toast.error("Error al eliminar el evento del calendario");
          }
        }

        // 6. Actualizar el estado local
        setTasks(prevTasks => {
          const newTasks = prevTasks.map(t => {
            const updatedTask = updatedTasks.find(ut => ut.id === t.id);
            return updatedTask || t;
          });
          return newTasks;
        });

        // Efecto visual
        setDropIndex(newIndex);
        setTimeout(() => setDropIndex(null), 2000);
        setDragOverIndex(null);

        toast.success("Tarea movida y reordenada correctamente");

      } catch (error) {
        console.error("Error al mover la tarea:", error);
        toast.error(`Error al mover la tarea: ${error.message}`);
      }
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  // 7. Cuando se crea una nueva tarea en el componente masivo
  const handleTaskCreated = useCallback(() => {
    setShowMassiveTask(false);
    if (businessEmail) {
      getPendingTasks(businessEmail, setTasks, setError);
    }
  }, [businessEmail]);

  // 8. Ordenamos las tareas que se mostrarán, según gradoImportancia
  const filteredTasksSorted = [...filteredTasks].sort(
    (a, b) => (a.gradoImportancia || 0) - (b.gradoImportancia || 0)
  );

  // 9. Función para obtener color de la prioridad
  const getPriorityColor = (priority) => {
    const normalizedPriority = priority?.toLowerCase();
    switch (normalizedPriority) {
      case 'alta':
      case 'high':
        return '#ff4d4d'; // rojo
      case 'media':
      case 'medium':
        return '#ffd700'; // amarillo
      case 'baja':
      case 'low':
        return '#4CAF50'; // verde
      default:
        return '#808080'; // gris por defecto
    }
  };

  // 10. Obtener color del área (sin recargar estado repetidamente)
  const getAreaColor = useCallback(async (areaId) => {
    if (!businessEmail || !areaId) return null;
    
    if (areasColors[areaId]) return areasColors[areaId];
    
    try {
      const db = getFirestore();
      const areaRef = doc(db, `business/${businessEmail}/areas`, areaId);
      const areaDoc = await getDoc(areaRef);
      if (areaDoc.exists()) {
        return areaDoc.data().color;
      }
      return null;
    } catch (error) {
      console.error("Error al obtener el color del área:", error);
      return null;
    }
  }, [businessEmail, areasColors]);

  // 11. useEffect para cargar colores de área
  useEffect(() => {
    const loadAreaColors = async () => {
      const uniqueAreas = [...new Set(tasks
        .filter(t => t.area && !areasColors[t.area])
        .map(t => t.area)
      )];
      if (uniqueAreas.length === 0) return;

      const newColors = { ...areasColors };
      let hasNewColors = false;

      for (const areaId of uniqueAreas) {
        const color = await getAreaColor(areaId);
        if (color) {
          newColors[areaId] = color;
          hasNewColors = true;
        }
      }
      if (hasNewColors) {
        setAreasColors(newColors);
      }
    };

    loadAreaColors();
  }, [tasks, getAreaColor]);

  // 12. Render de cada tarea
  const renderTaskItem = (task, index) => {
    let style = { ...defaultColors };
    const areaId = task.area || task.department;
    
    if (areaId && areasColors[areaId]) {
      const areaColor = areasColors[areaId];
      const color = tinycolor(areaColor);
      style = {
        backgroundColor: color.setAlpha(0.1).toString(),
        borderLeft: `3px solid ${areaColor}`,
        color: areaColor,
      };
    }

    // Determinar si se está "hover" (dragOverIndex)
    const isHovered = dragOverIndex === index && isOver;
    // Determinar si este slot fue "dropeado" recientemente
    const isDropped = dropIndex === index;

    let slotStyle = {};
    if (isHovered) {
      slotStyle.backgroundColor = "#cde6fe"; // azul clarito
    } else if (isDropped) {
      slotStyle.backgroundColor = "#daf7dc"; // verde clarito
    }

    const taskPriority = task.priority || task.prioridad;

    return (
      <div
        key={task.id}
        className="position-slot filled"
        data-index={index}
        style={slotStyle}
      >
        <div className="position-number">{index + 1}</div>
        <TaskItem
          task={task}
          onOpenMenu={handleOpenMenu}
          style={style}
          priorityColor={getPriorityColor(taskPriority)}
        />
      </div>
    );
  };

  // Render principal
  return (
    <div className="todo-task-container" ref={drop}>
      <div className="todo-task-header">
        <h2>Objetivos en mente</h2>
      </div>

      <div className="todo-task-content">
        <div className="todo-task-filters">
          <input
            type="text"
            placeholder="Buscar tareas..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button
            className="todo-task-create-button"
            onClick={() => setShowMassiveTask(true)}
          >
            + Descarga tu mente
          </button>
        </div>

        {loading && (
          <div className="todo-task-loading-indicator">
            <div className="todo-task-spinner"></div>
          </div>
        )}
        {error && <div>Error: {error.message}</div>}
        {!currentUser?.email && (
          <div>Por favor, inicie sesión para ver las tareas.</div>
        )}

        {!loading && !error && currentUser?.email && (
          <div className="todo-task-list">
            {filteredTasksSorted.length === 0 && isOver && (
              <div className="empty-drop-zone">
                Suelta aquí para añadir la primera tarea
              </div>
            )}
            {filteredTasksSorted.map((task, i) => renderTaskItem(task, i))}
          </div>
        )}
      </div>

      {/* Modal o panel de creación masiva */}
      {showMassiveTask && (
        <ResumeTareasPendientes
          isOpen={showMassiveTask}
          onClose={() => setShowMassiveTask(false)}
          onTaskCreated={handleTaskCreated}
        />
      )}

      {/* Detalle o menú contextual de una tarea */}
      {menuPosition && selectedTask && (
        <DetalleTask
          tarea={selectedTask}
          onClose={handleCloseMenu}
          onEdit={handleTaskEdit}
          businessName="Planius"
          context="Detalle de la tarea"
          horaInicio={selectedTask.horaInicio}
          horaTermino={selectedTask.horaTermino}
          style={{
            top: menuPosition.y,
            right: 20,
            position: "absolute",
            zIndex: 1000,
          }}
        />
      )}
    </div>
  );
};

export default React.memo(TodoTask);
