import React, { useEffect, useRef, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import AudioRecorder from "../voiceToText/AudioRecoder";
import ResumeTarea from "./ResumeTarea";
import MessageModal from "../ModalInformativo/MessageModal";
import "./PopUp.css";
import moment from "moment";
import Modal from "react-modal";

function PopUp({ onClose, selectedDateTime, type, fullScreen }) {
  const [mensaje, setMensaje] = useState("");
  const textAreaRef = useRef(null);
  const [showResume, setShowResume] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const { currentUser, businessEmail } = useContext(AuthContext);

  const fechaActualChile = () => {
    const fechaChile = moment().tz("America/Santiago").format("YYYY-MM-DD");
    return fechaChile;
  };

  const hoy = fechaActualChile();
  const horaActual = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const handleConfirm = () => {
    if (mensaje.trim()) {
      setShowResume(true);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      } else if (event.key === "Enter" && !event.shiftKey && mensaje.trim()) {
        handleConfirm();
      }
    };

    textAreaRef.current?.addEventListener("keydown", handleKeyDown);
    textAreaRef.current?.focus();

    return () => {
      textAreaRef.current?.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, mensaje]);

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleTaskCreated = (task) => {
    setShowResume(false);
    setTaskData(task);
    setIsSuccessModalOpen(true);
  };

  const handleResumeClose = () => {
    setShowResume(false);
    onClose();
  };

  const handleModalClose = () => {
    setIsSuccessModalOpen(false);
    onClose();
  };

  return (
    <>
      {!isSuccessModalOpen && (
        <Modal
          overlayClassName="popupBackground"
          className={`popupContainer ${fullScreen ? "fullscreen" : ""}`}
          isOpen={true}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick={true}
          onClick={handleClickOutside}
        >
          {!showResume ? (
            <>
              <div className="popupHeader">
                <h2 className="popupTitle">Agregar {type}</h2>
              </div>
              <div className="popupContent">
                <div className="popupField">
                  <label htmlFor="description" className="popupLabel">
                    Descripción de {type}:
                  </label>
                  <textarea
                    id="description"
                    ref={textAreaRef}
                    placeholder={
                      type === "tarea"
                        ? "Describe la tarea e indica si es todo el día, o si tendrá una hora de inicio"
                        : type === "evento"
                        ? "Describe en qué consiste el evento e indica una fecha, una hora de inicio y una de término."
                        : "Default placeholder"
                    }
                    className="popupInput"
                    value={mensaje}
                    onChange={(e) => setMensaje(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey) {
                        e.preventDefault();
                        setMensaje((prevMensaje) => prevMensaje + "\n");
                      }
                    }}
                  />
                </div>
                <div className="popupActions">
                  <AudioRecorder onTranscription={(text) => setMensaje(text)} />
                  <button
                    onClick={handleConfirm}
                    className="popupButton"
                    disabled={!mensaje.trim()}
                  >
                    Confirmar Tarea
                  </button>
                  <button onClick={onClose} className="popupButton">
                    Cancelar
                  </button>
                </div>
              </div>
            </>
          ) : currentUser && businessEmail ? (
            <ResumeTarea
              mensaje={mensaje}
              fechaHoy={hoy}
              onClose={handleResumeClose}
              horaActual={horaActual}
              onTaskCreated={handleTaskCreated}
              selectedDateTime={selectedDateTime}
              type={type}
            />
          ) : (
            <p>Cargando...</p>
          )}
        </Modal>
      )}
      <MessageModal
        isOpen={isSuccessModalOpen}
        onClose={handleModalClose}
        message="¡Se ha creado la tarea!✔"
        {...taskData}
      />
    </>
  );
}

export default PopUp;
