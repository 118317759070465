import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthContext } from '../../context/AuthContext';
import { db } from '../firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import Modal from 'react-modal';
import './PopUp.css';
import moment from 'moment-timezone';
import { createTicketNew } from '../Functions';
import { 
  FileText, 
  Tag, 
  Flag, 
  Check, 
  X,
  Wand2,
  CheckCircle2
} from 'lucide-react';
import { toast } from 'react-toastify';

function ResumeTareasPendientes({ isOpen, onClose, onTaskCreated }) {
  const { currentUser, businessEmail, businessName } = useContext(AuthContext);
  const tituloInputRef = useRef(null);

  const [titulo, setTitulo] = useState('');
  const [area, setArea] = useState('');
  const [areas, setAreas] = useState([]);
  const [prioridad, setPrioridad] = useState('Media');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [descripcion, setDescripcion] = useState('');
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
  const [isGeneratingSuggestions, setIsGeneratingSuggestions] = useState(false);
  const [selectedAreaColor, setSelectedAreaColor] = useState('');
  const [prioridadColor, setPrioridadColor] = useState('#FFA500');
  const [showTitleCheck, setShowTitleCheck] = useState(false);
  const [titleTimeout, setTitleTimeout] = useState(null);

  const prioridadColors = {
    alta: '#FF4444',
    media: '#FFA500',
    baja: '#4CAF50'
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'bullet' }],
    ]
  };

  const formats = [
    'bold', 'italic', 'underline',
    'list'
  ];

  const quillRef = useRef(null);

  useEffect(() => {
    const fetchAreas = async () => {
      if (businessEmail) {
        const q = query(collection(db, "business", businessEmail, "areas"));
        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
        });
        setAreas(items);
      }
    };
    fetchAreas();
  }, [businessEmail]);

  const handleAreaChange = (e) => {
    const selectedAreaId = e.target.value;
    setArea(selectedAreaId);
    
    const selectedArea = areas.find(a => a.id === selectedAreaId);
    if (selectedArea) {
      setSelectedAreaColor(selectedArea.color);
    } else {
      setSelectedAreaColor('');
    }
  };

  const handlePrioridadChange = (e) => {
    const selectedPrioridad = e.target.value;
    setPrioridad(selectedPrioridad);
    setPrioridadColor(prioridadColors[selectedPrioridad.toLowerCase()]);
  };

  const handleCreateTask = async () => {
    const missingFields = [];
    if (!titulo) missingFields.push('título');
    if (!area) missingFields.push('área');

    if (missingFields.length > 0) {
      const errorMsg = `Para poder crear la tarea, debes completar: ${missingFields.join(' y ')}`;
      setErrorMessage(errorMsg);
      toast.error(errorMsg);
      return;
    }

    setLoadingTasks(true);
    setSuccessMessage('Agregando tarea pendiente');
    setErrorMessage('');

    try {
      const selectedArea = areas.find(a => a.id === area);
      if (!selectedArea) {
        throw new Error('El área seleccionada no es válida');
      }

      const color = selectedArea.color || "#000000";
      const icon = selectedArea.icon || "📌";
      const taskStatus = 'Pendiente';
      const today = moment().tz("America/Santiago").format('DD-MM-YYYY');

      const tituloConIcono = `${icon} ${titulo}`;

      const refDoc = await createTicketNew(
        tituloConIcono,
        businessName,
        prioridad,
        "Sin fecha definida",
        today,
        area,
        taskStatus,
        currentUser.email,
        businessEmail,
        descripcion || '',
        descripcion || '',
        "No aplica",
        descripcion || '',
        currentUser.displayName,
        [currentUser.email],
        currentUser.email,
        today,
        "Sin hora definida",
        "Sin hora definida",
        'tarea',
        color,
        false,
        "Sin fecha definida",
        false,
        {
          area: area,
          department: area
        }
      );

      setLoadingTasks(false);
      onClose();
      if (onTaskCreated) onTaskCreated();
      toast.success('Tarea pendiente creada exitosamente');

    } catch (error) {
      console.error('Error creando la tarea:', error);
      const errorMsg = error.message || 'Error al crear la tarea';
      setErrorMessage(errorMsg);
      toast.error(errorMsg);
      setLoadingTasks(false);
    }
  };

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const generateAutomaticDescription = async () => {
    if (!titulo) {
      toast.warning('Por favor, ingresa un título primero');
      return;
    }

    setIsGeneratingDescription(true);
    try {
      const response = await fetch('/.netlify/functions/generateTaskDescription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          titulo,
          area: areas.find(a => a.id === area)?.id
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || data.details || 'Error al generar la descripción');
      }

      if (!data.description) {
        throw new Error('No se recibió una descripción válida');
      }

      setDescripcion(data.description);
      toast.success('¡Descripción generada exitosamente!');
    } catch (error) {
      console.error('Error detallado:', error);
      toast.error(error.message || 'Error al generar la descripción automática');
    } finally {
      setIsGeneratingDescription(false);
    }
  };

  const generateSuggestions = async (title) => {
    if (!title || title.length < 3) return;
    
    setIsGeneratingSuggestions(true);
    
    try {
      const response = await fetch('/.netlify/functions/generateTaskSuggestions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          titulo: title,
          availableAreas: areas.map(area => ({
            id: area.id,
            description: area.description || ''
          }))
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || data.details || 'Error al generar sugerencias');
      }

      if (data.area) {
        setArea(data.area);
        const selectedArea = areas.find(a => a.id === data.area);
        if (selectedArea) {
          setSelectedAreaColor(selectedArea.color);
        }
      }

      if (data.priority) {
        setPrioridad(data.priority);
        setPrioridadColor(prioridadColors[data.priority.toLowerCase()]);
      }

    } catch (error) {
      console.error('Error al generar sugerencias:', error);
    } finally {
      setIsGeneratingSuggestions(false);
    }
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitulo(newTitle);
    setShowTitleCheck(newTitle.trim().length > 0);
    
    if (titleTimeout) {
      clearTimeout(titleTimeout);
    }
    
    if (newTitle.length >= 3) {
      const newTimeout = setTimeout(() => {
        generateSuggestions(newTitle);
      }, 2000);
      
      setTitleTimeout(newTimeout);
    }
  };

  const handleTitleBlur = () => {
    if (titulo.trim().length > 0) {
      setShowTitleCheck(true);
      
      if (titulo.length >= 3) {
        if (titleTimeout) {
          clearTimeout(titleTimeout);
        }
        generateSuggestions(titulo);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (titleTimeout) {
        clearTimeout(titleTimeout);
      }
    };
  }, [titleTimeout]);

  return (
    <Modal
      overlayClassName="popupBackground"
      className="popupContainer"
      isOpen={isOpen}
      onRequestClose={onClose}
      onClick={handleClickOutside}
      shouldFocusAfterRender={false}
      shouldReturnFocusAfterClose={true}
    >
      <div className="popupHeader">
        <h3 className="popupTitle">
          <FileText size={20} style={{ marginRight: '10px' }}/> 
          Crear Tarea Pendiente
        </h3>
      </div>

      <div className="popupContent">
        <div className="edit-event-section">
          <div className="edit-form-content" style={{ display: 'flex', gap: '20px' }}>
            <div className="form-column" style={{ flex: '1' }}>
              <div className="form-field">
                <label>
                  <FileText size={16} />
                  Título
                </label>
                <div className="title-input-container">
                  <input
                    type="text"
                    value={titulo}
                    onChange={handleTitleChange}
                    onBlur={handleTitleBlur}
                    placeholder="¿Qué necesitas hacer?"
                    ref={tituloInputRef}
                    className="animate-field"
                    autoFocus
                  />
                  <CheckCircle2 
                    size={18} 
                    className={`title-check ${showTitleCheck ? 'visible' : ''}`}
                  />
                </div>
              </div>

              <div className="form-field">
                <label className="description-label">
                  <div>
                    <FileText size={16} />
                    Descripción
                  </div>
                  <button
                    type="button"
                    onClick={generateAutomaticDescription}
                    className="magic-button"
                    disabled={isGeneratingDescription || !titulo}
                    title={isGeneratingDescription ? 'Generando descripción...' : 'Generar descripción automática'}
                  >
                    {isGeneratingDescription ? (
                      <div className="spinner"></div>
                    ) : (
                      <Wand2 size={16} />
                    )}
                  </button>
                </label>
                <div className="quill-container">
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={descripcion}
                    onChange={setDescripcion}
                    modules={modules}
                    formats={formats}
                    placeholder="Agrega una descripción de la tarea..."
                    className="custom-quill"
                  />
                </div>
              </div>
            </div>

            <div className="form-column" style={{ flex: '1' }}>
              <div className="form-field">
                <label className="label-with-spinner-23f4">
                  <div className="label">
                    <Tag size={16} />
                    <span> Área</span>
                  </div>
                  {isGeneratingSuggestions && (
                    <div className="spinner-23f4" />
                  )}
                </label>
                <select
                  value={area}
                  onChange={handleAreaChange}
                  style={{
                    backgroundColor: selectedAreaColor ? `${selectedAreaColor}40` : '',
                    transition: 'background-color 0.3s ease'
                  }}
                >
                  <option value="">Seleccionar área</option>
                  {areas.map((area, index) => (
                    <option 
                      key={index} 
                      value={area.id}
                      data-icon={area.icon}
                      style={{
                        backgroundColor: `${area.color}40`
                      }}
                    >
                      {area.icon} {area.id}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-field">
                <label className="label-with-spinner-23f4">
                  <div className="label">
                    <Flag size={16} />
                    <span> Prioridad</span>
                  </div>
                  {isGeneratingSuggestions && (
                    <div className="spinner-23f4" />
                  )}
                </label>
                <select
                  value={prioridad}
                  onChange={handlePrioridadChange}
                  style={{
                    backgroundColor: `${prioridadColor}40`,
                    transition: 'background-color 0.3s ease'
                  }}
                >
                  <option value="Alta">Alta</option>
                  <option value="Media">Media</option>
                  <option value="Baja">Baja</option>
                </select>
              </div>
            </div>
          </div>

          {errorMessage && (
            <div className="errorMessageResumeTarea">
              <span>{errorMessage}</span>
            </div>
          )}

          {successMessage && (
            <div className="success-message-resumeTarea">
              {successMessage}
              {loadingTasks && (
                <div className="spinner-container-resumeTarea">
                  <div className="spinner-ring-resumeTarea"></div>
                  <div className="spinner-dot-resumeTarea"></div>
                </div>
              )}
            </div>
          )}

          <div className="form-actions">
            <button 
              onClick={handleCreateTask} 
              className="save-edittask-button" 
              disabled={loadingTasks}
            >
              <Check size={18} />
              Crear Tarea
            </button>
            <button onClick={onClose} className="cancel-button">
              <X size={18} />
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles = `
.quill-container {
  height: 200px;
  margin-bottom: 20px;
}

.quill-container .ql-container {
  height: calc(100% - 42px);
  overflow-y: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.quill-container .ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #f8f9fa;
}

.quill-container .ql-editor {
  min-height: 100%;
  font-size: 14px;
  padding: 12px 15px;
}

.ql-clean {
  display: none !important;
}

.ql-container::-webkit-scrollbar {
  width: 8px;
}

.ql-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.ql-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.ql-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ResumeTareasPendientes; 