import React, { useState, useEffect, useContext, Suspense, useCallback, memo } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Calendar, Kanban as KanbanIcon, ClipboardCheck } from "lucide-react";
import MassiveTask from "./addTarea/MasiveTask";

// Memoizar los componentes lazy
const Kanban = React.lazy(() => import("./Kanban"));
const CalendarioTickets = React.lazy(() => import("./calendario/CalendarioTickets"));
const Timeline = React.lazy(() => import("./timeLine/TimeLine"));
const Plani = React.lazy(() => import("./bot/Plani"));
const RevisionRapida = React.lazy(() => import("./DashboardComponents/RevisionRapida"));

// Memoizar los componentes de pestañas
const TabContent = memo(({ children }) => children);

// Memoizar el componente de carga
const LoadingSuspense = memo(() => <div>Cargando...</div>);

function GestionarTickets() {
  const navigate = useNavigate();
  const location = useLocation();
  const { businessName, initView } = useContext(AuthContext);
  const [activeKey, setActiveKey] = useState(initView);
  const [showMassiveTask, setShowMassiveTask] = useState(false);

  // Memoizar el manejador de cierre
  const handleClose = useCallback(() => {
    setShowMassiveTask(false);
  }, []);

  // Memoizar el manejador de selección de pestaña
  const handleSelect = useCallback((k) => {
    setActiveKey(k);
  }, []);

  useEffect(() => {
    document.title = `Planius AI`;
  }, []);

  useEffect(() => {
    if (location.state?.showPopup) {
      setShowMassiveTask(true);
    }
  }, [location.state]);

  // Memoizar el contenido de las pestañas
  const tabContent = {
    calendario: (
      <Suspense fallback={<LoadingSuspense />}>
        <CalendarioTickets />
      </Suspense>
    ),
    revision: (
      <Suspense fallback={<LoadingSuspense />}>
        <div style={{ width: "85%", margin: "auto", height: "80vh", overflowY: "scroll", padding: "1rem" }}>
          <RevisionRapida tasksPerPage={10} />
        </div>
      </Suspense>
    ),
    kanban: (
      <Suspense fallback={<LoadingSuspense />}>
        <Kanban />
      </Suspense>
    )
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <Tabs id="ticket-tabs" activeKey={activeKey} onSelect={handleSelect}>
        <Tab
          eventKey="calendario"
          title={
            <span>
              <Calendar size={11} style={{ marginRight: "5px" }} /> Calendario
            </span>
          }
        >
          <TabContent>
            {activeKey === "calendario" && tabContent.calendario}
          </TabContent>
        </Tab>
        <Tab
          eventKey="revision"
          title={
            <span>
              <ClipboardCheck size={11} style={{ marginRight: "5px" }} /> Revisión
            </span>
          }
        >
          <TabContent>
            {activeKey === "revision" && tabContent.revision}
          </TabContent>
        </Tab>
        <Tab
          eventKey="kanban"
          title={
            <span>
              <KanbanIcon size={11} style={{ marginRight: "5px" }} /> Kanban
            </span>
          }
        >
          <TabContent>
            {activeKey === "kanban" && tabContent.kanban}
          </TabContent>
        </Tab>
      </Tabs>
      
      <Suspense fallback={<LoadingSuspense />}>
        <Plani />
      </Suspense>
      <Footer />
      
      {showMassiveTask && (
        <MassiveTask onClose={handleClose} selectedDateTime={null} />
      )}
    </div>
  );
}

// Memoizar el componente principal
export default memo(GestionarTickets);
