import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faImage, faFile, faListAlt, faVideo } from '@fortawesome/free-solid-svg-icons';
import './Menu.css';

function Menu({ position, onAddItem, onClose }) {
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="menu_ws" ref={menuRef} style={{ top: `${position.y}px`, left: `${position.x}px` }}>
      <ul className="menu_list_ws">
        <li className="menu_item_ws" onClick={() => onAddItem('text')}>
          <FontAwesomeIcon icon={faEdit} style={{ marginRight: '10px' }} />
          Agregar cuadro de texto
        </li>
        <li className="menu_item_ws" onClick={() => onAddItem('image')}>
          <FontAwesomeIcon icon={faImage} style={{ marginRight: '10px' }} />
          Agregar imagen
        </li>
        <li className="menu_item_ws" onClick={() => onAddItem('video')}>
          <FontAwesomeIcon icon={faVideo} style={{ marginRight: '10px' }} />
          Agregar Vídeo de Youtube
        </li>
        <li className="menu_item_ws" onClick={() => onAddItem('file')}>
          <FontAwesomeIcon icon={faFile} style={{ marginRight: '10px' }} />
          Agregar archivo
        </li>
        <li className="menu_item_ws" onClick={() => onAddItem('checklist')}>
          <FontAwesomeIcon icon={faListAlt} style={{ marginRight: '10px' }} />
          Agregar lista de tareas
        </li>
      </ul>
    </div>
  );
}

export default Menu;
