import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { sendMail } from "../SendMail";
import { AuthContext } from "../../context/AuthContext";
import ImageUploader from "../ImageUploader";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ref } from "firebase/storage";
import { storage } from "../firebase";
import { updateProfile } from "firebase/auth";

Modal.setAppElement("#root");

function CambiarFotoModal(props) {
  const [modalIsOpenTicket, setModalIsOpenTicket] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [file, setFile] = useState(null);


  const handleActualizarImg = async (file) => {
    let finalImageURL = null; // Establecer la URL de la imagen en la imagen predeterminada primero
    
    const date = new Date().getTime();
    const name = currentUser.uid;
    const storageRef = ref(storage, `${name + date}`);
    const user = currentUser;
    try {
      await uploadBytesResumable(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      finalImageURL = downloadURL; // Si la subida de la imagen es exitosa, actualiza la URL de la imagen
    } catch (err) {
      console.error("Error al cargar la imagen:", err);
    }
    await updateProfile(user, {
      photoURL: finalImageURL, // Usar la URL final de la imagen aquí
    });
    alert("Imagen actualizada correctamente")
    handleCloseModalTicket();

  }

  useEffect(() => {
    if (props.openModalTicket) {
      setModalIsOpenTicket(true);
    }
  }, [props.openModalTicket]);

  const handleOpenModalTicket = () => {
    setModalIsOpenTicket(true);
  };
  const handleCloseModalTicket = () => {
    setModalIsOpenTicket(false);
    props.handleCloseTicket(); // Notificar al componente padre que el modal está cerrado
  };
 
  return (
    <Modal
      overlayClassName={"modal-overlay"}
      className={"modal-content"}
      isOpen={modalIsOpenTicket}
      onRequestClose={handleCloseModalTicket}
    >
      <h2 className="titleModal">Cambiar Imagen de Perfil</h2>

      <div className="descriptionModal">
        <div className="center">
          <ImageUploader onImageChange={setFile} />
        </div>
      </div>

      <div className="row">
      {file ? <button className="btnCloseModal" onClick={()=>{handleActualizarImg(file)}}>Actualizar</button> : null}

        <button className="btnCloseModal" onClick={handleCloseModalTicket}>
          Cancelar
        </button>
      </div>
    </Modal>
  );
}

export default CambiarFotoModal;
