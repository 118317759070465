import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import { eliminarEventoDeCalendar, eliminarSoloDeCalendar } from "./calendario/FunctionsCalendar";
import { toast } from 'react-toastify';
import { Trash2, Check, X } from "lucide-react";

const DeleteTarea = ({ tarea, isOpen, onClose, onDelete, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmDelete = async () => {
    if (isLoading) return; // Evitar múltiples clicks
    
    setIsLoading(true);

    try {
      // Primero eliminar de Firebase
      await deleteDoc(doc(db, "business", tarea.emailBusiness, "tickets", tarea.id));

      // Cerrar el modal y notificar la eliminación
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
        onDelete();
        onClose();
      }, 2000);

      // Luego eliminar de Google Calendar si es necesario
      if (tarea.idEventoCalendario) {
        await eliminarEventoDeCalendar(
          tarea,
          currentUser,
          accessToken,
          refreshToken,
          tokenExpiry,
          firebaseAccesToken,
          'single'
        );
      }

    } catch (error) {
      console.error("Error al eliminar la tarea:", error);
      toast.error(`Error al eliminar la tarea: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      overlayClassName={"popupBackground"}
      className={"popupContainer"}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">
          <Trash2 size={20} style={{ marginRight: '8px' }} />
          Eliminar Tarea
        </h2>
      </div>

      <div className="popupContent">
        {isLoading ? (
          <div className="detalleTarea__loading" style={{width:"90%", margin:"auto"}}>
            <FontAwesomeIcon icon={faSpinner} spin className="icon" />
            <span>Cargando...</span>
          </div>
        ) : showConfirmation ? (
          <div className="detalleTarea__confirmation">
            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
            <span>Tarea borrada</span>
          </div>
        ) : (
          <p>
            ¿Seguro que desea Eliminar {tarea.type}: {tarea.title} ? (esta acción no se puede deshacer)
          </p>
        )}
      </div>

      {!isLoading && !showConfirmation && (
        <div className="popupActions">
          <div className="row" style={{ margin: "auto", padding: "10px" }}>
            <button className="popupButton" onClick={handleConfirmDelete}>
              <Check size={18} style={{ marginRight: '5px' }} />
              Aceptar
            </button>
            <button
              className="popupButton"
              style={{ marginLeft: "10px" }}
              onClick={onClose}
            >
              <X size={18} style={{ marginRight: '5px' }} />
              Cancelar
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DeleteTarea;
