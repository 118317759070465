import React, { useState, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { uploadFile } from "../Functions.js";
import "./FileBox.css";

const ItemType = "ITEM";

function FileBox({ item, index, setItems, items }) {
  const [file, setFile] = useState(item.content);
  const [isUploading, setIsUploading] = useState(false);
  const ref = useRef(null);

  const getIconAndColorForFileType = (fileType) => {
    switch (fileType) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return { icon: faFileExcel, color: "#21A366" };
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return { icon: faFileWord, color: "#185ABD" };
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return { icon: faFilePowerpoint, color: "#C43E1C" };
      case "application/pdf":
        return { icon: faFilePdf, color: "#BD0000" };
      default:
        return { icon: faFileAlt, color: "#EEEEEE" };
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        const newItems = Array.from(items);
        const [movedItem] = newItems.splice(draggedItem.index, 1);
        newItems.splice(index, 0, movedItem);
        setItems(newItems);
        draggedItem.index = index;
      }
    },
    drop: (draggedItem, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const newItems = Array.from(items);
      const movedItem = newItems.find((i) => i.id === draggedItem.id);
      if (movedItem) {
        movedItem.position = {
          x: Math.round(item.position.x + delta.x),
          y: Math.round(item.position.y + delta.y),
        };
        setItems(newItems);
      }
    },
  });

  drag(drop(ref));

  const handleChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.size > 5000000) {
      alert("El archivo no debe superar los 5 MB");
      return;
    }
    setIsUploading(true);
    const filePath = `files/${selectedFile.name}`;
    const fileUrl = await uploadFile(selectedFile, filePath);
    const newFile = {
      name: selectedFile.name,
      type: selectedFile.type,
      content: fileUrl,
    };
    setFile(newFile);
    setIsUploading(false);
    const newItems = Array.from(items);
    const currentItem = newItems.find((i) => i.id === item.id);
    if (currentItem) {
      currentItem.content = newFile;
      setItems(newItems);
    }
  };

  const handleOpenFile = () => {
    if (file && file.content) {
      window.open(file.content, "_blank");
    }
  };

  const truncateFileName = (fileName) => {
    if (!fileName) {
      return "";
    }
    const maxLength = 20;
    if (fileName.length <= maxLength) {
      return fileName;
    }
    const extension = fileName.substring(fileName.lastIndexOf("."));
    const truncatedName = fileName.substring(
      0,
      maxLength - extension.length - 3
    );
    return truncatedName + "..." + extension;
  };

  const { icon, color } = getIconAndColorForFileType(file ? file.type : "");

  return (
    <div
      ref={ref}
      className="file-box"
      style={{
        opacity: isDragging ? 0.5 : 1,
        left: item.position.x,
        top: item.position.y,
        position: "absolute",
      }}
    >
      <input
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
        id={`file-input-${item.id}`}
      />
      <div
        className="file-content"
        onClick={() => document.getElementById(`file-input-${item.id}`).click()}
      >
        {isUploading ? (
          <div className="upload-placeholder">
            <span>Cargando...</span>
          </div>
        ) : file ? (
          <div className="file-info" onClick={handleOpenFile}>
            <div
              className="file-icon"
              style={{
                backgroundColor: color,
              }}
            >
              <FontAwesomeIcon icon={icon} size="2x" color="white" />
            </div>
            <div className="file-name">{truncateFileName(file.name)}</div>
          </div>
        ) : (
          <div className="upload-placeholder">
            <FontAwesomeIcon icon={faFileAlt} size="2x" color="#CCCCCC" />
            <span>Subir Archivo</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default FileBox;
