// CalendarSemana.js
import React, { useState, useContext, useRef, useEffect, useCallback, useMemo } from "react";
import { useDrop } from "react-dnd";
import tinycolor from "tinycolor2";
import "./calendarioSemanal.css";
import { AuthContext } from "../../context/AuthContext";
import { updateTicket } from "../Functions";
import { updateGoogleCalendarEvent, agregarEventoACalendario } from "./FunctionsCalendar";
import moment from "moment-timezone";
import ResizableDraggableTicket from "./ResizableDraggableTicket";
import DetalleTarea from "./DetalleTarea";
import GoogleMeets from "../GoogleMeets/GoogleMeets";
import { toast } from 'react-toastify';
import TabsContainer from "../ReorganizarPlanibot/TabsContainer";
import ResumeTarea from "../addTarea/ResumeTarea";
import DraggableTicketMulti from "./DraggableTicketMulti";
import AllDaySemanal from './ComponentsSemana/AllDaySemanal';
import TableHeader from './ComponentsSemana/TableHeader';
import CalendarRow from './ComponentsSemana/CalendarRow';
import { BorderColor } from "@mui/icons-material";
import PlanibotDetail from "../Planibot/PlanibotDetail";

const CalendarSemana = ({
  diaActual,
  mesActual,
  anioActual,
  semanaActual,
  tickets,
  coloresTicket,
  cambiarFecha,
  reordenarEventosConIA,
  timeZone,
  setTickets,
  isTabsVisible,
  manejarActualizacionTareasPendientes
}) => {
  const diasSemana = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const { businessEmail, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken } = useContext(AuthContext);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);
  const [draggedOverCell, setDraggedOverCell] = useState(null);
  const [selectedTarea, setSelectedTarea] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showGoogleMeets, setShowGoogleMeets] = useState(false);
  const [tarea, setTarea] = useState();
  const [sePuedeAbrir, setSePuedeAbrir] = useState(true);
  const [type, setType] = useState("tarea");
  const [currentCellTime, setCurrentCellTime] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentTimePosition, setCurrentTimePosition] = useState(null);
  const [draggingTicket, setDraggingTicket] = useState(null); // Nuevo estado para rastrear el ticket arrastrado
  const [isDragging, setIsDragging] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [isResizing, setIsResizing] = useState(false); // Nuevo estado
  const [showPlanibotDetail, setShowPlanibotDetail] = useState(false);
  const [selectedDateTickets, setSelectedDateTickets] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tareasPendientes, setTareasPendientes] = useState([]);
  const [isProcessingPlanibot, setIsProcessingPlanibot] = useState(false);

  const gridSize = 46;
  const calendarRef = useRef(null);

  const fechaInicio = useMemo(() => {
    const fecha = new Date(anioActual, mesActual, diaActual);
    const diaSemana = fecha.getDay();
    const diff = diaSemana === 0 ? -6 : 1 - diaSemana; // Si es domingo, retroceder 6 días, sino ajustar al lunes
    
    fecha.setDate(fecha.getDate() + diff);
    return fecha;
  }, [anioActual, mesActual, diaActual]);

  const fechaFin = useMemo(() => {
    const fecha = new Date(fechaInicio);
    fecha.setDate(fechaInicio.getDate() + 6); // Agregar 6 días para llegar al domingo
    return fecha;
  }, [fechaInicio]);

  const diasSemanaArr = useMemo(() => {
    const dias = [];
    for (let i = 0; i < 7; i++) {
      const fecha = new Date(fechaInicio);
      fecha.setDate(fechaInicio.getDate() + i);
      dias.push(new Date(fecha));
    }
    return dias;
  }, [fechaInicio]);

  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = moment().tz(timeZone);
      const hour = now.hours();
      const minutes = now.minutes();
      const cellHeight = 50;
      const topPosition = hour * cellHeight + (minutes / 60) * cellHeight;
      setCurrentTimePosition(topPosition);
    };

    updateCurrentTimePosition();
    const intervalId = setInterval(updateCurrentTimePosition, 300000);

    return () => clearInterval(intervalId);
  }, [timeZone]);

  useEffect(() => {
    if (calendarRef.current) {
      const scrollToMidday = () => {
        const horaObjetivo = 7; // 7:00 AM
        const alturaHora = 50; // Altura de cada celda de hora en píxeles
        const scrollTop = horaObjetivo * alturaHora;
        
        // Establecer el scrollTop directamente sin animación
        calendarRef.current.scrollTop = scrollTop;
      };

      // Ejecutar el scroll inmediatamente después de que el componente se monte
      scrollToMidday();
    }
  }, []); // Dependencias vacías para que solo se ejecute una vez al montar

  const obtenerTicketsDia = useMemo(() => {
    const cache = {};
    return (fecha) => {
      const key = moment(fecha).format('YYYY-MM-DD');
      if (!cache[key]) {
        cache[key] = tickets.filter((ticket) => {
          const fechaInicio = moment(ticket.vencimiento, "DD-MM-YYYY");
          const fechaFin = moment(ticket.fechaFin || ticket.vencimiento, "DD-MM-YYYY");
          const fechaActual = moment(fecha);
          return fechaActual.isBetween(fechaInicio, fechaFin, null, '[]');
        });
      }
      return cache[key];
    };
  }, [tickets]);
  

  const calcularRowSpan = (inicio, fin) => {
    const inicioEnMinutos = inicio.getHours() * 60 + inicio.getMinutes();
    const finEnMinutos = fin.getHours() * 60 + fin.getMinutes();
    return Math.ceil((finEnMinutos - inicioEnMinutos) / 60);
  };

  const omitirCeldas = {};

  const onDropTicket = async (ticket, dia, mes, anio, hour, offsetY, isAllDay, exactTime) => {
    const timezone = "America/Santiago";
    let newStartTime, newEndTime;

    if (isAllDay) {
      newStartTime = "Todo el día";
      newEndTime = "Todo el día";
    } else {
      newStartTime = moment.tz(`${anio}-${mes + 1}-${dia} ${exactTime}`, "YYYY-M-D HH:mm", timezone);

      const originalStartTime = moment.tz(ticket.horaInicio, "HH:mm", timezone);
      const originalEndTime = moment.tz(ticket.horaTermino, "HH:mm", timezone);
      const duration = moment.duration(originalEndTime.diff(originalStartTime));

      newEndTime = moment(newStartTime).add(duration);

      if (newEndTime.isSameOrBefore(newStartTime)) {
        newEndTime = moment(newStartTime).add(1, 'hour');
      }

      newStartTime = newStartTime.format("HH:mm");
      newEndTime = newEndTime.format("HH:mm");
    }

    const originalVencimiento = moment.tz(ticket.vencimiento, "DD-MM-YYYY", timezone);
    const originalFechaFin = moment.tz(ticket.fechaFin || ticket.vencimiento, "DD-MM-YYYY", timezone);
    const eventDurationDays = originalFechaFin.diff(originalVencimiento, 'days');

    const nuevaFechaVencimiento = moment.tz({ year: anio, month: mes, day: dia }, timezone);
    const nuevaFechaFin = nuevaFechaVencimiento.clone().add(eventDurationDays, 'days');

    const updatedData = {
      vencimiento: nuevaFechaVencimiento.format("DD-MM-YYYY"),
      fechaFin: nuevaFechaFin.format("DD-MM-YYYY"),
      horaInicio: newStartTime,
      horaTermino: newEndTime,
      status: "Proceso",
      area: ticket.area || ticket.department,
      department: ticket.area || ticket.department
    };

    try {
      await updateTicket(businessEmail, ticket.id, updatedData);
      setTickets((prevTickets) =>
        prevTickets.map((t) => (t.id === ticket.id ? { ...t, ...updatedData } : t))
      );
      setTarea((prevTarea) => ({ ...prevTarea, ...updatedData }));

      if (ticket.status === "Proceso" || ticket.status === "Terminado") {
        await updateGoogleCalendarEvent(ticket, updatedData, ticket.color, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
      } else {
        const fechaInicioISO = nuevaFechaVencimiento.toISOString();
        await agregarEventoACalendario(
          businessEmail,                   
          ticket.id,                       
          ticket.title,                    
          ticket.descripcion,              
          fechaInicioISO,                  
          nuevaFechaFin.toISOString(),     
          updatedData.horaInicio,          
          updatedData.horaTermino,         
          ticket.color,                    
          'primary',                       
          currentUser,                     
          accessToken,                     
          refreshToken,                    
          tokenExpiry,                     
          firebaseAccesToken,
          null,                           // recurrenceRule
          [],                             // attendees
          false,                          // enableMeet
          ticket.area || ticket.department // Pasar explícitamente el área
        );
      }

    } catch (error) {
      console.error("Error actualizando el ticket:", error);
    }

    setSePuedeAbrir(false);
  };

  const onResizeStop = async (ticket, newHeight) => {
    setIsResizing(true);
    
    // Validar que el ticket tenga horaInicio
    if (!ticket.horaInicio) {
      toast.error("Error: Hora de inicio no válida");
      setIsResizing(false);
      return;
    }

    const totalMinutes = (newHeight / 50) * 60;
    const newEndTime = moment(ticket.horaInicio, "HH:mm").add(
      totalMinutes,
      "minutes"
    );

    let horaInicio = moment(ticket.horaInicio, "HH:mm");
    let horaTermino = newEndTime;

    if (horaTermino.isSameOrBefore(horaInicio)) {
      horaTermino = horaInicio.clone().add(1, "hour");
    }

    // Solo enviar los campos que necesitamos actualizar
    const updatedData = {
      horaInicio: ticket.horaInicio, // Asegurarnos de incluir la hora de inicio
      horaTermino: horaTermino.format("HH:mm"),
      vencimiento: ticket.vencimiento,
      fechaFin: ticket.fechaFin || ticket.vencimiento, // Usar vencimiento como fallback
      title: ticket.title,
      descripcion: ticket.descripcion,
      status: ticket.status || "Proceso",
      type: ticket.type || "evento",
      area: ticket.area || ticket.department || "",
      department: ticket.area || ticket.department || "",
      color: ticket.color // Asegurarnos de incluir el color
    };

    try {
      // Validar que los datos necesarios estén presentes
      if (!updatedData.vencimiento || !updatedData.horaInicio || !updatedData.horaTermino) {
        throw new Error("Faltan datos necesarios para actualizar el ticket");
      }

      await updateTicket(businessEmail, ticket.id, updatedData);
      
      setTickets((prevTickets) =>
        prevTickets.map((t) => (t.id === ticket.id ? { ...t, ...updatedData } : t))
      );
      
      setTarea((prevTarea) => ({ ...prevTarea, ...updatedData }));

      if (ticket.idEventoCalendario) {
        const eventData = {
          ...updatedData,
          // Asegurarnos de que estos campos estén presentes para Google Calendar
          vencimiento: updatedData.vencimiento,
          fechaFin: updatedData.fechaFin,
          horaInicio: updatedData.horaInicio,
          horaTermino: updatedData.horaTermino
        };

        await updateGoogleCalendarEvent(
          ticket, 
          eventData, 
          ticket.color, 
          currentUser, 
          accessToken, 
          refreshToken, 
          tokenExpiry, 
          firebaseAccesToken
        );
      }

    } catch (error) {
      console.error("Error actualizando el ticket:", error);
      toast.error(error.message || "Error actualizando el ticket");
    } finally {
      setSePuedeAbrir(false);
      setTimeout(() => {
        setIsResizing(false);
        setSePuedeAbrir(true);
      }, 400);
    }
  };

  const [{ isOverDrop }, drop] = useDrop({
    accept: "TICKET",
    drop: (item, monitor) => {
      if (draggedOverCell) {
        const { dia, mes, anio, time, hour, isAllDay } = draggedOverCell;
        onDropTicket(
          item, 
          dia, 
          mes, 
          anio, 
          hour,
          0, // Ya no necesitamos el offsetY porque usamos time directamente
          isAllDay,
          time // Pasar el tiempo exacto
        );
        setDraggedOverCell(null);
        setDraggingTicket(null);
        return { time }; // Retornar el tiempo exacto
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleCellClick = useCallback((event, hour, dia, mes, anio) => {
    event.stopPropagation();
    const currentTime = new Date().getTime();
    
    // Si estamos redimensionando o arrastrando, no abrir el modal
    if (isDragging || isResizing) {
      return;
    }

    // Si el último clic fue hace menos de 300ms, consideramos que es un doble clic
    if (currentTime - lastClickTime < 300) {
      return;
    }

    const rect = event.target.getBoundingClientRect();
    const clickPositionInCell = event.clientY - rect.top;
    const isHalfHour = clickPositionInCell > (gridSize / 2);
    const selectedTime = isHalfHour
      ? `${hour.toString().padStart(2, '0')}:30`
      : `${hour.toString().padStart(2, '0')}:00`;

    setSelectedDateTime({
      date: `${anio}-${(mes + 1).toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`,
      time: selectedTime,
    });

    // Abrir directamente ResumeTarea
    setShowPopUp(true);
    setType("evento");

    setLastClickTime(currentTime);
  }, [isDragging, isResizing, lastClickTime, gridSize]);

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
    // Esperar un poco antes de permitir abrir el menú de nuevo
    setTimeout(() => {
      setIsDragging(false);
    }, 100);
  }, []);

  const handleTicketClick = (ticket, event) => {
    if (event && typeof event.stopPropagation === "function") {
      event.stopPropagation();
    }
    setSePuedeAbrir(false);
    handleCloseMenu();
    setSelectedTarea(ticket);
  };

  const handleCloseDetalleTarea = () => {
    setSelectedTarea(null);
    setSePuedeAbrir(true);
  };

  const handleCloseMenu = () => {
    setMenuPosition(null);
  };

  const handleAddItem = (item) => {
    switch (item) {
      case "task":
        setShowPopUp(true);
        setType("tarea");
        break;
      case "meeting":
        setShowGoogleMeets(true);
        break;
      case "event":
        setShowPopUp(true);
        setType("evento");
        break;
      default:
        break;
    }
    setMenuPosition(null);
  };

  const agruparTicketsPorSolapamiento = useMemo(() => {
    return (ticketsDia) => {
      if (!ticketsDia.length) return [];
      
      const grupos = [];
      const ticketsOrdenados = [...ticketsDia].sort((a, b) =>
        moment(a.horaInicio, "HH:mm").diff(moment(b.horaInicio, "HH:mm"))
      );

      ticketsOrdenados.forEach((ticket) => {
        const inicio = moment(ticket.horaInicio, "HH:mm");
        const fin = moment(ticket.horaTermino, "HH:mm");

        let grupoExistente = grupos.find(grupo =>
          grupo.some(t => {
            const tInicio = moment(t.horaInicio, "HH:mm");
            const tFin = moment(t.horaTermino, "HH:mm");
            return (inicio.isBefore(tFin) && fin.isAfter(tInicio)) ||
              (tInicio.isBefore(fin) && tFin.isAfter(inicio));
          })
        );

        if (grupoExistente) {
          grupoExistente.push(ticket);
        } else {
          grupos.push([ticket]);
        }
      });

      return grupos;
    };
  }, []);

  const memoizedPopUp = useMemo(() => {
    if (showPopUp) {
      return (
        <ResumeTarea
          isOpen={showPopUp}
          onClose={() => setShowPopUp(false)}
          selectedDateTime={selectedDateTime}
          type={type}
          selectedHour={selectedDateTime ? selectedDateTime.time : null}
        />
      );
    }
    return null;
  }, [showPopUp, selectedDateTime]);

  const handleEdit = useCallback((updatedTask) => {
    setTarea(updatedTask);
    setSelectedTarea(updatedTask);
  }, []);

  const handleDragOverCell = (e, dia, mes, anio, hour, isAllDay) => {
    if (!e || !e.target) return; // Validación inicial

    const cellRect = e.target.getBoundingClientRect();
    if (!cellRect) return; // Validar que existe el rectángulo

    const offsetY = e.clientY - cellRect.top;
    const minuteOffset = Math.floor((offsetY / gridSize) * 60);
    let selectedTime;

    if (isAllDay) {
      selectedTime = 'Todo el día';
    } else {
      if (!hour && hour !== 0) return; // Validar que hour existe
      
      if (minuteOffset < 15) {
        selectedTime = `${hour.toString().padStart(2, '0')}:00`;
      } else if (minuteOffset < 45) {
        selectedTime = `${hour.toString().padStart(2, '0')}:30`;
      } else {
        selectedTime = `${(hour + 1).toString().padStart(2, '0')}:00`;
      }
    }

    setDraggedOverCell({ dia, mes, anio, time: selectedTime, hour, isAllDay });
    
    if (dia && mes !== undefined && anio) {
      setSelectedDateTime({
        date: `${anio}-${(mes + 1).toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`,
        time: selectedTime,
      });

      setCurrentCellTime(
        isAllDay
          ? `Fecha: ${dia}/${mes + 1}/${anio}  Todo el día`
          : `Fecha: ${dia}/${mes + 1}/${anio}  Hora: ${selectedTime}`
      );
    }
  };

  const today = moment().tz(timeZone).startOf('day');

  const renderTicket = useCallback((ticket, dia, mes, anio) => {
    const fechaInicio = moment(ticket.vencimiento, "DD-MM-YYYY");
    const fechaFin = moment(ticket.fechaFin || ticket.vencimiento, "DD-MM-YYYY");
    const fechaActual = moment([anio, mes, dia]);
    const isMultiDay = !fechaInicio.isSame(fechaFin, 'day');

    if (!isMultiDay) {
      // Renderizado normal para eventos de un día
      return (
        <ResizableDraggableTicket
          key={`${ticket.id}-${dia}-${mes}-${anio}`}
          ticket={ticket}
          style={{
            backgroundColor: tinycolor(ticket.color).lighten(30).toString(),
            borderLeft: `4px solid ${ticket.color}`,
            color: tinycolor(ticket.color).darken(20).toString(),
          }}
          onClick={(e) => handleTicketClick(ticket, e)}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          setDraggingTicket={setDraggingTicket}
        />
      );
    }

    // Para eventos multi-día
    const isStart = fechaActual.isSame(fechaInicio, 'day');
    const isEnd = fechaActual.isSame(fechaFin, 'day');
    const isMiddle = !isStart && !isEnd && fechaActual.isBetween(fechaInicio, fechaFin, 'day', '[]');

    if (!isStart && !isMiddle && !isEnd) return null;

    const className = `calendario__ticket-multidia ${
      isStart ? 'calendario__ticket-multidia--inicio' : 
      isEnd ? 'calendario__ticket-multidia--fin' : 
      'calendario__ticket-multidia--medio'
    }`;

    const style = {
      backgroundColor: tinycolor(ticket.color).lighten(30).toString(),
      color: tinycolor(ticket.color).darken(20).toString(),
      borderLeft: isStart ? `4px solid ${ticket.color}` : 'none',
      width: '100%',
      position: 'absolute',
      top: 0,
    };

    return (
      <div
        key={`${ticket.id}-${dia}-${mes}-${anio}`}
        className={className}
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          handleTicketClick(ticket, e);
        }}
      >
        {(isStart || isMiddle) && (
          <>
            <span className="ticket-title">{ticket.title}</span>
            {isStart && (
              <span className="ticket-time">
                {ticket.horaInicio} - {ticket.horaTermino}
              </span>
            )}
          </>
        )}
      </div>
    );
  }, [handleTicketClick]);

  const handlePlanibotClick = (fecha) => {
    if (!isProcessingPlanibot) {
      setIsProcessingPlanibot(true);
      const selectedDate = moment(fecha).format('YYYY-MM-DD');
      
      const ticketsForSelectedDate = tickets.filter(ticket => {
        const ticketDate = moment(ticket.vencimiento, "DD-MM-YYYY").format("YYYY-MM-DD");
        return ticketDate === selectedDate;
      });

      const ticketsWithPriority = ticketsForSelectedDate.map(ticket => ({
        ...ticket,
        id: ticket.id,
        priority: ticket.priority || 'Media'
      }));

      setSelectedDateTickets(ticketsWithPriority);
      setShowPlanibotDetail(true);
      setSelectedDate(selectedDate);

      setTimeout(() => {
        setIsProcessingPlanibot(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (tareasPendientes.length > 0) {
      manejarActualizacionTareasPendientes(tareasPendientes.length);
    }
  }, [tareasPendientes, manejarActualizacionTareasPendientes]);

  return (
    <div className="calendar-todo-container">
      <div className={`calendarioSemanal__semana-horizontal ${isOverDrop ? "calendarioSemanal__semana-horizontal--over" : ""}`} ref={drop}>
        <div style={{border: "0px solid #ffffff"}} className="calendarioSemanal__all-day-section">
          <table className="calendarioSemanal__tabla">
            <TableHeader 
              diasSemana={diasSemana} 
              diasSemanaArr={diasSemanaArr}
              today={today}
              onPlanibotClick={handlePlanibotClick}
            />
            <tbody>
              <AllDaySemanal 
                diasSemanaArr={diasSemanaArr}
                obtenerTicketsDia={obtenerTicketsDia}
                hoveredCell={hoveredCell}
                setHoveredCell={setHoveredCell}
                draggedOverCell={draggedOverCell}
                draggingTicket={draggingTicket}
                handleDragOverCell={handleDragOverCell}
                handleCellClick={handleCellClick}
                today={today}
                renderTicket={renderTicket}
                handleTicketClick={handleTicketClick}
                setDraggingTicket={setDraggingTicket}
                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
                currentCellTime={currentCellTime}
              />
            </tbody>
          </table>
        </div>

        <div className="calendarioSemanal__tabla-wrapper" ref={calendarRef}>
          <table className="calendarioSemanal__tabla">
            <tbody>
              {Array.from({ length: 24 }).map((_, hour) => (
                <CalendarRow
                  key={hour}
                  hour={hour}
                  hoveredRow={hoveredRow}
                  setHoveredRow={setHoveredRow}
                  diasSemanaArr={diasSemanaArr}
                  obtenerTicketsDia={obtenerTicketsDia}
                  agruparTicketsPorSolapamiento={agruparTicketsPorSolapamiento}
                  handleCellClick={handleCellClick}
                  hoveredCell={hoveredCell}
                  setHoveredCell={setHoveredCell}
                  handleDragOverCell={handleDragOverCell}
                  draggedOverCell={draggedOverCell}
                  selectedDateTime={selectedDateTime}
                  draggingTicket={draggingTicket}
                  gridSize={gridSize}
                  handleTicketClick={handleTicketClick}
                  currentCellTime={currentCellTime}
                  setDraggingTicket={setDraggingTicket}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  onResizeStop={onResizeStop}
                  today={today}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedTarea && (
        <DetalleTarea
          tarea={selectedTarea}
          onClose={handleCloseDetalleTarea}
          onEdit={handleEdit}
          horaInicio={selectedDateTime?.time}
          horaTermino={selectedDateTime?.time}
          fechaTermino={selectedDateTime?.fechaTermino}
        />
      )}
      {memoizedPopUp}
      {showGoogleMeets && (
        <GoogleMeets
          isOpen={showGoogleMeets}
          onClose={() => setShowGoogleMeets(false)}
          emailBusiness={businessEmail}
          idTicket={selectedTarea?.id}
          evento={selectedTarea?.evento}
          descripcion={selectedTarea?.descripcion}
          fechaInicio={selectedDateTime?.date}
          fechaVencimiento={selectedDateTime?.date}
          businessName={"Planius"}
          horaInicio={selectedDateTime?.time}
          horaTermino={selectedDateTime?.time}
        />
      )}
      <TabsContainer
        tickets={tickets}
        setTickets={setTickets}
        timeZone={timeZone}
        reordenarEventosConIA={reordenarEventosConIA}
        isVisible={isTabsVisible}
        manejarActualizacionTareasPendientes={setTareasPendientes}
      />
      {showPlanibotDetail && (
        <PlanibotDetail
          isOpen={showPlanibotDetail}
          onClose={() => {
            setShowPlanibotDetail(false);
          }}
          tickets={selectedDateTickets}
          tareasPendientes={tareasPendientes}
          fecha={selectedDate}
          criterioUsuario={"ganar más dinero"}
          businessEmail={businessEmail}
          onTasksUpdated={(updatedTicketId) => {
            setTickets(prevTickets => prevTickets.filter(ticket => ticket.id !== updatedTicketId));
          }}
        />
      )}
    </div>
  );
};

export default CalendarSemana;