// CalendarioDia.jsx
import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useDrop } from "react-dnd";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import ResizableDraggableTicket from "./ResizableDraggableTicket";
import { AuthContext } from "../../context/AuthContext";
import { updateTicket } from "../Functions";
import { updateGoogleCalendarEvent, agregarEventoACalendario } from "./FunctionsCalendar";
import { toast } from "react-toastify";
import "./CalendarioDia.css";
import TabsContainer from "../ReorganizarPlanibot/TabsContainer";
import DetalleTarea from "./DetalleTarea";
import GoogleMeets from "../GoogleMeets/GoogleMeets";
import ResumeTarea from "../addTarea/ResumeTarea";

const CalendarioDia = ({
  diaActual,
  mesActual,
  anioActual,
  tickets,
  setTickets, // Asegúrate de recibir setTickets como prop
  coloresTicket,
  cambiarFecha,
  isTabsVisible,
  manejarActualizacionTareasPendientes,
  timeZone,
  reordenarEventosConIA,
}) => {
  const { businessEmail, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken } = useContext(AuthContext);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);
  const [draggedOverCell, setDraggedOverCell] = useState(null);
  const [currentCellTime, setCurrentCellTime] = useState("");
  const [currentTimePosition, setCurrentTimePosition] = useState(null);
  const [selectedTarea, setSelectedTarea] = useState(null);
  const [sePuedeAbrir, setSePuedeAbrir] = useState(true);
  const [draggingTicket, setDraggingTicket] = useState(null); // Nuevo estado para rastrear el ticket arrastrado
  const [selectedDateTime, setSelectedDateTime] = useState(null); // Define selectedDateTime
  const [showPopUp, setShowPopUp] = useState(false);
  const [showGoogleMeets, setShowGoogleMeets] = useState(false);
  const [type, setType] = useState("tarea");
  const [lastClickTime, setLastClickTime] = useState(0);
  const [isResizing, setIsResizing] = useState(false); // Nuevo estado
  const [tareasPendientes, setTareasPendientes] = useState([]);

  const calendarRef = useRef(null);

  moment.locale("es");

  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = moment();
      const hour = now.hours();
      const minutes = now.minutes();
      const cellHeight = 50; // Altura de cada celda en px
      const topPosition = hour * cellHeight + (minutes / 60) * cellHeight;
      setCurrentTimePosition(topPosition);
    };

    updateCurrentTimePosition();
    const intervalId = setInterval(updateCurrentTimePosition, 60000); // Actualiza cada minuto

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarHeight = calendarRef.current.scrollHeight;
      calendarRef.current.scrollTop = calendarHeight / 3.7;
    }
  }, []);

  useEffect(() => {
    if (tareasPendientes.length > 0) {
      manejarActualizacionTareasPendientes(tareasPendientes.length);
    }
  }, [tareasPendientes, manejarActualizacionTareasPendientes]);

  const obtenerTicketsDia = (dia) => {
    return tickets.filter((ticket) => {
      if (ticket.creacion) {
        const [ticketDia, ticketMes, ticketAnio] = ticket.vencimiento.split("-");
        const fechaTicket = new Date(ticketAnio, ticketMes - 1, ticketDia);
        const fechaActual = new Date(anioActual, mesActual, dia);
        return fechaTicket.toDateString() === fechaActual.toDateString();
      }
      return false;
    });
  };

  const ticketsDia = obtenerTicketsDia(diaActual);
  const ticketsTodoElDia = ticketsDia.filter((ticket) => ticket.horaInicio === "Todo el día");

  const calcularRowSpan = (inicio, fin) => {
    const inicioEnMinutos = inicio.getHours() * 60 + inicio.getMinutes();
    const finEnMinutos = fin.getHours() * 60 + fin.getMinutes();
    return Math.ceil((finEnMinutos - inicioEnMinutos) / 30); // 30 minutos por fila
  };

  const [{ isOverDrop }, drop] = useDrop({
    accept: "TICKET",
    drop: (item, monitor) => {
      if (draggedOverCell) {
        const { dia, mes, anio, hour, isAllDay } = draggedOverCell;
        const offsetY = monitor.getClientOffset().y - monitor.getInitialClientOffset().y;
        onDropTicket(item, dia, mes, anio, hour, offsetY, isAllDay);
        setDraggedOverCell(null);
        setDraggingTicket(null);
      }
    },
    collect: (monitor) => ({
      isOverDrop: monitor.isOver(),
    }),
  });

  const onDropTicket = async (ticket, dia, mes, anio, hour, offsetY, isAllDay) => {
    const timezone = "America/Santiago";
    let newStartTime, newEndTime;

    if (isAllDay) {
      newStartTime = "Todo el día";
      newEndTime = "Todo el día";
    } else {
      const isHalfHour = offsetY > 25;
      newStartTime = moment.tz(
        { year: anio, month: mes, day: dia, hour, minute: isHalfHour ? 30 : 0 },
        timezone
      );

      // Calcular duración desde el ticket original
      const originalStartTime = moment.tz(ticket.horaInicio, "HH:mm", timezone);
      const originalEndTime = moment.tz(ticket.horaTermino, "HH:mm", timezone);
      const duration = moment.duration(originalEndTime.diff(originalStartTime));

      newEndTime = newStartTime.clone().add(duration);

      // Asegurarse de que la hora de fin no sea antes o igual que la hora de inicio
      if (newEndTime.isSameOrBefore(newStartTime)) {
        newEndTime = newStartTime.clone().add(1, "hour");
      }

      newStartTime = newStartTime.format("HH:mm");
      newEndTime = newEndTime.format("HH:mm");
    }

    const nuevaFechaVencimiento = moment.tz({ year: anio, month: mes, day: dia }, timezone);
    const nuevaFechaFin = nuevaFechaVencimiento.clone();

    const updatedData = {
      vencimiento: nuevaFechaVencimiento.format("DD-MM-YYYY"),
      fechaFin: nuevaFechaFin.format("DD-MM-YYYY"),
      horaInicio: newStartTime,
      horaTermino: newEndTime,
      status: "Proceso",
    };

    try {
      await updateTicket(businessEmail, ticket.id, updatedData);
      setTickets((prevTickets) =>
        prevTickets.map((t) => (t.id === ticket.id ? { ...t, ...updatedData } : t))
      );

      if (ticket.status === "Proceso" || ticket.status === "Terminado") {
        await updateGoogleCalendarEvent(ticket, updatedData, ticket.color, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
      } else {
        const fechaInicioISO = nuevaFechaVencimiento.toISOString();
        await agregarEventoACalendario(
          businessEmail,
          ticket.id,
          ticket.title,
          ticket.descripcion,
          fechaInicioISO,
          nuevaFechaFin.toISOString(),
          updatedData.horaInicio,
          updatedData.horaTermino,
          ticket.color,
          'primary',
          currentUser, accessToken, refreshToken, tokenExpiry,
        );
      }

    } catch (error) {
      console.error("Error actualizando el ticket:", error);
      toast.error(`Error al actualizar el ticket: ${error.message}`);
    }

    setSePuedeAbrir(false);
  };

  const onResizeStop = async (ticket, newHeight) => {
    setIsResizing(true); // Activar el estado de redimensionamiento
    
    const totalMinutes = (newHeight / 50) * 60;
    const newEndTime = moment(ticket.horaInicio, "HH:mm").add(totalMinutes, "minutes");
    const updatedData = { ...ticket, horaTermino: newEndTime.format("HH:mm") };

    try {
      await updateTicket(businessEmail, ticket.id, updatedData);

      if (ticket.idEventoCalendario) {
        await updateGoogleCalendarEvent(ticket, updatedData, ticket.color, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
      }
    } catch (error) {
      console.error("Error actualizando el ticket:", error);
      toast.error(`Error al actualizar el ticket: ${error.message}`);
    }

    setSePuedeAbrir(false);
    // Agregar un timeout para resetear el estado de redimensionamiento
    setTimeout(() => {
      setIsResizing(false);
      setSePuedeAbrir(true);
    }, 400);
  };

  const handleDragOverCell = (e, dia, mes, anio, hour, isAllDay) => {
    e.preventDefault(); // Importante para permitir el drop
    setDraggedOverCell({ dia, mes, anio, hour, isAllDay });

    const cellRect = e.target.getBoundingClientRect();
    const offsetY = e.clientY - cellRect.top;
    const halfHour = offsetY > cellRect.height / 2 ? "30" : "00";

    if (isAllDay || isNaN(hour)) {
      setCurrentCellTime(`Fecha: ${dia}/${mes + 1}/${anio}  Todo el día`);
    } else {
      setCurrentCellTime(`Fecha: ${dia}/${mes + 1}/${anio}  Hora: ${hour}:${halfHour}`);
    }

    setSelectedDateTime({
      date: `${anio}-${(mes + 1).toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`,
      time: halfHour === "30" ? `${hour.toString().padStart(2, '0')}:30` : `${hour.toString().padStart(2, '0')}:00`,
    });
  };

  const agruparTicketsPorSolapamiento = (ticketsDia) => {
    const grupos = [];

    const ticketsOrdenados = ticketsDia.sort((a, b) =>
      moment(a.horaInicio, "HH:mm").diff(moment(b.horaInicio, "HH:mm"))
    );

    ticketsOrdenados.forEach((ticket) => {
      const inicio = moment(ticket.horaInicio, "HH:mm");
      const fin = moment(ticket.horaTermino, "HH:mm");

      let grupoExistente = grupos.find((grupo) =>
        grupo.some((t) => {
          const tInicio = moment(t.horaInicio, "HH:mm");
          const tFin = moment(t.horaTermino, "HH:mm");
          return (
            (inicio.isBefore(tFin) && fin.isAfter(tInicio)) ||
            (tInicio.isBefore(fin) && tFin.isAfter(inicio))
          );
        })
      );

      if (grupoExistente) {
        grupoExistente.push(ticket);
      } else {
        grupos.push([ticket]);
      }
    });

    return grupos;
  };

  const handleTicketClick = (ticket, event) => {
    if (event && typeof event.stopPropagation === "function") {
      event.stopPropagation();
    }
    setSePuedeAbrir(false);
    setSelectedTarea(ticket);
  };

  const handleCloseDetalleTarea = () => {
    setSelectedTarea(null);
    setSePuedeAbrir(true);
  };

  const isCellHovered = hoveredCell === `${diaActual}-todo-el-dia`;
  const ticketsToShow = isCellHovered ? ticketsTodoElDia : ticketsTodoElDia.slice(0, 3);
  const additionalTickets = ticketsTodoElDia.length - 3;

  const handleCellClick = useCallback((event, hour, dia, mes, anio) => {
    event.stopPropagation();
    const currentTime = new Date().getTime();
    
    // Si estamos redimensionando o el último clic fue muy reciente, no abrir el modal
    if (isResizing || currentTime - lastClickTime < 300) {
      return;
    }

    const rect = event.target.getBoundingClientRect();
    const clickPositionInCell = event.clientY - rect.top;
    const isHalfHour = clickPositionInCell > (50 / 2);
    const selectedTime = isHalfHour
      ? `${hour.toString().padStart(2, '0')}:30`
      : `${hour.toString().padStart(2, '0')}:00`;

    setSelectedDateTime({
      date: `${anio}-${(mes + 1).toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`,
      time: selectedTime,
    });

    // Abrir directamente ResumeTarea
    setShowPopUp(true);
    setType("evento");

    setLastClickTime(currentTime);
  }, [lastClickTime, isResizing]);

  // Añade estas funciones cerca de las otras funciones de manejo de eventos
  const handleDragStart = useCallback(() => {
    console.log("Drag started");
    // Puedes agregar lógica adicional aquí si es necesario
  }, []);

  const handleDragEnd = useCallback(() => {
    console.log("Drag ended");
    // Puedes agregar lógica adicional aquí si es necesario
  }, []);

  // Modificar la celda de "Todo el día" para incluir la verificación del handle de redimensionamiento
  const handleTodoDiaCellClick = (e) => {
    // Si el click viene de un elemento con la clase resizable-handle, no propagamos el evento
    if (e.target.closest('.react-resizable-handle')) {
      e.stopPropagation();
      return;
    }
    
    handleCellClick(e, 0, diaActual, mesActual, anioActual);
  };

  return (
    <div className="calendar-todo-container" ref={drop}>
      <div
        className={`calendario-dia-container-diario ${
          isOverDrop ? "calendarioSemanal__semana-horizontal--over" : ""
        }`}
      >
        <div className="calendario-dia-diario-wrapper" ref={calendarRef}>
          <table className="calendario-dia-diario">
            <thead>
              <tr>
                <th style={{ position: "sticky" }} className="hora-header">
                  Hora
                </th>
                <th className="tareas-header">Tareas</th>
              </tr>
            </thead>
            <tbody>
              <tr
                onMouseEnter={() => setHoveredRow(0)}
                onMouseLeave={() => setHoveredRow(null)}
                className={hoveredRow === 0 ? "calendarioSemanal__hovered-row" : ""}
              >
                <td style={{ position: "sticky", height: "60px" }} className="hora-cell">
                  Todo el día
                </td>
                <td
                  style={{ position: "sticky", height: "40px" }}
                  onMouseEnter={() => setHoveredCell(`${diaActual}-todo-el-dia`)}
                  onMouseLeave={() => setHoveredCell(null)}
                  onDragOver={(e) =>
                    handleDragOverCell(e, diaActual, mesActual, anioActual, 0, true)
                  }
                  onClick={handleTodoDiaCellClick}
                  className={
                    draggedOverCell &&
                    draggedOverCell.dia === diaActual &&
                    draggedOverCell.isAllDay
                      ? "calendarioSemanal__dragged-over-cell"
                      : hoveredCell === `${diaActual}-todo-el-dia`
                      ? "calendarioSemanal__hovered-cell"
                      : ""
                  }
                >
                  {ticketsToShow.map((ticket) => (
                    <ResizableDraggableTicket
                      key={ticket.id}
                      ticket={ticket}
                      style={{
                        backgroundColor:
                          coloresTicket[ticketsDia.indexOf(ticket) % coloresTicket.length],
                        height: "100%",
                      }}
                      onResizeStop={onResizeStop}
                      onClick={(e) => handleTicketClick(ticket, e)}
                      setDraggingTicket={setDraggingTicket}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                    />
                  ))}
                  {!isCellHovered && ticketsTodoElDia.length > 3 && (
                    <div className="and-more">y {additionalTickets} más</div>
                  )}
                  {/* Renderizar el placeholder cuando se está arrastrando sobre esta celda */}
                  {draggedOverCell &&
                    draggedOverCell.dia === diaActual &&
                    draggedOverCell.isAllDay &&
                    draggingTicket && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          height: '50px', // Altura para "Todo el día"
                          pointerEvents: 'none',
                          zIndex: 10, // Asegura que el placeholder esté por encima de otros elementos
                        }}
                      >
                        <ResizableDraggableTicket
                          ticket={draggingTicket}
                          isPlaceholder={true}
                        />
                      </div>
                    )}
                </td>
              </tr>
              {Array.from({ length: 24 }).map((_, hour) => (
                <tr
                  key={hour}
                  onMouseEnter={() => setHoveredRow(hour + 1)}
                  onMouseLeave={() => setHoveredRow(null)}
                  className={hoveredRow === hour + 1 ? "calendarioSemanal__hovered-row" : ""}
                >
                  <td className="hora-cell" style={{ height: "30px", marginRight: "20px" }}>
                    {`${hour.toString().padStart(2, "0")}:00`}
                  </td>
                  <td
                    onMouseEnter={() => setHoveredCell(`${diaActual}-${hour}`)}
                    onMouseLeave={() => setHoveredCell(null)}
                    onDragOver={(e) =>
                      handleDragOverCell(e, diaActual, mesActual, anioActual, hour, false)
                    }
                    onClick={(e) => {
                      // Si el click viene del handle de redimensionamiento, no propagamos
                      if (e.target.closest('.react-resizable-handle')) {
                        e.stopPropagation();
                        return;
                      }
                      handleCellClick(e, hour, diaActual, mesActual, anioActual);
                    }}
                    className={
                      draggedOverCell &&
                      draggedOverCell.dia === diaActual &&
                      draggedOverCell.hour === hour
                        ? "calendarioSemanal__dragged-over-cell"
                        : hoveredCell === `${diaActual}-${hour}`
                        ? "calendarioSemanal__hovered-cell"
                        : ""
                    }
                    style={{ position: "relative", height: "45.4px" }}
                  >
                    <div className="calendarioSemanal__ticket-container" style={{ position: 'relative', height: `${50}px` }}>
                      {agruparTicketsPorSolapamiento(ticketsDia).map((grupo, grupoIndex) => {
                        return grupo.map((ticket, ticketIndex) => {
                          const inicio = moment(ticket.horaInicio, "HH:mm");
                          if (inicio.hours() !== hour) return null;
                          const fin = moment(ticket.horaTermino, "HH:mm");
                          const rowSpan = calcularRowSpan(inicio.toDate(), fin.toDate());
                          const width = `${100 / grupo.length}%`;
                          return (
                            <div
                              key={ticket.id}
                              style={{
                                width,
                                height: `${rowSpan * 50}px`,
                                position: "absolute",
                                top: `${(inicio.minutes() / 60) * 100}%`,
                                left: `${(ticketIndex / grupo.length) * 100}%`,
                              }}
                            >
                              <ResizableDraggableTicket
                                ticket={ticket}
                                onResizeStop={onResizeStop}
                                onClick={(e) => handleTicketClick(ticket, e)}
                                style={{
                                  backgroundColor:
                                    coloresTicket[ticketsDia.indexOf(ticket) % coloresTicket.length],
                                  height: "100%",
                                  width: "100%",
                                  borderLeft: "4px solid",
                                  borderTop: "0.5px solid",
                                }}
                                setDraggingTicket={setDraggingTicket}
                                onDragStart={handleDragStart}
                                onDragEnd={handleDragEnd}
                              />
                            </div>
                          );
                        });
                      })}
                      {hour === moment().hours() && (
                        <div
                          className="current-time-line"
                          style={{
                            top: `${(moment().minutes() / 60) * 100}%`,
                            position: "absolute",
                            left: 0,
                            right: 0,
                            height: "2px",
                            backgroundColor: "rgba(255, 0, 0, 0.656)",
                          }}
                        ></div>
                      )}
                    </div>
                    {/* Renderizar el placeholder cuando se está arrastrando sobre esta celda */}
                    {draggedOverCell &&
                      draggedOverCell.dia === diaActual &&
                      draggedOverCell.hour === hour &&
                      draggingTicket && (
                        <div
                          style={{
                            position: 'absolute',
                            top: `${selectedDateTime?.time.endsWith(":30") ? 25 : 0}px`, // gridSize / 2 = 25px
                            left: 0,
                            right: 0,
                            height: `${selectedDateTime?.time.endsWith(":30") ? 25 : 50}px`, // gridSize / 2 o gridSize
                            pointerEvents: 'none',
                            zIndex: 10, // Asegura que el placeholder esté por encima de otros elementos
                          }}
                        >
                          <ResizableDraggableTicket
                            ticket={draggingTicket}
                            isPlaceholder={true}
                          />
                        </div>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <TabsContainer
        tickets={tickets}
        setTickets={setTickets}
        timeZone={timeZone}
        reordenarEventosConIA={reordenarEventosConIA}
        isVisible={isTabsVisible}
        manejarActualizacionTareasPendientes={setTareasPendientes}
      />
      {selectedTarea && (
        <DetalleTarea
          tarea={selectedTarea}
          onClose={handleCloseDetalleTarea}
          horaInicio={selectedTarea.horaInicio}
          horaTermino={selectedTarea.horaTermino}
          fechaTermino={selectedTarea.fechaTermino}
        />
      )}
      {showPopUp && (
        <ResumeTarea
          isOpen={showPopUp}
          onClose={() => setShowPopUp(false)}
          selectedDateTime={selectedDateTime}
          type={type}
          selectedHour={selectedDateTime ? selectedDateTime.time : null}
        />
      )}
      {showGoogleMeets && (
        <GoogleMeets
          isOpen={showGoogleMeets}
          onClose={() => setShowGoogleMeets(false)}
          emailBusiness={businessEmail}
          idTicket={selectedTarea?.id}
          evento={selectedTarea?.evento}
          descripcion={selectedTarea?.descripcion}
          fechaInicio={selectedDateTime?.date}
          fechaVencimiento={selectedDateTime?.date}
          businessName={"Planius"}
          horaInicio={selectedDateTime?.time}
          horaTermino={selectedDateTime?.time}
        />
      )}
    </div>
  );
};

export default CalendarioDia;