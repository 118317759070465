// ResizableDraggableTicket.js
import React, { useState, useEffect, useCallback, useRef, memo, useMemo } from "react";
import { useDrag, DragPreviewImage } from "react-dnd";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbtack,
  faVideo,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import tinycolor from "tinycolor2";
import ReactGA from "react-ga4";
import TooltipPortal from "./TooltipPortal";
import moment from "moment-timezone";
import { isMobile } from 'react-device-detect';
import { toast } from "react-toastify";

const ResizableDraggableTicket = memo(
  ({
    ticket,
    onResizeStop,
    onClick,
    currentCellTime,
    setDraggingTicket,
    isPlaceholder = false,
    onDragStart,
    onDragEnd,
    isOverlapping = false,
  }) => {
    const [currentEndTime, setCurrentEndTime] = useState(ticket.horaTermino);
    const [isResizing, setIsResizing] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const timeoutRef = useRef(null);
    const ticketRef = useRef(null);
    const [resizeStartTime, setResizeStartTime] = useState(null);
    const [canResize, setCanResize] = useState(!isMobile);

    const isAllDayEvent = ticket.horaInicio === "Todo el día";

    useEffect(() => {
      if (ticket.horaTermino) {
        setCurrentEndTime(ticket.horaTermino);
      } else if (ticket.horaInicio && ticket.horaInicio !== "Todo el día") {
        const [startHour, startMinutes] = ticket.horaInicio.split(":");
        const defaultEndHour = parseInt(startHour) + 1;
        setCurrentEndTime(`${defaultEndHour}:${startMinutes}`);
      }
    }, [ticket.horaTermino, ticket.horaInicio]);

    const calculateEndTime = useCallback((startTime, height) => {
      const startHour = parseInt(startTime.split(":")[0], 10);
      const startMinutes = parseInt(startTime.split(":")[1], 10);

      const totalMinutesAdded = (height / 12.5) * 15;
      const totalMinutes = startHour * 60 + startMinutes + totalMinutesAdded;

      const endHour = Math.floor(totalMinutes / 60);
      const endMinutes = totalMinutes % 60;

      return `${endHour}:${endMinutes < 10 ? "0" : ""}${endMinutes}`;
    }, []);

    const calculateHeight = useCallback((startTime, endTime) => {
      if (startTime === "Todo el día" || endTime === "Todo el día") {
        return 12.5;
      }

      if (!startTime) {
        return 12.5;
      }

      if (!endTime) {
        const [startHour, startMinutes] = startTime.split(":");
        const defaultEndHour = parseInt(startHour) + 1;
        endTime = `${defaultEndHour}:${startMinutes}`;
      }

      const startHour = parseInt(startTime.split(":")[0], 10);
      const startMinutes = parseInt(startTime.split(":")[1], 10);
      const endHour = parseInt(endTime.split(":")[0], 10);
      const endMinutes = parseInt(endTime.split(":")[1], 10);

      if (
        isNaN(startHour) ||
        isNaN(startMinutes) ||
        isNaN(endHour) ||
        isNaN(endMinutes)
      ) {
        return 12.5;
      }

      const totalStartMinutes = startHour * 60 + startMinutes;
      const totalEndMinutes = endHour * 60 + endMinutes;

      return ((totalEndMinutes - totalStartMinutes) / 15) * 12.5;
    }, []);

    const handleResizeStart = useCallback((e) => {
      if (isMobile) {
        e.preventDefault();
        setResizeStartTime(Date.now());
        
        const timeoutId = setTimeout(() => {
          setCanResize(true);
          setIsResizing(true);
        }, 2000);

        const handleTouchEnd = () => {
          clearTimeout(timeoutId);
          setResizeStartTime(null);
          setCanResize(false);
          document.removeEventListener('touchend', handleTouchEnd);
        };

        document.addEventListener('touchend', handleTouchEnd);
      } else {
        setIsResizing(true);
      }
    }, []);

    const handleResize = useCallback(
      (e, { size }) => {
        e.stopPropagation();
        if (!size) return; // Validar que size existe
        
        const endTime = calculateEndTime(ticket.horaInicio, size.height);
        setCurrentEndTime(endTime);
      },
      [ticket.horaInicio, calculateEndTime]
    );

    const handleResizeStopInternal = useCallback(
      (e, { size }) => {
        if (!size) return;
        
        e.stopPropagation();
        e.preventDefault();
        
        setIsResizing(false);
        onResizeStop(ticket, size.height);
      },
      [onResizeStop, ticket]
    );

    const transparentImage =
      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMCIgaGVpZ2h0PSIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciLz4=";

    const [{ isDraggingItem }, drag, preview] = useDrag({
      type: "TICKET",
      item: () => {
        if (ticket.status === "Proceso" && !ticket.idEventoCalendario) {
          toast.warning("El evento aún se está sincronizando. Por favor, espera un momento.");
          return null;
        }
        
        setDraggingTicket(ticket);
        onDragStart();
        return { 
          ...ticket, 
          originalStartTime: ticket.horaInicio,
          previewTime: currentCellTime 
        };
      },
      canDrag: () => {
        return !(ticket.status === "Proceso" && !ticket.idEventoCalendario);
      },
      end: (item, monitor) => {
        setDraggingTicket(null);
        onDragEnd();
        
        const dropResult = monitor.getDropResult();
        if (dropResult && dropResult.time) {
          item.horaInicio = dropResult.time;
        }
        
        const didDrop = monitor.didDrop();
        if (didDrop) {
          const target = document.elementFromPoint(
            monitor.getClientOffset()?.x || 0,
            monitor.getClientOffset()?.y || 0
          );
          if (target) {
            const preventClickEvent = new CustomEvent('preventClick', {
              bubbles: true,
              cancelable: true
            });
            target.dispatchEvent(preventClickEvent);
          }
        }
        
        ReactGA.event({
          category: "Ticket",
          action: "Drag Ticket Semanal",
          label: "Arrastrar Ticket en Vista Semanal",
          value: 1,
        });
      },
      collect: (monitor) => ({
        isDraggingItem: monitor.isDragging(),
      }),
    });

    const handleMouseEnter = useCallback(() => {
      if (!isDraggingItem && !isPlaceholder) {
        timeoutRef.current = setTimeout(() => {
          if (ticketRef.current) {
            const rect = ticketRef.current.getBoundingClientRect();
            setTooltipPosition({
              top: rect.top,
              left: rect.left + rect.width / 2,
            });
            setShowTooltip(true);
          }
        }, 500);
      }
    }, [isDraggingItem, isPlaceholder]);

    const handleMouseLeave = useCallback(() => {
      clearTimeout(timeoutRef.current);
      setShowTooltip(false);
    }, []);

    useEffect(() => {
      if (isDraggingItem) {
        setShowTooltip(false);
      }
    }, [isDraggingItem]);

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const isResizable = ticket.type !== !isAllDayEvent;
    const ticketClass = isAllDayEvent
      ? "calendario__ticket-semanal-todoeldia"
      : ticket.type === "tarea"
      ? "calendario__ticket-tarea"
      : "calendario__ticket-semanal";

    const backgroundColor = tinycolor(ticket.color).lighten(30).toString();
    const textColor = tinycolor(ticket.color).lighten(-15).toString();

    const hasItems = ticket.items && ticket.items.length > 0;

    const shouldShowTime = () => {
      if (!ticket.horaInicio || !currentEndTime) return true;
      const [startHour, startMinutes] = ticket.horaInicio.split(":").map(Number);
      const [endHour, endMinutes] = currentEndTime.split(":").map(Number);
      const startTotalMinutes = startHour * 60 + startMinutes;
      const endTotalMinutes = endHour * 60 + endMinutes;
      const duration = endTotalMinutes - startTotalMinutes;
      
      // Mostrar hora si dura más de 2 horas (incluso con overlapping) o si dura más de 30 min sin overlapping
      return duration >= 120 || (!isOverlapping && duration > 30);
    };

    const shouldShowIcons = () => {
      if (!ticket.horaInicio || !currentEndTime) return false;
      const [startHour, startMinutes] = ticket.horaInicio.split(":").map(Number);
      const [endHour, endMinutes] = currentEndTime.split(":").map(Number);
      const startTotalMinutes = startHour * 60 + startMinutes;
      const endTotalMinutes = endHour * 60 + endMinutes;
      const duration = endTotalMinutes - startTotalMinutes;
      return duration >= 120; // 120 minutos = 2 horas
    };

    const ticketContent = (
      <div
        className={`ticket-content ${isOverlapping ? "overlapping" : ""}`}
        style={{
          marginTop: "0px",
          fontSize: isOverlapping ? "0.52rem" : "0.7rem",
          padding: isOverlapping ? "1px" : undefined,
        }}
      >
        <div style={{ fontWeight: "500"}} className="ticket-title">{ticket.title}</div>
        {!isAllDayEvent && shouldShowTime() && (
          <div className="ticket-time" style={{ fontWeight: "200", marginLeft: "0px" }}>
            {`${ticket.horaInicio} - ${currentEndTime}`}
          </div>
        )}
        {(!isOverlapping || shouldShowIcons()) && (
          <div
            className="ticket-icons"
            style={{
              position: "absolute",
              bottom: "5px",
              right: "5px",
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            {hasItems && (
              <FontAwesomeIcon
                icon={faPaperclip}
                className="paperclip-icon"
                style={{ marginRight: "0" }}
              />
            )}
            {ticket.enlaceMeet && (
              <FontAwesomeIcon
                icon={faVideo}
                className="meet-icon"
                style={{ marginRight: "0" }}
              />
            )}
            {ticket.idEventoCalendario && (
              <FontAwesomeIcon
                icon={faGoogle}
                className="google-icon"
                style={{ marginRight: "0" }}
              />
            )}
            {!ticket.canEdit && (
              <FontAwesomeIcon
                icon={faThumbtack}
                className="non-editable-icon"
                style={{ marginRight: "0" }}
              />
            )}
          </div>
        )}
      </div>
    );

    const initialHeight = calculateHeight(ticket.horaInicio, ticket.horaTermino);

    const resizeHandles = useMemo(() => {
      if (!ticket.canEdit || isPlaceholder) return [];
      return canResize ? ['s'] : [];
    }, [ticket.canEdit, isPlaceholder, canResize]);

    const ticketElement = isAllDayEvent ? (
      <div
        ref={(node) => {
          if (!isPlaceholder && ticket.canEdit) {
            drag(node);
          }
          ticketRef.current = node;
        }}
        className={`${ticketClass} ${isDraggingItem ? "dragging" : ""} ${
          isPlaceholder ? "placeholder" : ""
        }`}
        onClick={(e) => {
          if (!isPlaceholder) {
            e.stopPropagation();
            onClick(e, ticket);
          }
        }}
        style={{
          opacity: isDraggingItem && !isPlaceholder ? 0 : 1,
          cursor: !isPlaceholder && ticket.canEdit ? "move" : "default",
          backgroundColor,
          color: textColor,
          position: "relative",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {ticketContent}
      </div>
    ) : (
      <ResizableBox
        width={Infinity}
        height={initialHeight || 12.5}
        minConstraints={[Infinity, 12.5]}
        maxConstraints={[Infinity, 1100]}
        resizeHandles={resizeHandles}
        draggableOpts={{ grid: [1, 12.5] }}
        onResizeStart={(e) => {
          if (e) {
            e.stopPropagation();
          }
          handleResizeStart(e);
        }}
        onResize={(e, data) => {
          if (e) {
            e.stopPropagation();
          }
          handleResize(e, data);
        }}
        onResizeStop={(e, data) => {
          if (!isPlaceholder) {
            handleResizeStopInternal(e, data);
          }
        }}
        handle={
          isResizable && !isPlaceholder ? (
            <span
              className="resize-handle"
              style={{ backgroundColor: "#ffffff00" }}
            />
          ) : null
        }
      >
        <div
          ref={(node) => {
            if (!isPlaceholder && ticket.canEdit) {
              drag(node);
            }
            ticketRef.current = node;
          }}
          className={`${ticketClass} ${isDraggingItem ? "dragging" : ""} ${
            isPlaceholder ? "placeholder" : ""
          }`}
          onClick={(e) => {
            if (!isPlaceholder) {
              e.stopPropagation();
              onClick(e, ticket);
            }
          }}
          style={{
            opacity: isDraggingItem && !isPlaceholder ? 0 : 1,
            cursor: !isPlaceholder && ticket.canEdit ? "move" : "default",
            height: "98%",
            position: "relative",
            backgroundColor,
            color: textColor,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {ticketContent}
        </div>
      </ResizableBox>
    );

    return (
      <div ref={preview} style={{ position: "relative" }}>
        <DragPreviewImage connect={preview} src={transparentImage} />

        {isResizing && isResizable && (
          <label className="current-time-indicator">
            Hora de término: {currentEndTime}
          </label>
        )}

        {ticketElement}

        {!isDraggingItem && showTooltip && !isPlaceholder && (
          <TooltipPortal position={tooltipPosition}>
            <p>
              <strong>Título:</strong> {ticket.title}
            </p>
            <p>
              <strong>Hora:</strong>{" "}
              {isAllDayEvent
                ? "Todo el día"
                : `${ticket.horaInicio} - ${currentEndTime}`}
            </p>
            <p>
              <strong>Tipo:</strong> {ticket.type}
            </p>
          </TooltipPortal>
        )}
      </div>
    );
  }
);

export default ResizableDraggableTicket;
