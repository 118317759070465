import React, { useState } from 'react';
import Modal from 'react-modal';
import './RecurrenceSelector.css';
import moment from 'moment-timezone';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

// Asegurarse de que Modal sepa dónde está el elemento raíz de la app
Modal.setAppElement('#root');

// Registrar el idioma español para el datepicker
registerLocale('es', es);

const RecurrenceSelector = ({ value, onChange }) => {
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const [endConfig, setEndConfig] = useState({
    type: 'never',
    occurrences: 1,
    untilDate: ''
  });

  const [customConfig, setCustomConfig] = useState({
    frequency: 'DAILY',
    interval: 1,
    weekDays: [],
    endType: 'never',
    endDate: '',
    occurrences: 1
  });

  const recurrenceOptions = [
    { value: '', label: 'No se repite' },
    { value: 'DAILY', label: 'Cada día' },
    { value: 'WEEKLY', label: 'Cada 7 días' },
    { value: 'MONTHLY', label: 'Una vez al mes' },
    { value: 'YEARLY', label: 'Una vez al año' },
    { value: 'WORKDAYS', label: 'Todos los días laborables (Lun-Vie)' },
    { value: 'WEEKLY_DAYS', label: 'Días específicos de la semana' },
    { value: 'CUSTOM', label: 'Personalizar...' }
  ];

  /**
   * handleEndConfigChange:
   *  - type: 'never', 'count', 'until'
   *  - val : puede ser int (para 'count') o string de fecha (para 'until'), o null
   *  - currentOption: valor de la frecuencia, ej. 'DAILY'
   */
  const handleEndConfigChange = (type, val, currentOption) => {
    setEndConfig(prev => ({
      ...prev,
      type,
      ...(type === 'count' ? { occurrences: val } : {}),
      ...(type === 'until' ? { untilDate: val } : {})
    }));

    let rrule = '';
    // Usar el valor recién pasado, NO el estado 'selectedOption'
    if (currentOption) {
      if (type === 'never') {
        // Definir fecha lejana (ejemplo: 31/12/2030 a las 23:59:59 UTC)
        const farDate = moment
          .tz('2030-12-31 23:59:59', 'America/Santiago')
          .utc()
          .format('YYYYMMDDTHHmmss[Z]');

        if (currentOption === 'WORKDAYS') {
          rrule = `RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;UNTIL=${farDate};INTERVAL=1`;
        } else {
          rrule = `RRULE:FREQ=${currentOption};UNTIL=${farDate};INTERVAL=1`;
        }

      } else if (type === 'count') {
        if (currentOption === 'WORKDAYS') {
          rrule = `RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;COUNT=${val};INTERVAL=1`;
        } else {
          rrule = `RRULE:FREQ=${currentOption};COUNT=${val};INTERVAL=1`;
        }

      } else if (type === 'until') {
        const untilDate = moment
          .tz(val, 'YYYY-MM-DD', 'America/Santiago')
          .endOf('day')
          .utc()
          .format('YYYYMMDDTHHmmss[Z]');

        if (currentOption === 'WORKDAYS') {
          rrule = `RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;UNTIL=${untilDate};INTERVAL=1`;
        } else {
          rrule = `RRULE:FREQ=${currentOption};UNTIL=${untilDate};INTERVAL=1`;
        }
      }
    }

    onChange(rrule);
  };

  /**
   * handleMainOptionChange:
   * Cuando se elige en el <select> principal 'DAILY', 'WEEKLY', etc.
   */
  const handleMainOptionChange = (val) => {
    setSelectedOption(val);

    if (val === '') {
      // "No se repite"
      onChange('');
      return;
    }
    if (val === 'CUSTOM') {
      // Abre modal personalizado
      setShowCustomModal(true);
      return;
    }
    if (val === 'WEEKLY_DAYS') {
      // Configuración directa para días específicos de la semana
      setCustomConfig(prev => ({
        ...prev,
        frequency: 'WEEKLY',
        interval: 1,
        weekDays: [],
        endType: 'never'
      }));
      setShowCustomModal(true);
      return;
    }
    // Para otras opciones, forzar la regla con la config actual de finalización
    handleEndConfigChange(
      endConfig.type,
      endConfig.type === 'count'
        ? endConfig.occurrences
        : endConfig.type === 'until'
        ? endConfig.untilDate
        : null,
      val
    );
  };

  /**
   * handleCustomSubmit: se ejecuta al confirmar configuración personalizada en el modal
   */
  const handleCustomSubmit = () => {
    let rrule = `FREQ=${customConfig.frequency};INTERVAL=${customConfig.interval}`;

    if (customConfig.frequency === 'WEEKLY' && customConfig.weekDays.length > 0) {
      const dayMap = {
        LU: 'MO',
        MA: 'TU',
        MI: 'WE',
        JU: 'TH',
        VI: 'FR',
        SA: 'SA',
        DO: 'SU'
      };
      const days = customConfig.weekDays.map((day) => dayMap[day]).join(',');
      rrule += `;BYDAY=${days}`;
    }

    if (customConfig.endType === 'until' && customConfig.endDate) {
      const untilDate = moment
        .tz(customConfig.endDate, 'YYYY-MM-DD', 'America/Santiago')
        .endOf('day')
        .utc()
        .format('YYYYMMDDTHHmmss[Z]');
      rrule += `;UNTIL=${untilDate}`;
    } else if (customConfig.endType === 'count') {
      rrule += `;COUNT=${customConfig.occurrences}`;
    } else {
      // never -> podrías poner COUNT muy grande o UNTIL lejano
      rrule += `;COUNT=999`;
    }

    onChange(`RRULE:${rrule}`);
    setShowCustomModal(false);
  };

  // Componente interno para seleccionar días de la semana
  const WeekDaySelector = ({ weekDays, onChange }) => {
    const diasSemana = [
      { code: 'LU', label: 'L' },
      { code: 'MA', label: 'M' },
      { code: 'MI', label: 'M' },
      { code: 'JU', label: 'J' },
      { code: 'VI', label: 'V' },
      { code: 'SA', label: 'S' },
      { code: 'DO', label: 'D' }
    ];

    return (
      <div className="recurrence-weekdays">
        {diasSemana.map(({ code, label }) => (
          <label key={code} className="recurrence-day-label">
            <input
              type="checkbox"
              checked={weekDays.includes(code)}
              onChange={(e) => {
                const newWeekDays = e.target.checked
                  ? [...weekDays, code]
                  : weekDays.filter(d => d !== code);
                onChange(newWeekDays);
              }}
              className="recurrence-day-input"
            />
            <span className="recurrence-day-text">{label}</span>
          </label>
        ))}
      </div>
    );
  };

  // Modificar la función que cierra el modal
  const handleCloseModal = () => {
    setShowCustomModal(false);
    setSelectedOption(''); // Resetear la opción seleccionada al cerrar
  };

  return (
    <div className="recurrence-selector">
      {/* Selector principal */}
      <select
        value={selectedOption}
        onChange={(e) => handleMainOptionChange(e.target.value)}
        className="recurrence-select"
      >
        {recurrenceOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {selectedOption && !['', 'CUSTOM', 'WEEKLY_DAYS'].includes(selectedOption) && (
        <div className="end-config-container">
          <label className="end-config-label">Termina:</label>
          <select
            value={endConfig.type}
            onChange={(e) =>
              handleEndConfigChange(
                e.target.value,
                e.target.value === 'count'
                  ? endConfig.occurrences
                  : e.target.value === 'until'
                  ? endConfig.untilDate
                  : null,
                selectedOption
              )
            }
            className="recurrence-select"
          >
            <option value="never">Nunca</option>
            <option value="count">Después de</option>
            <option value="until">Después del día</option>
          </select>

          {endConfig.type === 'count' && (
            <div className="count-input-container">
              <input
                type="number"
                min="1"
                value={endConfig.occurrences}
                onChange={(e) =>
                  handleEndConfigChange('count', parseInt(e.target.value), selectedOption)
                }
                className="recurrence-input"
              />
              <span>repeticiones</span>
            </div>
          )}

          {endConfig.type === 'until' && (
            <div className="recurrence-date-container">
              <span className="recurrence-date-label">Escoger fecha:</span>
              <DatePicker
                selected={endConfig.untilDate ? moment(endConfig.untilDate).toDate() : null}
                onChange={(date) => handleEndConfigChange('until', moment(date).format('YYYY-MM-DD'), selectedOption)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                locale="es"
                minDate={new Date()}
                maxDate={moment().add(5, 'years').toDate()}
                className="recurrence-input recurrence-datepicker"
                placeholderText="Selecciona fecha"
              />
            </div>
          )}
        </div>
      )}

      {/* Modal para personalización o selección de días específicos */}
      <Modal
        isOpen={showCustomModal}
        onRequestClose={handleCloseModal}
        className="recurrence-modal"
        overlayClassName="recurrence-modal-overlay"
        style={{
          overlay: {
            zIndex: 99999,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          content: {
            position: 'relative',
            margin: 'auto',
            zIndex: 100000,
            inset: 'auto'
          }
        }}
        portalClassName="modal-recurrence-portal"
      >
        <div className="recurrence-content">
          <h3 className="recurrence-header">
            {selectedOption === 'WEEKLY_DAYS' ? 'Seleccionar días de la semana' : 'Personalizar recurrencia'}
          </h3>

          {selectedOption === 'WEEKLY_DAYS' ? (
            <>
              <div className="recurrence-section">
                <h4 className="recurrence-section-title">Selecciona los días:</h4>
                <div className="recurrence-weekdays">
                  <WeekDaySelector
                    weekDays={customConfig.weekDays}
                    onChange={(newWeekDays) => setCustomConfig({ ...customConfig, weekDays: newWeekDays })}
                  />
                </div>
              </div>

              <div className="recurrence-section">
                <h4 className="recurrence-section-title">Termina:</h4>
                <div className="recurrence-end-options">
                  <select
                    value={customConfig.endType}
                    onChange={(e) => setCustomConfig({ ...customConfig, endType: e.target.value })}
                    className="recurrence-select"
                  >
                    <option value="never">Nunca</option>
                    <option value="until">El día</option>
                    <option value="count">Después de</option>
                  </select>

                  {customConfig.endType === 'until' && (
                    <div className="recurrence-date-container">
                      <span className="recurrence-date-label">Escoger fecha:</span>
                      <DatePicker
                        selected={customConfig.endDate ? moment(customConfig.endDate).toDate() : null}
                        onChange={(date) => setCustomConfig({ 
                          ...customConfig, 
                          endDate: moment(date).format('YYYY-MM-DD')
                        })}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        locale="es"
                        minDate={new Date()}
                        maxDate={moment().add(5, 'years').toDate()}
                        className="recurrence-input recurrence-datepicker"
                        placeholderText="Selecciona fecha"
                      />
                    </div>
                  )}

                  {customConfig.endType === 'count' && (
                    <div className="recurrence-count-wrapper">
                      <input
                        type="number"
                        min="1"
                        value={customConfig.occurrences}
                        onChange={(e) => setCustomConfig({ 
                          ...customConfig, 
                          occurrences: parseInt(e.target.value) 
                        })}
                        className="recurrence-input"
                      />
                      <span className="recurrence-count-label">repeticiones</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="recurrence-section">
                <h4 className="recurrence-section-title">Repetir cada:</h4>
                <div className="recurrence-interval-container">
                  <input
                    type="number"
                    min="1"
                    value={customConfig.interval}
                    onChange={(e) =>
                      setCustomConfig({ ...customConfig, interval: parseInt(e.target.value) })
                    }
                    className="recurrence-input"
                  />
                  <select
                    value={customConfig.frequency}
                    onChange={(e) => setCustomConfig({ ...customConfig, frequency: e.target.value })}
                    className="recurrence-select"
                  >
                    <option value="DAILY">días</option>
                    <option value="WEEKLY">semanas</option>
                    <option value="MONTHLY">meses</option>
                    <option value="YEARLY">años</option>
                  </select>
                </div>
              </div>

              {customConfig.frequency === 'WEEKLY' && (
                <div className="recurrence-section">
                  <h4 className="recurrence-section-title">Selecciona los días:</h4>
                  <div className="recurrence-weekdays">
                    <WeekDaySelector
                      weekDays={customConfig.weekDays}
                      onChange={(newWeekDays) => setCustomConfig({ ...customConfig, weekDays: newWeekDays })}
                    />
                  </div>
                </div>
              )}

              <div className="recurrence-section">
                <h4 className="recurrence-section-title">Termina:</h4>
                <div className="recurrence-end-options">
                  <select
                    value={customConfig.endType}
                    onChange={(e) => setCustomConfig({ ...customConfig, endType: e.target.value })}
                    className="recurrence-select"
                  >
                    <option value="never">Nunca</option>
                    <option value="until">El</option>
                    <option value="count">Después de</option>
                  </select>

                  {customConfig.endType === 'until' && (
                    <div className="recurrence-date-container">
                      <span className="recurrence-date-label">Escoger fecha:</span>
                      <DatePicker
                        selected={customConfig.endDate ? moment(customConfig.endDate).toDate() : null}
                        onChange={(date) => setCustomConfig({ 
                          ...customConfig, 
                          endDate: moment(date).format('YYYY-MM-DD')
                        })}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        locale="es"
                        minDate={new Date()}
                        maxDate={moment().add(5, 'years').toDate()}
                        className="recurrence-input recurrence-datepicker"
                        placeholderText="Selecciona fecha"
                      />
                    </div>
                  )}

                  {customConfig.endType === 'count' && (
                    <div className="recurrence-count-wrapper">
                      <input
                        type="number"
                        min="1"
                        value={customConfig.occurrences}
                        onChange={(e) => setCustomConfig({ ...customConfig, occurrences: parseInt(e.target.value) })}
                        className="recurrence-input"
                      />
                      <span className="recurrence-count-label">repeticiones</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="recurrence-actions">
            <button
              onClick={handleCustomSubmit}
              className="recurrence-btn recurrence-btn-primary"
            >
              Aceptar
            </button>
            <button
              onClick={handleCloseModal}
              className="recurrence-btn recurrence-btn-secondary"
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RecurrenceSelector;
