import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faTasks } from "@fortawesome/free-solid-svg-icons";
import ReorganizarConPlanibot from "../ReorganizarPlanibot/ReorganizarConPlanibot";
import TodoTask from "../todoTask/TodoTask";
import './TabsContainer.css';

const TabsContainer = ({ tickets, setTickets, timeZone, reordenarEventosConIA, isVisible, manejarActualizacionTareasPendientes }) => {
  const [activeTab, setActiveTab] = useState("tasks");
  const [tareasPendientes, setTareasPendientes] = useState(0);
  const [isMaximized, setIsMaximized] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isVisible) {
      timeoutId = setTimeout(() => setIsMaximized(true), 50);
    } else {
      setIsMaximized(false);
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible]);

  const handleActualizarTareasPendientes = (cantidad) => {
    setTareasPendientes(cantidad);
    manejarActualizacionTareasPendientes(cantidad);
  };

  return (
    <div className={`tabs-container ${!isVisible ? "collapsed" : ""} ${isMaximized ? "maximized" : ""}`}>

          <TodoTask
            onActualizarTareasPendientes={handleActualizarTareasPendientes}
            isVisible={isVisible}
          />

    </div>
  );
};

export default TabsContainer;