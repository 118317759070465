import React from "react";

function RbRewards() {
  return (
    <div>
      <div className="bigCard">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">
              Parametrización de recompensas🎁
              </h1>
            </div>
            <p>
             Gestiona las recompensas de tu equipo.
            </p>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default RbRewards;
