import React, { useState, useEffect, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import { LayoutDashboard, ListCheck, Archive, Power, Hammer, Layers, PlusCircle, Calendar } from "lucide-react";
import "./Sidebar.css";
import MiPerfil from "./Perfil/Perfil";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

function Sidebar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);
  const { businessName } = useContext(AuthContext);
  const { businessPhoto } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  useEffect(() => {
    const navMenu = document.getElementById("rectangle");

    if (menuOpen) {
      navMenu.classList.add("show");
    } else {
      navMenu.classList.remove("show");
    }
  }, [menuOpen]);

  useEffect(() => {
    if (!currentUser) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    setSubMenuOpen(false);
  };

  const renderSubMenu = () => {
    if (subMenuOpen) {
      return (
        <div>
          <NavLink className="subMenu" to="/solicitud-simple">
            Solicitud Simple
          </NavLink>
          <NavLink className="subMenu" to="/solicitud-periodica">
            Solicitud Periódica
          </NavLink>
        </div>
      );
    }
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const logoBusiness = useMemo(
    () => (
      <div style={{marginTop:"4rem"}}>
      </div>
    ),
    [businessPhoto, businessName]
  );

  if (loading) {
    return <div className="spinner"></div>;
  } else {
    return (
      <div
        id="rectangle"
        onMouseOver={abrir}
        onMouseLeave={cerrar}
        className="rectangle"
      >
        <button className="hamburger-menu" onClick={handleMenuClick}>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>

        <div style={{ visibility: isHovering ? "visible" : "hidden" }}>
          {logoBusiness}
        </div>
        
        <li>
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/dashboard"
          >
            <LayoutDashboard size={16} />
            {isHovering && <span className="menu-text">Dashboard</span>}
          </NavLink>
        </li>

        <li>
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/GestionarTickets"
          >
            <ListCheck size={16} />
            {isHovering && <span className="menu-text">Gestionar mi mente</span>}
          </NavLink>
        </li>

        <li>
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/archivados"
          >
            <Archive size={16} />
            {isHovering && <span className="menu-text">Históricas</span>}
          </NavLink>
        </li>

        <li>
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/areas"
          >
            <Layers size={16} />
            {isHovering && <span className="menu-text">Áreas</span>}
          </NavLink>
        </li>

        <li>
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/calendario"
          >
            <Calendar size={16} />
            {isHovering && <span className="menu-text">Mis Calendarios</span>}
          </NavLink>
        </li>

        <li>
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="link"
            to="/miPerfil"
          >
            <Hammer size={16} />
            {isHovering && <span className="menu-text">Configurar</span>}
          </NavLink>
        </li>

        <li className="closeSessionMenu">
          <NavLink
            style={({ isActive }) => ({
              background: isActive ? "#5723aa" : "",
            })}
            className="linkSession"
            to="/login"
            onClick={() => signOut(auth)}
          >
            <Power size={16} />
            {isHovering && <span className="menu-text">Cerrar Sesión</span>}
          </NavLink>
        </li>
      </div>
    );

    function abrir() {
      let side = document.getElementById("rectangle");
      side.classList.add("active");
      setIsHovering(true);
    }

    function cerrar() {
      let side = document.getElementById("rectangle");
      side.classList.remove("active");
      setIsHovering(false);
      setMenuOpen(false);
      setSubMenuOpen(false);
    }
  }
}
export default Sidebar;
