import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import RecordRTC from 'recordrtc';
import './AudioRecoder.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AudioRecorder = forwardRef(({ onTranscription, customButton }, ref) => {
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const recorderRef = useRef(null);
  const streamRef = useRef(null);
  const [counter, setCounter] = useState(30);

  useImperativeHandle(ref, () => ({
    startRecording,
    stopRecording,
    sendAudioToServer,
    cancelRecording
  }));

  const startRecording = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      toast.error('MediaDevices API no está disponible.');
      return;
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        streamRef.current = stream;
        recorderRef.current = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          numberOfAudioChannels: 1,
          desiredSampRate: 16000, // Tasa de muestreo recomendada para Whisper
          recorderType: RecordRTC.StereoAudioRecorder,
        });
        recorderRef.current.startRecording();
        setIsRecording(true);
        setCounter(30);
        toast.success('🤖 Escuchando solicitud');
      })
      .catch(e => {
        toast.error('Error al obtener el micrófono: ' + e.message);
      });
  };

  const stopRecording = () => {
    return new Promise((resolve, reject) => {
      if (!recorderRef.current) {
        reject(new Error('No hay grabación en curso.'));
        return;
      }

      recorderRef.current.stopRecording(() => {
        const audioBlob = recorderRef.current.getBlob();
        
        // Limpiar recursos
        if (recorderRef.current) {
          recorderRef.current.destroy();
          recorderRef.current = null;
        }

        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }

        setIsRecording(false);
        setCounter(30);
        resolve(audioBlob);
      });
    });
  };

  const cancelRecording = () => {
    if (!recorderRef.current) return;

    recorderRef.current.stopRecording(() => {
      if (recorderRef.current) {
        recorderRef.current.destroy();
        recorderRef.current = null;
      }

      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }

      setIsRecording(false);
      setCounter(30);
      toast.info('🤖 Grabación cancelada');
    });
  };

  const sendAudioToServer = async (audioBlob) => {
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const base64AudioMessage = reader.result.split(',')[1];
        try {
          const response = await fetch('/.netlify/functions/transcribeAudio', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ audioBase64: base64AudioMessage }),
          });

          if (response.ok) {
            const data = await response.json();
            onTranscription(data.text);
            resolve(data.text);
          } else {
            const errorText = await response.text();
            reject(new Error(errorText));
          }
        } catch (error) {
          reject(error);
        } finally {
          setLoading(false);
        }
      };
    });
  };

  useEffect(() => {
    let timer;
    if (isRecording && counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (counter === 0) {
      stopRecording();
    }
    return () => clearTimeout(timer);
  }, [isRecording, counter]);

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording().then(audioBlob => {
        sendAudioToServer(audioBlob);
      }).catch(error => {
        console.error("Error al detener la grabación:", error);
      });
    } else {
      startRecording();
    }
  };

  if (customButton) {
    return customButton(isRecording, toggleRecording);
  }

  return (
    <div className="audio-recorder-container">
      <button
        className={`ButtonGrabacion ${isRecording ? 'recording' : ''}`}
        onClick={toggleRecording}
      >
        {isRecording ? 'Detener' : 'Grabar'}
      </button>
      <ToastContainer />
    </div>
  );
});

export default AudioRecorder;
