import React, {
  useState,
  useEffect,
  useContext,
  useCallback
} from "react";
import { useDrop } from "react-dnd";
import { AuthContext } from "../../context/AuthContext";
import { getPendingTasks, updateTaskStatus } from "./TodoGetTask";
import { doc, updateDoc, getFirestore, getDoc } from 'firebase/firestore';
import TaskItem from "./TaskItem";
import DetalleTask from "./DetalleTask";
import { eliminarSoloDeCalendar } from "../calendario/FunctionsCalendar";
import { toast } from "react-toastify";
import "./TodoTask.css";
import ResumeTareasPendientes from "../addTarea/ResumeTareasPendientes";
import tinycolor from "tinycolor2";

const TodoTask = ({ onActualizarTareasPendientes = () => {} }) => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [showMassiveTask, setShowMassiveTask] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const {
    businessEmail,
    currentUser,
    accessToken,
    refreshToken,
    tokenExpiry,
    firebaseAccesToken
  } = useContext(AuthContext);

  const [searchTerm, setSearchTerm] = useState("");
  
  // Manejamos el menú contextual o edición
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  // Objeto que guarda posiciones globales: { [taskId]: number }
  const [positions, setPositions] = useState({});
  // Para almacenar los colores de las áreas
  const [areasColors, setAreasColors] = useState({});

  // Configuración de paginación
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 10;

  // Colores predeterminados si la tarea no tiene un área definida
  const defaultColors = {
    backgroundColor: '#f8faff',
    borderColor: '#e6e7eb',
    textColor: '#4a5568'
  };

  // 1. Cargamos inicialmente las tareas
  useEffect(() => {
    if (businessEmail) {
      const unsubscribe = getPendingTasks(
        businessEmail,
        (newTasks) => {
          setTasks(newTasks);
          setLoading(false);
        },
        setError
      );
      return () => unsubscribe();
    }
  }, [businessEmail]);

  // Función para actualizar el grado de importancia en Firebase
  const updateTaskImportance = async (taskId, importance) => {
    try {
      const db = getFirestore();
      const taskRef = doc(db, `business/${businessEmail}/tickets`, taskId);
      await updateDoc(taskRef, {
        gradoImportancia: importance
      });
    } catch (error) {
      console.error("Error al actualizar grado de importancia:", error);
      toast.error("Error al actualizar la prioridad de la tarea");
    }
  };

  // 2. Sincronizamos `positions` con tasks (cuando tasks cambie)
  useEffect(() => {
    const newPositions = {};

    // Función interna para setear la importancia en Firebase si no existe
    const updateImportanceIfNeeded = async (task, position) => {
      if (!task.gradoImportancia) {
        await updateTaskImportance(task.id, position);
      }
    };

    tasks.forEach((task, index) => {
      // Si la tarea ya tiene gradoImportancia, usarla como posición
      if (task.gradoImportancia) {
        newPositions[task.id] = task.gradoImportancia;
      } else {
        // Si no tiene, asignamos una
        newPositions[task.id] = index + 1;
        updateImportanceIfNeeded(task, index + 1);
      }
    });

    // Aseguramos que no haya huecos ni repeticiones
    const sortedByPos = Object.entries(newPositions).sort(
      (a, b) => a[1] - b[1]
    );
    sortedByPos.forEach(([id], idx) => {
      newPositions[id] = idx + 1;
    });
    
    setPositions(newPositions);
  }, [tasks]);

  // 3. Filtrado en base a searchTerm
  const filterTasks = useCallback(() => {
    let filtered = tasks;
    if (searchTerm) {
      filtered = filtered.filter((task) =>
        task.title?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredTasks(filtered);
    onActualizarTareasPendientes(filtered);
  }, [tasks, searchTerm, onActualizarTareasPendientes]);

  useEffect(() => {
    filterTasks();
  }, [filterTasks]);

  // 4. Handlers para buscador
  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  // 5. Manejo del menú contextual
  const handleOpenMenu = useCallback((event, task) => {
    event.stopPropagation();
    setMenuPosition({ x: event.clientX, y: event.clientY });
    setSelectedTask(task);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuPosition(null);
    setSelectedTask(null);
  }, []);

  // 6. Manejo de la edición de la tarea
  const handleTaskEdit = useCallback(
    (updatedTask) => {
      if (updatedTask === null) {
        // Se borró la tarea
        setTasks((prevTasks) =>
          prevTasks.filter((task) => task.id !== selectedTask.id)
        );
        setSelectedTask(null);
        setMenuPosition(null);
      } else {
        // Se actualizó la tarea
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === updatedTask.id ? updatedTask : task
          )
        );
      }
    },
    [selectedTask]
  );

  // 7. Lógica para reordenar posiciones
  const handlePositionChange = useCallback(
    async (taskId, newPosition) => {
      const currentPosition = positions[taskId];
      const updatedPositions = { ...positions };

      // 1) Si no la teníamos en el objeto, asume que es nueva
      if (!currentPosition) {
        const maxPos = Math.max(...Object.values(updatedPositions), 0);
        updatedPositions[taskId] = maxPos + 1;
      } 
      // 2) Mover hacia arriba
      else if (newPosition < currentPosition) {
        for (const [id, pos] of Object.entries(updatedPositions)) {
          if (pos >= newPosition && pos < currentPosition) {
            updatedPositions[id] = pos + 1;
            await updateTaskImportance(id, pos + 1);
          }
        }
        updatedPositions[taskId] = newPosition;
      } 
      // 3) Mover hacia abajo
      else if (newPosition > currentPosition) {
        for (const [id, pos] of Object.entries(updatedPositions)) {
          if (pos > currentPosition && pos <= newPosition) {
            updatedPositions[id] = pos - 1;
            await updateTaskImportance(id, pos - 1);
          }
        }
        updatedPositions[taskId] = newPosition;
      }

      // Actualizar la tarea arrastrada
      await updateTaskImportance(taskId, newPosition);

      setPositions(updatedPositions);
    },
    [positions]
  );

  // 8. React-DnD para el contenedor "pendiente"
  const [{ isOver }, drop] = useDrop({
    accept: "TICKET",
    drop: async (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      const dropTargetElement = document.elementFromPoint(clientOffset.x, clientOffset.y);

      let requestedPosition = 1;
      if (tasks.length > 0) {
        const positionElement = dropTargetElement?.closest(".position-slot");
        if (positionElement) {
          requestedPosition = parseInt(positionElement.dataset.position, 10);
        } else {
          requestedPosition = tasks.length + 1;
        }
      }

      // Verificamos si la tarea ya existe en el board
      const existsInBoard = tasks.find((task) => task.id === item.id);
      if (!existsInBoard) {
        const updatedData = {
          status: "Pendiente",
          gradoImportancia: requestedPosition
        };
        try {
          const db = getFirestore();
          const taskRef = doc(db, `business/${businessEmail}/tickets`, item.id);
          
          // Obtener el documento actual para verificar si el evento de Calendar está listo
          const taskDoc = await getDoc(taskRef);
          if (!taskDoc.exists()) {
            toast.warning("Espera a que el evento se agregue completamente antes de moverlo");
            return;
          }

          const taskData = taskDoc.data();
          
          // Verificar si el evento está completamente sincronizado con Google Calendar
          if (taskData.status === "Proceso" && !taskData.idEventoCalendario) {
            toast.warning("El evento aún se está sincronizando con Google Calendar. Por favor, espera un momento.");
            return;
          }

          await updateDoc(taskRef, updatedData);
          
          // Primero intentamos eliminar del calendario
          try {
            // Verificar todos los posibles campos de ID del evento
            const eventId = taskData.idEventoCalendario || taskData.googleCalendarEventId || taskData.eventId;
            
            if (eventId) {
              console.log("ID del evento a eliminar:", eventId);
              await eliminarSoloDeCalendar(
                {
                  ...taskData,
                  googleCalendarEventId: eventId
                },
                currentUser,
                accessToken,
                refreshToken,
                tokenExpiry,
                firebaseAccesToken
              );
              
              // También actualizar el documento para quitar la referencia al evento
              await updateDoc(taskRef, {
                idEventoCalendario: null,
                googleCalendarEventId: null,
                eventId: null,
                enlaceEventoCalendario: null
              });
              
              console.log("Tarea eliminada exitosamente del calendario");
            } else {
              console.log("No se encontró ID de evento de calendario en la tarea:", taskData);
            }
          } catch (calendarError) {
            console.error("Error al eliminar del calendario:", calendarError);
            toast.error("Error al eliminar el evento del calendario");
            return; // Si falla la eliminación del calendario, no continuamos
          }
          
          item.status = updatedData.status;
          item.gradoImportancia = updatedData.gradoImportancia;
          
          handlePositionChange(item.id, requestedPosition);
          toast.success("Tarea actualizada correctamente.");
        } catch (error) {
          console.error("Error al actualizar la tarea:", error);
          toast.error(`Error al actualizar la tarea: ${error.message}`);
        }
      } else {
        // Si ya existe, sólo reordenamos
        handlePositionChange(item.id, requestedPosition);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  // 9. Cuando se crea una nueva tarea en el componente masivo
  const handleTaskCreated = useCallback(() => {
    setShowMassiveTask(false);
    if (businessEmail) {
      getPendingTasks(businessEmail, setTasks, setError);
    }
  }, [businessEmail]);

  // 10. Ordenamos las tareas filtradas según la posición global
  const sortedFiltered = [...filteredTasks].sort(
    (a, b) => (positions[a.id] || 0) - (positions[b.id] || 0)
  );

  // 11. Paginación: determinamos cuántas páginas y cuál es la "sub-lista" a mostrar
  const totalPages = Math.ceil(sortedFiltered.length / tasksPerPage);
  const startIndex = (currentPage - 1) * tasksPerPage;
  const paginatedTasks = sortedFiltered.slice(startIndex, startIndex + tasksPerPage);

  // Función auxiliar para determinar el color del punto de prioridad
  const getPriorityColor = (priority) => {
    // Normalizar la prioridad
    const normalizedPriority = priority?.toLowerCase();
    switch (normalizedPriority) {
      case 'alta':
      case 'high':
        return '#ff4d4d'; // Rojo suave
      case 'media':
      case 'medium':
        return '#ffd700'; // Amarillo suave
      case 'baja':
      case 'low':
        return '#4CAF50'; // Verde suave
      default:
        return '#808080'; // Gris por defecto
    }
  };

  // Función para obtener el color del área
  const getAreaColor = async (areaId) => {
    if (!businessEmail || !areaId) return null;
    try {
      const db = getFirestore();
      const areaRef = doc(db, `business/${businessEmail}/areas`, areaId);
      const areaDoc = await getDoc(areaRef);
      if (areaDoc.exists()) {
        return areaDoc.data().color;
      }
      return null;
    } catch (error) {
      console.error("Error al obtener el color del área:", error);
      return null;
    }
  };

  // 12. Efecto para cargar los colores de las áreas
  useEffect(() => {
    const loadAreaColors = async () => {
      const colors = {};
      const promises = tasks.map(async (task) => {
        if (task.area && !colors[task.area]) {
          const color = await getAreaColor(task.area);
          if (color) {
            colors[task.area] = color;
          }
        }
      });
      await Promise.all(promises);
      setAreasColors(colors);
    };
    if (tasks.length > 0) {
      loadAreaColors();
    }
  }, [tasks, businessEmail]);

  // 13. Función que renderiza cada TaskItem con color y posición
  const renderTaskItem = (task) => {
    const pos = positions[task.id];
    let style = {};

    if (task.area && areasColors[task.area]) {
      const areaColor = areasColors[task.area];
      style = {
        backgroundColor: tinycolor(areaColor).lighten(30).toString(),
        borderLeft: `3px solid ${areaColor}`,
        color: tinycolor(areaColor).darken(20).toString(),
      };
    } else {
      // Colores por defecto
      style = {
        backgroundColor: defaultColors.backgroundColor,
        borderLeft: `3px solid ${defaultColors.borderColor}`,
        color: defaultColors.textColor,
      };
    }

    // Usar priority o prioridad
    const taskPriority = task.priority || task.prioridad;

    return (
      <div
        key={task.id}
        className="position-slot filled"
        data-position={pos}
      >
        <div className="position-number">{pos}</div>
        <TaskItem
          task={task}
          onOpenMenu={handleOpenMenu}
          position={pos}
          onPositionChange={handlePositionChange}
          style={style}
          priorityColor={getPriorityColor(taskPriority)}
        />
      </div>
    );
  };

  // Render principal
  return (
    <div className="todo-task-container" ref={drop}>
      <div className="todo-task-header">
        <h2>Objetivos en mente</h2>
      </div>

      <div className="todo-task-content">
        <div className="todo-task-filters">
          <input
            type="text"
            placeholder="Buscar tareas..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button
            className="todo-task-create-button"
            onClick={() => setShowMassiveTask(true)}
          >
            + Descarga tu mente
          </button>
        </div>

        {loading && (
          <div className="todo-task-loading-indicator">
            <div className="todo-task-spinner"></div>
          </div>
        )}
        {error && <div>Error: {error.message}</div>}
        {!currentUser?.email && (
          <div>Por favor, inicie sesión para ver las tareas.</div>
        )}

        {/* Mostramos sólo la página actual (paginatedTasks) */}
        {!loading && !error && currentUser?.email && (
          <>
            <div className="todo-task-list">
              {paginatedTasks.length === 0 && isOver && (
                <div className="empty-drop-zone">
                  Suelta aquí para añadir la primera tarea
                </div>
              )}
              {paginatedTasks.map((task) => renderTaskItem(task))}
            </div>
            
            {/* Paginación */}
            <div className="todo-task-pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  className={
                    index + 1 === currentPage ? "active" : ""
                  }
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Modal o panel de creación masiva */}
      {showMassiveTask && (
        <ResumeTareasPendientes
          isOpen={showMassiveTask}
          onClose={() => setShowMassiveTask(false)}
          onTaskCreated={handleTaskCreated}
        />
      )}

      {/* Detalle o menú contextual de una tarea */}
      {menuPosition && selectedTask && (
        <DetalleTask
          tarea={selectedTask}
          onClose={handleCloseMenu}
          onEdit={handleTaskEdit}
          businessName="Planius"
          context="Detalle de la tarea"
          horaInicio={selectedTask.horaInicio}
          horaTermino={selectedTask.horaTermino}
          style={{
            top: menuPosition.y,
            right: 20,
            position: "absolute",
            zIndex: 1000,
          }}
        />
      )}
    </div>
  );
};

export default React.memo(TodoTask);
