import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import AddEventSendGrid from "./addEventoSendGrid/AddEventSendGrid";
import { convertChatDate, convertDateFormat } from "./Functions.js";
import { doc, updateDoc, addDoc, onSnapshot, Timestamp, collection, getDoc, getFirestore, arrayRemove } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { db } from "./firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers, faPaperclip, faMagicWandSparkles, faPencil, faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import Footer from "./Footer";
import AddUserChat from "./modaChatlMenu/AddUserChat";
import CloseTicket from "./modaChatlMenu/CloseTicket";
import AgregarEvento from "./googleCalendar/AgregarEvento";
import AdjuntarArchivosModal from "./modaChatlMenu/AdjuntarArchivosModal";
import { getMessages, queryMessages } from "./Functions";
import { sendMail } from "./SendMail.jsx";

function Chat(props) {
  const [text, setText] = useState("<p><br></p>");
  const { currentUser } = useContext(AuthContext);
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();
  const contenidoChat = useRef(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCloseTicket, setOpenModalCloseTicket] = useState(false);
  const [openModalAdjuntar, setOpenModalAdjuntar] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const { businessEmail } = useContext(AuthContext);
  const location = useLocation();

  const defaultImageURL = "https://firebasestorage.googleapis.com/v0/b/chat-e4572.appspot.com/o/avatar.jpg?alt=media&token=41dc2376-4dfe-4a09-9000-b6fc8f865698";
  const customToolbarOptions = [
    ["bold", "italic", "underline", "strike", { align: [] }, { color: [] }, { background: [] }, { header: [3, 4, 5, 6, false] }, { header: 1 }, { header: 2 }, { font: [] }, { list: "ordered" }, { list: "bullet" }],
  ];

  const { userType: userTypeFromContext } = useContext(AuthContext);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    setUserType(userTypeFromContext);
  }, [userTypeFromContext]);

  function scrollToBottom() {
    contenidoChat.current.scrollTop = contenidoChat.current.scrollHeight;
  }

  const handleTextChange = (value) => {
    setText(value);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleCloseModalCloseTicket = () => {
    setOpenModalCloseTicket(true);
  };

  const handleCloseTicket = () => {
    setOpenModalCloseTicket(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModalAdjuntar = () => {
    setOpenModalAdjuntar(!openModalAdjuntar);
  };

  const handleCloseModalAdjuntar = () => {
    setOpenModalAdjuntar(false);
  };

  async function handleDeleteMember(idTicket, member) {
    let userResponse = window.confirm("¿Deseas eliminar a " + member + " del ticket?");

    if (userResponse) {
      const ticketRef = doc(db, "business", props.emailBusiness, "tickets", idTicket);

      try {
        await updateDoc(ticketRef, { to: arrayRemove(member) });
        alert("Usuario Eliminado");
      } catch (e) {
        console.error("Error eliminando usuario: ", e);
      }
    }
  }

  function htmlToPlainText(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.innerText;
  }

  function sanitizeHTML(html) {
    return DOMPurify.sanitize(html);
  }

  useEffect(() => {
    async function fetchFiles() {
      const ticketRef = doc(db, "business", props.emailBusiness, "tickets", props.id);
      const ticketSnap = await getDoc(ticketRef);

      if (ticketSnap.exists()) {
        setFileUrls(ticketSnap.data().adjuntos);
      }
    }

    fetchFiles();
  }, [props.emailBusiness, props.id, handleCloseModalAdjuntar]);

  const formatFileName = (url) => {
    let fileName = url.split("/").pop();
    fileName = fileName.split("?")[0];
    const fileBase = fileName.split(".").slice(0, -1).join(".");
    const fileExtension = fileName.split(".").pop();
    return `${fileBase.substring(0, 10)}.${fileExtension}`;
  };

  useEffect(() => {
    setLoading(true);
    const q = queryMessages(props.emailBusiness, props.id);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = getMessages(querySnapshot);
      if (items.length > 0) {
        setChats(items);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [props.emailBusiness, props.id]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const date = Timestamp.now();

  const handleSend = useCallback(async () => {
    try {
      if (text !== "") {
        await addDoc(collection(db, "business", props.emailBusiness, "tickets", props.id, "messages"), {
          name: currentUser.displayName,
          photo: currentUser.photoURL,
          uid: props.uid,
          date: date,
          message: text,
        });

        if (userType === "cliente") {
          for (let email of props.to) {
            await sendMail(email, "¡Nuevo Mensaje! - " + props.title, `${currentUser.displayName}, ha dicho: ${htmlToPlainText(text)} en el ticket: ${props.title} para responder ingresa a: https://app.planius.ai y busca el ticket en la sección de Gestión de Tickets.`);
          }
        } else {
          sendMail(props.from, "¡Nuevo Mensaje! - " + props.title, `${currentUser.displayName}, ha dicho: ${htmlToPlainText(text)} en el ticket: ${props.title} para responder ingresa a: https://app.planius.ai`);
        }
      }
      setText("");
    } catch (e) {
      console.error("Error enviando mensaje: ", e);
    }
  }, [text, props.uid, props.id, date, currentUser.displayName, currentUser.photoURL, htmlToPlainText, props.title, props.to, props.from, userType, props.emailBusiness]);

  return (
    <div className="fade-in">
      <div className="bigContent">
        <div className="contenedorChat">
          <div className="div-left-chat">
            <div className="titleChatSegmentLeft"><span>Adjuntos</span></div>
            <div className="ticketLbl">
              {fileUrls && fileUrls.map((url, index) => (
                <a href={url} download target="_blank" rel="noopener noreferrer" className="link2">
                  {formatFileName(url)}
                </a>
              ))}
            </div>
            <AddEventSendGrid
              emailBusiness={props.emailBusiness}
              evento={props.title}
              descripcion={props.context}
              participantes={currentUser.email}
              fechaInicio={moment(props.creacion, "DD-MM-YYYY").toISOString()}
              fechaVencimiento={moment(props.vencimiento, "DD-MM-YYYY").toISOString()}
              idTicket={props.id}
            />
            <AgregarEvento
              evento={props.title}
              descripcion={props.context}
              fechaInicio={moment(props.creacion, "DD-MM-YYYY").toISOString()}
              fechaVencimiento={moment(props.vencimiento, "DD-MM-YYYY").toISOString()}
              emailBusiness={props.emailBusiness}
              idTicket={props.id}
              businessName={props.businessName}
            />
            <div className="titleChatSegmentWhite">
              <span><FontAwesomeIcon icon={faUsers} style={{ marginRight: "10px" }} /> Miembros </span>
            </div>
            {props.to && props.to.map((member, index) => (
              <li className="ticketLbl" key={index} onClick={() => { handleDeleteMember(props.id, member); }}>
                {member}
              </li>
            ))}
            <div className="titleChatSegmentWhite">
              <span><FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} /> Departamento</span>
            </div>
            <div className="ticketLbl">{props.department}</div>
            <div className="titleChatSegmentWhite">
              <span><FontAwesomeIcon icon={faMagicWandSparkles} style={{ marginRight: "10px" }} /> Actividades</span>
            </div>
            <div className="ticketLbl">{props.skill}</div>
            <div className="titleChatSegmentWhite">
              <span><FontAwesomeIcon icon={faPencil} style={{ marginRight: "10px" }} /> Creador</span>
            </div>
            <div className="ticketLbl">{props.creator} | {props.from}</div>
            <div className="titleChatSegmentWhite">
              <span><FontAwesomeIcon icon={faPencil} style={{ marginRight: "10px" }} /> Hora de creación</span>
            </div>
            <div className="ticketLbl">{props.horaCreacion}</div>
          </div>
          <div className="div-content-chat">
            <div className="titleChat">{props.title}</div>
            <div ref={contenidoChat} id="chatContent" className="chatContent">
              {loading && (
                <div className="loading-container">
                  <p>Cargando Mensajes... </p>
                  <div className="spinner"></div>
                </div>
              )}
              <div className="mensajeOriginal">
                <div className="mensaje">
                  <div className="row">
                    <strong><p className="hraChat">{props.creator}</p></strong>
                  </div>
                  <div className="mensaje">{props.descripcion}</div>
                  <strong><p className="hraChat">{props.department}</p></strong>
                </div>
              </div>
              {chats.map((chat, index) => (
                <div key={index}>
                  <div></div>
                  <div>
                    <img
                      className="imgChat"
                      src={chat.photo ? chat.photo : defaultImageURL}
                      alt="Foto de perfil"
                      onError={(e) => {
                        if (e.target.src !== defaultImageURL) {
                          e.target.src = defaultImageURL;
                        }
                      }}
                    />
                  </div>
                  <div className={chat.uid === props.uid ? "manesajeUsuario" : "mensajeCliente"}>
                    <div className="row">
                      <strong><p className="hraChat">{chat.name}:</p></strong>
                    </div>
                    <div
                      className="mensaje"
                      dangerouslySetInnerHTML={{ __html: sanitizeHTML(chat.message) }}
                    ></div>
                    <p className="hraChat">{convertChatDate(chat.date)}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="chatInput">
              <div className="quillContainer">
                <ReactQuill
                  className="quillEditor ql-snow ql-container ql-toolbar custom-quill-editor custom-quill-toolbar"
                  theme="snow"
                  value={text}
                  onChange={handleTextChange}
                  placeholder="Redacta el contenido del mensaje..."
                  modules={{ toolbar: customToolbarOptions }}
                />
              </div>
              {text !== "<p><br></p>" && (
                <button className="bchat" onClick={handleSend}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              )}
            </div>
          </div>
          <div className="div-right-chat">
            <div className="titleChatSegment">
              {(userType === "admin" || userType === "agent" || userType === "super") && (
                <>
                  <div className="circular-menu">
                    <div onClick={handleOpenModalAdjuntar} className="circular-button">
                      <FontAwesomeIcon icon={faPaperclip} />
                    </div>
                    <div onClick={handleOpenModal} className="circular-button">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div onClick={handleCloseModalCloseTicket} className="circular-button">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  </div>
                </>
              )}
              {userType === "cliente" && (
                <>
                  <div className="circular-menu">
                    <div onClick={handleOpenModalAdjuntar} className="circular-button">
                      <FontAwesomeIcon icon={faPaperclip} />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="titleChatSegment"><span>Empresa</span></div>
            <div className="DescriptionMessage">{props.businessName}</div>
            <div className="titleChatSegment"><span>Resumen</span></div>
            <div className="DescriptionMessage">{props.context}</div>
            <div className="titleChatSegment"><span>Plan de trabajo</span></div>
            <div className="DescriptionMessage">{props.plan}</div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      <AddUserChat openModal={openModal} ticketId={props.id} handleCloseModalTicket={handleCloseModal} />
      <CloseTicket
        openModalTicket={openModalCloseTicket}
        ticketId={props.id}
        title={props.title}
        from={props.from}
        creator={props.creator}
        to={props.to}
        emailBusiness={props.emailBusiness}
        agente={currentUser.displayName}
        handleCloseTicket={handleCloseTicket}
      />
      <AdjuntarArchivosModal openModalAdjuntar={openModalAdjuntar} idTicket={props.id} emailBusiness={props.emailBusiness} handleCloseModalAdjuntar={handleCloseModalAdjuntar} />
    </div>
  );
}

export default Chat;
