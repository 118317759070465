import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import ReactDOM from "react-dom";
import "./DetalleTarea.css";
import {
  Save,
  Edit,
  Trash2,
  Mail,
  X,
  User,
  Briefcase,
  StickyNote,
  Settings,
  CheckCircle,
  Loader,
  CheckSquare,
  Paperclip,
  BadgeAlert,
  Brain,
  Clock,
  Users,
  Video,
  Flag
} from "lucide-react";
import { updateTicketDetalle, getAreas, queryAreas } from "../Functions";
import { updateGoogleCalendarEventDetalleTarea, eliminarEventoDeCalendar, updateGoogleCalendarEvent, eliminarSoloDeCalendar, sincronizarDesdeGoogleCalendar } from "./FunctionsCalendar";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment-timezone";
import DeleteTarea from "../DeleteTarea";
import { toast } from "react-toastify";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { onSnapshot } from "firebase/firestore";
import DeleteRecurrentEvent from './DeleteRecurrentEvent';
import { 
  deleteDoc, 
  doc, 
  collection, 
  query, 
  where, 
  getDocs 
} from "firebase/firestore";
import { db } from "../firebase";
import md5 from 'md5';
import AttendeeSelector from '../addTarea/AttendeeSelector';
import EditRecurrentEvent from './EditRecurrentEvent';

const DetalleTarea = ({ 
  tarea, 
  onClose, 
  onEdit, 
  businessName, 
  context, 
  horaInicio, 
  horaTermino 
}) => {
  const ref = useRef(null);
  const deleteModalRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [areas, setAreas] = useState([]);

  const parseTime = (timeStr) => {
    if (timeStr && timeStr !== "Todo el día") {
      return moment(timeStr, "HH:mm").toDate();
    }
    return null;
  };

  const [editedTask, setEditedTask] = useState({
    ...tarea,
    vencimiento: moment(tarea.vencimiento, "DD-MM-YYYY").toDate(),
    creacion: moment(tarea.creacion, "DD-MM-YYYY").toDate(),
    fechaFin: moment(tarea.fechaFin, "DD-MM-YYYY").toDate(),
    horaInicio: parseTime(tarea.horaInicio),
    horaTermino: parseTime(tarea.horaTermino),
    title: tarea.title || "",
    descripcion: tarea.descripcion || "",
    allDay: tarea.allDay || false,
    department: tarea.department || "",
    priority: tarea.priority || "",
  });

  const [allDay, setAllDay] = useState(editedTask.allDay);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken } = useContext(AuthContext);
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();
  const [isDeleteRecurrentModalOpen, setIsDeleteRecurrentModalOpen] = useState(false);
  const [attendees, setAttendees] = useState(() => {
    const allAttendees = new Set([
      ...(tarea.to || []),
      tarea.from,
      currentUser.email
    ]);
    return Array.from(allAttendees);
  });
  const [enableMeet, setEnableMeet] = useState(!!tarea.enlaceMeet);
  const [attendeeError, setAttendeeError] = useState('');
  const [isEditRecurrentModalOpen, setIsEditRecurrentModalOpen] = useState(false);
  const [pendingEdit, setPendingEdit] = useState(null);

  useEffect(() => {
    if (tarea.emailBusiness) {
      const q = queryAreas(tarea.emailBusiness);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAreas(querySnapshot);
        setAreas(prevAreas => {
          const areArraysEqual = (arr1, arr2) => 
            arr1.length === arr2.length && 
            arr1.every((item, index) => 
              JSON.stringify(item) === JSON.stringify(arr2[index])
            );
          
          return areArraysEqual(prevAreas, items) ? prevAreas : items;
        });
      });
      return unsubscribe;
    }
  }, [tarea.emailBusiness]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (!isDeleteModalOpen ||
          (deleteModalRef.current && !deleteModalRef.current.contains(event.target)))
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, isDeleteModalOpen]);

  const handleEditClick = () => {
    if (tarea.recurringEventId) {
      setIsEditRecurrentModalOpen(true);
    } else {
      setIsEditing(true);
    }
  };

  const handleRecurrentEdit = async (editType) => {
    setIsEditRecurrentModalOpen(false);
    setTimeout(() => {
      setIsEditing(true);
      setPendingEdit(editType);
    }, 100);
  };

  const handleSendBrain = async () => {
    setIsLoading(true);
    
    // Remover "✅ " del título si está presente
    const updatedTitle = tarea.title.startsWith("✅ ") ? tarea.title.slice(2) : tarea.title;
    
    const updatedData = {
      ...tarea,
      title: updatedTitle,
      status: "Pendiente",
    };

    try {
      // Actualizar la tarea en la base de datos
      await updateTicketDetalle(tarea.emailBusiness, tarea.id, updatedData, areas);
      
      // Eliminar el evento del calendario
      await eliminarEventoDeCalendar(tarea, navigate,  currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
      // Actualizar el estado en el frontend
      onEdit(updatedData);
      
      // Mostrar notificación de éxito
      toast.success("¡Tarea enviada a Pendientes y eliminada del calendario!");
    } catch (error) {
      console.error("Error al enviar la tarea a Pendientes:", error);
      toast.error("Error al enviar la tarea a Pendientes");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const updateTitleWithIcon = (title, newIcon) => {
    // Expresión regular para detectar si el título comienza con un emoji
    const emojiRegex = /^(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/u;
    
    if (emojiRegex.test(title)) {
      // Si el título comienza con un emoji, reemplazarlo con el nuevo ícono
      return newIcon + title.replace(emojiRegex, '').trim();
    } else {
      // Si no comienza con un emoji, simplemente añadir el nuevo ícono al principio
      return newIcon + ' ' + title.trim();
    }
  };

  const handleSaveClick = async () => {
    setIsLoading(true);

    try {
      const updatedTask = { ...editedTask };
      
      // Obtener el área seleccionada y sus datos
      const selectedArea = areas.find(area => area.name === updatedTask.department);
      
      if (!selectedArea) {
        toast.error('Por favor selecciona un área válida');
        setIsLoading(false);
        return;
      }

      // Mantener los datos originales que no deben cambiar en eventos recurrentes
      if (tarea.recurringEventId) {
        updatedTask.recurringEventId = tarea.recurringEventId;
        updatedTask.color = tarea.color; // Mantener el color original
        updatedTask.isRoutine = true; // Mantener la marca de evento recurrente
      }

      // Asegurarnos de que tanto department como area estén definidos
      updatedTask.department = selectedArea.name;
      updatedTask.area = selectedArea.name;

      // Formatear fechas y horas
      updatedTask.vencimiento = moment(updatedTask.vencimiento).format("DD-MM-YYYY");
      updatedTask.fechaFin = moment(updatedTask.fechaFin).format("DD-MM-YYYY");
      updatedTask.creacion = moment(updatedTask.creacion).format("DD-MM-YYYY");

      if (allDay) {
        updatedTask.horaInicio = "Todo el día";
        updatedTask.horaTermino = "Todo el día";
        updatedTask.allDay = true;
      } else {
        updatedTask.horaInicio = moment(updatedTask.horaInicio).format("HH:mm");
        updatedTask.horaTermino = moment(updatedTask.horaTermino).format("HH:mm");
        updatedTask.allDay = false;
      }

      // Obtener el color del área seleccionada
      const newColor = selectedArea.color;

      // Actualizar los invitados y la configuración de Meet
      updatedTask.to = attendees;
      updatedTask.enlaceMeet = enableMeet;

      // Agregar el tipo de edición si es un evento recurrente
      if (tarea.recurringEventId) {
        updatedTask.editType = pendingEdit;
        // Mantener la regla de recurrencia original
        updatedTask.recurrenceRule = tarea.recurrenceRule;
      }

      console.log("Datos actualizados antes de guardar:", updatedTask);

      // Guardar en Firebase y Google Calendar
      await updateTicketDetalle(tarea.emailBusiness, tarea.id, updatedTask, areas);
      const updatedEvent = await updateGoogleCalendarEvent(
        tarea,
        updatedTask,
        tarea.recurringEventId ? tarea.color : newColor,  // Mantener color original si es recurrente
        currentUser,
        accessToken,
        refreshToken,
        tokenExpiry,
        firebaseAccesToken,
        pendingEdit
      );

      // Actualizar el enlace de Meet si se creó uno nuevo
      if (updatedEvent?.conferenceData?.entryPoints) {
        const meetLink = updatedEvent.conferenceData.entryPoints.find(
          ep => ep.entryPointType === 'video'
        )?.uri;
        
        if (meetLink) {
          updatedTask.enlaceMeet = meetLink;
        }
      }

      // Si es un evento recurrente y se están editando todos los eventos de la serie,
      // sincronizar con Google Calendar
      if (tarea.recurringEventId && pendingEdit === 'all') {
        // Esperar un momento para asegurar que Google Calendar haya procesado los cambios
        await new Promise(resolve => setTimeout(resolve, 2000));

        toast.info('Sincronizando eventos recurrentes...');
        try {
          await sincronizarDesdeGoogleCalendar(
            tarea.emailBusiness,
            currentUser,
            accessToken,
            refreshToken,
            tokenExpiry,
            firebaseAccesToken
          );
          toast.success('¡Eventos recurrentes sincronizados correctamente!');
        } catch (syncError) {
          console.error('Error en la sincronización:', syncError);
          toast.error('Error al sincronizar los eventos recurrentes');
        }
      }

      onEdit(updatedTask);
      setIsEditing(false);
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);

      toast.success("¡Tarea o evento actualizado y sincronizado!");

      // Limpiar el tipo de edición pendiente
      setPendingEdit(null);

      // Después de la actualización, asegurarnos de actualizar el estado local
      if (!enableMeet) {
        updatedTask.enlaceMeet = null;
      }
    } catch (error) {
      console.error("Error al actualizar la tarea:", error);
      toast.error("Error al actualizar la tarea");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleDeleteClick = () => {
    // Si es un evento recurrente, mostrar el modal de opciones recurrentes
    if (tarea.recurringEventId) {
      setIsDeleteRecurrentModalOpen(true);
    } else {
      // Si no es recurrente, mostrar el modal normal de eliminación
      setIsDeleteModalOpen(true);
    }
  };

  const handleDeleteTarea = async () => {
    try {
      setIsLoading(true);
      await eliminarEventoDeCalendar(
        tarea,
        currentUser,
        accessToken,
        refreshToken,
        tokenExpiry,
        firebaseAccesToken,
        'single'
      );
      
      toast.success('Tarea eliminada correctamente');
      onClose();
    } catch (error) {
      console.error('Error al eliminar la tarea:', error);
      toast.error('Error al eliminar la tarea');
    } finally {
      setIsLoading(false);
    }
  };

  const handleMarkAsCompleted = async () => {
    setIsLoading(true);
    const updatedTitle = tarea.title.startsWith("✅ ") ? tarea.title : `✅ ${tarea.title}`;
    const updatedData = {
      ...tarea,
      title: updatedTitle,
      status: "Terminado",
    };

    try {
      await updateTicketDetalle(tarea.emailBusiness, tarea.id, updatedData, areas);
      await updateGoogleCalendarEvent(tarea, updatedData, tarea.color,  currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);

      onEdit(updatedData);
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);

      // Activar el confetti
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);

      toast.success("¡Tarea marcada como terminada y sincronizada!");
    } catch (error) {
      console.error("Error actualizando el ticket:", error);
      toast.error("Error al marcar la tarea como terminada");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenWorkSpace = () => {
    navigate("/WorkSpaces", {
      state: {
        title: tarea.title,
        businessName: tarea.businessName,
        priority: tarea.priority,
        vencimiento: tarea.vencimiento,
        creacion: tarea.creacion,
        department: tarea.department,
        status: tarea.status,
        emailClient: tarea.emailCreator,
        emailBusiness: tarea.emailBusiness,
        descripcion: tarea.descripcion,
        context: tarea.context,
        skill: tarea.skill,
        plan: tarea.plan,
        creator: tarea.creator,
        id: tarea.id,
        uid: currentUser.uid,
        to: tarea.to,
        from: tarea.from,
        horaCreacion: tarea.horaCreacion,
      },
    });
  };

  const handleAreaChange = (e) => {
    const newDepartment = e.target.value;
    const selectedArea = areas.find(area => area.name === newDepartment) || {};
    const newIcon = selectedArea.icon || "";
  
    setEditedTask(prevTask => {
      const oldTitle = prevTask.title || "";
      // Regex para detectar emoji al inicio del string:
      const emojiRegex = /^(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/u;
      
      // 1. Le quitas el ícono viejo si existe.
      const titleSinIcono = oldTitle.replace(emojiRegex, "").trim();
  
      // 2. Le agregas el nuevo ícono al inicio, si existe.
      const updatedTitle = newIcon
        ? `${newIcon} ${titleSinIcono}`
        : titleSinIcono;
  
      return {
        ...prevTask,
        department: newDepartment,
        title: updatedTitle,
        // Ojo: aquí NO estás seteando color; eso lo harás al guardar.
      };
    });
  };
  

  const currentArea = useMemo(() => {
    return areas.find(area => area.name === tarea.department) || {};
  }, [areas, tarea.department]);

  useEffect(() => {
    const newEditedTask = {
      ...tarea,
      vencimiento: moment(tarea.vencimiento, "DD-MM-YYYY").toDate(),
      creacion: moment(tarea.creacion, "DD-MM-YYYY").toDate(),
      fechaFin: moment(tarea.fechaFin, "DD-MM-YYYY").toDate(),
      horaInicio: parseTime(tarea.horaInicio),
      horaTermino: parseTime(tarea.horaTermino),
      title: tarea.title || "",
      descripcion: tarea.descripcion || "",
      allDay: tarea.allDay || false,
      department: tarea.department || "",
    };

    setEditedTask(prevTask => {
      return JSON.stringify(prevTask) === JSON.stringify(newEditedTask) 
        ? prevTask 
        : newEditedTask;
    });
  }, [tarea]);

  const handleDeleteRecurrentEvent = async (deleteType) => {
    setIsLoading(true);
    try {
      // Primero eliminamos de Google Calendar
      await eliminarEventoDeCalendar(
        tarea,
        currentUser,
        accessToken,
        refreshToken,
        tokenExpiry,
        firebaseAccesToken,
        deleteType
      );

      // Luego eliminamos de Firebase según el tipo de eliminación
      const businessRef = doc(db, "business", tarea.emailBusiness);
      
      switch (deleteType) {
        case 'single':
          // Eliminar solo esta instancia
          await deleteDoc(doc(businessRef, "tickets", tarea.id));
          break;

        case 'future':
          // Eliminar esta instancia y las futuras
          // Obtener todas las tareas con el mismo recurringEventId y fecha posterior
          const futureQuery = query(
            collection(businessRef, "tickets"),
            where("recurringEventId", "==", tarea.recurringEventId),
            where("vencimiento", ">=", tarea.vencimiento)
          );
          const futureSnapshot = await getDocs(futureQuery);
          // Eliminar cada documento
          const futureDeletions = futureSnapshot.docs.map(doc => 
            deleteDoc(doc.ref)
          );
          await Promise.all(futureDeletions);
          break;

        case 'all':
          // Eliminar toda la serie
          const allQuery = query(
            collection(businessRef, "tickets"),
            where("recurringEventId", "==", tarea.recurringEventId)
          );
          const allSnapshot = await getDocs(allQuery);
          // Eliminar cada documento
          const allDeletions = allSnapshot.docs.map(doc => 
            deleteDoc(doc.ref)
          );
          await Promise.all(allDeletions);
          break;
      }

      toast.success('Eventos eliminados correctamente');
      onClose();
    } catch (error) {
      console.error('Error al eliminar eventos:', error);
      toast.error('Error al eliminar los eventos');
    } finally {
      setIsLoading(false);
    }
  };

  const AttendeeAvatar = React.memo(({ email }) => {
    const getInitials = (email) => {
      return email
        .split('@')[0] // Tomar solo la parte antes del @
        .slice(0, 2) // Tomar los primeros 2 caracteres
        .toUpperCase();
    };

    const getRandomColor = (email) => {
      const colors = ['1abc9c', '2ecc71', '3498db', '9b59b6', 'f1c40f', 'e67e22', 'e74c3c', '34495e'];
      const index = email.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % colors.length;
      return colors[index];
    };

    const avatarUrl = `https://ui-avatars.com/api/?name=${getInitials(email)}&background=${getRandomColor(email)}&color=fff&bold=true&format=svg`;

    return (
      <div className="attendee-avatar" title={email}>
        <img 
          src={avatarUrl}
          alt={getInitials(email)}
          loading="lazy"
        />
        <span className="attendee-email">{email}</span>
      </div>
    );
  });

  AttendeeAvatar.displayName = 'AttendeeAvatar';

  if (!tarea) return null;

  const {
    title,
    fecha,
    horaInicio: tareaHoraInicio,
    horaTermino: tareaHoraTermino,
    department,
    descripcion,
    vencimiento,
    skill,
    status,
    allDay: tareaAllDay,
  } = isEditing ? editedTask : tarea;

  // Modificación: especificar formatos de entrada al parsear fechas y horas
  const displayVencimiento = moment(vencimiento, "DD-MM-YYYY").isValid()
    ? moment(vencimiento, "DD-MM-YYYY").format("DD-MM-YYYY")
    : "Fecha inválida";

  const displayHoraInicio =
    tareaHoraInicio && tareaHoraInicio !== "Todo el día"
      ? moment(tareaHoraInicio, "HH:mm").isValid()
        ? moment(tareaHoraInicio, "HH:mm").format("HH:mm")
        : "Hora inválida"
      : null;

  const displayHoraTermino =
    tareaHoraTermino && tareaHoraTermino !== "Todo el día"
      ? moment(tareaHoraTermino, "HH:mm").isValid()
        ? moment(tareaHoraTermino, "HH:mm").format("HH:mm")
        : "Hora inválida"
      : null;

  const uniqueAttendees = [...new Set(attendees)]; // Eliminar duplicados si los hay

  return ReactDOM.createPortal(
    <div ref={ref} className="detalleTarea">
      <div className="detalleTarea__header">
        <X className="icon" onClick={onClose} />
      </div>
      {isEditing ? (
        <label>
          Título:
          <input
            type="text"
            name="title"
            value={editedTask.title}
            onChange={handleChange}
            className="detalleTarea__input detalleTarea__input--text"
            style={{ textAlign: "center", width: "100%" }}
          />
        </label>
      ) : (
        <h3>{title || "(Sin título)"}</h3>
      )}
      <div className="detalleTarea__body">
        {isEditing ? (
          <>
            <label>
              Fecha de vencimiento:
              <DatePicker
                selected={editedTask.vencimiento}
                onChange={(date) =>
                  setEditedTask((prevTask) => ({
                    ...prevTask,
                    vencimiento: date,
                    fechaFin: date,
                    creacion: date,
                  }))
                }
                dateFormat="dd-MM-yyyy"
                className="detalleTarea__input detalleTarea__input--text"
              />
            </label>
            <label>
              <input
                type="checkbox"
                checked={allDay}
                onChange={(e) => {
                  setAllDay(e.target.checked);
                  if (e.target.checked) {
                    setEditedTask((prevTask) => ({
                      ...prevTask,
                      horaInicio: "Todo el día",
                      horaTermino: "Todo el día",
                      allDay: true,
                    }));
                  } else {
                    setEditedTask((prevTask) => ({
                      ...prevTask,
                      horaInicio: parseTime(tarea.horaInicio) || null,
                      horaTermino: parseTime(tarea.horaTermino) || null,
                      allDay: false,
                    }));
                  }
                }}
              />
              Todo el día
            </label>
            {!allDay && (
              <>
                <label>
                  Hora de Inicio:
                  <DatePicker
                    selected={editedTask.horaInicio}
                    onChange={(date) =>
                      setEditedTask((prevTask) => ({ ...prevTask, horaInicio: date }))
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Hora"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    className="detalleTarea__input detalleTarea__input--time"
                  />
                </label>
                <label>
                  Hora de Término:
                  <DatePicker
                    selected={editedTask.horaTermino}
                    onChange={(date) =>
                      setEditedTask((prevTask) => ({ ...prevTask, horaTermino: date }))
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Hora"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    className="detalleTarea__input detalleTarea__input--time"
                  />
                </label>
              </>
            )}
            <label>
              Descripción:
              <textarea
                name="descripcion"
                value={editedTask.descripcion}
                onChange={handleChange}
                className="detalleTarea__input"
              />
            </label>
            <label>
              Área:
              <select
                name="department"
                value={editedTask.department}
                onChange={handleAreaChange}
                className="detalleTarea__input detalleTarea__input--select"
              >
                <option value="">Seleccionar área</option>
                {areas.map((area) => (
                  <option key={area.name} value={area.name}>
                    {area.name}
                  </option>
                ))}
              </select>
            </label>
            {tarea.type === 'evento' && (
              <div className="detalleTarea__attendees-section">
                <AttendeeSelector
                  attendees={attendees}
                  setAttendees={setAttendees}
                  enableMeet={enableMeet}
                  setEnableMeet={setEnableMeet}
                  error={attendeeError}
                />
              </div>
            )}
          </>
        ) : (
          <p>
            {`${displayVencimiento}, ${
              tareaAllDay
                ? "Todo el día"
                : `${displayHoraInicio} – ${displayHoraTermino}`
            }`}
          </p>
        )}

        <div className="detalleTarea__info">
          <StickyNote className="icon-margin" />
          <div
            className="detalleTarea__description"
            dangerouslySetInnerHTML={{ __html: descripcion }}
          />
        </div>
        <hr />
        <div className="detalleTarea__info">
          <Briefcase className="icon-margin" />
          {areas.length > 0 ? (
            <span>
              {currentArea?.icon} {tarea.department || "Sin Área"}
            </span>
          ) : (
            <span>Cargando áreas...</span>
          )}
        </div>
        <div className="detalleTarea__info">
          <Flag className="icon-margin" />
          <span>{tarea.priority || "Sin Prioridad"}</span>
        </div>
        <div className="detalleTarea__info">
          <BadgeAlert className="icon-margin" />
          <span>{tarea.type || "Sin Tipo"}</span>
        </div>
        <div className="detalleTarea__info">
          <User className="icon-margin" />
          <a
            href={tarea.enlaceMeet || tarea.enlaceEventoCalendario}
            target="_blank"
            rel="noopener noreferrer"
            className="detalleTarea__enlace"
          >
            {tarea.enlaceMeet ? "Unirse a reunión en Meet" : "Abrir en Google Calendar"}
          </a>
        </div>
        <div className="detalleTarea__info">
          <Settings className="icon-margin" />
          <span>{tarea.type || "Sin Área"}</span>
        </div>
        <div className="detalleTarea__info">
          <Clock className="icon-margin" />
          <span>{tarea.recurringEventId ? "Recurrente" : "No Recurrente"}</span>
          </div>
        {uniqueAttendees.length > 0 && (
          <div className="detalleTarea__info">
            <Users className="icon-margin" />
            <div className="detalleTarea__attendees">
              {uniqueAttendees.map((email, index) => (
                <AttendeeAvatar 
                  key={`${email}-${index}`} 
                  email={email} 
                />
              ))}
            </div>
          </div>
        )}
        {tarea.enlaceMeet && (
          <div className="detalleTarea__info">
            <Video className="icon-margin" />
            <a
              href={tarea.enlaceMeet}
              target="_blank"
              rel="noopener noreferrer"
              className="detalleTarea__enlace"
            >
              Unirse a reunión en Meet
            </a>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="detalleTarea__loading" style={{ width: "90%", margin: "auto" }}>
          <div className="spinner" />
          <span>Actualizando...</span>
        </div>
      )}
      {showConfirmation && (
        <div className="detalleTarea__confirmation">
          <CheckCircle className="icon" />
          <span>La tarea ha sido actualizada</span>
        </div>
      )}
      <div className="detalleTarea__actions">
        {isEditing ? (
          <Save className="icon" onClick={handleSaveClick} />
        ) : (
          <>
            <div className="detalleTarea__edit-delete-icons">
              <Paperclip className="icon" onClick={handleOpenWorkSpace} />
              {tarea.canEdit && (
                <>
                  <Edit className="icon" onClick={handleEditClick} />
                  <Brain className="icon" onClick={handleSendBrain} />
                  <Trash2 
                    className="icon" 
                    onClick={handleDeleteClick}
                  />
                </>
              )}
            </div>
            <div className="detalleTarea__complete-button-container">
              <button
                className="detalleTarea__complete-button"
                onClick={handleMarkAsCompleted}
                disabled={status === "Terminado"}
              >
                <CheckSquare className="icon" />
                {status === "Terminado" ? "Tarea completada" : "Marcar como terminado"}
              </button>
            </div>
          </>
        )}
      </div>
      {isDeleteModalOpen && !tarea.recurringEventId && (
        <DeleteTarea
          ref={deleteModalRef}
          tarea={tarea}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDeleteTarea}
          currentUser={currentUser}
          accessToken={accessToken}
          refreshToken={refreshToken}
          tokenExpiry={tokenExpiry}
          firebaseAccesToken={firebaseAccesToken}
        />
      )}
      {isDeleteRecurrentModalOpen && tarea.recurringEventId && (
        <DeleteRecurrentEvent
          isOpen={isDeleteRecurrentModalOpen}
          onClose={() => setIsDeleteRecurrentModalOpen(false)}
          onDelete={handleDeleteRecurrentEvent}
        />
      )}
      {isEditRecurrentModalOpen && (
        <EditRecurrentEvent
          isOpen={isEditRecurrentModalOpen}
          onClose={() => setIsEditRecurrentModalOpen(false)}
          onEdit={handleRecurrentEdit}
        />
      )}
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={300}
          recycle={false}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
    </div>,
    document.body
  );
};

export default DetalleTarea;
