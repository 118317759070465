import React, { useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { X } from 'lucide-react';
import './DetalleTarea.css';

const DeleteRecurrentEvent = ({ isOpen, onClose, onDelete }) => {
  const handleDelete = useCallback(async (type) => {
    try {
      onClose();
      await onDelete(type);
    } catch (error) {
      console.error('Error al eliminar:', error);
    }
  }, [onDelete, onClose]);

  const stopPropagation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div 
      className="modal-overlay"
      onMouseDown={(e) => e.target === e.currentTarget && onClose()}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100000
      }}
    >
      <div 
        className="modal-content"
        onMouseDown={stopPropagation}
        onClick={stopPropagation}
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '90%',
          maxWidth: '400px',
          position: 'relative'
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            background: 'none',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          <X size={20} />
        </button>

        <h3 style={{ marginBottom: '20px' }}>Eliminar evento recurrente</h3>
        <p style={{ marginBottom: '20px' }}>¿Qué eventos desea eliminar?</p>
        
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <button 
            onMouseDown={(e) => {
              e.stopPropagation();
              handleDelete('single');
            }}
            style={{
              padding: '10px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: '#1a256b',
              color: 'white'
            }}
          >
            Solo este evento
          </button>
          
          <button 
            onMouseDown={(e) => {
              e.stopPropagation();
              handleDelete('all');
            }}
            style={{
              padding: '10px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: '#dc3545',
              color: 'white'
            }}
          >
            Todos los eventos de la serie
          </button>

          <button 
            onMouseDown={(e) => {
              e.stopPropagation();
              onClose();
            }}
            style={{
              padding: '10px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: '#6c757d',
              color: 'white'
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DeleteRecurrentEvent; 