import React, { useState, useEffect, useRef } from 'react';
import { onSnapshot, collection, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import AddAreasModal from '../AddAreasModal';
import './CreateProject.css';

const CreateProject = ({ nextStep, prevStep, handleChange, formData, currentUser }) => {
  const [projectList, setProjectList] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const AddAreasRef = useRef(null);
  const idBusiness = sessionStorage.getItem('businessId');

  useEffect(() => {
    if (idBusiness) {
      const unsubscribe = onSnapshot(
        collection(db, 'business', idBusiness, 'areas'),
        (snapshot) => {
          const areas = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProjectList(areas);
        }
      );
      return () => unsubscribe();
    }
  }, [idBusiness]);

  const handleOpenModal = () => {
    AddAreasRef.current.handleOpen();
  };

  const handleDeleteProject = async (id) => {
    try {
      const projectDocRef = doc(db, 'business', idBusiness, 'areas', id);
      await deleteDoc(projectDocRef);
      setProjectList((prevList) => prevList.filter((project) => project.id !== id));
    } catch (error) {
      console.error('Error al eliminar el proyecto:', error);
    }
  };

  const handleNextStep = () => {
    if (projectList.length === 0) {
      alert('Debes agregar al menos un proyecto antes de continuar.');
      return;
    }
    handleChange('projects')({ target: { value: projectList } });
    nextStep();
  };

  const handleTooltipToggle = (id) => {
    setActiveTooltip(activeTooltip === id ? null : id);
  };

  return (
    <div className="form-step">
      <h2 className="form-title">¿Qué áreas integran tu vida?</h2>
      <div className="intro-text-container">
        <p className="intro-text">
          <span className="highlight">💥 {currentUser.displayName}</span>, ¡tú eres el gran proyecto de tu vida!
        </p>
        <p className="intro-text">
          Sin embargo, tu vida se compone de varias áreas, cada una con objetivos independientes.
        </p>
        <p className="intro-text">
          ¿Cuáles son esas áreas que te gustaría categorizar en Planius para que podamos enfocarnos de manera personalizada en cada una? 🎯
        </p>
        <p className="intro-text example">
          <em>Ejemplo: Trabajo, Salud, Finanzas, Hogar, Mindfulness, etc.</em>
        </p>
      </div>
      <div className="project-list-container">
        {projectList.map((project) => (
          <div 
            key={project.id} 
            className="project-item"
            onMouseEnter={() => handleTooltipToggle(project.id)}
            onMouseLeave={() => handleTooltipToggle(project.id)}
          >
            <div className="project-content">
              <span className="project-icon">{project.icon}</span>
              <span className="project-name">{project.name}</span>
              <span
                className="project-color-dot"
                style={{ '--project-color': project.color }}
              />
            </div>
            <button
              className="delete-button"
              onClick={() => handleDeleteProject(project.id)}
              aria-label={`Eliminar ${project.name}`}
            >
              ✕
            </button>
            {activeTooltip === project.id && (
              <div className="project-tooltip">
                <div className="tooltip-arrow"></div>
                <h4>{project.name}</h4>
                <p><strong>Objetivo:</strong> {project.description}</p>
                <p><strong>Nombre:</strong> {project.name}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="form-actions">
        <button className="formButton" onClick={prevStep}>Volver</button>
        <button className="formButton" onClick={handleOpenModal}>
          Agregar Área
        </button>
        <button className="formButton" onClick={handleNextStep}>
          Avanzar
        </button>
      </div>
      <AddAreasModal ref={AddAreasRef} onAdd={() => {}} />
    </div>
  );
};

export default CreateProject;