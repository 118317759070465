import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth, db } from "./firebase";
import logo from "./img/logo-banner.png";
import placeholderImage from "./img/placeholder.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBriefcase,
  faUser,
  faBuilding,
  faTrophy,
  faUsers,
  faMoon,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../context/AuthContext";
import { DarkModeContext } from "../App";
import FloatingBtn from "./floatingBtn/FloatingBtn";
import Notifications from "./Notifications/Notificacions";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import "./Navbar.css";

function Navbar(props) {
  const navigate = useNavigate();
  const {
    currentUser,
    userType,
    numberOfAgents,
    puntosSoportick2,
    businessName,
    department,
  } = useContext(AuthContext);
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
  const pomodoroRef = useRef(null);

  const [menuVisible, setMenuVisible] = useState(false);
  const [notificationMenuVisible, setNotificationMenuVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  useEffect(() => {
    const db = getFirestore();
    const user = auth.currentUser;
    if (user) {
      const email = user.email;
      const notificationsRef = collection(db, `users/${email}/notifications`);
      const q = query(notificationsRef, where("read", "==", false));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setUnreadNotificationsCount(snapshot.size);
      });

      return () => unsubscribe();
    }
  }, []);

  const handleClick = () => {
    navigate("/gestionarTickets");
  };

  const handleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleMouseLeave = () => {
    setMenuVisible(false);
  };

  const handleNotificationMenu = () => {
    setNotificationMenuVisible(!notificationMenuVisible);
  };

  const handleNotificationMouseLeave = () => {
    setNotificationMenuVisible(false);
  };

  const toggleNotifications = () => {
    setNotificationsVisible(!notificationsVisible);
  };

  const handleDarkModeToggle = () => {
    toggleDarkMode();
  };

  const formatDisplayName = (fullName) => {
    if (!fullName) return '';
    
    const nameParts = fullName.split(' ');
    if (nameParts.length <= 2) return fullName;
    
    const firstName = nameParts[0];
    const firstLastName = nameParts[nameParts.length - 2];
    
    return `${firstName} ${firstLastName}`;
  };

  const userImage = useMemo(
    () => (
      <div
        className="photoNav-container"
        data-notification-count={unreadNotificationsCount}
      >
        <img
          onClick={handleMenu}
          className="photoNav"
          src={currentUser.photoURL || placeholderImage}
          alt={"Foto de " + (currentUser.displayName || "usuario")}
        />
        <span className="notification-badge">{unreadNotificationsCount}</span>
      </div>
    ),
    [currentUser.photoURL, unreadNotificationsCount]
  );

  return (
    <div>
      <nav className={darkMode ? 'dark-mode' : ''}>
        <div className="nav-wrapper">
          <img
            onClick={handleClick}
            className="logonav"
            src={logo}
            alt="logo"
          />

        {/*   <button onClick={handleDarkModeToggle} className="dark-mode-btn">
            <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
          </button> */}

          <div className="responsive-user-info">
            {userImage}
            <div className="nav-title">{formatDisplayName(currentUser.displayName)}</div>
          </div>

          <div className="user-info" onMouseLeave={handleMouseLeave}>
            <div className="nav-title">{formatDisplayName(currentUser.displayName)}</div>
            {userImage}
            {menuVisible && (
              <div className="dropdown-navBar">
                <div
                  onClick={() => navigate("/miPerfil")}
                  className="dropdown-item-nav"
                >
                  Mi perfil
                </div>
                <div
                  onClick={toggleNotifications}
                  className="dropdown-item-nav"
                >
                  Notificaciones
                </div>
                <div
                  onClick={() => {
                    if (pomodoroRef.current) {
                      pomodoroRef.current.resetAndClose();
                    }
                    signOut(auth);
                    navigate("/");
                  }}
                  className="dropdown-item-nav"
                >
                  Cerrar Sesión
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      {notificationsVisible && <Notifications onClose={toggleNotifications} />}
      {/*       <FloatingBtn />
 */}    </div>
  );
}

export default Navbar;