import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import logoLogin from "./img/logo-login.png";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setErrorMessage("Por favor ingrese un email válido");
      return;
    }

    setLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('Se ha enviado un enlace de recuperación de contraseña a tu correo electrónico.');
        setLoading(false);
        navigate("/login"); // Asumiendo que quieras redirigir al usuario a la página de inicio de sesión después.
      })
      .catch((error) => {
        setErrorMessage("Error al enviar el correo de recuperación de contraseña. Asegúrate de que el correo electrónico esté registrado.");
        console.error("Error al enviar correo de recuperación: ", error);
        setLoading(false);
      });
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <img className="logoLogin" src={logoLogin} alt="" />
        <span className="titleLogin">Recuperar Contraseña</span>
        <form onSubmit={handleSubmit}>
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            name="email"
            required={true}
            type="text"
            placeholder="Email"
          />
          <button type="submit" disabled={loading}>
            Enviar correo de recuperación
          </button>
        </form>
        {loading && <div className="spinner"></div>}
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};
