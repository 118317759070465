import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { storage } from "./../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";

Modal.setAppElement("#root");

function AdjuntarArchivosModal(props) {
  const [modalAdjuntOpen, setModalAdjuntOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para el spinner
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    if (e.target.files.length > 5) {
      alert("No puedes subir más de 5 archivos a la vez.");
      return;
    }
    setFiles(e.target.files);
  };

  const handleAdjuntar = async () => {
    setIsLoading(true); // Comienza la carga, muestra el spinner

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = file.name;
      const storageRef = ref(storage, `${fileName}`);

      if (file.size > 20 * 1024 * 1024) {
        alert(
          "El archivo es demasiado grande. Por favor, sube un archivo de menos de 20MB."
        );
        return;
      }

      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (url) => {
          setFileUrls((prevUrls) => [...prevUrls, url]);
          await updateFirestoreDoc(url);
        });
      });
    }

    alert("Archivos adjuntados correctamente");
    console.log("archivos adjuntados");
    handleCloseModalAdjuntar();
    setIsLoading(false); // La carga ha terminado, oculta el spinner
  };

  async function updateFirestoreDoc(url) {
    const db = getFirestore();
    const ticketRef = doc(db, "business", props.emailBusiness, "tickets", props.idTicket);
    await updateDoc(ticketRef, {
      adjuntos: arrayUnion(url),
    });
  }

  const handleCloseModalAdjuntar = () => {
    setModalAdjuntOpen(false);
    props.handleCloseModalAdjuntar();
  };

  useEffect(() => {
    if (props.openModalAdjuntar) {
      setModalAdjuntOpen(true);
    }
  }, [props.openModalAdjuntar]);

  return (
    <Modal
      overlayClassName={"modal-overlay"}
      className={"modal-content"}
      isOpen={modalAdjuntOpen}
      onRequestClose={handleCloseModalAdjuntar}
    >
      <h2 className="titleModal">Adjuntar Archivos</h2>

      <div className="descriptionModal">
        <p>
          Seleccione los archivos que desea adjuntar al ticket (tamaño máximo
          20mb, máximo 5 archivos)
        </p>
      </div>

      <input type="file" onChange={handleFileChange} multiple />

      <ul>
        {fileUrls.map((url, index) => (
          <li key={index}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              Archivo {index + 1}
            </a>
          </li>
        ))}
      </ul>
      <div className="row">
        <button className="btnCloseModal" onClick={handleAdjuntar}>
          Adjuntar
        </button>
        <button className="btnCloseModalCancel" onClick={handleCloseModalAdjuntar}>
          Cancelar
        </button>
      </div>

      {/* Aquí se muestra el spinner si isLoading es true */}
      {isLoading && <div className="spinner"></div>}
    </Modal>
  );
}

export default AdjuntarArchivosModal;
