import React, { useState } from "react";
import { doc, getDoc, updateDoc, addDoc, setDoc, collection } from "firebase/firestore";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { db, auth, storage } from "./firebase";
import sendMail from "./SendMail";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ref } from "firebase/storage";
import * as yup from "yup";
import logoLogin from "./img/logo-login.png";
import ImageUploader from "./ImageUploader";
import moment from "moment/moment";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { signInAnonymously } from "firebase/auth";
import { useEffect } from "react";
import { EmailAuthProvider, linkWithCredential } from "firebase/auth";

const signInAnon = async () => {
  try {
    await signInAnonymously(auth);
  } catch (error) {
    console.log(error);
  }
};

const passwordValidationSchema = yup
  .string()
  .required("La contraseña es obligatoria")
  .matches(
    /^(?=.*[A-Z])(?=.*[!_@.#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
    "La contraseña debe contener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 símbolo especial"
  );

const schema = yup.object().shape({
  username: yup
    .string()
    .required("El nombre de usuario es obligatorio")
    .min(3, "El nombre de usuario debe tener al menos 3 caracteres")
    .max(30, "El nombre de usuario debe tener máximo 30 caracteres"),
  email: yup
    .string()
    .required("El email es obligatorio")
    .email("Debe ser un email válido"),
  password: passwordValidationSchema,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("La confirmación de contraseña es obligatoria"),
});

function RegisterUser(e) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const [userType, setUserType] = useState("");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState(null);
  const [emailNotRegistered, setEmailNotRegistered] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    signInAnon();
  }, []);

  const onSubmit = async () => {
    try {
      setLoading(true);
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      //Create a unique image name
      const date = new Date().getTime();
      const storageRef = ref(storage, `${name + date}`);

      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          try {
            //Update profile
            await updateProfile(res.user, {
              displayName: name,
              photoURL: downloadURL,
            });
            //create user on firestore
            await updateDoc(doc(db, "users", res.user.email), {
              uid: res.user.uid,
              displayName: name,
              photoURL: downloadURL,
              status: "activo",
              date: date,
              businessName: "",
            });

            const hoy = moment().format("DD-MM-YYYY");
            const tomorrow = moment().add(1, "days");
            //create empty user chats on firestore
            await addDoc(collection(db, "tickets"), {
              uid: res.user.uid,
              title: "¡Bienvenido a la plataforma!",
              businessName: "Pied Piper",
              priority: "alta",
              vencimiento: tomorrow.format("DD-MM-YYYY"),
              creacion: hoy,
              department: "RRHH",
              status: "Pendiente",
              email: res.user.email,
              today: date,
              descripcion: "Ticket de bienvenida a la plataforma",
            });

            setLoading(false);
            navigate("/");
          } catch (error) {
            console.log(error);
          }
        });
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const validateEmail = async (email) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length === 0) {
        const docRef = doc(db, "users", email);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          setName(docSnap.data().displayName);
          setCategory(docSnap.data().category);
          setEmail(docSnap.data().email);
  
          if (docSnap.data().status === "pendiente") {
            setValidate(true);
          } else {
            alert("Usuario ya se encuentra registrado, por favor inicie sesión");
            navigate("/login");
          }
          setError(false);
          return true;
        } else {
          setValidate(false);
          setError(true);
          return false;
        }
      } else {
        alert("Mail está registrado pero no ha recibido invitación");
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <img src={logoLogin} className="logoLogin" alt="" />
        <span className="logo"> Registrar </span>
      {loading ? (
          <div className="spinner"></div>
        ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="email">Email</label>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="email"
                  id="email"
                  placeholder="Email"
                  onBlur={async (e) => {
                    if (await trigger("email")) {
                      setEmail(e.target.value);
                      const isValidEmail = await validateEmail(e.target.value);
                      setEmailNotRegistered(!isValidEmail);
                    }
                  }}
                />
              )}
            />
            {errors.email && <p className="errRed">{errors.email.message}</p>}
          </div>
          {emailNotRegistered  ? (
            <span>
              Correo no registrado, póngase en contacto con su empresa para que
              le reenvíe la invitación
            </span>
          ) : (
            ""
          )}
          {validate ? (
            <>
              <div>
                <label htmlFor="password">Contraseña</label>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      type="password"
                      id="password"
                      placeholder="Contraseña"
                      onBlur={(e) =>
                        trigger("password") && setPassword(e.target.value)
                      }
                    />
                  )}
                />
                {errors.password && (
                  <p className="errRed">{errors.password.message}</p>
                )}
              </div>

              <div>
                <label htmlFor="confirmPassword">Confirmar contraseña</label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      type="password"
                      id="confirmPassword"
                      placeholder="Confirmar contraseña"
                      onBlur={() => trigger("confirmPassword")}
                    />
                  )}
                />
                {errors.confirmPassword && (
                  <p className="errRed">{errors.confirmPassword.message}</p>
                )}
              </div>

              <div>
                <label htmlFor="avatar">Foto de Perfil</label>
                <ImageUploader onImageChange={setFile} />
              </div>
              <button onClick={onSubmit} type="submit">Registrar</button>
            </>
          ) : (
            ""
          )}
        </form>
        )}
      </div>
    </div>
  );
}

export default RegisterUser;
