import { toast } from 'react-toastify';
import moment from "moment";
import "moment-timezone";
import { db, storage, auth } from "../firebase";
import { signOut } from 'firebase/auth';
import { getValidAccessToken } from '../Functions';
import {
  collection,
  doc,
  query,
  where,
  or,
  and,
  getDoc,
  getDocs,
  writeBatch,
  collectionGroup,
  deleteDoc,
  setDoc,
  getFirestore,
  updateDoc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { addDoc } from 'firebase/firestore';
import { deleteField } from 'firebase/firestore';
import { v4 as uuid } from 'uuid';

//función para mapear los collores de google Calendar
export const colorMap = {
  '#7986CB': '1',  // Corresponde a Lavender
  '#66C18C': '2',  // Corresponde a Sage
  '#B084C8': '3',  // Corresponde a Grape
  '#E67C73': '4',  // Corresponde a Flamingo
  '#F6BF26': '5',  // Corresponde a Banana
  '#F4511E': '6',  // Corresponde a Tangerine
  '#67C3EB': '7',  // Corresponde a Peacock
  '#616161': '8',  // Corresponde a Graphite
  '#3F51B5': '9',  // Corresponde a Blueberry
  '#4CAF50': '10', // Corresponde a Basil
  '#EF5350': '11'  // Corresponde a Tomato
};


export const updateGoogleCalendarEvent = async (
  ticket, 
  updatedData, 
  color, 
  currentUser, 
  accessToken, 
  refreshToken, 
  tokenExpiry, 
  firebaseAccesToken,
  editType = 'single'
) => {
  const attemptUpdate = async (token) => {
    // Función auxiliar para limpiar íconos duplicados del título
    const cleanTitle = (title) => {
      // Eliminar todos los emojis del inicio del título
      const cleanedTitle = title.replace(/^(\p{Emoji}\s*)+/gu, '').trim();
      return cleanedTitle;
    };

    // Obtener el área y su color si se está cambiando el departamento
    let areaColor = color;
    let areaIcon = '';
    let areaData = null;
    
    // Solo obtener datos del área si se está cambiando explícitamente
    if (updatedData.department) {
      try {
        const areaRef = doc(db, 'business', ticket.emailBusiness, 'areas', updatedData.department);
        const areaDoc = await getDoc(areaRef);
        if (areaDoc.exists()) {
          areaData = areaDoc.data();
          areaColor = areaData.color || color;
          areaIcon = areaData.icon || '';
        }
      } catch (error) {
        console.error('Error al obtener el color del área:', error);
      }
    } else {
      // Mantener los datos del área existente
      areaColor = ticket.areaColor || color;
      areaIcon = ticket.areaIcon || '';
    }

    const calendarId = ticket.calendarId || 'primary';
    const eventId = editType === 'all' && ticket.recurringEventId 
      ? ticket.recurringEventId 
      : ticket.idEventoCalendario;

    if (!eventId) {
      throw new Error("Event ID not found in the ticket data");
    }

    const isAllDay = updatedData.horaInicio === "Todo el día" && updatedData.horaTermino === "Todo el día";
    
    // Validar fechas antes de procesar
    if (!updatedData.vencimiento || !updatedData.fechaFin) {
      throw new Error('Fechas de inicio y fin son requeridas');
    }

    // Construir el objeto de evento para la actualización
    const event = {
      summary: updatedData.title || ticket.title,
      description: updatedData.descripcion || ticket.descripcion,
      attendees: updatedData.to ? updatedData.to.map(email => ({ 
        email,
        responseStatus: email === currentUser.email ? 'accepted' : 'needsAction'
      })) : undefined,
    };

    if (areaColor) {
      const colorId = colorMap[areaColor];
      if (colorId) {
        event.colorId = colorId;
      }
    }

    // Configurar fechas según el tipo de evento
    if (isAllDay) {
      const startMoment = moment(updatedData.vencimiento, "DD-MM-YYYY");
      const endMoment = moment(updatedData.fechaFin, "DD-MM-YYYY");
      
      if (!startMoment.isValid() || !endMoment.isValid()) {
        throw new Error('Formato de fecha inválido');
      }

      // Para eventos de todo el día
      event.start = {
        date: startMoment.format("YYYY-MM-DD")
      };
      
      // Si es el mismo día o la fecha fin es anterior, usar el día siguiente
      if (endMoment.isSameOrBefore(startMoment, 'day')) {
        event.end = {
          date: startMoment.clone().add(1, 'days').format("YYYY-MM-DD")
        };
      } else {
        event.end = {
          date: endMoment.clone().add(1, 'days').format("YYYY-MM-DD")
        };
      }
    } else {
      // Para eventos con hora específica
      const [startHour, startMinute] = updatedData.horaInicio.split(':');
      const [endHour, endMinute] = updatedData.horaTermino.split(':');

      const startDateTime = moment(updatedData.vencimiento, "DD-MM-YYYY")
        .set({
          hour: parseInt(startHour),
          minute: parseInt(startMinute),
          second: 0
        });

      const endDateTime = moment(updatedData.fechaFin, "DD-MM-YYYY")
        .set({
          hour: parseInt(endHour),
          minute: parseInt(endMinute),
          second: 0
        });

      event.start = {
        dateTime: startDateTime.toISOString(),
        timeZone: 'America/Santiago'
      };
      event.end = {
        dateTime: endDateTime.toISOString(),
        timeZone: 'America/Santiago'
      };
    }

    // Configuración de Meet
    if (updatedData.enlaceMeet === false) {
      event.conferenceData = null;
    } else if (updatedData.enlaceMeet === true) {
      event.conferenceData = {
        createRequest: {
          requestId: uuid(),
          conferenceSolutionKey: { type: "hangoutsMeet" }
        }
      };
    }

    // Actualizar en Google Calendar
    let updateUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`;
    const params = new URLSearchParams();
    
    if (updatedData.enlaceMeet !== undefined) {
      params.append('conferenceDataVersion', '1');
    }
    
    if (ticket.recurringEventId && editType === 'single') {
      params.append('recurringEventId', ticket.recurringEventId);
    }
    
    if (params.toString()) {
      updateUrl += `?${params.toString()}`;
    }

    // Usar PUT para eventos todo el día y PATCH para eventos normales
    const method = isAllDay ? 'PUT' : 'PATCH';
    
    // Para PUT necesitamos asegurarnos de que todos los campos requeridos estén presentes
    if (method === 'PUT') {
      event.status = 'confirmed';
      if (!event.description) event.description = '';
      if (!event.summary) event.summary = 'Sin título';
    }

    const response = await fetch(updateUrl, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event)
    });

    const responseData = await response.json();
    if (!response.ok) {
      console.error("Response Data:", responseData);
      // Intentar con PUT si PATCH falla para eventos recurrentes
      if (method === 'PATCH' && ticket.recurringEventId) {
        console.log('Reintentando con PUT para evento recurrente...');
        event.status = 'confirmed';
        if (!event.description) event.description = '';
        if (!event.summary) event.summary = 'Sin título';
        
        const retryResponse = await fetch(updateUrl, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(event)
        });
        
        if (!retryResponse.ok) {
          const retryErrorData = await retryResponse.json();
          throw new Error(retryErrorData.error?.message || retryErrorData.error || 'Error al actualizar el evento');
        }
        
        return await retryResponse.json();
      }
      throw new Error(responseData.error?.message || responseData.error || 'Error al actualizar el evento');
    }

    // Actualizar en Firebase
    if (editType === 'all' && ticket.recurringEventId) {
      const ticketsRef = collection(db, `business/${ticket.emailBusiness}/tickets`);
      const q = query(ticketsRef, 
        where('recurringEventId', '==', ticket.recurringEventId)
      );
      const querySnapshot = await getDocs(q);
      
      const batch = writeBatch(db);
      
      // Manejar los invitados
      let attendees;
      if (updatedData.to !== undefined) {
        // Si se están actualizando los invitados, usar la nueva lista
        attendees = Array.from(new Set([currentUser.email, ...updatedData.to]));
      } else {
        // Si no se están actualizando, mantener los existentes
        attendees = ticket.to || [];
      }
      
      const areaUpdateData = updatedData.department ? {
        department: updatedData.department,
        area: updatedData.department,
        areaIcon: areaData?.icon || '',
        areaColor: areaData?.color || color,
        areaDescription: areaData?.description || '',
      } : {
        // Mantener los datos del área existente
        department: ticket.department,
        area: ticket.area,
        areaIcon: ticket.areaIcon,
        areaColor: ticket.areaColor,
        areaDescription: ticket.areaDescription,
      };
      
      const baseUpdateData = {
        ...areaUpdateData,
        color: areaColor,
        title: areaIcon 
          ? `${areaIcon} ${cleanTitle(updatedData.title || ticket.title)}` 
          : (updatedData.title || ticket.title),
        descripcion: updatedData.descripcion || ticket.descripcion,
        to: attendees,
        ...(updatedData.enlaceMeet !== undefined && { 
          enlaceMeet: responseData.conferenceData?.entryPoints?.find(
            ep => ep.entryPointType === 'video'
          )?.uri || null 
        }),
        isRoutine: true,
        recurringEventId: ticket.recurringEventId,
        ...(ticket.recurrenceRule && { recurrenceRule: ticket.recurrenceRule }),
        type: 'evento'
      };

      console.log('Actualizando serie con datos:', baseUpdateData);

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        // Mantener las fechas y horas originales de cada evento en la serie
        const updateData = {
          ...baseUpdateData,
          // Mantener las fechas y horas originales
          vencimiento: docData.vencimiento,
          fechaFin: docData.fechaFin,
          horaInicio: docData.horaInicio,
          horaTermino: docData.horaTermino,
          creacion: docData.creacion,
          horaCreacion: docData.horaCreacion,
          idEventoCalendario: docData.idEventoCalendario,
          enlaceEventoCalendario: docData.enlaceEventoCalendario,
          ...((!updatedData.department && docData.area) && {
            department: docData.department,
            area: docData.area,
            areaIcon: docData.areaIcon,
            areaColor: docData.areaColor,
            areaDescription: docData.areaDescription,
          })
        };
        batch.update(doc.ref, updateData);
      });

      await batch.commit();
    } else {
      const ticketRef = doc(db, `business/${ticket.emailBusiness}/tickets/${ticket.id}`);
      const updateData = {
        ...(updatedData.area && { 
          area: updatedData.area,
          ...(areaData && {
            areaIcon: areaData.icon,
            areaColor: areaData.color,
            areaDescription: areaData.description,
          })
        }),
        color: areaColor,
        ...(updatedData.title && { 
          title: areaIcon ? `${areaIcon} ${cleanTitle(updatedData.title)}` : updatedData.title
        }),
        ...(updatedData.descripcion && { descripcion: updatedData.descripcion }),
        ...(updatedData.to && { to: updatedData.to }),
        ...(updatedData.enlaceMeet !== undefined && { 
          enlaceMeet: responseData.conferenceData?.entryPoints?.find(
            ep => ep.entryPointType === 'video'
          )?.uri || null 
        }),
        vencimiento: updatedData.vencimiento,
        fechaFin: updatedData.fechaFin,
        horaInicio: updatedData.horaInicio,
        horaTermino: updatedData.horaTermino,
        isRoutine: ticket.isRoutine,
        recurringEventId: ticket.recurringEventId,
        ...(ticket.recurrenceRule && { recurrenceRule: ticket.recurrenceRule })
      };
      
      await updateDoc(ticketRef, updateData);
    }

    return responseData;
  };

  try {
    toast.info('🤖 Actualizando en calendar');
    const token = await getValidAccessToken(currentUser.email, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
    const result = await attemptUpdate(token);
    toast.success('¡Evento actualizado correctamente!');
    return result;
  } catch (error) {
    console.error('Error al actualizar el evento en Google Calendar:', error);
    if (error.message.includes("invalid authentication credentials")) {
      toast.error('Por tu seguridad, las credenciales han vencido, vuelve a iniciar sesión');
      signOut(auth);
    } else {
      toast.error(`Error al actualizar el evento: ${error.message}`);
    }
    throw error;
  }
};

//      let token = await getValidAccessToken(currentUser.email, accessToken, refreshToken, tokenExpiry);

export const updateGoogleCalendarEventDetalleTarea = async (ticket, updatedData, color, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken) => {
  const attemptUpdate = async (token) => {
    const calendarId = ticket.calendarId || 'primary';
    const eventId = ticket.idEventoCalendario;

    if (!eventId) {
      throw new Error("Event ID not found in the ticket data");
    }

    const colorId = colorMap[color] || '3'; // Default to '3' if color not found

    const event = {
      summary: updatedData.title || ticket.title,
      description: updatedData.descripcion || ticket.descripcion,
      colorId: colorId,
      start: {},
      end: {}
    };

    // Manejo de la fecha en formato correcto
    const fechaISO = moment.tz(updatedData.vencimiento, 'DD-MM-YYYY', 'America/Santiago');
    const fechaFinISO = moment.tz(updatedData.fechaFin, 'DD-MM-YYYY', 'America/Santiago');

    // Verificación de fechas y validación de fin
    if (fechaFinISO.isBefore(fechaISO)) {
      throw new Error("La fecha de fin no puede ser anterior a la fecha de inicio.");
    }

    // Si es un evento de todo el día
    if (updatedData.horaInicio === "Todo el día" && updatedData.horaTermino === "Todo el día") {
      event.start.date = fechaISO.format('YYYY-MM-DD'); // Formato correcto para Google Calendar
      event.end.date = fechaFinISO.format('YYYY-MM-DD');
    } else {
      // Evento con horas específicas
      const [horaInicioHoras, minutoInicio] = updatedData.horaInicio.split(':');
      const [horaTerminoHoras, minutoTermino] = updatedData.horaTermino.split(':');

      const fechaInicio = fechaISO.clone().set({ hour: horaInicioHoras, minute: minutoInicio, second: 0 });
      const fechaFin = fechaFinISO.clone().set({ hour: horaTerminoHoras, minute: minutoTermino, second: 0 });

      // Validar que la fecha de fin sea posterior a la fecha de inicio
      if (!fechaFin.isAfter(fechaInicio)) {
        throw new Error("La hora de fin debe ser posterior a la hora de inicio.");
      }

      event.start.dateTime = fechaInicio.toISOString(); // Formato ISO 8601 para horas
      event.end.dateTime = fechaFin.toISOString();
      event.start.timeZone = 'America/Santiago';
      event.end.timeZone = 'America/Santiago';
    }


    const response = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(event),
      }
    );

    const responseData = await response.json();
    if (!response.ok) {
      console.error("Response Data:", responseData);
      throw new Error(responseData.error.message);
    }

    toast.success('¡Tarea o evento sincronizado!');
  };

  try {
    toast.info('🤖 Actualizando en calendar');
    let token = await getValidAccessToken(currentUser.email, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
    await attemptUpdate(token);
  } catch (error) {
    console.error(`Error al actualizar el evento en Google Calendar: ${error.message}`);
    if (error.message.includes("invalid authentication credentials")) {
      toast.error('Por tu seguridad, las credenciales han vencido, vuelve a iniciar sesión');
      signOut(auth);
    } else {
      toast.error(`Error al actualizar el evento: ${error.message}`);
    }
  }
};


export const eliminarEventoDeCalendar = async (
  tarea,
  currentUser,
  accessToken,
  refreshToken,
  tokenExpiry,
  firebaseAccesToken,
  deleteType = 'single'
) => {
  try {
    // Obtener un token válido primero
    let token = await getValidAccessToken(
      currentUser.email, 
      accessToken, 
      refreshToken, 
      tokenExpiry, 
      firebaseAccesToken
    );

    const eventId = tarea.eventId || tarea.idEventoCalendario;
    const calendarId = tarea.calendarId || 'primary';
    const recurringEventId = tarea.recurringEventId;

    // Si es un evento recurrente y queremos eliminar toda la serie
    if (recurringEventId && deleteType === 'all') {
      // Eliminar la serie completa usando el ID del evento maestro
      const deleteUrl = `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendarId)}/events/${encodeURIComponent(recurringEventId)}`;
      
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok && response.status !== 410) {
        const errorData = await response.text();
        throw new Error(`Error al eliminar la serie de eventos: ${response.status} ${response.statusText}`);
      }

      // Eliminar todos los eventos de la serie en Firebase de una sola vez
      const ticketsRef = collection(db, `business/${tarea.emailBusiness}/tickets`);
      const q = query(ticketsRef, where('recurringEventId', '==', recurringEventId));
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

    } else {
      // Si es un evento individual o una instancia específica
      const deleteUrl = `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendarId)}/events/${encodeURIComponent(eventId)}`;

      const response = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok && response.status !== 410) {
        const errorData = await response.text();
        throw new Error(`Error al eliminar el evento: ${response.status} ${response.statusText}`);
      }

      // Eliminar solo el evento específico de Firebase
      const businessRef = doc(db, "business", tarea.emailBusiness);
      await deleteDoc(doc(businessRef, "tickets", tarea.id));
    }

    return true;

  } catch (error) {
    console.error('Error en eliminarEventoDeCalendar:', error);
    if (error.message.includes("invalid authentication credentials")) {
      toast.error('Por tu seguridad, las credenciales han vencido, vuelve a iniciar sesión');
      signOut(auth);
    } else {
      toast.error(`Error al eliminar el evento: ${error.message}`);
    }
    throw error;
  }
};

export const eliminarSoloDeCalendar = async (
  tarea,
  currentUser,
  accessToken,
  refreshToken,
  tokenExpiry,
  firebaseAccesToken
) => {
  try {
    // Obtener un token válido
    const token = await getValidAccessToken(
      currentUser.email,
      accessToken,
      refreshToken,
      tokenExpiry,
      firebaseAccesToken
    );

    // Verificar todos los posibles campos de ID
    const eventId = tarea.googleCalendarEventId || tarea.idEventoCalendario || tarea.eventId;
    
    if (!eventId) {
      console.log("No hay ID de evento de Google Calendar para eliminar", tarea);
      return;
    }

    console.log("Intentando eliminar evento con ID:", eventId);

    const response = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok && response.status !== 404) {
      const errorText = await response.text();
      console.error("Error response:", errorText);
      throw new Error(`Error al eliminar evento: ${response.statusText}`);
    }

    console.log("Evento eliminado exitosamente del calendario");
    return true;
  } catch (error) {
    console.error("Error en eliminarSoloDeCalendar:", error);
    throw error;
  }
};

export const agregarEventoACalendario = async (
  emailBusiness,
  idTicket,
  evento,
  descripcion,
  fechaInicio,
  fechaVencimiento,
  horaInicio,
  horaTermino,
  color,
  calendarId,
  currentUser,
  accessToken,
  refreshToken,
  tokenExpiry,
  firebaseAccesToken,
  recurrenceRule,
  attendees = [],
  enableMeet = false,
  area
) => {
  const attemptAdd = async (token) => {
    // Validación más robusta del área
    const areaToUse = area || 'Sin área';

    // Primero actualizar el documento original con el área
    const ticketRef = doc(db, `business/${emailBusiness}/tickets/${idTicket}`);
    const ticketSnap = await getDoc(ticketRef);
    
    if (ticketSnap.exists()) {
      const ticketData = ticketSnap.data();
      // Usar el área existente si está disponible
      const finalArea = area || ticketData.area || ticketData.department || 'Sin área';
      
      await updateDoc(ticketRef, {
        area: finalArea,
        department: finalArea,
        type: 'evento'
      });

    }

    // Resto del código para crear el evento en Google Calendar
    const event = {
      summary: `${evento}`,
      description: descripcion,
      colorId: colorMap[color] || '3',
      start: {},
      end: {},
      recurrence: recurrenceRule ? [recurrenceRule] : undefined,
      sendUpdates: 'all',
      guestsCanModify: false,
      guestsCanInviteOthers: true,
      guestsCanSeeOtherGuests: true,
      reminders: {
        useDefault: true
      }
    };

    // Configurar attendees
    if (attendees && attendees.length > 0) {
      // Asegurarse de que el creador esté incluido en los attendees
      const allAttendees = new Set([...attendees, currentUser.email]);
      
      event.attendees = Array.from(allAttendees).map(email => ({
        email,
        responseStatus: email === currentUser.email ? 'accepted' : 'needsAction',
        organizer: email === currentUser.email
      }));

    }

    // Configurar fechas
    if (!horaInicio || !horaTermino || horaInicio === "Todo el día" || horaTermino === "Todo el día") {
      // Para eventos de todo el día
      const startDate = moment(fechaInicio).format('YYYY-MM-DD');
      const endDate = moment(fechaInicio).add(1, 'days').format('YYYY-MM-DD'); // Añadir un día para eventos de todo el día

      event.start = { date: startDate };
      event.end = { date: endDate };
    } else {
      // Para eventos con hora específica
      event.start = {
        dateTime: moment.tz(`${fechaInicio} ${horaInicio}`, 'YYYY-MM-DD HH:mm', 'America/Santiago').toISOString(),
        timeZone: 'America/Santiago'
      };
      event.end = {
        dateTime: moment.tz(`${fechaVencimiento} ${horaTermino}`, 'YYYY-MM-DD HH:mm', 'America/Santiago').toISOString(),
        timeZone: 'America/Santiago'
      };
    }

    // Configurar Meet si está habilitado
    if (enableMeet) {
      event.conferenceData = {
        createRequest: {
          requestId: `${Math.random().toString(36).substring(2)}`,
          conferenceSolutionKey: { type: "hangoutsMeet" }
        }
      };
    }

    // Asegurarse de que el área se incluya en los datos del evento
    const eventData = {
      ...event,
      area: areaToUse,
      department: areaToUse,
    };

    try {
      const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events${enableMeet ? '?conferenceDataVersion=1' : ''}`;
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error?.message || 'Error al crear el evento');
      }

      // Actualizar el documento con los datos de Google Calendar
      const updateData = {
        idEventoCalendario: data.id,
        enlaceEventoCalendario: data.htmlLink,
        calendarId: calendarId,
        recurrenceRule: recurrenceRule || null,
        area: areaToUse,
        department: areaToUse,
        isRoutine: !!recurrenceRule,
        recurringEventId: data.recurringEventId || data.id,
        type: 'evento',
        ...(data.conferenceData?.entryPoints && {
          enlaceMeet: data.conferenceData.entryPoints.find(
            ep => ep.entryPointType === 'video'
          )?.uri
        })
      };

      await updateDoc(ticketRef, updateData);

      // Si es un evento recurrente, crear los eventos de la serie con el área correcta
      if (recurrenceRule) {
        const batch = writeBatch(db);
        const ticketsRef = collection(db, `business/${emailBusiness}/tickets`);
        
        // Esperar un momento para que Google Calendar cree todos los eventos de la serie
        await new Promise(resolve => setTimeout(resolve, 2000));

        // Sincronizar y propagar el área a todos los eventos de la serie
        await sincronizarDesdeGoogleCalendar(
          emailBusiness,
          currentUser,
          accessToken,
          refreshToken,
          tokenExpiry,
          firebaseAccesToken
        );

        // Obtener todos los eventos de la serie y actualizar sus áreas
        const seriesQuery = query(
          ticketsRef,
          where('recurringEventId', '==', data.id)
        );
        
        const seriesSnapshot = await getDocs(seriesQuery);
        
        seriesSnapshot.forEach((doc) => {
          batch.update(doc.ref, {
            area: areaToUse,
            department: areaToUse,
            type: 'evento'
          });
        });

        await batch.commit();
      }

      return data;
    } catch (error) {
      console.error('Error detallado al crear el evento:', error);
      throw error;
    }
  };

  try {
    toast.info('🤖 Agregando evento en calendar');
    let token = await getValidAccessToken(currentUser.email, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
    const result = await attemptAdd(token);
    toast.success('¡Evento agregado correctamente!');
    return result;
  } catch (error) {
    if (error.message.includes("invalid authentication credentials")) {
      toast.error('Por tu seguridad, las credenciales han vencido, vuelve a iniciar sesión');
      signOut(auth);
    } else {
      toast.error(`Error al agregar el evento: ${error.message}`);
    }
    throw error;
  }
};


export const eliminarEventoDelCalendario = async (emailBusiness, idTicket, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken) => {
  const attemptDelete = async (token) => {
    const ticketRef = doc(db, `business/${emailBusiness}/tickets/${idTicket}`);
    const ticketSnap = await getDoc(ticketRef);
    const eventId = ticketSnap.exists() && ticketSnap.data().idEventoCalendario;

    if (!eventId) {
      throw new Error('No se encontró el ID del evento en el ticket.');
    }

    let response = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Error al eliminar el evento');
    }

    await updateDoc(ticketRef, {
      idEventoCalendario: deleteField(),
    });
  };

  try {
    toast.info('🤖 Eliminando evento en calendar');
    let token = await getValidAccessToken(currentUser.email, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
    await attemptDelete(token);
    toast.success('¡Evento eliminado correctamente!');
  } catch (error) {
    console.error(`Error al eliminar el evento en Google Calendar: ${error.message}`);
    if (error.message.includes("invalid authentication credentials")) {
      toast.error('Por tu seguridad, las credenciales han vencido, vuelve a iniciar sesión');
      signOut(auth);
    } else {
      toast.error(`Error al eliminar el evento: ${error.message}`);
    }
  }
};

export const obtenerCalendariosDeUsuario = async (currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken) => {
  try {
 

    // Obtener un token válido
    let token = await getValidAccessToken(currentUser.email, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);

    const response = await fetch('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      const responseData = await response.json();
      console.error("Error al obtener los calendarios:", responseData);
      throw new Error(responseData.error.message);
    }

    const data = await response.json();
    return data.items; // Devuelve la lista de calendarios

  } catch (error) {
    console.error(`Error al obtener los calendarios de Google Calendar: ${error.message}`);
    throw error;
  }
};
/**
 * Función principal de sincronización con Google Calendar.
 * Optimizada y sin duplicados.
 */
export const sincronizarDesdeGoogleCalendar = async (
  emailBusiness,
  currentUser,
  accessToken,
  refreshToken,
  tokenExpiry,
  firebaseAccesToken
) => {
  // ----------- Referencias y datos base -----------
  const userRef = doc(db, 'users', currentUser.email);
  const userDoc = await getDoc(userRef);

  // Calendarios seleccionados por el usuario
  const selectedCalendars = userDoc.data()?.calendariosSeleccionados || ['primary'];

  // Colores personalizados de cada calendario
  const calendarColors = userDoc.data()?.calendarColors || {};

  // Tokens de sincronización almacenados
  const storedSyncTokens = userDoc.data()?.syncTokens || {};

  // Paleta de colores "por defecto" de Google
  const colors = [
    '#7986CB', '#66C18C', '#B084C8', '#E67C73', '#F6BF26',
    '#F4511E', '#67C3EB', '#616161', '#3F51B5', '#4CAF50', '#EF5350',
  ];
  const defaultColor = '#5A9BD5';

  // ----------- Funciones auxiliares -----------
  const formatDate = (date) => moment(date).format('DD-MM-YYYY');
  const formatTime = (date, allDay) => allDay ? 'Todo el día' : moment(date).format('HH:mm');
  const isAllDayEvent = (event) => Boolean(event.start.date) && !event.start.dateTime;
  const isRecurringEvent = (event) => Boolean(event.recurringEventId || event.recurrence);

  const isMultiDayEvent = (startDate, endDate) => {
    return !startDate.isSame(endDate, 'day');
  };

  const arraysEqual = (a, b) => {
    if (!a || !b) return false;
    if (a.length !== b.length) return false;
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();
    return sortedA.every((val, idx) => val === sortedB[idx]);
  };

  const getMeetLinkFromEvent = (event) => {
    if (event.conferenceData && event.conferenceData.entryPoints) {
      const meetEntryPoint = event.conferenceData.entryPoints.find(
        (entryPoint) => entryPoint.entryPointType === 'video'
      );
      return meetEntryPoint ? meetEntryPoint.uri : null;
    }
    return null;
  };

  const getColorFromGoogleEvent = (colorId, calendarId) => {
    if (colorId) {
      const colorIndex = parseInt(colorId) - 1;
      return colors[colorIndex] || defaultColor;
    }
    return calendarColors[calendarId] || defaultColor;
  };

  const canEditEvent = (event, currentUser) => {
    // El creador siempre puede editar
    if (event.creator?.email === currentUser.email) return true;
    // Verificar si el usuario es organizador o tiene permisos de edición
    const userAttendee = event.attendees?.find(
      attendee => attendee.email === currentUser.email
    );
    return userAttendee?.organizer || userAttendee?.responseStatus === 'accepted';
  };

  // Eliminar duplicados en Firestore antes de procesar
  const eliminarEventosDuplicados = async (ticketsRef) => {
    const querySnapshot = await getDocs(ticketsRef);
    const eventosMap = new Map();
    const duplicadosParaEliminar = [];

    querySnapshot.forEach((docSnap) => {
      const evento = docSnap.data();
      // Ignorar eventos importados
      if (evento.syncSource === 'calendar-import') {
        return;
      }

      const idEventoCalendario = evento.idEventoCalendario;
      if (idEventoCalendario) {
        if (eventosMap.has(idEventoCalendario)) {
          const eventoExistente = eventosMap.get(idEventoCalendario);
          const fechaExistente = moment(eventoExistente.creacion, 'DD-MM-YYYY');
          const fechaNuevo = moment(evento.creacion, 'DD-MM-YYYY');

          if (fechaNuevo.isAfter(fechaExistente)) {
            duplicadosParaEliminar.push(eventoExistente.docId);
            eventosMap.set(idEventoCalendario, {
              ...evento,
              docId: docSnap.id
            });
          } else {
            duplicadosParaEliminar.push(docSnap.id);
          }
        } else {
          eventosMap.set(idEventoCalendario, {
            ...evento,
            docId: docSnap.id
          });
        }
      }
    });

    // Eliminar duplicados
    await Promise.all(
      duplicadosParaEliminar.map(async (docId) => {
        await deleteDoc(doc(ticketsRef, docId));
      })
    );

    return eventosMap;
  };

  // Función recursiva para obtener TODOS los eventos (manejo de pageToken y syncToken).
  const fetchAllCalendarEvents = async (calendarId, token, syncToken, pageToken) => {
    // Parámetros base
    let url = `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendarId)}/events?singleEvents=true&orderBy=startTime`;

    if (syncToken) {
      // Sincronización incremental
      url += `&syncToken=${syncToken}`;
    } else {
      // Primera sincronización con rango
      const timeMin = new Date();
      timeMin.setDate(timeMin.getDate() - 15);

      const timeMax = new Date();
      timeMax.setFullYear(timeMax.getFullYear() + 1);
      timeMax.setHours(23, 59, 59, 999);

      url += `&timeMin=${timeMin.toISOString()}&timeMax=${timeMax.toISOString()}`;
    }

    if (pageToken) {
      url += `&pageToken=${pageToken}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      if (response.status === 404) {
        console.warn(`Calendario no encontrado: ${calendarId}. Omitiendo...`);
        return { events: [], nextSyncToken: null };
      }
      const responseData = await response.json();
      console.error('Error en la respuesta de Google Calendar:', responseData);
      throw new Error(responseData.error?.message || 'Error desconocido');
    }

    const data = await response.json();
    const googleEvents = data.items || [];
    const allEvents = [...googleEvents];

    // Manejo de paginación
    if (data.nextPageToken) {
      const moreEvents = await fetchAllCalendarEvents(calendarId, token, syncToken, data.nextPageToken);
      allEvents.push(...moreEvents.events);
      return { events: allEvents, nextSyncToken: moreEvents.nextSyncToken || data.nextSyncToken };
    }

    return { events: allEvents, nextSyncToken: data.nextSyncToken };
  };

  // ----------- Inicio del flujo principal -----------
  try {
    toast.info('🤖 Sincronizando eventos desde Google Calendar...');

    // Renovar token si es necesario
    let token = await getValidAccessToken(
      currentUser.email,
      accessToken,
      refreshToken,
      tokenExpiry,
      firebaseAccesToken
    );

    // Conjunto para registrar todos los idEventoCalendario de Google
    const allGoogleEventIds = new Set();

    // Referencia a la colección de tickets
    const ticketsRef = collection(db, `business/${emailBusiness}/tickets`);
    // Obtener todos los tickets de Firebase antes de sincronizar (una sola vez)
    const firebaseTicketsSnapshot = await getDocs(ticketsRef);
    const firebaseTickets = firebaseTicketsSnapshot.docs.map((d) => ({
      id: d.id,
      ...d.data(),
    }));

    // Mapa idEventoCalendario -> ticket
    const ticketsMap = new Map();
    for (const t of firebaseTickets) {
      if (t.idEventoCalendario) {
        ticketsMap.set(t.idEventoCalendario, t);
      }
    }

    // Eliminar duplicados ANTES de arrancar
    await eliminarEventosDuplicados(ticketsRef);

    // Para ir acumulando actualizaciones/creaciones/borrados
    const batch = writeBatch(db);

    // ----------- 1) Obtener eventos de todos los calendarios en paralelo -----------
    const calendarFetchPromises = selectedCalendars.map(async (calendarId) => {
      const calendarSyncToken = storedSyncTokens[calendarId] || null;

      let allEventsData;
      try {
        // Obtenemos todos los eventos (puede recurrir si hay pageToken)
        const { events, nextSyncToken } = await fetchAllCalendarEvents(
          calendarId,
          token,
          calendarSyncToken,
          null
        );
        allEventsData = { events, nextSyncToken };
      } catch (error) {
        // Si el syncToken expiró o está inválido
        if (error.message.includes('Sync token is no longer valid')) {
          console.warn('Sync token inválido. Haciendo sincronización completa...');
          delete storedSyncTokens[calendarId];
          // Reintentar sin syncToken
          const { events, nextSyncToken } = await fetchAllCalendarEvents(
            calendarId,
            token,
            null,
            null
          );
          allEventsData = { events, nextSyncToken };
        } else {
          console.error(`Error durante la sincronización del calendario ${calendarId}:`, error);
          // Retornar para que no se rompa el flujo en los demás calendarios
          return { calendarId, events: [], nextSyncToken: null };
        }
      }
      return { calendarId, ...allEventsData };
    });

    // Esperamos a que se resuelvan todas las descargas de calendarios
    const calendarsData = await Promise.all(calendarFetchPromises);

    // ----------- 2) Procesar todos los calendarios y sus eventos -----------
    const processCalendarsPromises = calendarsData.map(async (calendarResult) => {
      const { calendarId, events: googleEvents, nextSyncToken } = calendarResult;
      if (!calendarId) return;

      if (nextSyncToken) {
        storedSyncTokens[calendarId] = nextSyncToken;
      }

      // Crear un mapa para almacenar las propiedades del evento padre
      const recurringEventProperties = new Map();

      // Primer paso: recopilar propiedades de eventos recurrentes padre
      googleEvents.forEach(event => {
        if (event.recurrence && !event.recurringEventId) {
          const existingParentTicket = ticketsMap.get(event.id);
          // Este es un evento padre recurrente
          recurringEventProperties.set(event.id, {
            area: existingParentTicket?.area || '',
            department: existingParentTicket?.department || '',
            skill: existingParentTicket?.skill || 'No aplica',
            priority: existingParentTicket?.priority || 'Baja',
            from: existingParentTicket?.from || currentUser.email,
          });
        }
      });

      const eventPromises = googleEvents.map(async (event) => {
        const allDay = isAllDayEvent(event);
        let startDate, endDate, formattedStartDate, formattedEndDate;

        if (allDay) {
          startDate = moment(event.start.date);
          // restamos un día al endDate (Google lo maneja un poco distinto)
          endDate = moment(event.end.date).subtract(1, 'days');
          formattedStartDate = startDate.format('DD-MM-YYYY');
          formattedEndDate = endDate.format('DD-MM-YYYY');
        } else {
          startDate = moment(event.start.dateTime || event.start.date);
          endDate = moment(event.end.dateTime || event.end.date);
          // Ajustar endDate si termina exactamente a medianoche
          if (endDate.format('HH:mm:ss') === '00:00:00') {
            endDate.subtract(1, 'seconds');
          }
          formattedStartDate = startDate.format('DD-MM-YYYY');
          formattedEndDate = endDate.format('DD-MM-YYYY');
        }

        const multiDay = isMultiDayEvent(startDate, endDate);
        // Asegurarnos de que fechaFin no sea menor que vencimiento
        if (formattedEndDate < formattedStartDate) {
          formattedEndDate = formattedStartDate;
        }

        // Obtener attendees
        let attendees = event.attendees
          ? event.attendees.map((a) => a.email).filter((e) => e)
          : [];
        // Agregar organizer si no está
        if (event.organizer?.email && !attendees.includes(event.organizer.email)) {
          attendees.unshift(event.organizer.email);
        }

        const existingTicket = ticketsMap.get(event.id);
        let parentProperties = null;

        // Si es un evento recurrente, obtener propiedades del padre
        if (event.recurringEventId) {
          parentProperties = recurringEventProperties.get(event.recurringEventId);
        }

        const eventData = {
          title: event.summary || 'Sin título',
          descripcion: event.description || event.summary || 'Sin descripción',
          horaInicio: formatTime(startDate, allDay),
          horaTermino: formatTime(endDate, allDay),
          idEventoCalendario: event.id,
          enlaceEventoCalendario: event.htmlLink,
          enlaceMeet: getMeetLinkFromEvent(event),
          calendarId,
          emailBusiness,
          status: 'Proceso',
          to: [...new Set([...attendees, currentUser.email])],
          type: 'evento',
          from: event.creator?.email || currentUser.email,
          skill: parentProperties?.skill || existingTicket?.skill || 'No aplica',
          priority: parentProperties?.priority || existingTicket?.priority || 'Baja',
          vencimiento: formattedStartDate,
          fechaFin: formattedEndDate,
          creacion: existingTicket
            ? existingTicket.creacion
            : formatDate(new Date()),
          horaCreacion: existingTicket
            ? existingTicket.horaCreacion
            : formatTime(new Date(), false),
          color: getColorFromGoogleEvent(event.colorId, calendarId),
          canEdit: canEditEvent(event, currentUser) || false,
          allDay,
          multiDay,
          isRoutine: isRecurringEvent(event),
          recurringEventId: event.recurringEventId || null,
          // Usar área del padre si está disponible, sino mantener la existente
          area: parentProperties?.area || existingTicket?.area || '',
          department: parentProperties?.department || existingTicket?.department || '',
        };

        allGoogleEventIds.add(event.id);

        if (!existingTicket) {
          const newDocRef = doc(ticketsRef);
          batch.set(newDocRef, eventData);
          ticketsMap.set(event.id, { id: newDocRef.id, ...eventData });
        } else {
          if (existingTicket.syncSource === 'calendar-import') {
            return;
          }
          const hasChanges =
            existingTicket.status === 'Proceso' &&
            (
              existingTicket.title !== eventData.title ||
              existingTicket.horaInicio !== eventData.horaInicio ||
              existingTicket.horaTermino !== eventData.horaTermino ||
              existingTicket.vencimiento !== eventData.vencimiento ||
              existingTicket.fechaFin !== eventData.fechaFin ||
              existingTicket.color !== eventData.color ||
              existingTicket.enlaceMeet !== eventData.enlaceMeet ||
              existingTicket.multiDay !== eventData.multiDay ||
              existingTicket.isRoutine !== eventData.isRoutine ||
              existingTicket.area !== eventData.area ||
              !arraysEqual(existingTicket.to || [], eventData.to)
            );

          if (hasChanges) {
            const existingDocRef = doc(ticketsRef, existingTicket.id);
            batch.update(existingDocRef, eventData);
            ticketsMap.set(event.id, { id: existingTicket.id, ...eventData });
          }
        }
      });

      await Promise.all(eventPromises);
    });

    // Esperar a que terminen de procesarse todos los calendarios
    await Promise.all(processCalendarsPromises);

    // ----------- 3) Eliminar eventos de Firebase que ya no existen en Google -----------
    // (excepto los importados y los que tienen status 'Pendiente')
    ticketsMap.forEach((ticket, idEventoCalendario) => {
      if (
        !allGoogleEventIds.has(idEventoCalendario) &&
        ticket.status !== 'Pendiente' &&
        ticket.syncSource !== 'calendar-import'
      ) {
        const ticketDocRef = doc(ticketsRef, ticket.id);
        batch.delete(ticketDocRef);
        ticketsMap.delete(idEventoCalendario);
      }
    });

    // ----------- 4) Actualizar syncTokens y realizar commit del batch -----------
    batch.update(userRef, { syncTokens: storedSyncTokens });
    await batch.commit();

    toast.success('¡Sincronización completada correctamente!');
  } catch (error) {
    console.error('Error al sincronizar eventos desde Google Calendar:', error.message);
    if (error.message.includes('invalid authentication credentials')) {
      toast.error('Por seguridad, las credenciales han vencido. Vuelve a iniciar sesión.');
      signOut(auth);
    } else {
      toast.error(`Error al sincronizar eventos: ${error.message}`);
    }
  }
};