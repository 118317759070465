import React from 'react';
import ReactDOM from 'react-dom';

const TooltipPortal = ({ children, position }) => {
  return ReactDOM.createPortal(
    <div 
      className="ticket-tooltip" 
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        transform: 'translate(-50%, -100%)',
        zIndex: 9999
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export default TooltipPortal;