import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import SendNameBusiness from "../SendNameBusiness";
import ImageUploader from "../ImageUploader";
import DefaultImage from "../img/default_user.jpg";
import "./RbDescription.css"; // Asegúrate de tener el archivo CSS

function RbDescription(props) {
  const [businessName, setBusinessName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [color, setColor] = useState("#10439F");
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [objetivo, setObjetivo] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [isBusinessNameValid, setIsBusinessNameValid] = useState(false);
  const [isDescriptionValid, setIsDescriptionValid] = useState(false);
  const [isObjetivoValid, setIsObjetivoValid] = useState(false);
  const [areAttributesValid, setAreAttributesValid] = useState(false);
  const [isProjectSaved, setIsProjectSaved] = useState(false); // Nuevo estado

  const colors = [
    "#C70039", // Morado
    "#10439F", // Azul
    "#E63946", // Rojo coral
    "#F4A261", // Naranja suave
    "#2A9D8F", // Verde esmeralda
    "#E9C46A", // Amarillo dorado
    "#9C27B0", // Púrpura vibrante
    "#00B4D8", // Cian claro
    "#6D6875", // Gris lavanda
    "#264653", // Verde azulado oscuro
    "#2C3E50", // Azul oscuro
    "#A8DADC", // Azul hielo
    "#FFB4A2", // Rosa salmón
    "#8D99AE", // Gris azulado
    "#FFD700", // Dorado
    "#FF6F61", // Coral
  ];

  const attributeOptions = [
    "Innovación",
    "Sostenibilidad",
    "Eficiencia",
    "Eficacia",
    "Escalabilidad",
    "Impacto social",
    "Rentabilidad",
    "Colaboración",
    "Calidad",
    "Adaptabilidad",
    "Productividad",
    "Seguridad",
    "Integridad",
    "Transparencia",
    "Creatividad",
  ];

  useEffect(() => {
    setIsBusinessNameValid(businessName.trim() !== "");
    setIsDescriptionValid(description.trim() !== "");
    setIsObjetivoValid(objetivo.trim() !== "");
    setAreAttributesValid(selectedAttributes.length === 3);
    if (
      isBusinessNameValid &&
      isDescriptionValid &&
      isObjetivoValid &&
      areAttributesValid
    ) {
      props.handleAvanzar(isProjectSaved); // Permitir avanzar solo si el proyecto está guardado
    } else {
      props.handleAvanzar(false);
    }
  }, [
    businessName,
    description,
    objetivo,
    selectedAttributes,
    isBusinessNameValid,
    isDescriptionValid,
    isObjetivoValid,
    areAttributesValid,
    isProjectSaved, // Incluye isProjectSaved en las dependencias
    props,
  ]);

  const handleColorClick = () => {
    setShowColorPalette(!showColorPalette);
  };

  const handleColorSelect = (selectedColor) => {
    setColor(selectedColor);
    setShowColorPalette(false);
  };

  const handleImageChange = (imageFile) => {
    setFile(imageFile);
  };

  const handleAttributeChange = (e) => {
    const value = e.target.value;
    setSelectedAttributes((prev) =>
      prev.includes(value)
        ? prev.filter((attr) => attr !== value)
        : [...prev, value]
    );
  };

  const handleProjectSaved = () => {
    setIsProjectSaved(true);
  };

  return (
    <div>
      <div className="bigCard">
        <div style={{maxHeight:"600px", overflow:"scroll"}}>
          <div className="bigCardContent">
            <div className="columBigcard">
              <div className="centerDivBigCard">
                <h1 className="titleBigCard">Cuéntanos sobre tu proyecto:🤓</h1>
              </div>
            </div>
            <hr />
            <div className="colums">
              <div className="inputContainer">
                <input
                  type="text"
                  placeholder="¿Cómo quieres llamar a tu proyecto?"
                  className="inputRegister"
                  onChange={(e) => setBusinessName(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={isBusinessNameValid ? faCheckCircle : faTimesCircle}
                  className={`validationIcon ${isBusinessNameValid ? "valid" : "invalid"
                    }`}
                />
              </div>
              <div className="inputContainer">
                <input
                  type="text"
                  placeholder="Describe en qué consiste"
                  className="inputRegister"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={isDescriptionValid ? faCheckCircle : faTimesCircle}
                  className={`validationIcon ${isDescriptionValid ? "valid" : "invalid"
                    }`}
                />
              </div>
              <div className="inputContainer">
                <input
                  type="text"
                  placeholder="¿Para qué creaste este proyecto?"
                  className="inputRegister"
                  onChange={(e) => setObjetivo(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={isObjetivoValid ? faCheckCircle : faTimesCircle}
                  className={`validationIcon ${isObjetivoValid ? "valid" : "invalid"
                    }`}
                />
              </div>
              <div className="colorPickerContainer">
                <h3>Selecciona el color con el identificarás tu proyecto:</h3>
                <div
                  className="colorBox"
                  style={{ backgroundColor: color }}
                  onClick={handleColorClick}
                ></div>
                {showColorPalette && (
                  <div className="colorPalettePopup">
                    <div className="colorPaletteContainer">
                      {colors.map((colorOption, index) => (
                        <div
                          key={index}
                          className="colorOption"
                          style={{ backgroundColor: colorOption }}
                          onClick={() => handleColorSelect(colorOption)}
                        ></div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="attributesContainer">
                <h3 className="subtileSelector">
                  Selecciona 3 atributos que describan tu objetivo:{" "}
                  {areAttributesValid ? (
                    <FontAwesomeIcon icon={faCheckCircle} className="valid" />
                  ) : (
                    <FontAwesomeIcon icon={faTimesCircle} className="invalid" />
                  )}
                </h3>
                <br />
                <div className="attributesOptions">
                  {attributeOptions.map((attribute, index) => (
                    <label key={index} className="attributeOption">
                      <input
                        type="checkbox"
                        id={`attribute-${index}`}
                        value={attribute}
                        onChange={handleAttributeChange}
                        checked={selectedAttributes.includes(attribute)}
                        disabled={
                          selectedAttributes.length >= 3 &&
                          !selectedAttributes.includes(attribute)
                        }
                      />
                      {attribute}
                    </label>
                  ))}
                </div>
              </div>
              <ImageUploader onImageChange={handleImageChange} type="1" />

              {/* Renderizar BtnCargando y pasar handleProjectSaved como prop */}
              {isBusinessNameValid &&
                isDescriptionValid &&
                isObjetivoValid &&
                areAttributesValid && (
                  <SendNameBusiness
                    name={businessName}
                    description={description}
                    file={file}
                    color={color}
                    objetivo={objetivo}
                    attributes={selectedAttributes.join(", ")}
                    onSave={handleProjectSaved}
                  />
                )}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default RbDescription;
