import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import ReactPaginate from "react-paginate";
import { queryGetTickets, getTickets } from "./Functions.js";
import { onSnapshot } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import TablaArchivados from "./archivados/TablaArchivados";
import "./archivados/Archivados.css"

function ConsultarTicketsArchivados(props) {
  const [busqueda, setBusqueda] = useState("");
  const [tickets, setTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { department } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const { category } = useContext(AuthContext);
  const { businessEmail } = useContext(AuthContext);

  const itemsPerPage = 10;

  useEffect(() => {
    if ( businessEmail && currentUser && currentUser.email) {
      const { q1, q2, q3 } = queryGetTickets(
        category,
        businessEmail,
        "General",
        "Archivado",
        currentUser.email
      );
  
      let items1 = [];
      let items2 = [];
      let items3 = [];
  
      const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
        items1 = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTickets([...items1, ...items2, ...items3]);
        setIsLoading(false);
      });
  
      let unsubscribe2 = () => {};
      if (q2) {
        unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
          items2 = querySnapshot2.docs.map((doc) => {
            if (!items1.some((ticket) => ticket.id === doc.id)) {
              return { id: doc.id, ...doc.data() };
            }
          }).filter(Boolean);
          setTickets([...items1, ...items2, ...items3]);
          setIsLoading(false);
        });
      }
  
      let unsubscribe3 = () => {};
      if (q3) {
        unsubscribe3 = onSnapshot(q3, (querySnapshot2) => {
          items3 = querySnapshot2.docs.map((doc) => {
            if (
              !items1.some((ticket) => ticket.id === doc.id) &&
              !items2.some((ticket) => ticket.id === doc.id)
            ) {
              return { id: doc.id, ...doc.data() };
            }
          }).filter(Boolean);
          setTickets([...items1, ...items2, ...items3]);
          setIsLoading(false);
        });
      }
  
      return () => {
        unsubscribe1();
        unsubscribe2();
        unsubscribe3();
      };
    }
  }, [category, businessEmail, department, currentUser]);
    

  const filteredTickets = tickets.filter((ticket) =>
    ticket.title.toLowerCase().includes(busqueda.toLowerCase())
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const currentItems = filteredTickets.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div>
        <div className="center">
        <h1 className="titleBigCard">Tareas Archivadas</h1>

            <div className="inputIconContainer2">
                <div className="iconWrapperMorado">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <input
                    type="text"
                    placeholder="Buscar Ticket"
                    onChange={(e) => setBusqueda(e.target.value)}
                    className="inputBusqueda10"
                />
            </div>
        </div>
        {isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="loading-container">
                    <p>Cargando...</p>
                    <div className="spinner"></div>
                </div>
            </div>
        ) : (
            <div className="statsContent">
                <div className="table-container">
                    <table className="tableTop">
                        <thead>
                            <tr>
                                <th>Solicitud</th>
                                <th>Creador</th>
                                <th>Fecha de cierre</th>
                                <th>Calificación</th>
                                <th>Cerrado por</th>
                                <th>Comentario</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((ticket) => (
                                <TablaArchivados
                                    key={ticket.id}
                                    title={ticket.title}
                                    creator={ticket.creator}
                                    vencimiento={ticket.vencimiento}
                                    calificacion={ticket.calificacion}
                                    fechacierre = {ticket.fechacierre}
                                    horaCierre = {ticket.horaCierre}
                                    cerradoporemail= {ticket.cerradoporemail}
                                    cerradopornombre= {ticket.cerradopornombre}
                                    timertracker= {ticket.timertracker}
                                    comentario = {ticket.comentario}
                                    cerradopor ={ticket.cerradopor}

                                    />
                            ))}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Siguiente"}
                    breakLabel={""}
                    pageCount={Math.ceil(tickets.length / itemsPerPage)}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={"ticketsNuevos__pagination"}
                    activeClassName={"active"}
                    previousClassName={"custom-previous-button"}
                    nextClassName={"custom-next-button"}
                    previousLinkClassName={"btnAnterior"}
                    nextLinkClassName={"btnSiguiente"}
                    pageClassName={"custom-page-number"}
                    pageLinkClassName={"custom-page-link"}
                />
            </div>
        )}
    </div>
);

}

export default ConsultarTicketsArchivados;
