import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Areas from "./Areas";
import { getAreas4Business } from "../Functions";
import "./selectArea.css";
import Spinner from "../selectBusiness/Spinner";
import { updateEmail } from "firebase/auth";

function SelectArea() {
  const { currentUser } = useContext(AuthContext);
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para el spinner
  const idBusiness = sessionStorage.getItem("businessId");
  const userType = sessionStorage.getItem("userType");

  useEffect(() => {
    getAreas4Business(userType, idBusiness, currentUser.email)
      .then((areas) => {
        setAreas(areas);
        setLoading(false); // Desactiva el spinner después de cargar los tableros
      })
      .catch((error) => {
        console.error("Error al cargar los tableros:", error);
        setLoading(false); // Desactiva el spinner si hay un error
      });
  }, [userType]);

  return (
    <div className="selectBusiness Container">
      <Navbar
        name={currentUser.displayName}
        photo={currentUser.photoURL}
        caption="NavBar"
      />
      <main className="content">
        <section className="businessSection">
          <h3 className="businessName">Selecciona un área</h3>
          {loading ? <Spinner /> : <Areas areas={areas} />}
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default SelectArea;
