import React from "react";
import emailjs from "@emailjs/browser";

export const sendMail = async (email, subject, messageHTML) => {
  const emailData = {
    to_email: email,
    subject: subject,
    message: messageHTML
  };

  try {
    const response = await emailjs.send(
      "service_rc8nh34", // service_id
      "template_8shgt8v", // template_id
      emailData,
      "7fgsCumBGbZzJAsCA" // AK
    );
    console.log("Email enviado exitosamente:", response);
  } catch (error) {
    console.error("Error al enviar el correo electrónico:", error);
  }
};