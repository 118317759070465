import React from "react";
import TutorialCard from "../TutorialCard/TutorialCard";

function RbIntro() {
  return (
    <div className="bigCard">
      <div className="bigCardContent">
        <div className="columBigcard">
          <div className="centerDivBigCard">
            <h1 className="titleBigCard">Menú de creación de proyectos</h1>
          </div>
          <div className="introContent">
            <div className="introText">
              <span className="textIntro">Primero lo primero... 😃</span>
              <div className="divTextIntro">
                <span className="textIntro">
                  ¡Hola! 👋 Para aprovechar al máximo la IA de Planius,
                  necesitamos una descripción detallada de tu proyecto.
                  Cuéntanos de manera clara y específica los objetivos, las
                  áreas involucradas y las competencias necesarias.
                  <br />
                  <br />
                  <strong>
                    🤓 Recuerda, cuanto más claro sea el contexto que
                    proporciones, mejores serán los resultados.
                  </strong>
                  <br />
                  <br />
                  ¡Estamos aquí para ayudarte a aumentar tu productividad!
                </span>
              </div>
            </div>

            <div className="introImage">
              <TutorialCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RbIntro;
