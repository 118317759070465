// App.js
import React, { useContext, useState, useEffect, memo } from "react";
import "./style.scss";
import logo from "./components/img/logo-login.png";
import { Login } from "./components/Login";
import SignInWithGoogle from "./components/SingWithGoogle";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import GestionarTickets from "./components/GestionarTickets";
import Configuracion from "./components/Configuracion";
import TicketDetails from "./components/TicketDetails";
import RegisterBusiness from "./components/registerBusiness/RegisterBusiness";
import RegisterUser from "./components/RegisterUser";
import CreateTicket from "./components/IA/CreateTicket";
import Clientes from "./components/Clientes";
import Areas from "./components/Areas";
import NewTicket from "./components/NewTicket";
import Registro from "./components/Registro";
import ImageUploader from "./components/ImageUploader";
import MyProfile from "./components/myProfile/MyProfile";
import StartNow from "./components/register/StartNow";
import RegistroUsuarios from "./components/RegistroUsuarios";
import { VerificarEmail } from "./components/VerificarEmail";
import Archivados from "./components/archivados/Archivados";
import TicketResume from "./components/IA/TicketResume";
import ReactGA from "react-ga4";
import { ForgotPassword } from "./components/ForgotPass";
import SelectBusiness from "./components/selectBusiness/SelectBusiness";
import SelectArea from "./components/selectArea/SelectArea";
import CalendarioTickets from "./components/calendario/CalendarioTickets";
import Layout from "./components/Pomodoro/Layout";
import CrearPerfil from "./components/CrearPerfil/CrearPerfil";
import Perfil from "./components/Perfil/Perfil";
import { GoogleAuth } from "./components/auth";


import Politicas from "./components/politicas/Politicas";
import Condiciones from "./components/condicionesDeServicio/Condiciones";
import WorkSpaces from "./components/workspaces/WorkSpaces";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GestionCalendarios from "./components/calendario/GestionCalendarios";

const LazyHome = React.lazy(() => import('./components/Home'));
const LazyStats = React.lazy(() => import('./components/Stats'));
const LazyConfiguracion = React.lazy(() => import('./components/Configuracion'));
const LazyGestionarTickets = React.lazy(() => import('./components/GestionarTickets'));
const LazyWorkSpaces = React.lazy(() => import('./components/workspaces/WorkSpaces'));

export const DarkModeContext = React.createContext();
export const DarkModeDispatchContext = React.createContext();

const MemoizedFooter = memo(Footer);

const ProtectedRoute = memo(({ children, currentUser }) => {
  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return children;
});

function App() {
  const { currentUser, userType } = useContext(AuthContext);

  console.log(currentUser, "CURR")
  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(false);

  ReactGA.initialize("G-G5MSZYLLS1", { debug_mode: true });

  useEffect(() => {
    if (userType && currentUser) {
      setLoading(false);
      return;
    }
    
    const timeoutId = setTimeout(() => setLoading(false), 200);
    return () => clearTimeout(timeoutId);
  }, [userType, currentUser]);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const darkModeContextValue = React.useMemo(
    () => ({
      darkMode,
    }),
    [darkMode]
  );

  const darkModeDispatchValue = React.useMemo(
    () => ({
      toggleDarkMode,
    }),
    []
  );

  const protectedRoutes = React.useMemo(
    () => ({
      dashboard: (
        <ProtectedRoute currentUser={currentUser}>
          <LazyHome />
        </ProtectedRoute>
      ),
      gestionarTickets: (
        <ProtectedRoute currentUser={currentUser}>
          <LazyGestionarTickets />
        </ProtectedRoute>
      ),
      // ... otros elementos protegidos
    }),
    [currentUser] // Solo se recrea si cambia el usuario
  );

  if (loading) {
    return (
      <div className="spinner-container">
        <img className="logo-init-container" src={logo} alt="Logo de Carga" />
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <DarkModeContext.Provider value={darkModeContextValue}>
      <DarkModeDispatchContext.Provider value={darkModeDispatchValue}>
        <div className={`page-container ${darkMode ? "dark-mode" : ""}`}>
          <div className="content-wrap">
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <React.Suspense fallback={
                  <div className="spinner-container">
                    <img className="logo-init-container" src={logo} alt="Logo de Carga" />
                    <div className="spinner"></div>
                  </div>
                }>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      <Route
                        index
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <LazyGestionarTickets />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="login" element={<Login />} />
                      <Route path="signin" element={<SignInWithGoogle />} />
                      <Route
                        path="GestionarTickets"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <LazyGestionarTickets />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="Configuracion"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <LazyConfiguracion />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="dashboard"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <LazyHome />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="calendario"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <GestionCalendarios />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="clientes"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <Clientes />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="newTicket"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <NewTicket />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="areas"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <Areas />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="miPerfil"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <Perfil />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="CrearPerfil"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <CrearPerfil />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="archivados"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <Archivados />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="workSpaces"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <LazyWorkSpaces />
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="selectArea"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <SelectArea />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="ticketDetails"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <TicketDetails />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="myprofile"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <MyProfile />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="registerBusiness"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <RegisterBusiness />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="stats"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <LazyStats />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="ticketResume"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <TicketResume />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="selectBusiness"
                        element={
                          <ProtectedRoute currentUser={currentUser}>
                            <SelectBusiness />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="registerUser" element={<RegisterUser />} />
                      <Route path="googleAuth" element={<GoogleAuth />} />
                      <Route path="createTicket" element={<CreateTicket />} />
                      <Route path="registro" element={<Registro />} />
                      <Route path="image" element={<ImageUploader />} />
                      <Route path="startNow" element={<StartNow />} />
                      <Route
                        path="registrarusuarios"
                        element={<RegistroUsuarios />}
                      />
                      <Route path="verificaremail" element={<VerificarEmail />} />
                      <Route path="forgotPassword" element={<ForgotPassword />} />
                      <Route path="politicas" element={<Politicas />} />
                      <Route path="condiciones" element={<Condiciones />} />

                    </Route>
                  </Routes>
                </React.Suspense>
              </BrowserRouter>
            </DndProvider>
          </div>
          <ToastContainer />
          <MemoizedFooter />
        </div>
      </DarkModeDispatchContext.Provider>
    </DarkModeContext.Provider>
  );
}

export default App;