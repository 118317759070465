import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

const ModalColorTarea = ({ tarea, isOpen, onClose, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#5a67d8");

  const colors = [
    "#5a67d8", // Color principal
    "#4c51bf", // Color más oscuro
    "#7f9cf5", // Color más claro
    "#ff8c00", // Naranja que combina bien
    "#f6ad55", // Otro tono naranja complementario
    "#2E5090"
  ];

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleConfirmChange = async () => {
    setIsLoading(true);

    try {
      await setDoc(doc(db, "business", tarea.emailBusiness, "tickets", tarea.id), {
        ...tarea,
        colorTarea: selectedColor
      }, { merge: true });
      setIsLoading(false);
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error al cambiar el color de la tarea: ", error);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      overlayClassName={"popupBackground"}
      className={"popupContainer"}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Color Tarea</h2>
      </div>

      <div className="popupContent">
        {isLoading ? (
          <div className="detalleTarea__loading">
            <FontAwesomeIcon icon={faSpinner} spin className="icon" />
            <span>Cargando...</span>
          </div>
        ) : showConfirmation ? (
          <div className="detalleTarea__confirmation">
            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
            <span>Color Cambiado</span>
          </div>
        ) : (
          <div>
            <p>Seleccione un color para la tarea:</p>
            <div className="colorOptions">
              {colors.map((color) => (
                <div
                  key={color}
                  className="colorOption"
                  style={{
                    backgroundColor: color,
                    border: selectedColor === color ? "3px solid black" : "1px solid grey",
                    width: "30px",
                    height: "30px",
                    display: "inline-block",
                    margin: "5px",
                    cursor: "pointer"
                  }}
                  onClick={() => handleColorChange(color)}
                ></div>
              ))}
            </div>
          </div>
        )}
      </div>

      {!isLoading && !showConfirmation && (
        <div className="popupActions">
          <div className="row" style={{ margin: "10px", padding: "10px" }}>
            <button className="popupButton" onClick={handleConfirmChange}>
              Aceptar
            </button>
            <button
              className="popupButton"
              style={{ marginLeft: "10px" }}
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalColorTarea;
