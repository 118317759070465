import React, { useEffect } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useState } from "react";
import { auth, db, storage } from "./firebase";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ref } from "firebase/storage";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller, set } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import logoLogin from "./img/logo-login.png";
import ImageUploader from "./ImageUploader";
import moment from "moment/moment";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { sendEmailVerification } from "firebase/auth";

//validadores de contraseña y correo*********************************

const passwordValidationSchema = yup
  .string()
  .required("La contraseña es obligatoria")
  .matches(
    /^(?=.*[A-Z])(?=.*[!_@.#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
    "La contraseña debe contener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 símbolo especial"
  );

const schema = yup.object().shape({
  username: yup
    .string()
    .required("El nombre de usuario es obligatorio")
    .min(3, "El nombre de usuario debe tener al menos 3 caracteres")
    .max(30, "El nombre de usuario debe tener máximo 30 caracteres"),
  email: yup
    .string()
    .required("El email es obligatorio")
    .email("Debe ser un email válido"),
  password: passwordValidationSchema,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("La confirmación de contraseña es obligatoria"),
});

const hoy = moment().format("DD-MM-YYYY");

function Registro(e) {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const context = useContext(AuthContext);
  const { setCurrentUser, setBusinessEmail } = useContext(AuthContext);
  const { setUserType } = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      await sendEmailVerification(res.user);


      const date = new Date().getTime();
      const storageRef = ref(storage, `${name + date}`);

      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          try {
            //Update profile
            await updateProfile(res.user, {
              displayName: name,
              photoURL: downloadURL,
            });
            setCurrentUser(res.user); // Actualiza el currentUser en el AuthContext
            //create user on firestore
            await setDoc(doc(db, "users", res.user.email), {
              uid: res.user.uid,
              businessEmail: res.user.email,
              displayName: name,
              email,
              photoURL: downloadURL,
              department: "",
              userType: "admin",
              category: "admin",
              stautus: "activo",
              date: date,
              businessName: "",
            });

            setBusinessEmail(res.user.email); // Actualiza el businessEmail en el AuthContext
            setUserType("admin"); // Actualiza el userType en el AuthContext

            //create Business on firestore
            await setDoc(doc(db, "business", res.user.email), {
              uid: res.user.uid,
              adminemail: email,
              photoBusiness: "",
              date: date,
              businessName: "",
              businessEmail: email,
              description: "",
              photoBusiness: "",
            });
          } catch (err) {
            setErr(true);
          } finally {
            if (auth.currentUser) {
              setLoading(false);
              navigate("/registerBusiness");
            } else {
              console.log("User not logged in yet");
            }
          }
        });
      });
    } catch (e) {
      setLoading(false);
      if (e.code === "auth/email-already-in-use") {
        setErr("error, email ya registrado");
      } else {
        setErr(true);
      }
    }
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <img className="logoLogin" src={logoLogin} alt="" />
        <span className="titleLogin">Empresas</span>
        <span
          style={{
            fontSize: "12px",
            textAlign: "center",
            padding: "calc(100vh/150)",
          }}
        >
          Primero crea un usuario administrador representante de la empresa
        </span>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="username">Nombre y Apellido</label>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  id="username"
                  placeholder="Nombre y apellido"
                  onBlur={(e) => trigger("username") && setName(e.target.value)}
                />
              )}
            />
            {errors.username && (
              <p className="errRed">{errors.username.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="email"
                  id="email"
                  placeholder="Email"
                  onBlur={(e) => trigger("email") && setEmail(e.target.value)}
                />
              )}
            />
            {errors.email && <p className="errRed">{errors.email.message}</p>}
          </div>
          <div>
            <label htmlFor="password">Contraseña</label>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="password"
                  id="password"
                  placeholder="Contraseña"
                  onBlur={(e) =>
                    trigger("password") && setPassword(e.target.value)
                  }
                />
              )}
            />
            {errors.password && (
              <p className="errRed">{errors.password.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="confirmPassword">Confirmar contraseña</label>
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirmar contraseña"
                  onBlur={() => trigger("confirmPassword")}
                />
              )}
            />
            {errors.confirmPassword && (
              <p className="errRed">{errors.confirmPassword.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="avatar">Foto de Perfil</label>
            <ImageUploader onImageChange={setFile} />
          </div>
          <button type="submit">Registrar</button>
          {loading && <p className="loading">Cargando...</p>}
          {err && (
            <p className="errRed">
              {typeof err === "string" ? err : "Error al registrarse"}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

export default Registro;
