import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Bot } from 'lucide-react';

const TableHeader = ({ diasSemana, diasSemanaArr, today, onPlanibotClick }) => {
  const [hoveredDay, setHoveredDay] = useState(null);

  const getDiaSemana = (fecha) => {
    const dia = fecha.getDay();
    // Convertir de domingo (0) a 6, y restar 1 al resto para que lunes sea 0
    return dia === 0 ? 6 : dia - 1;
  };

  return (
    <thead>
      <tr>
        <th className="calendarioSemanal__hora-sticky">Hora</th>
        {diasSemanaArr.map((fecha, index) => {
          const isToday = today.isSame(moment(fecha).startOf("day"));
          return (
            <th
              key={index}
              className={`${isToday ? "calendarioSemanal__hoy" : ""} calendarioSemanal__header-cell`}
              style={{
                padding: "0px",
                fontSize: "10px",
                fontWeight: "400",
                position: "relative"
              }}
              onMouseEnter={() => setHoveredDay(index)}
              onMouseLeave={() => setHoveredDay(null)}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                {diasSemana[getDiaSemana(fecha)]} {fecha.getDate()}
                {hoveredDay === index && (
                  <Bot
                    size={16}
                    color="#000080"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onPlanibotClick(fecha);
                    }}
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader; 