import React, { useState } from 'react';

import "./CalendarHeader.css";
import ReactGA from "react-ga4";
import moment from 'moment';
import 'moment/locale/es';
import {
RefreshCcw,
  Fullscreen,
  Brain,
} from "lucide-react";

const VISTAS = ["mes", "semana", "dia"];
const VISTA_LABELS = {
  mes: "Mes",
  semana: {
    desktop: "Semana",
    mobile: "Sem"
  },
  dia: "Día"
};

moment.locale('es');

const IconButton = ({ onClick, icon: Icon, className, isLoading, notificationCount }) => (
  <button 
    onClick={onClick} 
    className={`${className} ${isLoading ? 'loading-sync' : ''}`}
    disabled={isLoading}
  >
    {isLoading ? (
      <div className="spinner-sync"></div>
    ) : (
      <Icon color="#333" size={20} />
    )}
    {notificationCount > 0 && (
      <span className="notification-bubble">{notificationCount}</span>
    )}
  </button>
);

const CalendarTitle = ({ vista, anioActual, mesActual, diaActual, meses, cambiarFecha }) => {
  const formatTitle = () => {
    if (vista === "dia") {
      const fecha = new Date(anioActual, mesActual, diaActual);
      return (
        <>
          <span className="fecha-completa">
            {moment(fecha).format("dddd, D [de] MMMM [de] YYYY")}
          </span>
          <span className="fecha-corta">
            {moment(fecha).format("DD/MM")}
          </span>
        </>
      );
    }
    return (
      <>
        <span className="fecha-completa">
          {`${meses[mesActual]} ${anioActual}`}
        </span>
        <span className="fecha-corta">
          {`${(mesActual + 1).toString().padStart(2, '0')}/${String(anioActual).slice(-2)}`}
        </span>
      </>
    );
  };

  return (
    <h2 className="titleCalendar-diario">
      <button onClick={() => cambiarFecha(-1)} className="calendario__flecha" aria-label="Anterior">&lt;</button>
      {formatTitle()}
      <button onClick={() => cambiarFecha(1)} className="calendario__flecha" aria-label="Siguiente">&gt;</button>
    </h2>
  );
};

const CalendarHeader = ({
  meses,
  mesActual,
  anioActual,
  diaActual,
  vista,
  modoZen,
  cambiarVista,
  cambiarFecha,
  toggleZenMode,
  onSyncGoogleCalendar,
  toggleTabs,
  tareasPendientes,
  isTabsVisible,
  irAHoy
}) => {
  const [isSyncing, setIsSyncing] = useState(false);

  const logEvent = (action, label, value) => {
    ReactGA.event({
      category: "Interacción",
      action,
      label,
      value
    });
  };

  const handleZenModeClick = () => {
    logEvent("Modo zen", modoZen ? 'Salir Modo Zen' : 'Entrar Modo Zen', modoZen ? 0 : 1);
    toggleZenMode();
  };

  const handleTabsToggle = () => {
    logEvent("Toggle Tabs", isTabsVisible ? 'Ocultar TabsContainer' : 'Mostrar TabsContainer');
    toggleTabs();
  };

  const handleSync = async () => {
    if (isSyncing) return;
    setIsSyncing(true);
    try {
      await onSyncGoogleCalendar();
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <div className="calendario__header">
      <div className="vista-botones">
        {VISTAS.map((v) => (
          <button
            key={v}
            className={`vista-boton ${vista === v ? 'activa' : ''}`}
            onClick={() => cambiarVista({ target: { value: v } })}
          >
            {v === 'semana' ? (
              <>
                <span className="vista-label-desktop">
                  {VISTA_LABELS[v].desktop}
                </span>
                <span className="vista-label-mobile">
                  {VISTA_LABELS[v].mobile}
                </span>
              </>
            ) : (
              VISTA_LABELS[v]
            )}
          </button>
        ))}
        <button 
          className="vista-boton hoy-boton"
          onClick={irAHoy}
        >
          Hoy
        </button>
      </div>
      <div className="calendario__navegacion">
        <CalendarTitle
          vista={vista}
          anioActual={anioActual}
          mesActual={mesActual}
          diaActual={diaActual}
          meses={meses}
          cambiarFecha={cambiarFecha}
        />
      </div>
      <div className="calendario__modo-zen">
        <IconButton onClick={handleZenModeClick} icon={Fullscreen} className="sync-calendar-btn" />
        <IconButton 
          onClick={handleSync} 
          icon={RefreshCcw} 
          className="sync-calendar-btn"
          isLoading={isSyncing}
        />
        <IconButton
          onClick={handleTabsToggle}
          icon={Brain}
          className={`toggle-tabs-btn ${isTabsVisible ? 'active' : ''}`}
          notificationCount={tareasPendientes}
        />
      </div>
    </div>
  );
};

export default CalendarHeader;
