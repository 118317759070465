import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc, deleteField } from 'firebase/firestore';
import { db } from '../firebase';
import google from '../img/google.png';
import ModalAddDeleteCalendar from './modalAddDeleteCalendar';
import ModalSuccessAddCalendar from './modalSuccessAddCalendar';
import ModalErrorCalendar from './modalErrorCalendar';
import axios from 'axios';
import { renewAccessToken } from '../Functions'; // Asegúrate de importar correctamente

const AgregarEvento = ({ emailBusiness, idTicket, evento, descripcion, fechaInicio, fechaVencimiento, businessName, horaInicio, horaTermino }) => {
    const [loading, setLoading] = useState(false);
    const [eventId, setEventId] = useState(null);
    const [isGoogleUser, setIsGoogleUser] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
    const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSignInButton, setShowSignInButton] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        const fetchEventId = async () => {
            const ticketRef = doc(db, `business/${emailBusiness}/tickets/${idTicket}`);
            const ticketSnap = await getDoc(ticketRef);
            if (ticketSnap.exists() && ticketSnap.data().idEventoCalendario) {
                setEventId(ticketSnap.data().idEventoCalendario);
            }
        };

        if (user) {
            setIsGoogleUser(user.providerData.some(provider => provider.providerId === 'google.com'));
            fetchEventId();
        }
    }, [emailBusiness, idTicket]);

    const getValidAccessToken = async () => {
        let accessToken = sessionStorage.getItem('accessToken');
        if (!accessToken) {
            accessToken = await renewAccessToken();
        }
        return accessToken;
    };

    const agregarEventoACalendario = async () => {
        setLoading(true);
        try {
            let token = await getValidAccessToken();
            const calendarId = 'primary';
            const event = {
                summary: `(Planius🚀) ${evento} [${businessName}]`,
                description: descripcion,
                start: {},
                end: {},
                colorId: '3',
            };

            if (horaInicio && horaTermino && horaInicio !== "Todo el día" && horaTermino !== "Todo el día") {
                const fechaInicioObj = new Date(fechaInicio);
                const fechaFinObj = new Date(fechaVencimiento);

                const [horaInicioHoras, minutoInicio] = horaInicio.split(':');
                const [horaTerminoHoras, minutoTermino] = horaTermino.split(':');

                fechaInicioObj.setHours(horaInicioHoras, minutoInicio, 0);
                fechaFinObj.setHours(horaTerminoHoras, minutoTermino, 0);

                const fechaHoraInicioISO = fechaInicioObj.toISOString();
                const fechaHoraFinISO = fechaFinObj.toISOString();

                event.start.dateTime = fechaHoraInicioISO;
                event.end.dateTime = fechaHoraFinISO;
            } else {
                const fecha = fechaVencimiento.split('T')[0];
                event.start = {
                    date: fecha,
                    timeZone: 'America/Santiago'
                };
                event.end = {
                    date: fecha,
                    timeZone: 'America/Santiago'
                };
            }

            let response = await fetch(
                `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(event),
                }
            );

            let data = await response.json();
            if (!response.ok) {
                if (response.status === 400) {
                    throw new Error('Los datos enviados no son válidos. Por favor, verifica las fechas y horas proporcionadas.');
                } else if (response.status === 401) {
                    token = await renewAccessToken();
                    response = await fetch(
                        `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
                        {
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(event),
                        }
                    );
                    data = await response.json();
                    if (!response.ok) {
                        throw new Error(data.error.message);
                    }
                } else {
                    throw new Error(data.error.message);
                }
            }

            setEventId(data.id);
            await updateDoc(doc(db, `business/${emailBusiness}/tickets/${idTicket}`), {
                idEventoCalendario: data.id,
                enlaceEventoCalendario: data.htmlLink,
            });
            setSuccessModalIsOpen(true);
        } catch (error) {
            setErrorMessage(`Error al agregar evento al calendario: ${error.message}`);
            setErrorModalIsOpen(true);
        }
        setLoading(false);
    };

    const eliminarEventoDelCalendario = async () => {
        setLoading(true);
        try {
            let token = await getValidAccessToken();
            if (token && eventId) {
                let response = await fetch(
                    `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
                    {
                        method: 'DELETE',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    if (response.status === 401) {
                        token = await renewAccessToken();
                        response = await fetch(
                            `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
                            {
                                method: 'DELETE',
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                        if (!response.ok) {
                            throw new Error('Error al eliminar el evento');
                        }
                    } else {
                        throw new Error('Error al eliminar el evento');
                    }
                }

                const ticketRef = doc(db, `business/${emailBusiness}/tickets/${idTicket}`);
                await updateDoc(ticketRef, {
                    idEventoCalendario: deleteField(),
                });

                setSuccessModalIsOpen(true);
                setEventId(null);
            } else {
                setErrorMessage('Debes iniciar sesión para eliminar un evento del calendario o el evento ya no existe.');
                setErrorModalIsOpen(true);
            }
        } catch (error) {
            setErrorMessage(`Error al eliminar el evento: ${error.message}`);
            setErrorModalIsOpen(true);
        }
        setLoading(false);
    };

    const handleEventoClick = async () => {
        if (!isGoogleUser) {
            setErrorMessage('Funcionalidad disponible solo para usuarios que iniciaron sesión con Google.');
            setErrorModalIsOpen(true);
            return;
        }
        setConfirmModalIsOpen(true);
    };

    const handleConfirmModal = async () => {
        setConfirmModalIsOpen(false);
        if (eventId) {
            await eliminarEventoDelCalendario();
        } else {
            await agregarEventoACalendario();
        }
    };

    const handleCloseConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };

    const handleCloseSuccessModal = () => {
        setSuccessModalIsOpen(false);
    };

    const handleCloseErrorModal = () => {
        setErrorModalIsOpen(false);
    };

    return (
        <div style={{margin:"auto"}}>
            {isGoogleUser && (
                <button className="buttonCalendar" onClick={handleEventoClick} disabled={loading} style={{margin:"auto"}}>
                    <span className="btnContent">
                        <img src={google} alt="Google" className="googleLogo" />
                        {loading ? 'Procesando...' : eventId ? 'Eliminar Tarea de Google Calendar' : 'Agregar Tarea a Google Calendar'}
                    </span>
                </button>
            )}

            <ModalAddDeleteCalendar 
                isOpen={confirmModalIsOpen} 
                onRequestClose={handleCloseConfirmModal} 
                eventId={eventId} 
                handleConfirmModal={handleConfirmModal} 
            />

            <ModalSuccessAddCalendar 
                isOpen={successModalIsOpen} 
                onRequestClose={handleCloseSuccessModal} 
                eventId={eventId} 
            />

            <ModalErrorCalendar 
                isOpen={errorModalIsOpen} 
                onRequestClose={handleCloseErrorModal} 
                errorMessage={errorMessage} 
            />

            {loading && <div className="Spinner"></div>}
        </div>
    );
};

export default AgregarEvento;
