import React from 'react';
import Modal from 'react-modal';

const ModalAddDeleteCalendar = ({ isOpen, onRequestClose, eventId, handleConfirmModal }) => (
    <Modal
        overlayClassName={'popupBackground'}
        className={'popupContainer'}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
    >
        <div className="popupHeader">
            <h2 className="popupTitle">Confirmar</h2>
        </div>
        <div className="popupContent">
            <p>{eventId ? '¿Estás seguro de que deseas eliminar este evento de tu calendario?' : '¿Estás seguro de que deseas agregar este evento a tu calendario?'}</p>
        </div>
        <div className="popupActions" style={{display:"flex", flexDirection:"row", justifyContent:"center", margin:"auto", gap:"10px"}}>
            <button className="popupButton" onClick={handleConfirmModal}>Sí</button>
            <button className="popupButton" onClick={onRequestClose}>No</button>
        </div>
    </Modal>
);

export default ModalAddDeleteCalendar;
