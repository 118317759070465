// GoogleMeets.jsx

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./GoogleMeets.css";
import moment from "moment";
import { createTicketNew } from "../Functions";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import DatePicker from "react-datepicker";
import { PlusCircle, XCircle } from "lucide-react"; // Importando íconos de Lucide

const GoogleMeets = ({
  emailBusiness,
  evento,
  descripcion,
  fechaInicio,
  fechaVencimiento,
  businessName,
  horaInicio,
  horaTermino,
  isOpen,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [newAttendee, setNewAttendee] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState(60); // Duración en minutos
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem("accessToken")
  );
  const [showSignInButton, setShowSignInButton] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment().add(1, 'hour').toDate());

  useEffect(() => {
    const loadGapiClient = () => {
      window.gapi.load("client:auth2", initClient);
    };

    const initClient = () => {
      window.gapi.client
        .init({
          apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
          clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope:
            "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
        })
        .then(() => {
          const authInstance = window.gapi.auth2.getAuthInstance();
          if (authInstance.isSignedIn.get()) {
            const token = authInstance.currentUser
              .get()
              .getAuthResponse().access_token;
            setAccessToken(token);
            sessionStorage.setItem("accessToken", token);
          }
        })
        .catch((error) => {
          console.error("Error initializing Google API client:", error);
        });
    };

    loadGapiClient();
  }, []);

  const handleSignIn = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/calendar");
    provider.addScope("https://www.googleapis.com/auth/calendar.events");

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        setAccessToken(token);
        sessionStorage.setItem("accessToken", token);
        setShowSignInButton(false);
      })
      .catch((error) => {
        console.error("Error signing in:", error);
        setErrorMessage("Error al iniciar sesión. Por favor, inténtalo de nuevo.");
        setErrorModalIsOpen(true);
      });
  };

  const agregarEventoACalendario = async () => {
    // Validaciones básicas
    if (!title.trim()) {
      setErrorMessage("El título de la reunión es obligatorio.");
      setErrorModalIsOpen(true);
      return;
    }
    if (!description.trim()) {
      setErrorMessage("La descripción de la reunión es obligatoria.");
      setErrorModalIsOpen(true);
      return;
    }
    if (attendees.length === 0) {
      setErrorMessage("Debe agregar al menos un asistente.");
      setErrorModalIsOpen(true);
      return;
    }
    if (!accessToken) {
      setErrorMessage(
        "No se pudo obtener el token de acceso. Por favor, vuelva a iniciar sesión."
      );
      setErrorModalIsOpen(true);
      setShowSignInButton(true);
      return;
    }

    setLoading(true);
    try {
      const event = {
        summary: title || `(Planius🚀) [${businessName}] ${evento}`,
        description: description,
        start: {
          dateTime: startDate.toISOString(),
          timeZone: "America/Santiago",
        },
        end: {
          dateTime: endDate.toISOString(),
          timeZone: "America/Santiago",
        },
        colorId: "3",
        attendees: attendees
          .filter((email) => email)
          .map((email) => ({ email })),
        conferenceData: {
          createRequest: {
            requestId: Math.random().toString(36).substring(2),
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
          },
        },
      };

      // Validaciones adicionales para el ticket
      const safeEvento = title || evento || "Reunión";
      const safeBusinessName = businessName || "Sin nombre";
      const safeDescripcion = description || "No description provided.";
      const safeResponsables = attendees.filter((email) => email) || [];

      // Formatear fechas
      const currentDate = new Date();
      const formattedDate = moment(currentDate).format("DD-MM-YYYY");
      const formattedFechaTermino = moment(
        fechaVencimiento,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");

      // Verificación y validación de variables
      if (
        !safeEvento ||
        !safeBusinessName ||
        !formattedFechaTermino ||
        !formattedDate ||
        !safeDescripcion ||
        !safeResponsables.length ||
        !getAuth().currentUser.email ||
        !fechaInicio ||
        !horaInicio ||
        !horaTermino
      ) {
        throw new Error(
          "Uno o más campos necesarios están vacíos o indefinidos."
        );
      }

      // Asegúrate de que 'type' está definido
      const type = "Reunión"; // O el valor que corresponda

      // Verificar los valores antes de crear el ticket
      console.log({
        safeEvento,
        safeBusinessName,
        area: "Reunión",
        formattedFechaTermino,
        formattedDate,
        skill: "Reunión",
        estado: "Pendiente",
        responsable: getAuth().currentUser.email,
        emailBusiness,
        descripcion: safeDescripcion,
        descripcionCorta: safeDescripcion,
        competencia: "Reunión",
        descripcionCompetencia: safeDescripcion,
        nombreUsuario: getAuth().currentUser.displayName,
        responsables: safeResponsables,
        emailUsuario: getAuth().currentUser.email,
        fechaInicio,
        horaInicio: horaInicio === "Todo el día" ? "Todo el día" : horaInicio,
        horaTermino: horaTermino === "Todo el día" ? "Todo el día" : horaTermino,
        type: type
      });

      const ticketId = await createTicketNew(
        safeEvento,
        safeBusinessName,
        "Reunión", // Área
        formattedFechaTermino,
        formattedDate,
        "Reunión", // Skill
        "Pendiente",
        getAuth().currentUser.email,
        emailBusiness,
        safeDescripcion,
        safeDescripcion,
        "Reunión", // Competencia
        safeDescripcion,
        getAuth().currentUser.displayName,
        safeResponsables,
        getAuth().currentUser.email,
        fechaInicio,
        horaInicio === "Todo el día" ? "Todo el día" : horaInicio,
        horaTermino === "Todo el día" ? "Todo el día" : horaTermino,
        type // Asegúrate de pasar 'type' correctamente
      );
    } catch (error) {
      console.error("Error al crear el ticket en Planius:", error);
      if (
        error.message.includes("Request had invalid authentication credentials")
      ) {
        setErrorMessage(
          "El token de acceso ha expirado o no es válido. Por favor, vuelva a iniciar sesión."
        );
        setAccessToken(null); // Limpiar el token de acceso
        sessionStorage.removeItem("accessToken");
        setShowSignInButton(true); // Mostrar el botón de iniciar sesión
      } else {
        setErrorMessage(
          `Error al agregar evento al calendario: ${error.message}`
        );
      }
      setErrorModalIsOpen(true);
    }
    setLoading(false);
  };

  const handleAddAttendee = () => {
    const email = newAttendee.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && emailRegex.test(email) && !attendees.includes(email)) {
      setAttendees([...attendees, email]);
      setNewAttendee("");
    } else {
      setErrorMessage("Por favor, ingresa un correo electrónico válido y único.");
      setErrorModalIsOpen(true);
    }
  };

  const handleRemoveAttendee = (email) => {
    setAttendees(attendees.filter((attendee) => attendee !== email));
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Modal
      overlayClassName="popupBackground"
      className="popupContainer"
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false} // Asegúrate de configurar esto correctamente en producción
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Nueva Reunión</h2>
      </div>
      {showSignInButton ? (
        <div className="popupContent">
          <p>
            Tu sesión ha expirado o no tiene los permisos necesarios. Por favor,
            vuelve a iniciar sesión.
          </p>
          <button className="popupButton" onClick={handleSignIn}>
            Iniciar sesión con Google
          </button>
        </div>
      ) : (
        <div className="popupContent">
          <div className="popupField">
            <label className="popupLabel" htmlFor="title">
              Título de la Reunión
            </label>
            <input
              id="title"
              className="popupInput"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Título de la Reunión"
            />
          </div>
          <div className="popupField">
            <label className="popupLabel" htmlFor="description">
              Descripción de la Reunión
            </label>
            <textarea
              id="description"
              className="popupTextarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Descripción de la Reunión"
              rows="4"
            />
          </div>
          <div className="popupField">
            <label className="popupLabel" htmlFor="duration">
              Duración (minutos)
            </label>
            <input
              id="duration"
              className="popupInput"
              type="number"
              value={duration}
              onChange={(e) => setDuration(parseInt(e.target.value) || 0)}
              placeholder="Duración en minutos"
              min="1"
            />
          </div>
          <div className="popupField">
            <label className="popupLabel">Fecha y Hora</label>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              showTimeSelect
              timeIntervals={15}
              dateFormat="Pp"
              className="popupInput"
              placeholderText="Selecciona fecha y hora"
              wrapperClassName="datePickerWrapper"
            />
          </div>
          <div className="popupField attendees-gmeets">
            <label className="popupLabel" htmlFor="attendees">
              Invitados
            </label>
            <div className="popupAttendees">
              {attendees.map((attendee, index) => (
                <div key={index} className="popupAttendee">
                  <span>{attendee}</span>
                  <button
                    className="popupRemoveAttendee"
                    onClick={() => handleRemoveAttendee(attendee)}
                    aria-label={`Eliminar ${attendee}`}
                  >
                    <XCircle size={16} />
                  </button>
                </div>
              ))}
              <div className="popupAddAttendeeContainer">
                <input
                  id="attendees"
                  className="popupInput"
                  type="email"
                  value={newAttendee}
                  onChange={(e) => setNewAttendee(e.target.value)}
                  placeholder="Agregar correo de invitado"
                />
                <button
                  className="popupAddAttendeeButton"
                  onClick={handleAddAttendee}
                  aria-label="Agregar invitado"
                >
                  <PlusCircle size={16} /> Agregar
                </button>
              </div>
            </div>
          </div>
          <p className="popupText">
            ¿Estás seguro de que deseas agregar esta reunión a tu calendario?
          </p>
        </div>
      )}
      <div className="popupActionsRow">
        {!showSignInButton && (
          <button
            className="popupButton"
            onClick={agregarEventoACalendario}
            disabled={loading}
          >
            {loading ? "Agregando..." : "Agregar Reunión"}
          </button>
        )}
        <button
          className="popupButton cancelButton"
          onClick={onClose}
          disabled={loading}
        >
          Cancelar
        </button>
      </div>
      {loading && <div className="popupSpinner"></div>}
      {errorModalIsOpen && (
        <Modal
          overlayClassName="popupBackground"
          className="popupContainer"
          isOpen={errorModalIsOpen}
          onRequestClose={() => setErrorModalIsOpen(false)}
          ariaHideApp={false} // Asegúrate de configurar esto correctamente en producción
        >
          <div className="popupHeader">
            <h2 className="popupTitle">Error</h2>
          </div>
          <div className="popupContent">
            <p>{errorMessage}</p>
          </div>
          <div className="popupActions">
            <button
              className="popupButton"
              onClick={() => setErrorModalIsOpen(false)}
            >
              Cerrar
            </button>
          </div>
        </Modal>
      )}
    </Modal>
  );
};

export default GoogleMeets;
