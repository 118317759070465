import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button, useMediaQuery, useTheme, IconButton, Tabs, Tab } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { generarDetallePlanibot } from '../IA/Gpt';
import './PlanibotDetail.css';
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import TableChartIcon from '@mui/icons-material/TableChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import moment from 'moment';
import DeleteTarea from "../DeleteTarea";
import { AuthContext } from "../../context/AuthContext";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Typography, Box, Grid } from '@mui/material';
import AddFromPlani from '../addTarea/AddFromPlani';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReprogFromPlani from '../addTarea/ReprogFromPlani';
import MoveToCalendarPlani from '../addTarea/MoveToCalendarPlani';

const PlanibotDetail = ({ isOpen, onClose, tickets, tareasPendientes, fecha, criterioUsuario, businessEmail, onTasksUpdated }) => {
  const [detalles, setDetalles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const { currentUser, accessToken, refreshToken, tokenExpiry, miPorque } = useContext(AuthContext);
  const fetchedRef = useRef(false);
  const [detalleSeleccionado, setDetalleSeleccionado] = useState(null);
  const [tabActual, setTabActual] = useState(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [addedTasks, setAddedTasks] = useState(new Set());
  const [isReprogModalOpen, setIsReprogModalOpen] = useState(false);
  const [taskToReprog, setTaskToReprog] = useState(null);
  const [reprogTasks, setReprogTasks] = useState(new Set());
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [taskToMove, setTaskToMove] = useState(null);
  const [movedTasks, setMovedTasks] = useState(new Set());

  const fetchDetalles = useCallback(async () => {
    if (!isOpen || fetchedRef.current) return;

    setLoading(true);
    setError(null);
    try {
      const ticketsData = tickets.map(ticket => ({
        id: ticket.id,
        title: ticket.title,
        vencimiento: ticket.vencimiento,
        horaInicio: ticket.horaInicio,
        horaTermino: ticket.horaTermino,
        priority: ticket.priority,
        status: ticket.status
      }));

      const tareasPendientesData = tareasPendientes.map(tarea => ({
        id: tarea.id,
        title: tarea.title,
        vencimiento: tarea.vencimiento,
        priority: tarea.priority || 'Media',
        status: 'Pendiente'
      }));

      const response = await axios.post('/.netlify/functions/generarDetallePlanibot', {
        tickets: [...ticketsData, ...tareasPendientesData],
        fecha,
        miPorque
      });

      const resultado = response.data.detalles;
      
      if (!Array.isArray(resultado) || resultado.length === 0) {
        setDetalles([]);
        return;
      }

      const detallesConId = resultado.map((detalle, index) => ({
        ...detalle,
        id: detalle.id || `sugerencia-${index}-${Date.now()}`
      }));

      const detallesOrdenados = detallesConId.sort((a, b) => {
        if (a.horaInicio === 'Todo el día' && b.horaInicio === 'Todo el día') return 0;
        if (a.horaInicio === 'Todo el día') return -1;
        if (b.horaInicio === 'Todo el día') return 1;
        return moment(a.horaInicio, 'HH:mm').diff(moment(b.horaInicio, 'HH:mm'));
      });

      setDetalles(detallesOrdenados);
      fetchedRef.current = true;
    } catch (error) {
      console.error("Error detallado al obtener detalles:", error);
      let errorMessage = "Hubo un error al generar el detalle. ";
      if (error.response) {
        console.error("Respuesta de error del servidor:", error.response.data);
        errorMessage += error.response.data.details || error.response.data.error || "";
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [isOpen, tickets, tareasPendientes, fecha, criterioUsuario]);

  useEffect(() => {
    if (isOpen && !fetchedRef.current) {
      fetchDetalles();
    }

    return () => {
      if (!isOpen) {
        fetchedRef.current = false;
      }
    };
  }, [isOpen, fetchDetalles]);

  const handleAddToCalendar = (detalle) => {
    setSelectedTask(detalle);
    setIsAddModalOpen(true);
  };

  const handleAcceptRescheduling = (detalle) => {
    setTaskToReprog(detalle);
    setIsReprogModalOpen(true);
  };

  const handleMoveToCalendar = (detalle) => {
    const tareaOriginal = tareasPendientes.find(tarea => tarea.id === detalle.id);
    
    if (!tareaOriginal) {
      toast.error("No se encontró la tarea original");
      return;
    }

    setTaskToMove({
      ...detalle,
      id: tareaOriginal.id
    });
    setIsMoveModalOpen(true);
  };

  const handleDeleteTask = (detalle) => {
    if (detalle && detalle.id && !detalle.id.startsWith('new-')) {
      const originalTicket = tickets.find(ticket => ticket.id === detalle.id);
      if (originalTicket) {
        setTaskToDelete({
          ...originalTicket,
          emailBusiness: businessEmail
        });
        setIsDeleteModalOpen(true);
      } else {
        toast.error("Error al preparar la tarea para eliminar. Por favor, intente de nuevo.");
      }
    } else {
      toast.error("No se puede eliminar esta tarea. Falta información necesaria.");
    }
  };

  const handleDeleteConfirm = useCallback(() => {
    if (taskToDelete && taskToDelete.id) {
      setDetalles(prevDetalles => prevDetalles.filter(d => d.id !== taskToDelete.id));
      if (onTasksUpdated) onTasksUpdated(taskToDelete.id);
      toast.success("Tarea eliminada con éxito");
    } else {
      toast.error("Error al eliminar la tarea. Por favor, intente de nuevo.");
    }
    setIsDeleteModalOpen(false);
    setTaskToDelete(null);
  }, [taskToDelete, onTasksUpdated]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setTaskToDelete(null);
  };

  const handleTaskCreated = (detalleId) => {
    setAddedTasks(prev => new Set([...prev, detalleId]));
    if (onTasksUpdated) onTasksUpdated();
  };

  const handleTaskReprogrammed = (detalleId) => {
    setReprogTasks(prev => new Set([...prev, detalleId]));
    if (onTasksUpdated) onTasksUpdated();
  };

  const handleTaskMoved = (detalleId) => {
    setMovedTasks(prev => new Set([...prev, detalleId]));
    if (onTasksUpdated) onTasksUpdated();
  };

  const renderActionButton = (detalle) => {
    if (detalle.tipo === 'sugerencia') {
      return addedTasks.has(detalle.id) ? (
        <CheckCircleIcon 
          color="success" 
          sx={{ 
            fontSize: 30,
            animation: 'fadeIn 0.5s ease-in',
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
                transform: 'scale(0.8)'
              },
              '100%': {
                opacity: 1,
                transform: 'scale(1)'
              }
            }
          }} 
        />
      ) : (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => handleAddToCalendar(detalle)}
          className="planibot-action-button"
          startIcon={<AddIcon />}
        >
          Agregar
        </Button>
      );
    } else if (detalle.tipo === 'pendiente') {
      return movedTasks.has(detalle.id) ? (
        <CheckCircleIcon 
          color="success" 
          sx={{ 
            fontSize: 30,
            animation: 'fadeIn 0.5s ease-in',
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
                transform: 'scale(0.8)'
              },
              '100%': {
                opacity: 1,
                transform: 'scale(1)'
              }
            }
          }} 
        />
      ) : (
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={() => handleMoveToCalendar(detalle)}
          className="planibot-action-button"
          startIcon={<EventIcon />}
        >
          Calendario
        </Button>
      );
    } else if (detalle.horaSugerida) {
      return reprogTasks.has(detalle.id) ? (
        <CheckCircleIcon 
          color="success" 
          sx={{ 
            fontSize: 30,
            animation: 'fadeIn 0.5s ease-in',
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
                transform: 'scale(0.8)'
              },
              '100%': {
                opacity: 1,
                transform: 'scale(1)'
              }
            }
          }} 
        />
      ) : (
        <Button 
          variant="contained" 
          color="info" 
          onClick={() => handleAcceptRescheduling(detalle)}
          className="planibot-action-button"
          startIcon={<UpdateIcon />}
        >
          Reprogramar
        </Button>
      );
    } else if (detalle.tipo === 'delete' && detalle.id && !detalle.id.startsWith('new-')) {
      return (
        <Button 
          variant="contained" 
          color="error" 
          onClick={() => handleDeleteTask(detalle)}
          className="planibot-action-button"
          startIcon={<DeleteIcon />}
        >
          Eliminar
        </Button>
      );
    }
    return null;
  };

  const renderTabla = () => (
    <TableContainer component={Paper} className="planibot-table-container">
      <Table className="planibot-table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">Hora</TableCell>
            <TableCell align="center">Evento</TableCell>
            <TableCell align="center">Detalle</TableCell>
            <TableCell align="center">Prioridad</TableCell>
            <TableCell align="center">Hora Sugerida</TableCell>
            <TableCell align="center">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TransitionGroup component={null}>
            {detalles.map((detalle, index) => (
              <CSSTransition
                key={index}
                timeout={500}
                classNames="fade"
              >
                <TableRow 
                  className={`priority-${detalle.priority?.toLowerCase()} ${detalle.tipo}`}
                >
                  <TableCell align="center">{detalle.horaInicio} - {detalle.horaTermino}</TableCell>
                  <TableCell align="center">{detalle.evento}</TableCell>
                  <TableCell align="center">{detalle.detalle}</TableCell>
                  <TableCell align="center">
                    <span className={`priority-badge priority-${detalle.priority?.toLowerCase()}`}>
                      {detalle.priority}
                    </span>
                  </TableCell>
                  <TableCell align="center">{detalle.horaSugerida || '-'}</TableCell>
                  <TableCell align="center">
                    {renderActionButton(detalle)}
                  </TableCell>
                </TableRow>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderTimeline = () => (
    <Timeline position="alternate">
      {detalles.map((detalle, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot color={getPriorityColor(detalle.priority)} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span">
              {detalle.evento}
            </Typography>
            <Typography>{detalle.horaInicio} - {detalle.horaTermino}</Typography>
            <Typography>{detalle.detalle}</Typography>
            {renderActionButton(detalle)}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );

  const getPriorityColor = (priority) => {
    switch(priority.toLowerCase()) {
      case 'alta': return 'error';
      case 'media': return 'warning';
      case 'baja': return 'success';
      default: return 'primary';
    }
  };

  const renderEstadisticas = () => {
    const totalTareas = detalles.length;
    const tareasPorTipo = detalles.reduce((acc, detalle) => {
      acc[detalle.tipo] = (acc[detalle.tipo] || 0) + 1;
      return acc;
    }, {});
    const tareasPorPrioridad = detalles.reduce((acc, detalle) => {
      acc[detalle.priority] = (acc[detalle.priority] || 0) + 1;
      return acc;
    }, {});

    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Estadísticas del día</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="subtitle1">Total de tareas</Typography>
              <Typography variant="h4">{totalTareas}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="subtitle1">Por tipo</Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Sugerencias</TableCell>
                    <TableCell align="right">{tareasPorTipo.sugerencia || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tareas del usuario</TableCell>
                    <TableCell align="right">{tareasPorTipo.userTask || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Pendientes</TableCell>
                    <TableCell align="right">{tareasPorTipo.pendiente || 0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="subtitle1">Por prioridad</Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Alta</TableCell>
                    <TableCell align="right">{tareasPorPrioridad.Alta || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Media</TableCell>
                    <TableCell align="right">{tareasPorPrioridad.Media || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Baja</TableCell>
                    <TableCell align="right">{tareasPorPrioridad.Baja || 0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderContenidoTab = () => {
    switch (tabActual) {
      case 0:
        return renderTabla();
      case 1:
        return renderTimeline();
      case 2:
        return renderEstadisticas();
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog 
        open={isOpen} 
        onClose={onClose} 
        maxWidth="lg"
        fullWidth 
        fullScreen={fullScreen}
        className="planibot-dialog"
      >
        <DialogContent className="planibot-content">
          {loading ? (
            <div className="planibot-loading-container">
              <CircularProgress className="planibot-loading" />
              <p>Analizando y optimizando tu día...</p>
            </div>
          ) : error ? (
            <p className="planibot-error">{error}</p>
          ) : (
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs value={tabActual} onChange={(e, newValue) => setTabActual(newValue)} aria-label="planibot tabs">
                  <Tab icon={<TableChartIcon />} aria-label="Tabla" />
                  <Tab icon={<TimelineIcon />} aria-label="Línea de tiempo" />
                  <Tab icon={<BarChartIcon />} aria-label="Estadísticas" />
                </Tabs>
              </Box>
              {renderContenidoTab()}
            </>
          )}
        </DialogContent>
      </Dialog>
      {taskToDelete && (
        <DeleteTarea
          tarea={taskToDelete}
          isOpen={isDeleteModalOpen}
          onClose={handleDeleteCancel}
          onDelete={handleDeleteConfirm}
          currentUser={currentUser}
          accessToken={accessToken}
          refreshToken={refreshToken}
          tokenExpiry={tokenExpiry}
        />
      )}
      {detalleSeleccionado && (
        <Dialog open={!!detalleSeleccionado} onClose={() => setDetalleSeleccionado(null)}>
          <DialogTitle>{detalleSeleccionado?.evento}</DialogTitle>
          <DialogContent>
            <Typography>Hora: {detalleSeleccionado?.horaInicio} - {detalleSeleccionado?.horaTermino}</Typography>
            <Typography>Prioridad: {detalleSeleccionado?.priority}</Typography>
            <Typography>Tipo: {detalleSeleccionado?.tipo}</Typography>
            <Typography>Detalle: {detalleSeleccionado?.detalle}</Typography>
            {detalleSeleccionado?.horaSugerida && (
              <Typography>Hora sugerida: {detalleSeleccionado.horaSugerida}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDetalleSeleccionado(null)}>Cerrar</Button>
            {renderActionButton(detalleSeleccionado)}
          </DialogActions>
        </Dialog>
      )}
      {selectedTask && (
        <AddFromPlani
          isOpen={isAddModalOpen}
          onClose={() => {
            setIsAddModalOpen(false);
            setSelectedTask(null);
          }}
          detalle={selectedTask}
          businessEmail={businessEmail}
          fecha={fecha}
          onTaskCreated={() => {
            handleTaskCreated(selectedTask.id);
            setIsAddModalOpen(false);
            setSelectedTask(null);
          }}
        />
      )}
      {taskToReprog && (
        <ReprogFromPlani
          isOpen={isReprogModalOpen}
          onClose={() => {
            setIsReprogModalOpen(false);
            setTaskToReprog(null);
          }}
          detalle={taskToReprog}
          businessEmail={businessEmail}
          fecha={fecha}
          onTaskUpdated={() => {
            handleTaskReprogrammed(taskToReprog.id);
            setIsReprogModalOpen(false);
            setTaskToReprog(null);
          }}
        />
      )}
      {taskToMove && (
        <MoveToCalendarPlani
          isOpen={isMoveModalOpen}
          onClose={() => {
            setIsMoveModalOpen(false);
            setTaskToMove(null);
          }}
          detalle={taskToMove}
          businessEmail={businessEmail}
          fecha={fecha}
          onTaskUpdated={() => {
            handleTaskMoved(taskToMove.id);
            setIsMoveModalOpen(false);
            setTaskToMove(null);
          }}
        />
      )}
    </>
  );
};

export default React.memo(PlanibotDetail);
