import React from "react";
import { useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "./TrashCan.css";
import { deleteFile } from "../Functions"; // Importa la función deleteFile

const ItemType = "ITEM";

function TrashCan({ setItems, items, setHasChanges }) {
  const [, drop] = useDrop({
    accept: ItemType,
    drop: async (item) => {
      const fullItem = items[item.index];

      const newItems = items.filter((i) => i.id !== item.id);

      // Elimina el archivo del storage de Firebase si es de tipo file o image
      if (fullItem.type === "file" || fullItem.type === "image") {
        await deleteFile(fullItem.content);
      }

      setItems(newItems);
      setHasChanges(true); // Marcar cambios
    },
  });

  return (
    <div ref={drop} className="trash-can">
      <FontAwesomeIcon icon={faTrashAlt} size="2x" />
    </div>
  );
}

export default TrashCan;
