import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import {
  Calendar as CalendarIcon,
  PlusCircle,
  CheckCircle,
  Bot
} from 'lucide-react';
import './MenuAddCalendar.css';

function MenuAddCalendar({ position, onAddItem, onClose, theme = 'light' }) {
  const menuRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const menu = menuRef.current;
    if (menu) {
      const { x, y } = position;
      const { innerWidth, innerHeight } = window;
      const menuRect = menu.getBoundingClientRect();

      let newX = x;
      let newY = y;

      // Ajustar posición horizontal si el menú se sale de la pantalla
      if (x + menuRect.width > innerWidth) {
        newX = innerWidth - menuRect.width - 10;
      }

      // Colocar el menú debajo del clic, pero ajustar si se sale de la pantalla
      newY = y + 10; // 10px debajo del clic
      if (newY + menuRect.height > innerHeight) {
        newY = y - menuRect.height - 10; // Colocar encima del clic si no cabe debajo
      }

      menu.style.left = `${newX}px`;
      menu.style.top = `${newY}px`;
    }
  }, [position]);

  const handleItemClick = useCallback((itemId, event) => {
    event.preventDefault();
    event.stopPropagation();
    onAddItem(itemId);
  }, [onAddItem]);

  const menuItems = [
    {
      id: 'event',
      icon: <PlusCircle className="menuAddCalendar__icon" />,
      label: 'Agregar Nuevo'
    },
/*     {
      id: 'meeting',
      icon: <CheckCircle className="menuAddCalendar__icon" />,
      label: 'Nueva Reunión'
    }, */
    {
      id: 'planibot',
      icon: <Bot className="menuAddCalendar__icon" />,
      label: 'Mi día ideal'
    }
  ];

  return ReactDOM.createPortal(
    <div
      className={`menuAddCalendar ${theme}`}
      ref={menuRef}
      role="menu"
      aria-labelledby="menu-button"
    >
      <ul className="menuAddCalendar__list">
        {menuItems.map((item) => (
          <li
            key={item.id}
            className="menuAddCalendar__item"
            onClick={(e) => handleItemClick(item.id, e)}
            role="menuitem"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleItemClick(item.id, e);
              }
            }}
          >
            {item.icon}
            <span className="menuAddCalendar__text">{item.label}</span>
          </li>
        ))}
      </ul>
    </div>,
    document.body
  );
}

export default React.memo(MenuAddCalendar);
