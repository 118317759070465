import React from 'react';
import Modal from 'react-modal';

const ModalSuccessAddCalendar = ({ isOpen, onRequestClose, eventId }) => (
    <Modal
        overlayClassName={'popupBackground'}
        className={'popupContainer'}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
    >
        <div className="popupHeader">
            <h2 className="popupTitle">{eventId ? '¡Evento agregado!' : '¡Evento eliminado!'}</h2>
        </div>
        <div className="popupContent">
            <p>{eventId ? 'El evento se ha agregado a tu calendario exitosamente.' : 'El evento se ha eliminado de tu calendario exitosamente.'}</p>
        </div>
        <div className="popupActions">
            <button className="popupButton" onClick={onRequestClose}>Cerrar</button>
        </div>
    </Modal>
);

export default ModalSuccessAddCalendar;
