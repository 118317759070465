import React, { useEffect, useState, useContext } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { auth } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faTimes,
  faCheckCircle,
  faCommentDots,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./Notifications.css";
import { AuthContext } from "../../context/AuthContext";

const Notifications = ({ isOpen, onClose }) => {
  const [notifications, setNotifications] = useState([]);
  const db = getFirestore();
  const navigate = useNavigate();
  const { updateBusinessContext } = useContext(AuthContext);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const email = user.email;
      const notificationsRef = collection(db, `users/${email}/notifications`);
      const q = query(notificationsRef, where("read", "==", false));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const notificationsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(notificationsData);
      });

      return () => unsubscribe();
    }
  }, [db]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".notifications-sidebar") === null) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleMarkAsRead = async (notificationId) => {
    const user = auth.currentUser;
    if (user) {
      const email = user.email;
      const notificationRef = doc(
        db,
        `users/${email}/notifications`,
        notificationId
      );
      await deleteDoc(notificationRef);
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId
        )
      );
    }
  };

  const handleNotificationClick = async (workspaceData, department, emailBusiness) => {
    sessionStorage.setItem("businessId", emailBusiness);
    sessionStorage.setItem("area", department);
    sessionStorage.setItem("userType", "agent");
    
    try {
      navigate("/WorkSpaces", {
        state: workspaceData,
      });
      await updateBusinessContext(emailBusiness, department);
    } catch (error) {
      console.error(error);
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case "chatMessage":
        return <FontAwesomeIcon icon={faCommentDots} />;
      case "workspace":
      default:
        return <FontAwesomeIcon icon={faBriefcase} />;
    }
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString();
  };

  return (
    <div className={`notifications-sidebar ${isOpen ? "open" : ""}`}>
      <div className="notifications-header">
        <h2>Notificaciones</h2>
        <button className="close-btn2" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <div key={notification.id} className="notification-item animate">
            <div className="notification-content-container">
              <div className="notification-icon">
                {getNotificationIcon(notification.type)}
              </div>
              <div
                className="notification-content"
                onClick={() => handleNotificationClick(
                  notification.workspaceData,
                  notification.department,
                  notification.emailBusiness
                )}
              >
                <p className="title">{notification.title}</p>
                <p className="message">{notification.message}</p>
                <p className="timestamp">{formatDate(notification.timestamp)}</p>
              </div>
            </div>
            <div className="mark-as-read-container">
              <button
                className="mark-as-read-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleMarkAsRead(notification.id);
                }}
              >
                <FontAwesomeIcon icon={faCheckCircle} />
                <span className="mark-as-read-text">Marcar como leída</span>
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="no-notifications">
          No tienes notificaciones en este momento
        </div>
      )}
    </div>
  );
};

export default Notifications;
