import React, { useState, useEffect } from 'react';
import { Users, Video, X, AlertCircle, Mail, Info } from 'lucide-react';
import './AttendeeSelector.css';

const AttendeeSelector = ({ 
  attendees, 
  setAttendees, 
  enableMeet, 
  setEnableMeet,
  error 
}) => {
  const [newAttendee, setNewAttendee] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [inputStatus, setInputStatus] = useState(''); // '' | 'valid' | 'invalid'
  const [validationMessage, setValidationMessage] = useState('');

  const isEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const email = newAttendee.trim();
    if (email.length > 0) {
      const hasEmailFormat = isEmailFormat(email);

      setIsValidEmail(hasEmailFormat);
      setShowSuggestion(hasEmailFormat);
      setInputStatus(hasEmailFormat ? 'valid' : 'invalid');

      if (!hasEmailFormat && email.length > 0) {
        setValidationMessage('Formato de correo inválido');
      } else {
        setValidationMessage('');
      }
    } else {
      setIsValidEmail(false);
      setShowSuggestion(false);
      setInputStatus('');
      setValidationMessage('');
    }
  }, [newAttendee]);

  const handleAddAttendee = (emailToAdd) => {
    const email = emailToAdd.trim();
    
    if (email && !attendees.includes(email)) {
      setAttendees([...attendees, email]);
      setNewAttendee('');
      setShowSuggestion(false);
      setInputStatus('');
      setValidationMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && isValidEmail) {
      e.preventDefault();
      handleAddAttendee(newAttendee);
    }
  };

  const handleRemoveAttendee = (emailToRemove) => {
    setAttendees(attendees.filter(email => email !== emailToRemove));
  };

  return (
    <div className="attendee-selector">
      <div className="form-field">
        <label>
          <Users size={16} />
          Invitados
        </label>
        <div className="attendee-input-container">
          <input
            type="email"
            value={newAttendee}
            onChange={(e) => setNewAttendee(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Agregar invitado por email"
            className={`attendee-input ${inputStatus}`}
          />
          {validationMessage && (
            <div className="validation-message">
              <Info size={14} />
              <span>{validationMessage}</span>
            </div>
          )}
          {showSuggestion && (
            <div className="email-suggestion" onClick={() => handleAddAttendee(newAttendee)}>
              <Mail size={16} />
              <span>{newAttendee}</span>
            </div>
          )}
        </div>
      </div>

      {attendees.length > 0 && (
        <div className="attendees-list">
          {attendees.map((email, index) => (
            <div key={index} className="attendee-tag">
              <span>{email}</span>
              <button
                onClick={() => handleRemoveAttendee(email)}
                className="remove-attendee"
              >
                <X size={14} />
              </button>
            </div>
          ))}
        </div>
      )}

      {error && (
        <div className="attendee-error">
          <AlertCircle size={14} />
          <span>{error}</span>
        </div>
      )}

      <div className="form-field checkbox-field meet-option">
        <div className="section-title">
          <Video size={16} />
          <span>Reunión Virtual</span>
        </div>
        
        <div className="all-day-toggle">
          <span className="all-day-label">
            <Video size={16} />
            ¿Videollamada de Meet?
          </span>
          <input
            className="all-day-checkbox"
            type="checkbox"
            checked={enableMeet}
            onChange={(e) => setEnableMeet(e.target.checked)}
          />
          <div className="toggle-slider"></div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeSelector; 