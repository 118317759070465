import React, { useState } from "react";
import { onSnapshot } from "firebase/firestore";
import { queryAreas } from "../Functions";
import { getAreas } from "../Functions";
import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore"; // Importa las funciones necesarias de Firestore
import moment from "moment";
import OpenAI from "openai";
import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod";

const Evento = z.object({
  id: z.string().optional(),
  title: z.string(),
  vencimiento: z.string(),
  horaInicio: z.string(),
  horaTermino: z.string(),
  descripcion: z.string(),
  items: z.array(z.object({
    content: z.string(),
    type: z.string(),
    position: z.object({
      x: z.number(),
      y: z.number()
    }),
    width: z.number(),
    height: z.number()
  })).optional()
});

const RespuestaReorganizacion = z.object({
  eventos: z.array(Evento),
  respuesta: z.string()
});

export async function getTitleGPT(mensaje) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: "Eres un agente especialista en gestionar tickets de soporte de solicitudes de usuario en Soportick.com",
        },
        {
          role: "user",
          content: "Del siguiente mensaje, extrae el título que mejor sirva para identificar la solicitud en un máximo de 40 caracteres. No escribas el número de caracteres al final del título ni devuelvas el título entre comillas, solo en texto limpio: " + mensaje,
        },
      ],
    });


    return completion.choices[0].message.content;
  } catch (error) {
    console.error("Error al obtener el título:", error);
    throw error;
  }
}

export async function getContextGPT(text, hoy, fechaVencimiento) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 400,
      temperature: 0.7,
      messages: [
        {
          role: "system",
          content: "Eres un agente de gestión de tickets de soporte",
        },
        {
          role: "user",
          content: "Sintetiza la siguiente solicitud para que sea más fácil que otro agente llegue a la solución: " + text + ". Hoy es: " + hoy + " y la solicitud vence el: " + fechaVencimiento,
        },
      ],
    });

    return completion.choices[0].message.content;
  } catch (error) {
    console.error("Error al obtener el contexto:", error);
    throw error;
  }
}

export async function getStepsGpt(text) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 200,
      temperature: 0.7,
      messages: [
        {
          role: "system",
          content: "Eres un agente de gestión de tickets de soporte",
        },
        {
          role: "user",
          content: "Identifica y enumera 3 tareas muy resumidas, sintetizadas y lo más cortas posible, necesarias para resolver la siguiente solicitud: " + text,
        },
      ],
    });

    return completion.choices[0].message.content;
  } catch (error) {
    console.error("Error al obtener los pasos:", error);
    throw error;
  }
}

const stringSimilarity = require("string-similarity");

export async function getArea(text, area) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 20,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres un especialista que solo escoge una palabra de un array y la palabra debe ser la más coherente o parecida al contenido de la solicitud. Escoge 'deporte' de la lista en caso de que exista. Evita hablar de más, solo responde con una palabra de la lista y evita escribir una palabra que no esté en la lista. Por ejemplo: Realizar presupuesto: escoger la palabra 'Finanzas', 'presupuestos', 'administración' o cualquiera que tenga sentido y que se encuentre en el array.",
        },
        {
          role: "user",
          content: "Tienes en frente una lista de tableros de Trello y tienes que archivar una solicitud, la cual es la siguiente: " + text + ". De los siguientes tableros: " + area + ", limítate a escoger nada más del siguiente array el nombre del tablero en el que deberíamos almacenar dicha solicitud porque resulta más coherente con el contexto. Si la solicitud tiene una palabra igual o similar en su contenido, escoge esa.",
        },
      ],
    });

    const result = completion.choices[0].message.content;

    // Encontrar el área más parecida en el array de áreas
    const bestMatch = stringSimilarity.findBestMatch(result, area);

    // Si la coincidencia es suficientemente alta, devolver el área correspondiente
    const similarityThreshold = 0.4;
    if (bestMatch.bestMatch.rating >= similarityThreshold) {
      return bestMatch.bestMatch.target;
    } else {
      return "área por definir";
    }
  } catch (error) {
    console.error("Error al obtener el área:", error);
    throw error;
  }
}

export async function getAreaMasParecida(message, businessEmail) {
  let idsAreas = [];

  if (businessEmail) {
    const q = query(collection(db, "business", businessEmail, "areas"));
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    idsAreas = items.map((item) => item.id);
  }

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 20,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres un especialista que solo escoge una palabra de un array y la palabra debe ser la más coherente o parecida al contenido de la solicitud. Escoge 'deporte' de la lista en caso de que exista. Evita hablar de más, solo responde con una palabra de la lista y evita escribir una palabra que no esté en la lista. Por ejemplo: Realizar presupuesto: escoger la palabra 'Finanzas', 'presupuestos', 'administración' o cualquiera que tenga sentido y que se encuentre en el array.",
        },
        {
          role: "user",
          content: "Tienes en frente una lista de tableros de Trello y tienes que archivar una solicitud, la cual es la siguiente: " + message + ". De los siguientes tableros: " + idsAreas + ", limítate a escoger nada más del siguiente array el nombre del tablero en el que deberíamos almacenar dicha solicitud porque resulta más coherente con el contexto. Si la solicitud tiene una palabra igual o similar en su contenido, escoge esa.",
        },
      ],
    });

    const result = completion.choices[0].message.content;

    // Encontrar el área más parecida en el array de áreas
    const bestMatch = stringSimilarity.findBestMatch(result, idsAreas);

    // Si la coincidencia es suficientemente alta, devolver un objeto con el área más parecida y la lista completa de áreas
    const similarityThreshold = 0.4;
    if (bestMatch.bestMatch.rating >= similarityThreshold) {
      return {
        mostSimilarArea: bestMatch.bestMatch.target,
        allAreas: idsAreas,
      };
    } else {
      return {
        mostSimilarArea: "área por definir",
        allAreas: idsAreas,
      };
    }
  } catch (error) {
    console.error("Error al obtener el área:", error);
    return {
      mostSimilarArea: "",
      allAreas: [],
    }; // Devolver un objeto con valores predeterminados en caso de error
  }
}

export async function getCompetenciaMasParecida(message, businessEmail, area) {
  let idsCompetencias = [];

  if (businessEmail) {
    const q = query(collection(db, "business", businessEmail, "areas", area, "competencias"));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return {
        mostSimilarCompetencia: "Por Definir",
        allCompetencias: [],
      };
    }

    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    idsCompetencias = items.map((item) => item.id);
  }

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 20,
      temperature: 1,
      messages: [
        {
          role: "system",
          content:
            "Eres una IA que solo escoge una opción de una lista y la opción debe ser la más coherente o parecida. Por ejemplo, si se menciona 'fútbol', debes escoger 'deporte' de la lista. Evita hablar de más, solo responde con una palabra de la lista y evita escribir una palabra que no esté en ella.",
        },
        {
          role: "user",
          content:
            "En relación al siguiente texto: " +
            message +
            ", debes escoger una palabra que sirva para resumir la actividad que hay que hacer, y esa palabra debe ser una de la siguiente lista: " +
            idsCompetencias +
            ". Solo puedes escoger de esa lista, no puede ser otra palabra que no esté en ella. Y tiene que ser la que más se parezca sin importar mayúsculas ni tildes. Si no consigues una, escoge igual cualquiera que pueda tener similitud.",
        },
      ],
    });

    const result = completion.choices[0].message.content;
    const similarityThreshold = 0.4;

    const { mostSimilarCompetencia, allCompetencias } = findMostSimilarCompetencia(
      result,
      idsCompetencias,
      similarityThreshold
    );

    return { mostSimilarCompetencia, allCompetencias };
  } catch (error) {
    console.error("Error al obtener la competencia:", error);
    // Return allCompetencias even if there's an error or no match is found
    return { mostSimilarCompetencia: "Por Definir", allCompetencias: idsCompetencias };
  }
}


function findMostSimilarCompetencia(result, idsCompetencias, similarityThreshold) {
  const bestMatch = stringSimilarity.findBestMatch(result, idsCompetencias);

  if (bestMatch.bestMatch.rating >= similarityThreshold) {
    return {
      mostSimilarCompetencia: bestMatch.bestMatch.target,
      allCompetencias: idsCompetencias,
    };
  } else {
    return {
      mostSimilarCompetencia: "Por Definir",
      allCompetencias: idsCompetencias,
    };
  }
}

export async function getCompetencia(text, competencias, area) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 20,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres una IA que solo escoge una opción de una lista y la opción debe ser la más coherente o parecida. Por ejemplo, si se menciona 'fútbol', debes escoger 'deporte' de la lista. Evita hablar de más, solo responde con una palabra de la lista y evita escribir una palabra que no esté en ella.",
        },
        {
          role: "user",
          content: "En relación al siguiente texto: " + text + ", debes escoger una palabra que sirva para resumir la actividad que hay que hacer, y esa palabra debe ser una de la siguiente lista: " + competencias + ". Solo puedes escoger de esa lista, no puede ser otra palabra que no esté en ella. Y tiene que ser la que más se parezca sin importar mayúsculas ni tildes. Si no consigues una, escoge igual cualquiera que pueda tener similitud.",
        },
      ],
    });

    const result = completion.choices[0].message.content;

    // Verificar si el array de competencias está vacío
    if (competencias.length === 0) {
      return "Por Definir";
    }

    // Encontrar la competencia más parecida en el array de competencias
    const bestMatch = stringSimilarity.findBestMatch(result, competencias);

    // Si la coincidencia es suficientemente alta, devolver la competencia correspondiente
    const similarityThreshold = 0.4;
    if (bestMatch.bestMatch.rating >= similarityThreshold) {
      return bestMatch.bestMatch.target;
    } else {
      return "Por Definir";
    }
  } catch (error) {
    console.error("Error al obtener la competencia:", error);
    throw error;
  }
}

export async function welcomeBot(
  nombre,
  tickets,
  fecha,
  proyecto,
  area,
  descripcion,
  objetivos
) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 200,
      temperature: 0.9,
      messages: [
        {
          role: "system",
          content: "Eres una IA que habla chileno cuico y eres un experto en aumento de productividad organizacional",
        },
        {
          role: "user",
          content: "De las siguientes tareas (no las menciones de nuevo), simplemente analiza profundamente y escoge cuál es la más idónea para resolver. Considera que el proyecto en el que estás trabajando es: " + proyecto + ". El área del proyecto es: " + area + ". La descripción del mismo es: " + descripcion + ". Los objetivos que espera el creador del proyecto son: " + objetivos + ". La fecha de vencimiento es: " + fecha + ". Considera que los agentes deben ser muy eficientes. Debes escoger exclusivamente de las siguientes tareas: " + tickets + ". ¿Cuál es la tarea más idónea que el agente debe realizar para ser más productivo y alinearse a los objetivos del proyecto? Sintetiza y evita repetir el contexto, selecciona la tarea de la lista y justifica el porqué de forma resumida en dialecto chileno. Dale importancia a las actividades que están por vencerse o que ya están vencidas, puesto que no está bien dejar que se venzan. Solo evita una tarea vencida o por vencer si existiese una tarea más importante que atente contra la ejecución del proyecto.",
        },
      ],
    });

    const IaResponse = completion.choices[0].message.content;
    return IaResponse;
  } catch (error) {
    console.error("Error en welcomeBot:", error);
    throw error;
  }
}

export async function consejoProductivo(objetivo) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 200,
      temperature: 0.9,
      messages: [
        {
          role: "system",
          content: "Eres un experto en aumento de productividad organizacional",
        },
        {
          role: "user",
          content: "Proporciona un consejo que sirva para mejorar la productividad y cumplir este objetivo:" + objetivo + ". Todo de forma sintetizada sin alargarte mucho, pero sin exagerar. Empieza directamente con el consejo",
        },
      ],
    });

    return completion.choices[0].message.content;
  } catch (error) {
    console.error("Error al obtener el consejo productivo:", error);
    return "Hubo un error al obtener un consejo productivo. Por favor, intenta de nuevo más tarde.";
  }
}


const areas = [
  "libros",
  "deportes",
  "peliculas",
  "finanzas",
  "contabilidad",
  "negocios",
  "personas",
  "recursosHumanos",
  "graficos",
  "archivos",
  "baseDeDatos",
  "programacion",
  "reuniones",
  "proyectos",
  "ventas",
  "marketing",
  "cliente",
  "soporte",
  "envios",
  "calendario",
];

export async function getAreaIcon(name) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 20,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres una IA que solo escoge una opción de una lista y la opción debe ser la más coherente o parecida. Por ejemplo, si se menciona 'fútbol', debes escoger 'deportes' de la lista. Evita hablar de más, solo responde con una palabra de la lista y evita escribir una palabra que no esté en ella.",
        },
        {
          role: "user",
          content: "De la siguiente lista: " + name + ", selecciona la palabra que mejor lo describa y que sea más coherente. Por ejemplo: Libros = libros, ejercicio = deportes. La lista es la siguiente: [libros, deportes, peliculas, finanzas, contabilidad, negocios, personas, recursos Humanos, graficos, archivos, base DeDatos, programacion, reuniones, proyectos, ventas, marketing, cliente, soporte, envios, calendario]. Solo puedes escoger de esa lista, no puede ser otra palabra que no esté en ella. Y tiene que ser la que más se parezca sin importar mayúsculas ni tildes. Si no consigues una, escoge igual la que consideres más parecida pero estrictamente de la lista.",
        },
      ],
    });

    const result = completion.choices[0].message.content.trim();

    // Verificar que el resultado sea una cadena de texto válida y que el array areas esté definido
    if (typeof result === "string" && Array.isArray(areas)) {
      // Encontrar el área más parecida en el array de áreas
      const bestMatch = stringSimilarity.findBestMatch(result, areas);

      // Si la coincidencia es suficientemente alta, devolver el área correspondiente
      const similarityThreshold = 0.5;
      if (bestMatch.bestMatch.rating >= similarityThreshold) {
        return bestMatch.bestMatch.target;
      } else {
        return "área por definir";
      }
    } else {
      console.error("El resultado de la API o el array de áreas no es válido");
      return "área por definir";
    }
  } catch (error) {
    console.error("Error al obtener el icono del área:", error);
    throw error;
  }
}

export async function getAreaSugerencia(proyecto) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 100,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres una IA que sugiere las áreas o departamentos que podría tener un proyecto",
        },
        {
          role: "user",
          content:
            "Este es el proyecto en el que está trabajando el cliente: " +
            proyecto +
            ". Debes escoger máximo 10 áreas que podría tener este proyecto. Evita hablar más de la cuenta, solo escribe las áreas separadas por una coma. Como ejemplo: El proyecto del cliente es un restaurante, tu respondes: finanzas, cocina, recetas, RRHH, contabilidad, tesorería, proveedores",
        },
      ],
    });


    const result = completion.choices[0].message.content;
    return result;
  } catch (error) {
    console.error("Error al obtener sugerencias de áreas:", error);
    throw error;
  }
}

export async function getCompetenciaSugerencia(area) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 100,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres una IA que sugiere las competencias que tiene un área en un proyecto",
        },
        {
          role: "user",
          content: "Esta es el área que seleccionó el cliente: " + area + ". Debes escoger máximo 10 competencias que podría tener esta área. Evita hablar más de la cuenta, solo escribe las competencias separadas por una coma. Como ejemplo: El área es tesorería, tu respondes: pagos, transferencias, conciliaciones bancarias, depósitos",
        },
      ],
    });

    const result = completion.choices[0].message.content;
    return result;
  } catch (error) {
    console.error("Error al obtener sugerencias de competencias:", error);
    throw error;
  }
}

export async function getFecha(message, type, hoy) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 100,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres un experto en planificación que escoge la fecha de"+ type + " de una actividad",
        },
        {
          role: "user",
          content: "hoy es: " + hoy + "y esta es la solicitud del cliente:" + message + " y de aquí debes escoger la fecha de" + type + "de la misma en este formato xx-xx-xxxx (día, mes y año). Si no consigues fecha dentro de la solicitud devuelves, la fecha de hoy. Limítate a sólo devolver la fecha, no hables de más" ,
        },
      ],
    });

    const result = completion.choices[0].message.content;
    console.log("la fecha de" + type + "es:" + result)
    return result;
  } catch (error) {
    console.error("Error al obtener sugerencias de competencias:", error);
    throw error;
  }
}

export async function getHora(message, type, horaActual) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  console.log("hora actual:" + horaActual)

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o",
      max_tokens: 100,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres un experto en planificación que escoge la hora de"+ type + " de una actividad",
        },
        {
          role: "user",
          content: "esta es la solicitud del cliente:" + message +" y esta es la hora actual: " + horaActual + " y de aquí debes escoger la hora de " + type +  " de la misma en este formato xx:xx (por ejemplo 14:30 en formato de 24 horas ). Si no consigues hora dentro de la solicitud devuelves: todo el día. Limítate a sólo devolver la hora, no hables de más" ,
        },
      ],
    } );

    const result = completion.choices[0].message.content;
    console.log("la hora de" + type + "es:" + result)
    return result;
  } catch (error) {
    console.error("Error al obtener sugerencias de competencias:", error);
    throw error;
  }
}

export async function getObjectiveProject(descriptionUser, Objectives, atributes) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 100,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres un especialista en OKR que debe realizar una breve descripción de un proyecto",
        },
        {
          role: "user",
          content: "basado en la siguiente descripción" + descriptionUser + "los siguientes objetivos" + Objectives + "y estos 3 atributos" + atributes + "redacta una descripción que sintetice los objetivos de este proyecto en máximo 200 caracteres" ,
        },
      ],
    } );

    const result = completion.choices[0].message.content;
    return result;
  } catch (error) {
    console.error("Error al obtener sugerencias de competencias:", error);
    throw error;
  }
}



export async function massiveTaskJson(mensaje, businessEmail) {
  let idsAreas = [];

  if (businessEmail) {
    const q = query(collection(db, "business", businessEmail, "areas"));
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    idsAreas = items.map((item) => item.id);
  }

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const fechaHoy = moment().tz("America/Santiago").format("YYYY-MM-DD HH:mm:ss");

    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 5000,
      temperature: 0.8,
      messages: [
        {
          role: "system",
          content: "Eres una IA que genera JSON estructurado, con una lista de tareas. Asegúrate de clasificar correctamente cada ítem como 'tarea' o 'evento' segn la descripción dada.",
        },
        {
          role: "user",
          content: `Del siguiente texto: "${mensaje}" y considerando que hoy es: "${fechaHoy}", extrae una lista de tareas en la siguiente estructura JSON:
          {
            "tasks": [
              {"fecha": "YYYY-MM-DD", "horaInicio": "HH:MM", "horaFin": null, "título": "Título", "tipo": "tarea", "todoElDía": true, "area": "", "competencia": "", "status": "Pendiente" o "Proceso", "priority": "Alta"}
            ]
          }.
          Si no tiene una hora especifica en el mensaje, considerarla entonces como una tarea. Las tareas no deben tener una hora específica y deben tener "todoElDía": true y "horaInicio": "Todo el día". Asegúrate de clasificar correctamente cada ítem como 'tarea'. Si hay citas, reuniones, fiestas, etc, o hay una hora claramente establecida en el mensaje, entonces serán considerados eventos, deben ser claramente descritos como tales y deberán tener tanto "horaInicio" como "horaFin" según corresponda, con formato 24:00 hrs, ejemplo 19:45. Si asignas horaInicio, debes estimar la hora término y debe ir si o si, no puede ser null. De las siguientes áreas: ${idsAreas}, asigna la más adecuada para cada tarea. Debes escoger una prioridad bien sea baja, media, alta, determinada por la urgencia que detectes en la ejecución de la tarea y asignarla a "priority".
          Si identificas una fecha de ejecución de la tarea en el mensaje inicial, el "status" debe ser "Proceso". Si no identificas una fecha de ejecución clara, el "status" debe ser "Pendiente" y la fecha debe ser null. En relación a la respuesta debe ser estrictamente con la forma que se muestra en el json para asegurar que no exista ningún error.`,
        },
      ],
      response_format: {
        type: "json_schema",
        json_schema: {
          name: "task_event_schema",
          strict: true,
          schema: {
            type: "object",
            properties: {
              tasks: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    fecha: { type: "string" },
                    horaInicio: { type: "string" },
                    horaFin: { type: ["string", "null"] },
                    título: { type: "string" },
                    tipo: { type: "string" },
                    todoElDía: { type: "boolean" },
                    area: { type: "string" },
                    competencia: { type: "string" },
                    status: { type: "string" },
                    priority: { type: "string" }
                  },
                  required: ["fecha", "horaInicio", "horaFin", "título", "tipo", "todoElDía", "area", "competencia", "status", "priority"],
                  additionalProperties: false
                }
              }
            },
            required: ["tasks"],
            additionalProperties: false
          }
        }
      }
    });

    let jsonResponse = completion.choices[0].message;

    if (!jsonResponse || !jsonResponse.content) {
      console.error("La respuesta de la IA no contiene la estructura esperada:", jsonResponse);
      throw new Error("Estructura JSON inesperada en la respuesta de la IA.");
    }

    // Parsear el contenido JSON del string a un objeto
    jsonResponse = JSON.parse(jsonResponse.content);

    // Ajustar horaFin para eventos sin horaFin específica
    for (const task of jsonResponse.tasks) {
      if (task.tipo === "evento" && !task.horaFin && !task.todoElDía) {
        const [hours, minutes] = task.horaInicio.split(":");
        const newHours = (parseInt(hours, 10) + 1) % 24;
        task.horaFin = `${newHours.toString().padStart(2, "0")}:${minutes}`;
      }

      // Verificar y asignar área más parecida utilizando stringSimilarity
      if (task.area && idsAreas.length > 0) {
        const bestMatch = stringSimilarity.findBestMatch(task.area, idsAreas);
        const similarityThreshold = 0.4;
        if (bestMatch.bestMatch.rating >= similarityThreshold) {
          task.area = bestMatch.bestMatch.target;
        } else {
          task.area = "área por definir";
        }
      }

      // Asignar prioridad de manera aleatoria para demostración
      const priorities = ["Alta", "Media", "Baja"];
      task.priority = priorities[Math.floor(Math.random() * priorities.length)];
    }

    // Obtener competencias para cada tarea
    for (const task of jsonResponse.tasks) {
      if (task.area !== "área por definir") {
        try {
          const competenciaResult = await getCompetenciaMasiva(task.título, businessEmail, task.area);
          task.competencia = competenciaResult.mostSimilarCompetencia;
        } catch (error) {
          console.error(`Error obteniendo competencia para el área ${task.area}:`, error);
          task.competencia = "Por Definir";
        }
      } else {
        task.competencia = "Por Definir";
      }
    }

    console.log("JSON analizado:", jsonResponse);

    return jsonResponse.tasks;
  } catch (error) {
    console.error("Error en massiveTaskJson:", error);
    throw error;
  }
}





export async function getCompetenciaMasiva(tareaOevento, businessEmail, area) {
  let idsCompetencias = ["Por Definir"];

  if (businessEmail) {
    const q = query(collection(db, "business", businessEmail, "areas", area, "competencias"));
    const querySnapshot = await getDocs(q);

    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    if (items.length > 0) {
      idsCompetencias = items.map((item) => item.id);
    }
  }

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 200,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres una IA que solo escoge una opción de una lista y la opción debe ser la más coherente o parecida. Por ejemplo, si se menciona 'fútbol', debes escoger 'deporte' de la lista. Evita hablar de más, solo responde con una palabra de la lista y evita escribir una palabra que no esté en ella.",
        },
        {
          role: "user",
          content: "En relación a la siguiente tarea o evento" + tareaOevento + ", debes escoger una palabra que sirva para resumir la actividad que hay que hacer, y esa palabra debe ser una de la siguiente lista: " + idsCompetencias + ". Solo puedes escoger de esa lista, no puede ser otra palabra que no esté en ella. Y tiene que ser la que más se parezca sin importar mayúsculas ni tildes. Si no consigues una, escoge igual cualquiera que pueda tener similitud pero siempre debes escoger una.",
        },
      ],
    });

    const result = completion.choices[0].message.content.trim();
    const similarityThreshold = 0.4;

    const bestMatch = stringSimilarity.findBestMatch(result, idsCompetencias);

    const mostSimilarCompetencia = bestMatch.bestMatch.rating >= similarityThreshold ? bestMatch.bestMatch.target : idsCompetencias[0];

    return { mostSimilarCompetencia, allCompetencias: idsCompetencias };
  } catch (error) {
    console.error("Error al obtener la competencia:", error);
    return { mostSimilarCompetencia: "Por Definir", allCompetencias: idsCompetencias };
  }
}

export const reordenarEventosConIA = async (jsonEventos, instruccion, hoy, usar_workspace) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  

  try {
    const completion = await openai.beta.chat.completions.parse({
      model: "gpt-4o-2024-08-06",
      messages: [
        {
          role: "system",
          content: `Eres un asistente de calendario inteligente. Tu tarea es interpretar las solicitudes del usuario y realizar cambios en su calendario. Debes responder en formato JSON, manteniendo la estructura original de los eventos y añadiendo un campo 'respuesta' con tu explicación de los cambios realizados. Si se te pide eliminar o modificar eventos pasados, debes hacerlo sin cuestionar. Si 'usar_workspace' es true, debes agregar items creativos al workspace del evento.`
        },
        {
          role: "user",
          content: `La fecha de hoy es ${hoy}. Aquí están los eventos relevantes: ${jsonEventos}. 
          La instrucción es: "${instruccion}". 
          Usar workspace: ${usar_workspace}.
          Por favor, realiza los cambios necesarios y responde con el JSON actualizado, incluyendo una explicación de los cambios en el campo 'respuesta'. Si 'usar_workspace' es true, agrega items creativos al campo 'items' del evento. La 'respuesta' no debe contener detaller técnicos, y esto debe ser en lenguaje amigable`
        }
      ],
      response_format: zodResponseFormat(RespuestaReorganizacion, "respuesta_reorganizacion"),
    });

    const respuestaModificacion = completion.choices[0].message.parsed;
    console.log("Respuesta de la IA:", respuestaModificacion);
    console.log("eventos pasados " + jsonEventos )

    return respuestaModificacion;
  } catch (error) {
    console.error("Error en reordenarEventosConIA:", error);
    if (error.response?.data?.refusal) {
      throw new Error(`La IA se negó a procesar la solicitud: ${error.response.data.refusal}`);
    }
    throw error;
  }
};

export const generarDetallePlanibot = async (tickets, fecha, criterioUsuario) => {
  console.log("Iniciando generarDetallePlanibot con:", { tickets, fecha, criterioUsuario });
  
  const openai = new OpenAI({
    apiKey: process.env.OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  try {
    console.log("Preparando llamada a OpenAI API");
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 4000,
      temperature: 1,
      messages: [
        {
          role: "system",
          content: "Eres un asistente de planificación inteligente optimizado para tareas y eventos de planificación en formato JSON. Debes mantener los IDs originales de las tareas existentes."
        },
        {
          role: "user",
          content: `Analiza las siguientes tareas y eventos para el día ${fecha}:

          y considera los siguientes criterios del usuario: ${criterioUsuario} al momento de optimizar la planificación.

          ${JSON.stringify(tickets)}

          Optimiza la planificación siguiendo estas pautas:
          1. Debes mantener estrictamente los horarios recibidos de las tareas aunque puedas sugerir reprogramaciones en el campo horaSugerida, pero en horaInicio y horaTermino manten el horario original. Solo puedes cambiar directamente la horaInicio y horaTermino de las tareas cuyo status es = "Pendiente", estas si puedes agregarlas donde creas que sea conveniente. Si status es "Proceso" sugiere reprogramaciones en bloques de tiempo libres en el campo horaSugerida.
          2. Prioriza y asigna estas tareas al comienzo del día, ordenándolas de mayor a menor prioridad.
          3. Considera tareas importantes aquellas que:
             - Están alineadas con las metas del usuario.
             - Tienen prioridad alta y urgencia clara.
             - Generarán un cambio significativo o resolverán problemas críticos.
             - Son dependientes de otras tareas, es decir deben realizarse primero para poder realizar otras cosas.
          4. Deja espacio para tareas inesperadas y descansos cortos.
          5. Identifica las horas más productivas del día y reserva esos bloques para las tareas más importantes y complejas.
          6. Agrupa tareas similares para reducir el cambio de contexto.
          7. Para tareas de aprendizaje, desarrollo personal, experimentación o reflexión, propón una rutina de 30 mins a 1 hora diaria.
          8. Cuando reprogrames tareas con status pendiente, asigna horaInicio y horaTermino que consideres más adecuadas para la tarea.
          9. Para las tareas sugeridas por la IA, incluye un campo "tipo" con el valor "sugerencia". Para las tareas del usuario con status "Proceso", usa "userTask". Para las tareas con status "Pendiente", usa "pendiente".
          10. Si hay tareas o eventos que coincidan en su hora de ejecución, sugiere reprogramarlas en el campo horaSugerida manteniendo en el horario original la que consideres más importante en función a ${criterioUsuario}.
          11. Considera que el horario está en 24 hrs, si el usuario fija una tarea en madrugada a las 3:00 am por ejemplo, o en un momento que afecte su sueño, propón una hora más adecuada y reprograma con horaSugerida.
          12. Si consigues que una tarea debe reprogramarse, manten la hora original que recibiste en el json en horaInicio y horaTermino, pero sugiere una nueva hora en el campo "horaSugerida". Pero siempre manten la horaInicio y horaTermino con la hora que recibiste estrictamente.
          13. En el campo "detalle" escribe las justificaciones y observaciones que consideres. Por ejemplo, si modificas una hora, explica porqué la cambiaste, si agregas una tarea, explica porqué la agregaste, y si hay una tarea que decides dejar en la misma hora, jusifica. Todo de forma muy resumida.
          14. Las tareas que agregues, deben estar alineadas al criterio del usuario que es: ${criterioUsuario}. si el usuario quiere ganar más dinero, puedes sugerir tareas que generen más dinero, sugiere cosas asociadas. También sugiere tareas que mejoren la vida del usuario, como hacer ejercicio, meditar, etc.
          15. Solo sugiere reprogramciones en bloques de tiempo libres.
          16. Para las tareas pendientes, sugiere horarios libres para realizarlas y asígnales una prioridad basada en su importancia y urgencia.
          17. Si identificas una tarea inapropiada, ilegal o peligrosa, sugiere su eliminación asignando el tipo "delete" y proporciona una breve explicación en el campo "detalle" independientemente de su status y prioridad.
          18. Si una tarea es delegable según la matriz de Eisenhower, sugiere en el detalle que se delegue.
          19. Considera el tiempo de traslado para las tareas, si es muy largo, sugerir una hora más temprana. Considéralo también entre tarea y tarea. por ejemplo si el usuario tiene que hacer algo a las 10:00 y dice que tardará una hora y luego debe ir a hacer otra tarea que implica traslado, sugiere considerar ese tiempo en su calendario.
          20. Si detectas un bloque libre de tiempo largo, sugiere una actividad como "¡Tiempo Libre!". y asigna el horario que tenga libre pero hasta las 22:00
          21. Para efectos de planificación, considera que el usuario tiene un descanso de 1 hora para almorzar, asígnale un horario para ello al medio día.
          22. Nunca superes el horario de 22:00 para reorganizar o agendar tareas.
          23. Siempre sugiere reprogramar tareas que están asignadas a la madrugada a un horario más conveniente, por ejemplo 7:00 am.
          24. Usa la lógica para deducir si hay tareas que no tienen sentido el horario. Por ejemplo ir al mecánico a las 2:00 am, los mecánicos trabajando desde las 8:00am, sugiere reprogramar.
          25. No puedes reprogramar tareas donde la propiedad canEdit= False, es decir, no puedes reprogramar tareas que el usuario no puede editar.
          26. No consideres la fecha de vencimiento para nada. Ya que las tareas se pueden ejecutar en cualquier fecha.
          27. Mantén el ID original de las tareas existentes. No modifiques ni generes nuevos IDs para las tareas existentes.
          28. Para las nuevas tareas sugeridas (si las hay), usa un ID temporal como "new-1", "new-2", etc.
          29. Si identificas una tarea inapropiada, ilegal o peligrosa, sugiere su eliminación asignando el tipo "delete" y proporciona una breve explicación en el campo "detalle". Mantén el ID original de estas tareas.
          30. Mientras menos tareas tenga el usuario en el día, sugiere más tareas para lograr las metas del usuario. Si tiene muchas tareas, evita sugerir nuevas.
          Devuelve el resultado como un objeto JSON con la siguiente estructura:
          {
            "detalles": [
              {
                "id": "string",
                "horaInicio": "HH:MM",
                "horaTermino": "HH:MM",
                "evento": "Nombre del evento o tarea",
                "detalle": "Descripción detallada y recomendaciones",
                "priority": "Alta/Media/Baja",
                "tipo": "sugerencia/userTask/pendiente/delete",
                "horaSugerida": "HH:MM" (opcional, solo si se sugiere reprogramar)
              },
              ...
            ]
          }`
        }
      ],
      response_format: {
        type: "json_object"
      }
    });

    console.log("Respuesta recibida de OpenAI API");

    let jsonResponse = completion.choices[0].message;

    if (!jsonResponse || !jsonResponse.content) {
      console.error("La respuesta de la IA no contiene la estructura esperada:", jsonResponse);
      throw new Error("Estructura JSON inesperada en la respuesta de la IA.");
    }

    console.log("Contenido de la respuesta:", jsonResponse.content);

    // Parsear el contenido JSON del string a un objeto
    try {
      jsonResponse = JSON.parse(jsonResponse.content);
      console.log("JSON parseado correctamente:", jsonResponse);
    } catch (parseError) {
      console.error("Error al parsear la respuesta JSON:", parseError);
      throw new Error("Error al parsear la respuesta JSON de la IA.");
    }

    // Asegurarse de que la respuesta sea un objeto válido con el array de detalles
    if (!jsonResponse || !Array.isArray(jsonResponse.detalles)) {
      console.error("Estructura de respuesta inválida:", jsonResponse);
      throw new Error("La respuesta de la IA no contiene un array válido de detalles.");
    }

    console.log("Detalles generados:", jsonResponse.detalles);
    return jsonResponse.detalles;
  } catch (error) {
    console.error("Error en generarDetallePlanibot:", error);
    if (error.response) {
      console.error("Detalles del error de la API:", error.response.data);
    }
    throw error;
  }
};

// Crear un array para almacenar el historial de la conversación
const conversationHistory = [];

// Definir el esquema de respuesta usando Zod
const RespuestaSchema = z.object({
  respuesta: z.string().describe("Mensaje de respuesta al usuario"),
  requiereConfirmacion: z.boolean().describe("Indica si se necesita confirmación del usuario"),
  solicitud_identificada: z.union([
    z.array(z.string()),
    z.null()
  ]).describe("Lista de solicitudes identificadas o null si no hay"),
  usar_workspace: z.boolean().describe("Indica si la tarea requiere un espacio de trabajo creativo")
});

export const responderUsuario = async (mensaje, contexto, eventosJson, fechaHoraActual) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  // Parsear los eventos del JSON
  let eventos = [];
  try {
    eventos = JSON.parse(eventosJson);
  } catch (error) {
    console.error("Error al parsear eventos:", error);
  }

  const mensajesContexto = [];

  // Añadir el mensaje del sistema
  mensajesContexto.push({
    role: "system",
    content: `Eres un asistente divertido y juvenil de calendario llamado Planibot. Tu tarea es interpretar las solicitudes del usuario y responder adecuadamente.
    Hoy es ${fechaHoraActual} por lo que si te preguntan por eventos de hoy, debes comparar con la fecha actual y si no coinciden entonces indica que no hay eventos de hoy.
    Si el usuario pregunta por sus eventos, debes mostrarlos en la respuesta incluyéndolos en el campo "eventos" del JSON.
    Si detectas una solicitud relacionada con modificar el calendario, como agregar eventos, debes interpretarla, y debes preguntar al usuario si desea proceder con la modificación.
    Si te piden escoger la hora de un evento tienes no puedes asignarla en un horario pasado, por lo que debes comparar con la hora actual.
    Si el usuario te pide que escojas la hora de un evento, trata de considerar cuánto puede tardar esa tarea, y considera si necesita traslado o preparación previa, y justifícalo en la respuesta, por ejemplo ir al doctor, puedes preguntar qué tan lejos estás del doctor, el usuario te responde 1 hora, entonces consideras agregar un evento adicional llamado traslado al doctor
    Si el usuario te pide que agregues un evento, debes escribirlo en solicitud_identificada, puede ser un sólo evento o múltiples, incluyendo fecha y hora.
    Si ya existe un evento con la misma fecha y hora, sugiere reprogramar el evento, si el usuario te insiste, entonces créalo, si no sugiere una hora distinta que no solape.
    Si te piden crear un evento trata de escoger el nombre más adecuado y sugerente de forma resumida, por ejemplo "crea una reunión con los directores de la empresa", nombre sugerido "Reunión de Directorio"
    Si te piden rutinas o eventos específicos, compórtate como un experto en el tema solicitado. Por ejemplo si te piden rutina de ejercicio, entonces sugiere una rutina de ejercicio coherente como lo haría un entrenador y distribúyela en el momento adecuado, por ejemplo "lunes 10 minutos de pierna, martes 10 minutos de pecho, etc".
    Si te preguntan por actividades de ayer o tiempo pasado, responde que no tienes accesso al pasado
    en la respuesta nunca debes responder con los eventos o las rutinas, simplemente debes responder "He creado lo que solicitas" o alguna frase divertida como "oki doki", "Eso estaba listo antes de que lo pidieras" acompañado de algún emoji, pero el resultado debe ir en solicitud identificada.
    Evita solapar horarios, si existen dos eventos que se solapan, sugiere reprograr el que se está creando a un horario disponible a menos que el usuario insista en que se cree en ese horario.
    Siempre que crees algo, un evento o algo, debes mostrar requiereConfirmacioewfn = true
    La respuesta debe estar en formato JSON con la siguiente estructura:
    {
      "respuesta": "Mensaje al usuario",
      "requiereConfirmacion": boolean,
      "solicitud_identificada": en texto plano, si son varios eventos separalos e identifica la hora de inicio, termino y fecha, o si no hay responde null,
      "usar_workspace": boolean,
      "eventos": array de eventos estrictamente si el usuario pregunta por eventos que ya existen en el calendario como por ejemplo "eventos de hoy", "que eventos tengo para mañana", sino devuelve null
    }`
  });

  // Añadir el contexto de eventos y hora actual
  if (fechaHoraActual && eventosJson) {
    mensajesContexto.push({
      role: "system",
      content: `La hora actual es ${fechaHoraActual}. Los eventos actuales del usuario son: ${eventosJson}`
    });
  }

  // Añadir el historial de conversación
  if (contexto) {
    const mensajesAnteriores = contexto.split('\n').filter(msg => msg.trim());
    mensajesAnteriores.forEach(msg => {
      mensajesContexto.push({ role: "user", content: msg });
    });
  }

  // Añadir el mensaje actual
  mensajesContexto.push({ role: "user", content: mensaje });

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-2024-08-06",
      messages: mensajesContexto,
      response_format: { type: "json_object" },
      temperature: 0.7,
      max_tokens: 500
    });

    const content = completion.choices[0].message.content;
    console.log("Respuesta cruda de la IA:", content);

    let parsedContent;
    try {
      parsedContent = JSON.parse(content);

      // Si el usuario pregunta por eventos
      if (mensaje.toLowerCase().includes("eventos") || 
          mensaje.toLowerCase().includes("agenda") || 
          mensaje.toLowerCase().includes("calendario")) {
        
        // Si pregunta específicamente por "hoy", filtrar solo eventos de hoy
        if (mensaje.toLowerCase().includes("hoy")) {
          const hoy = moment().format("DD-MM-YYYY");
          const eventosHoy = eventos.filter(evento => 
            evento.vencimiento === hoy
          );
          if (eventosHoy.length > 0) {
            parsedContent.eventos = eventosHoy;
          }
        } 
        // Si pregunta por la semana o no especifica, mostrar todos los eventos
        else if (mensaje.toLowerCase().includes("semana") || 
                 mensaje.toLowerCase().includes("rutina") ||
                 mensaje.toLowerCase().includes("próximos días")) {
          parsedContent.eventos = eventos;
        }
      }
    } catch (parseError) {
      console.error("Error al parsear la respuesta JSON:", parseError);
      return {
        respuesta: "Lo siento, hubo un error al procesar tu solicitud.",
        requiereConfirmacion: false,
        solicitud_identificada: null,
        usar_workspace: false,
        eventos: []
      };
    }

    // Asegurarse de que todos los campos necesarios estén presentes
    const respuesta = {
      respuesta: parsedContent.respuesta || "Lo siento, no pude entender tu solicitud.",
      requiereConfirmacion: parsedContent.requiereConfirmacion === true,
      solicitud_identificada: Array.isArray(parsedContent.solicitud_identificada) ? 
        parsedContent.solicitud_identificada : 
        (parsedContent.solicitud_identificada ? [parsedContent.solicitud_identificada] : null),
      usar_workspace: parsedContent.usar_workspace === true,
      eventos: parsedContent.eventos || []
    };

    // Añadir la respuesta al historial
    conversationHistory.push({
      role: "assistant",
      content: respuesta.respuesta
    });

    return respuesta;

  } catch (error) {
    console.error("Error en responderUsuario:", error);
    return {
      respuesta: "Lo siento, hubo un error al procesar tu solicitud.",
      requiereConfirmacion: false,
      solicitud_identificada: null,
      usar_workspace: false,
      eventos: []
    };
  }
};







