import React, { useState, useEffect, useContext, useCallback } from "react";
import { AuthContext } from "../../context/AuthContext.js";
import { enterFullScreen, exitFullScreen } from "../Functions.js";
import CalendarDia from "./CalendarDia.jsx";
import CalendarSemana from "./CalendarSemana.jsx";
import CalendarMes from "./CalendarMes.jsx";
import CalendarHeader from "./CalendarHeader.jsx";
import { reordenarEventosConIA } from "../IA/Gpt";
import { sincronizarDesdeGoogleCalendar } from "./FunctionsCalendar.js";
import "./CalendarioTickets.css";
import { meses } from "./CalendarMes.jsx";
import { getTicketsCalendar } from "../Functions.js";
import moment from "moment-timezone";

function CalendarioTickets() {
  const obtenerDiaSemana = (dia, mes, anio) => {
    const fecha = new Date(anio, mes, dia);
    const diaSemana = fecha.getDay();
    return diaSemana === 0 ? 6 : diaSemana - 1;
  };

  const [fechaActual, setFechaActual] = useState(new Date());
  const [tickets, setTickets] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { category, department, businessEmail, timeZone, accessToken, refreshToken, tokenExpiry } = useContext(AuthContext);
  const [vista, setVista] = useState(() => {
    return window.innerWidth <= 768 ? "dia" : "semana";
  });
  const [modoOscuro, setModoOscuro] = useState(false);
  const [isTabsVisible, setIsTabsVisible] = useState(false);
  const [tareasPendientes, setTareasPendientes] = useState(0);
  const coloresTicket = [
    "#5a67d8", "#4c51bf", "#7f9cf5", "#ff8c00", "#f6ad55",
  ];

  const [modoZen, setModoZen] = useState(false);

  const [mesActual, setMesActual] = useState(fechaActual.getMonth());
  const [anioActual, setAnioActual] = useState(fechaActual.getFullYear());
  const [diaActual, setDiaActual] = useState(fechaActual.getDate());
  const [semanaActual, setSemanaActual] = useState(() => {
    const fecha = new Date(fechaActual);
    const primerDiaDelMes = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
    const diaSemana = primerDiaDelMes.getDay();
    const ajustePrimerLunes = diaSemana === 0 ? -6 : 1 - diaSemana;
    const primerLunes = new Date(primerDiaDelMes);
    primerLunes.setDate(primerLunes.getDate() + ajustePrimerLunes);
    
    const diff = Math.floor((fecha - primerLunes) / (7 * 24 * 60 * 60 * 1000));
    return diff + 1;
  });

  const toggleDarkMode = () => {
    setModoOscuro(prevMode => {
      const newMode = !prevMode;
      if (newMode) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
      return newMode;
    });
  };

  const toggleTabs = useCallback(() => {
    setIsTabsVisible(prevVisible => !prevVisible);
  }, []);

  const manejarActualizacionTareasPendientes = useCallback((cantidad) => {
    setTareasPendientes(cantidad);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setFechaActual(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (category && businessEmail && currentUser && currentUser.email) {
      console.log('Cargando tickets con parámetros:', {
        category,
        businessEmail,
        department,
        userEmail: currentUser.email
      });
      
      const unsubscribe = getTicketsCalendar(
        category, 
        businessEmail, 
        department, 
        currentUser.email, 
        (newTickets) => {
          console.log('Tickets cargados:', newTickets);
          setTickets(newTickets);
        }
      );
      return () => unsubscribe();
    }
  }, [category, businessEmail, department, currentUser]);

  useEffect(() => {
    const actualizarFechaHora = () => {
      // Eliminamos la línea que actualiza fechaHoraActual
      // const now = moment().tz(timeZone);
      // setFechaHoraActual(now.format("YYYY-MM-DD HH:mm:ss"));
    };
    actualizarFechaHora();
    const timer = setInterval(actualizarFechaHora, 1000);
    return () => clearInterval(timer);
  }, [timeZone]);

  const handleDayClick = (dia, mes = mesActual, anio = anioActual) => {
    setDiaActual(dia);
    setMesActual(mes);
    setAnioActual(anio);
    setVista("dia");
  };

  const cambiarVista = (e) => setVista(e.target.value);

  const cambiarFecha = (direccion) => {
    if (vista === "dia") {
      const nuevaFecha = new Date(anioActual, mesActual, diaActual + direccion);
      setDiaActual(nuevaFecha.getDate());
      setMesActual(nuevaFecha.getMonth());
      setAnioActual(nuevaFecha.getFullYear());
    } else if (vista === "semana") {
      const nuevaFecha = new Date(
        anioActual,
        mesActual,
        diaActual + direccion * 7
      );
      setDiaActual(nuevaFecha.getDate());
      setMesActual(nuevaFecha.getMonth());
      setAnioActual(nuevaFecha.getFullYear());
      setSemanaActual(
        Math.ceil(
          (nuevaFecha.getDate() +
            obtenerDiaSemana(
              1,
              nuevaFecha.getMonth(),
              nuevaFecha.getFullYear()
            ) -
            1) /
          7
        )
      );
    } else if (vista === "mes") {
      if (direccion === -1) {
        if (mesActual === 0) {
          setMesActual(11);
          setAnioActual(anioActual - 1);
        } else {
          setMesActual(mesActual - 1);
        }
      } else {
        if (mesActual === 11) {
          setMesActual(0);
          setAnioActual(anioActual + 1);
        } else {
          setMesActual(mesActual + 1);
        }
      }
    }
  };

  const toggleZenMode = () => {
    if (!modoZen) {
      enterFullScreen(document.documentElement);
    } else {
      exitFullScreen();
    }
    setModoZen(!modoZen);
  };

  const handleSyncGoogleCalendar = async () => {
    await sincronizarDesdeGoogleCalendar(businessEmail, currentUser, accessToken, refreshToken, tokenExpiry);
  };

  const renderCalendarView = () => {
    const commonProps = {
      tickets,
      coloresTicket,
      cambiarFecha,
      setTickets,
      timeZone,
      reordenarEventosConIA,
      modoOscuro,
      isTabsVisible,
      manejarActualizacionTareasPendientes,
      businessEmail,  // Asegúrate de pasar businessEmail
    };
    

    switch (vista) {
      case "dia":
        return (
          <div className={`calendar-view calendar-view-dia ${vista === "dia" ? "active" : ""}`}>
            <CalendarDia
              diaActual={diaActual}
              mesActual={mesActual}
              anioActual={anioActual}
              {...commonProps}
            />
          </div>
        );
      case "semana":
        return (
          <div className={`calendar-view calendar-view-semana ${vista === "semana" ? "active" : ""}`}>
            <CalendarSemana
              diaActual={diaActual}
              mesActual={mesActual}
              anioActual={anioActual}
              semanaActual={semanaActual}
              handleDayClick={handleDayClick}
              {...commonProps}
            />
          </div>
        );
      case "mes":
        return (
          <div className={`calendar-view calendar-view-mes ${vista === "mes" ? "active" : ""}`}>
            <CalendarMes
              mesActual={mesActual}
              anioActual={anioActual}
              {...commonProps}
            />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setVista(window.innerWidth <= 768 ? "dia" : "semana");
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const irAHoy = () => {
    const hoy = moment().tz(timeZone);
    setDiaActual(hoy.date());
    setMesActual(hoy.month());
    setAnioActual(hoy.year());
  };

  return (
    <div className={`
      ${vista === "mes" ? "calendario" : "calendario"}
      ${modoZen ? "modo-zen" : ""} 
      ${modoOscuro ? "dark-mode" : ""}
    `}>
      <CalendarHeader
        meses={meses}
        mesActual={mesActual}
        anioActual={anioActual}
        diaActual={diaActual}
        vista={vista}
        modoZen={modoZen}
        modoOscuro={modoOscuro}
        cambiarVista={cambiarVista}
        cambiarFecha={cambiarFecha}
        toggleZenMode={toggleZenMode}
        toggleDarkMode={toggleDarkMode}
        onSyncGoogleCalendar={handleSyncGoogleCalendar}
        toggleTabs={toggleTabs}
        tareasPendientes={tareasPendientes}
        isTabsVisible={isTabsVisible}
        irAHoy={irAHoy}
      />
      
      {renderCalendarView()}
    </div>
  );
}

export default CalendarioTickets;
