import React from 'react';
import moment from 'moment-timezone';
import ResizableDraggableTicket from '../ResizableDraggableTicket';

const CalendarCell = ({
  index,
  fecha,
  hour,
  handleCellClick,
  hoveredCell,
  setHoveredCell,
  handleDragOverCell,
  draggedOverCell,
  selectedDateTime,
  draggingTicket,
  gruposTickets,
  gridSize,
  handleTicketClick,
  currentCellTime,
  setDraggingTicket,
  handleDragStart,
  handleDragEnd,
  onResizeStop,
  today
}) => {
  const dia = fecha.getDate();
  const mes = fecha.getMonth();
  const anio = fecha.getFullYear();
  const isToday = today.isSame(moment(fecha).startOf("day"));

  const isDraggingOverThisCell = draggedOverCell &&
    draggedOverCell.dia === dia &&
    parseInt(draggedOverCell.time.split(":")[0], 10) === hour &&
    !draggedOverCell.isAllDay;

  const handleCellClickWrapper = (e) => {
    if (e.target.closest('.react-resizable-handle')) {
      e.stopPropagation();
      return;
    }
    
    handleCellClick(e, hour, dia, mes, anio);
  };

  return (
    <td
      key={index}
      onClick={handleCellClickWrapper}
      onMouseEnter={() => setHoveredCell(`${dia}-${hour}`)}
      onMouseLeave={() => setHoveredCell(null)}
      onDragOver={(e) => handleDragOverCell(e, dia, mes, anio, hour, false)}
      className={
        isDraggingOverThisCell
          ? "calendarioSemanal__dragged-over-cell"
          : hoveredCell === `${dia}-${hour}`
            ? "calendarioSemanal__hovered-cell"
            : ""
      }
      style={{ position: 'relative' }}
    >
      <div className="calendarioSemanal__ticket-container" style={{ position: 'relative', height: `${gridSize}px` }}>
        {gruposTickets.map((grupo, grupoIndex) => (
          grupo.map((ticketParaHora, ticketIndex) => {
            const inicio = moment(ticketParaHora.horaInicio, "HH:mm");
            if (inicio.hours() !== hour) return null;
            const fin = moment(ticketParaHora.horaTermino, "HH:mm");
            const rowSpan = Math.ceil((fin.hours() * 60 + fin.minutes() - (inicio.hours() * 60 + inicio.minutes())) / 60);
            const width = `${100 / grupo.length}%`;
            
            return (
              <div
                key={ticketParaHora.id}
                style={{
                  width,
                  height: rowSpan * gridSize,
                  position: "absolute",
                  top: `${(inicio.minutes() / 60) * 100}%`,
                  left: `${(ticketIndex / grupo.length) * 100}%`,
                }}
              >
                <ResizableDraggableTicket
                  ticket={ticketParaHora}
                  onResizeStop={onResizeStop}
                  onClick={(e) => handleTicketClick(ticketParaHora, e)}
                  currentCellTime={currentCellTime}
                  style={{
                    backgroundColor: ticketParaHora.color,
                    height: "100%",
                    width: "100%",
                    borderLeft: "4px solid",
                    borderTop: "0.5px solid",
                  }}
                  setDraggingTicket={setDraggingTicket}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  isOverlapping={grupo.length > 1} // 
                />
              </div>
            );
          })
        ))}
        {isToday && hour === moment().hours() && (
          <div
            className="current-time-line"
            style={{
              top: `${(moment().minutes() / 60) * 100}%`,
              position: "absolute",
              left: 0,
              right: 0,
              height: "2px",
              backgroundColor: "rgba(255, 0, 0, 0.656)",
            }}
          />
        )}
      </div>
      {isDraggingOverThisCell && draggingTicket && selectedDateTime && (
        <div
          style={{
            position: 'absolute',
            top: `${selectedDateTime.time.endsWith(":30") ? gridSize / 2 : 0}px`,
            left: 0,
            right: 0,
            height: `${selectedDateTime.time.endsWith(":30") ? gridSize / 2 : gridSize}px`,
            pointerEvents: 'none',
            zIndex: 10,
          }}
        >
          <ResizableDraggableTicket
            ticket={draggingTicket}
            isPlaceholder={true}
          />
        </div>
      )}
    </td>
  );
};

export default CalendarCell; 