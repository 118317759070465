import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ImageUploader from "./ImageUploader";
import { auth, db, storage } from "./firebase";
import logoLogin from "./img/logo-login.png";
import "./register.css";
import google from "./img/google.png";

const emailSchema = yup
  .object({
    email: yup
      .string()
      .required("El email es obligatorio")
      .email("Debe ser un email válido"),
  })
  .required();

const userSchema = yup
  .object({
    name: yup.string().required("El nombre es obligatorio"),
    password: yup
      .string()
      .required("La contraseña es obligatoria")
      .matches(
        /^(?=.*[A-Z])(?=.*[!_@.#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
        "La contraseña debe contener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 símbolo especial"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir"),
  })
  .required();

const RegistroUsuarios = () => {
  const navigate = useNavigate();
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(isEmailChecked ? userSchema : emailSchema),
  });

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Planius AI - Registro";

    return () => {
      document.title = originalTitle;
    };
  }, []);

  const checkEmail = async (data) => {
    setLoading(true);
    const { email } = data;
    clearErrors("email");
    setEmail(email);
    setIsEmailChecked(true);
    setLoading(false);
  };

  const signInWithGoogle = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/calendar");
    provider.addScope("https://www.googleapis.com/auth/calendar.events");

    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const refreshToken = result.user.refreshToken;

      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      const expirationTime = Date.now() + 60 * 60 * 1000; // Token expiration time set to 1 hour
      sessionStorage.setItem("tokenExpirationTime", expirationTime);

      const user = result.user;
      const { displayName, email, photoURL } = user;

      // Obtener la zona horaria del usuario
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      await setDoc(
        doc(db, "users", email),
        {
          uid: user.uid,
          displayName,
          email,
          photoURL,
          userType: "standard",
          status: "activo",
          date: new Date(),
          name: displayName,
          timezone, // Guardar la zona horaria en Firestore
          initView: "calendario",
        },
        { merge: true }
      );

      navigate("/selectBusiness");
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error);
      alert("Error al iniciar sesión con Google.");
    }

    setLoading(false);
  };

  const registerUser = async (data) => {
    setLoading(true);
    const { name, password } = data;
    const defaultImageUrl = "https://app.planius.ai/static/media/default_user.ccc3d6213b93d4be8013.jpg"; // Define la URL de la imagen por defecto

    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        throw new Error("El correo electrónico ya se encuentra registrado");
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      let finalImageURL = defaultImageUrl;
      if (file) {
        const storageRef = ref(storage, `/${user.uid}`);
        const uploadTask = await uploadBytesResumable(storageRef, file);
        finalImageURL = await getDownloadURL(uploadTask.ref);
      }

      await updateProfile(user, {
        displayName: name,
        photoURL: finalImageURL,
      });

      // Obtener la zona horaria del usuario
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      await setDoc(
        doc(db, "users", email),
        {
          uid: user.uid,
          displayName: name,
          email,
          photoURL: finalImageURL,
          userType: "standard",
          status: "activo",
          date: new Date(),
          timezone, // Guardar la zona horaria en Firestore
          initView: "calendario",
        },
        { merge: true }
      );

      await sendEmailVerification(user);

      alert(
        "Registro exitoso. Por favor, verifica tu correo electrónico antes de iniciar sesión."
      );
      navigate("/login");
    } catch (error) {
      console.error("Error al registrar usuario:", error);
      alert(error.message);
    }

    setLoading(false);
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <img className="logoLogin" src={logoLogin} alt="" />
        <span className="titleLogin">Registrar Usuario</span>
        <span
          style={{
            fontSize: "12px",
            textAlign: "center",
            padding: "calc(100vh/150)",
          }}
        >
          Ingresa los datos de usuario
        </span>

        <form
          onSubmit={handleSubmit(isEmailChecked ? registerUser : checkEmail)}
        >
          {!isEmailChecked && (
            <div className="">
              <label htmlFor="email">Ingresa tu email:</label>
              <input {...register("email")} placeholder="Email" />
              {errors.email && <p className="errRed">{errors.email.message}</p>}
              <div className="buttonContainer">
                <button type="submit" className="centeredButton">Continuar con el Registro</button>
              </div>
              <div className="separator">
                <span className="line"></span>
                <span className="text">o</span>
                <span className="line"></span>
              </div>
              <div className="buttonContainer">
                <button
                  type="button"
                  onClick={signInWithGoogle}
                  className="centeredButton googleButton"
                >
                  <span className="btnContent">
                    <img className="googleLogo" src={google} alt="Google" />
                    <span className="googleText">Continuar con Google</span>
                  </span>
                </button>
              </div>
            </div>
          )}

          {isEmailChecked && (
            <>
              <div>
                <label htmlFor="name">Nombre y Apellido</label>
                <input {...register("name")} placeholder="Nombre y Apellido" />
                {errors.name && <p className="errRed">{errors.name.message}</p>}
              </div>
              <div>
                <label htmlFor="password">Contraseña</label>
                <input
                  {...register("password")}
                  type="password"
                  placeholder="Contraseña"
                />
                {errors.password && (
                  <p className="errRed">{errors.password.message}</p>
                )}
              </div>
              <div>
                <label htmlFor="confirmPassword">Confirmar contraseña</label>
                <input
                  {...register("confirmPassword")}
                  type="password"
                  placeholder="Confirmar contraseña"
                />
                {errors.confirmPassword && (
                  <p className="errRed">{errors.confirmPassword.message}</p>
                )}
              </div>
              <div>
                <label htmlFor="avatar">Foto de Perfil</label>
                <ImageUploader onImageChange={setFile} />
              </div>
              <div className="buttonContainer">
                <button type="submit" className="centeredButton">Registrar</button>
              </div>
            </>
          )}
        </form>

        {loading && (
          <div className="centerSpinner">
            <div className="spinner"></div>
            <p>Cargando...</p>
          </div>
        )}
      </div>
    </div>
  );

};

export default RegistroUsuarios;
