import React from 'react';
import './Planes.css';

const Planes = ({ prevStep, nextStep, handleChange, formData }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="formStep">
      <h2>Escoja un plan:</h2>
      <form onSubmit={handleSubmit}>
        <div className="plansContainer">
          <label className={`plan ${formData.plan === 'free' ? 'selected' : ''}`}>
            <input 
              type="radio" 
              value="free" 
              checked={formData.plan === 'free'} 
              onChange={handleChange('plan')} 
            />
            <div className="planDetails">
              <h3>Free</h3>
              <p>Integración de IA limitada</p>
            </div>
          </label>
          <label className={`plan ${formData.plan === 'professional' ? 'selected' : ''}`}>
            <input 
              type="radio" 
              value="professional" 
              checked={formData.plan === 'professional'} 
              onChange={handleChange('plan')} 
            />
            <div className="planDetails">
              <h3>Profesional</h3>
              <p className="price">$12 / mes</p>
              <p>Máxima integración de IA</p>
            </div>
          </label>
          <label className={`plan ${formData.plan === 'teams' ? 'selected' : ''}`}>
            <input 
              type="radio" 
              value="teams" 
              checked={formData.plan === 'teams'} 
              onChange={handleChange('plan')} 
            />
            <div className="planDetails">
              <h3>Teams</h3>
              <p className="price">$18 / mes</p>
              <p>Todo lo anterior y permite agregar equipos a proyectos</p>
            </div>
          </label>
        </div>
        <div className="formActions">
          <button type="button" className="formButton" onClick={prevStep}>Volver</button>
          <button 
            type="submit" 
            className="formButton" 
            disabled={!formData.plan}
          >
            Terminar registro
          </button>
        </div>
      </form>
    </div>
  );
};

export default Planes;