import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import "./selectArea.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faFutbol,
  faFilm,
  faChartLine,
  faCalculator,
  faBriefcase,
  faUsers,
  faUserTie,
  faChartBar,
  faFile,
  faDatabase,
  faCode,
  faHandshake,
  faTasks,
  faDollarSign,
  faBullhorn,
  faUserCircle,
  faHeadset,
  faTruck,
  faCalendar,
  faFaceSmile,
} from "@fortawesome/free-solid-svg-icons";

const iconMap = {
  libros: faBook,
  deportes: faFutbol,
  peliculas: faFilm,
  finanzas: faChartLine,
  contabilidad: faCalculator,
  negocios: faBriefcase,
  personas: faUsers,
  recursosHumanos: faUserTie,
  graficos: faChartBar,
  archivos: faFile,
  baseDeDatos: faDatabase,
  programacion: faCode,
  reuniones: faHandshake,
  proyectos: faTasks,
  ventas: faDollarSign,
  marketing: faBullhorn,
  cliente: faUserCircle,
  soporte: faHeadset,
  envios: faTruck,
  calendario: faCalendar,
};

function getIconByDescription(description) {
  return iconMap[description] || faFaceSmile;
}

function Areas(props) {
  const [areas, setAreas] = useState([]);
  const { updateBusinessContext } = useContext(AuthContext);
  const navigate = useNavigate();
  const userType = sessionStorage.getItem("userType");

  const handleAreaClick = async (area) => {
    sessionStorage.setItem("area", area.name);
    const newBusinessID = sessionStorage.getItem("businessId");
    navigate("/gestionarTickets");
    try {
      await updateBusinessContext(newBusinessID, area.name);
    } catch (error) {}
  };

  useEffect(() => {
    if (props.areas) {
      setAreas(props.areas);
    }
  }, [props.areas]);

  useEffect(() => {
    if (userType === "cliente" && areas.length === 0) {
      const handleNavigate = async () => {
        const areaName = "General";
        sessionStorage.setItem("area", areaName);
        const newBusinessID = sessionStorage.getItem("businessId");
        try {
          navigate("/gestionarTickets");
          await updateBusinessContext(newBusinessID, areaName);
        } catch (error) {}
      };
      handleNavigate();
    }
  }, [userType, areas.length, navigate, updateBusinessContext]);

  const handleNavigate = async (path, areaName) => {
    sessionStorage.setItem("area", areaName);
    const newBusinessID = sessionStorage.getItem("businessId");
    try {
      navigate(path);
      await updateBusinessContext(newBusinessID, areaName);
    } catch (error) {}
  };

  return (
    <div className="boardsContainer">
      {areas.length > 0 ? (
        <>
          {areas.map((area, index) => (
            <div key={index}>
              <div className="board" onClick={() => handleAreaClick(area)}>
                <div className="boardIcon">
                  <FontAwesomeIcon icon={getIconByDescription(area.icon)} />
                </div>
              </div>
              <div className="boardTitle">{area.name}</div>
            </div>
          ))}
          <div key="allAreasBoard">
            <div
              className="board allAreasBoard"
              onClick={() => handleNavigate("/gestionarTickets", "General")}
            ></div>
            <div className="boardTitle">Todas las áreas</div>
          </div>
        </>
      ) : (
        <>
          {userType === "admin" && (
            <div key="addNewBoard">
              <div
                className="board addNewBoard"
                onClick={() => handleNavigate("/areas", "General")}
              ></div>
              <div className="boardTitle">Agregar Área</div>
            </div>
          )}
          <div key="allAreasBoard">
            <div
              className="board allAreasBoard"
              onClick={() => handleNavigate("/gestionarTickets", "General")}
            ></div>
            <div className="boardTitle">Todas las áreas</div>
          </div>
        </>
      )}
    </div>
  );
}

export default Areas;
