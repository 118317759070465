import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { db } from '../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import './PopUp.css';
import moment from 'moment-timezone';
import { RefreshCcw } from 'lucide-react';

const MoveToCalendarPlani = ({ isOpen, onClose, detalle, businessEmail, fecha, onTaskUpdated }) => {
  const { currentUser } = useContext(AuthContext);

  const [titulo, setTitulo] = useState('');
  const [todoElDia, setTodoElDia] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [horaInicio, setHoraInicio] = useState('');
  const [horaTermino, setHoraTermino] = useState('');
  const [fechaVencimiento, setFechaVencimiento] = useState('');

  useEffect(() => {
    if (detalle) {
      setTitulo(detalle.evento || '');
      setTodoElDia(detalle.horaInicio === 'Todo el día');
      setHoraInicio(detalle.horaInicio === 'Todo el día' ? '' : detalle.horaInicio);
      setHoraTermino(detalle.horaTermino === 'Todo el día' ? '' : detalle.horaTermino);
      setFechaVencimiento(moment(fecha).format('YYYY-MM-DD'));
    }
  }, [detalle, fecha]);

  const handleMoveToCalendar = async () => {
    setLoadingTasks(true);
    setSuccessMessage('Moviendo tarea al calendario');
    setErrorMessage('');

    try {
      if (!detalle || !detalle.id || !businessEmail) {
        throw new Error('Faltan datos necesarios para actualizar la tarea');
      }

      console.log('Intentando actualizar tarea:', {
        businessEmail,
        tareaId: detalle.id,
        ruta: `business/${businessEmail}/tickets/${detalle.id}`,
        nuevaFecha: moment(fecha).format('DD-MM-YYYY')
      });

      const tareaRef = doc(db, "business", businessEmail, "tickets", detalle.id);
      const tareaDoc = await getDoc(tareaRef);

      if (!tareaDoc.exists()) {
        throw new Error(`No se encontró la tarea con ID: ${detalle.id}`);
      }

      await updateDoc(tareaRef, {
        status: 'Proceso',
        lastEditBy: currentUser.email,
        lastEditDate: moment().tz("America/Santiago").format('DD-MM-YYYY'),
        vencimiento: moment(fecha).format('DD-MM-YYYY'),
        fechaFin: moment(fecha).format('DD-MM-YYYY'),
        fechaVencimiento: moment(fecha).format('DD-MM-YYYY'),
        horaInicio: detalle.horaInicio || 'Todo el día',
        horaTermino: detalle.horaTermino || 'Todo el día'
      });

      setSuccessMessage('Tarea movida al calendario exitosamente');
      setTimeout(() => {
        setLoadingTasks(false);
        if (onTaskUpdated) onTaskUpdated();
        onClose();
      }, 1000);

    } catch (error) {
      console.error('Error detallado al mover la tarea:', error);
      setErrorMessage(
        `Error al mover la tarea al calendario: ${error.message || 'Error desconocido'}`
      );
      setLoadingTasks(false);
    }
  };

  return (
    <Modal
      overlayClassName="popupBackground"
      className="popupContainer"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Mover Tarea al Calendario</h2>
      </div>

      <div className="popupContent">
        <div className="popupField">
          <label className="popupLabel">Título</label>
          <input
            type="text"
            value={titulo}
            disabled
            className="popupInput"
          />
        </div>

        <div className="popupField">
          <label className="popupLabel">Fecha</label>
          <input
            type="date"
            value={fechaVencimiento}
            disabled
            className="popupInput"
          />
        </div>

        <div className="popupField">
          <label>
            <input
              type="checkbox"
              checked={todoElDia}
              disabled
            />
            ¿Todo el día?
          </label>
        </div>

        {!todoElDia && (
          <>
            <div className="popupField">
              <label className="popupLabel">Hora de Inicio</label>
              <input
                type="time"
                value={horaInicio}
                disabled
                className="popupInput"
              />
            </div>
            <div className="popupField">
              <label className="popupLabel">Hora de Término</label>
              <input
                type="time"
                value={horaTermino}
                disabled
                className="popupInput"
              />
            </div>
          </>
        )}

        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}

        {successMessage && (
          <div className="success-message">
            {successMessage}
            {loadingTasks && (
              <div className="loadingSpinner">
                <RefreshCcw size={24} className="spinner" />
              </div>
            )}
          </div>
        )}

        <div className="center" style={{display:"flex", flexDirection:"row", margin:"auto", padding:"10px", alignContent:"center"}}>
          <button 
            className="popupButton" 
            style={{marginRight:"10px"}} 
            onClick={handleMoveToCalendar} 
            disabled={loadingTasks}
          >
            Mover al Calendario
          </button>
          <button className="popupButton" onClick={onClose}>
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MoveToCalendarPlani; 