import React, { useState, useContext } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { User, Mail, Phone, Shield, Star, Users, Activity, Camera } from 'lucide-react';
import './Miperfil.css';
import { updateParameters } from "../Functions";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Footer from "../Footer";

const MiPerfil = () => {
  const { currentUser } = useContext(AuthContext);
  const {
    businessEmail,
    userType,
    category,
    status,
    department,
    businessName,
    businessAdminEmail,
    businessPhoto,
    businessDescription,
    numberOfTickets,
  } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState('personal');
  const [initView, setInitView] = useState('calendario');
  const [workspaces, setWorkspaces] = useState('no');
  const [frequency, setFrequency] = useState('diario');
  const [isFotoModalOpen, setIsFotoModalOpen] = useState(false);

  const openFotoModal = () => {
    setIsFotoModalOpen(true);
  };

  const handleSave = async () => {
    try {
      await updateParameters(
        currentUser.email,
        initView,
        workspaces,
        frequency
      );
      alert("Parámetros guardados con éxito");
    } catch (error) {
      console.error("Error al guardar los parámetros: ", error);
      alert("Hubo un error al guardar los parámetros");
    }
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="miperfil-container">
        <div className="miperfil-wrapper">
          <div className="miperfil-card">
            <div className="miperfil-header">
              <h2 className="miperfil-title">Mi Perfil</h2>
            </div>
            <div className="miperfil-tabs">
              {['Personal', 'Proyecto', 'Parámetros'].map((tab) => (
                <button
                  key={tab}
                  className={`miperfil-tab ${activeTab === tab.toLowerCase() ? 'miperfil-tab-active' : ''}`}
                  onClick={() => setActiveTab(tab.toLowerCase())}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div className="miperfil-content">
              {activeTab === 'personal' && (
                <div className="miperfil-grid">
                  <div className="miperfil-image-wrapper">
                    <div className="miperfil-image-container">
                      <img src={currentUser.photoURL} alt="Foto de perfil" className="miperfil-image" />
                      <button className="miperfil-camera-button" onClick={openFotoModal}>
                        <Camera size={16} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <InfoItem icon={<User size={16} />} label="Nombre" value={currentUser.displayName} />
                    <InfoItem icon={<Mail size={16} />} label="Email" value={currentUser.email} />
                    <InfoItem icon={<Phone size={16} />} label="Teléfono" value="+56 xxxxxxx" />
                    <InfoItem icon={<Shield size={16} />} label="Privilegios" value={userType} />
                    <InfoItem icon={<Star size={16} />} label="Plan" value="Premium" />
                    <InfoItem icon={<Users size={16} />} label="Tipo de usuario" value={category || "Administrador"} />
                    <InfoItem icon={<Activity size={16} />} label="Status" value={status} />
                    <button className="miperfil-button">Cambiar contraseña</button>
                  </div>
                </div>
              )}

              {activeTab === 'proyecto' && (
                <div className="miperfil-grid">
                  <div className="miperfil-image-wrapper">
                    <img src={businessPhoto} alt="Foto de empresa" className="miperfil-image" />
                  </div>
                  <div>
                  <InfoItem icon={<User size={16} />} label="Nombre" value={businessName} />
                    <InfoItem icon={<Mail size={16} />} label="Administrador" value={businessAdminEmail} />
                    <InfoItem icon={<Activity size={16} />} label="Descripción" value={businessDescription} />
                    <InfoItem icon={<Star size={16} />} label="Número de tareas" value={numberOfTickets} />
                    <InfoItem icon={<Users size={16} />} label="Plan" value="Gratis" />
                  </div>
                </div>
              )}

              {activeTab === 'parámetros' && (
                <div>
                  <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                    <div className="miperfil-grid">
                      <FormGroup
                        label="Vista inicial predeterminada"
                        value={initView}
                        onChange={setInitView}
                        options={[
                          { value: 'timeLine', label: 'Timeline' },
                          { value: 'kanban', label: 'Kanban' },
                          { value: 'calendario', label: 'Calendario' },
                        ]}
                      />
                      <FormGroup
                        label="Workspaces"
                        value={workspaces}
                        onChange={setWorkspaces}
                        options={[
                          { value: 'sí', label: 'Sí' },
                          { value: 'no', label: 'No' },
                        ]}
                        type="radio"
                      />
                      <FormGroup
                        label="Frecuencia de informes"
                        value={frequency}
                        onChange={setFrequency}
                        options={[
                          { value: 'diario', label: 'Diario' },
                          { value: 'semanal', label: 'Semanal' },
                          { value: 'mensual', label: 'Mensual' },
                        ]}
                        type="radio"
                      />
                    </div>
                    <button type="submit" className="miperfil-button">Guardar cambios</button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const InfoItem = ({ icon, label, value }) => (
  <div className="miperfil-info-item">
    <div className="miperfil-icon">{icon}</div>
    <div>
      <span className="miperfil-label">{label}:</span> {value}
    </div>
  </div>
);

const FormGroup = ({ label, value, onChange, options, type = 'select' }) => (
  <div className="miperfil-form-group">
    <label className="miperfil-form-label">{label}</label>
    {type === 'select' ? (
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="miperfil-select"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <div className="miperfil-radio-group">
        {options.map((option) => (
          <label key={option.value} className="miperfil-radio-label">
            <input
              type="radio"
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
              className="miperfil-radio-input"
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
    )}
  </div>
);

export default MiPerfil;
