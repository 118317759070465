import React, { useState, useEffect, useContext, useMemo } from "react";
import Modal from "react-modal";
import "./ResumeMassiveTask.css";
import { Check, XCircle, ArrowLeft, Calendar, Pencil, FileText, Tag, Flag, Clock, AlertTriangle, X } from "lucide-react";
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from "../../context/AuthContext";
import moment from 'moment';
import 'moment/locale/es';

function ResumeMasiveTask({
  isOpen,
  onClose,
  tasks,
  confirmedTasks,
  onCheckboxChange,
  handleCreateTasks,
  successMessage,
  setJsonResult
}) {
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [areas, setAreas] = useState([]);
  const { businessEmail } = useContext(AuthContext);

  useEffect(() => {
    if (isOpen) {
      setSelectedEventIndex(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedEventIndex(null);
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchAreas = async () => {
      if (businessEmail) {
        const q = query(collection(db, "business", businessEmail, "areas"));
        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
        });
        setAreas(items);
      }
    };
    fetchAreas();
  }, [businessEmail]);

  const getAreaDetails = (areaId) => {
    return areas.find(area => area.id === areaId) || { color: '#000000', icon: '📌' };
  };

  const handleEventClick = (index) => {
    setSelectedEventIndex(index);
  };

  const handleSaveEvent = (field, value) => {
    setJsonResult((prev) => {
      const newTasks = [...prev];
      newTasks[selectedEventIndex] = {
        ...newTasks[selectedEventIndex],
        [field]: value
      };
      return newTasks;
    });
    selectedTasksCount = 0;
    setSelectedEventIndex(null);
  };

  const handleSaveChanges = () => {
    setSelectedEventIndex(null);
  };

  const handleClose = () => {
    setSelectedEventIndex(null);
    setJsonResult([]);
    onClose();
  };

  // Modificar el cálculo de tareas seleccionadas
  const selectedTasksCount = useMemo(() => {
    return Object.values(confirmedTasks).filter(value => value === true).length;
  }, [confirmedTasks]);

  const task = selectedEventIndex !== null ? tasks[selectedEventIndex] : null;
  const selectedAreaDetails = task ? getAreaDetails(task.area) : null;

  return (
    <Modal
      overlayClassName="popupBackground"
      className="popupContainer"
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Lista de Tareas o Eventos Sugeridos</h2>
      </div>
      <div className="popupContent">
        {selectedEventIndex === null ? (
          tasks.map((task, index) => {
            const areaDetails = getAreaDetails(task.area);
            
            return (
              <div 
                key={index} 
                className={`evento-card ${confirmedTasks[index] === true ? 'task-confirmed' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onCheckboxChange(index);
                }}
              >
                <div className="evento-content">
                  <div className="evento-titulo">{task.título}</div>
                  <div className="evento-detalles">
                    <div className="detalles-principales">
                      <span 
                        className="area-tag"
                        style={{
                          backgroundColor: `${areaDetails.color}40`,
                          color: areaDetails.color
                        }}
                      >
                        {areaDetails.icon} {task.area}
                      </span>
                      <span 
                        className="priority-tag"
                        style={{
                          backgroundColor: task.priority.toLowerCase() === 'alta' ? '#FF444440' : 
                                         task.priority.toLowerCase() === 'media' ? '#FFA50040' : 
                                         '#4CAF5040',
                          color: task.priority.toLowerCase() === 'alta' ? '#FF4444' : 
                                task.priority.toLowerCase() === 'media' ? '#FFA500' : 
                                '#4CAF50'
                        }}
                      >
                        Prioridad: {task.priority}
                      </span>
                    </div>
                    <div className="fecha-hora">
                      <Calendar size={14} />
                      <span>{moment(task.fecha).format('dddd D [de] MMMM').replace(/^\w/, (c) => c.toUpperCase())}</span>
                      <span>•</span>
                      <span>{task.horaInicio} - {task.horaFin}</span>
                    </div>
                  </div>
                </div>
                <div className="evento-actions">
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEventClick(index);
                    }} 
                    className="edit-button-eventtask"
                    aria-label="Editar tarea"
                  >
                    <Pencil size={18} strokeWidth={2.5} />
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="edit-event-section">
            <div className="edit-header">
              <h3>
                <FileText size={20} />
                Detalles del Evento
              </h3>
            </div>
            
            <div className="edit-form-content">
              <div className="form-field">
                <label>
                  <FileText size={16} />
                  Título
                </label>
                <input
                  type="text"
                  value={task.título || ''}
                  onChange={(e) => handleSaveEvent('título', e.target.value)}
                  placeholder="Nombre del evento"
                />
              </div>
              
              <div className="form-field">
                <label>
                  <Tag size={16} />
                  Área
                </label>
                <select
                  value={task.area || ''}
                  onChange={(e) => handleSaveEvent('area', e.target.value)}
                  style={{
                    backgroundColor: selectedAreaDetails ? `${selectedAreaDetails.color}40` : '',
                    color: selectedAreaDetails ? selectedAreaDetails.color : ''
                  }}
                >
                  {areas.map(area => (
                    <option 
                      key={area.id} 
                      value={area.id}
                      style={{
                        backgroundColor: `${area.color}40`,
                        color: area.color
                      }}
                    >
                      {area.icon} {area.id}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="form-field">
                <label>
                  <Calendar size={16} />
                  Fecha
                </label>
                <input
                  type="date"
                  value={task.fecha || ''}
                  onChange={(e) => handleSaveEvent('fecha', e.target.value)}
                />
              </div>
              
              <div className="form-field">
                <label>
                  <Flag size={16} />
                  Prioridad
                </label>
                <select
                  value={task.priority || 'Media'}
                  onChange={(e) => handleSaveEvent('priority', e.target.value)}
                  style={{
                    backgroundColor: task.priority?.toLowerCase() === 'alta' ? '#FF444440' : 
                                    task.priority?.toLowerCase() === 'media' ? '#FFA50040' : 
                                    '#4CAF5040',
                    color: task.priority?.toLowerCase() === 'alta' ? '#FF4444' : 
                           task.priority?.toLowerCase() === 'media' ? '#FFA500' : 
                           '#4CAF50'
                  }}
                >
                  <option 
                    value="Alta"
                    style={{
                      backgroundColor: '#FF444440',
                      color: '#FF4444'
                    }}
                  >
                    Alta
                  </option>
                  <option 
                    value="Media"
                    style={{
                      backgroundColor: '#FFA50040',
                      color: '#FFA500'
                    }}
                  >
                    Media
                  </option>
                  <option 
                    value="Baja"
                    style={{
                      backgroundColor: '#4CAF5040',
                      color: '#4CAF50'
                    }}
                  >
                    Baja
                  </option>
                </select>
              </div>
              
              <div className="form-field">
                <label>
                  <Clock size={16} />
                  Hora Inicio
                </label>
                <input
                  type="time"
                  value={task.horaInicio || ''}
                  onChange={(e) => handleSaveEvent('horaInicio', e.target.value)}
                />
              </div>
              
              <div className="form-field">
                <label>
                  <Clock size={16} />
                  Hora Fin
                </label>
                <input
                  type="time"
                  value={task.horaFin || ''}
                  onChange={(e) => handleSaveEvent('horaFin', e.target.value)}
                />
              </div>
            </div>

            <div className="form-actions">
              <button onClick={() => setSelectedEventIndex(null)} className="cancel-button">
                <X size={18} />
                Cancelar
              </button>
              <button onClick={handleSaveChanges} className="save-edittask-button">
                <Check size={18} />
                Guardar
              </button>
            </div>
          </div>
        )}
        {selectedEventIndex === null && (
          <button 
            onClick={handleCreateTasks}
            className="popupButton"
            disabled={selectedTasksCount === 0}
          >
            Crear Tareas y Eventos seleccionados ({selectedTasksCount})
          </button>
        )}
        {successMessage && selectedTasksCount > 0 && (
          <div className="success-message">
            {successMessage}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ResumeMasiveTask;
