import React from 'react';

const TimeCell = ({ hour }) => {
  return (
    <td className="calendarioSemanal__hora-sticky">
      {`${hour.toString().padStart(2, "0")}:00`}
    </td>
  );
};

export default TimeCell; 