import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import AdjuntarArchivosModal from "../modaChatlMenu/AdjuntarArchivosModal";
import { useState } from "react";

function TicketResume() {
  const location = useLocation();
  const ticketId = location.state.ticketId;
  const title = location.state.title;
  const businessName = location.state.businessName;
  const priority = location.state.priority;
  const today = location.state.today;
  const areas = location.state.areas;
  const businessEmail = location.state.businessEmail;
  const mensaje = location.state.mensaje;
  const contexto = location.state.contexto;
  const competencias = location.state.competencias;
  const pasos = location.state.pasos;
  const creator = location.state.creator;
  const responsables = location.state.responsables;
  const from = location.state.from;
  const horaCreacion = location.state.horaCreacion;

  const navigate = useNavigate();
  const [openModalAdjuntar, setOpenModalAdjuntar] = useState(false);

  const handleFinalizar = () => {
    navigate("/GestionarTickets");
  };

  const handleCloseModalAdjuntar = () => {
    setOpenModalAdjuntar(false);
  };

  const handleOpenModalAdjuntar = () => {
    setOpenModalAdjuntar(!openModalAdjuntar);
  };

    

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="bigCard">
        <div className="column">
          <h2 className="titleBigCard">Resumen de la tarea Creada</h2>
          <hr />
          <table className="prettyTable">
            <tbody>
              <tr>
                <td className="titleColumn">ID</td>
                <td>{ticketId}</td>
              </tr>
              <tr>
                <td className="titleColumn">Título</td>
                <td>{title}</td>
              </tr>
              <tr>
                <td className="titleColumn">Empresa</td>
                <td>{businessName}</td>
              </tr>
              <tr>
                <td className="titleColumn">Prioridad</td>
                <td>{priority}</td>
              </tr>
              <tr>
                <td className="titleColumn">Fecha</td>
                <td>{today}</td>
              </tr>
              <tr>
                <td className="titleColumn">Áreas</td>
                <td>{areas}</td>
              </tr>
              <tr>
                <td className="titleColumn">Email de Empresa</td>
                <td>{businessEmail}</td>
              </tr>
              <tr>
                <td className="titleColumn">Mensaje</td>
                <td>{mensaje}</td>
              </tr>
              <tr>
                <td className="titleColumn">Competencias</td>
                <td>{competencias}</td>
              </tr>
              <tr>
                <td className="titleColumn">Creador</td>
                <td>{creator}</td>
              </tr>
              <tr>
                <td className="titleColumn">Responsables</td>
                <td>{responsables}</td>
              </tr>
              <tr>
                <td className="titleColumn">Email del Creador</td>
                <td>{from}</td>
              </tr>
              <tr>
                <td className="titleColumn">Hora de Creación</td>
                <td>{horaCreacion}</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div className="buttonGroup">
            <button className="attachButtonCancel">Eliminar Tarea</button>
            <button
              className="attachButtonAdjuntar"
              onClick={handleOpenModalAdjuntar}
            >
              Adjuntar Archivos
            </button>
            <button className="attachButton" onClick={() => handleFinalizar()}>
              Finalizar
            </button>
          </div>
        </div>
      </div>

      <AdjuntarArchivosModal
        openModalAdjuntar={openModalAdjuntar}
        idTicket={ticketId}
        handleCloseModalAdjuntar={handleCloseModalAdjuntar}
        emailBusiness={businessEmail}
      />

      <Footer />
    </div>
  );
}

export default TicketResume;
