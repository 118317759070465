import React from 'react';
import ResizableDraggableTicket from '../ResizableDraggableTicket';
import DraggableTicketMulti from '../DraggableTicketMulti';
import moment from 'moment-timezone';
import tinycolor from "tinycolor2";

const AllDaySemanal = ({
  diasSemanaArr,
  obtenerTicketsDia,
  hoveredCell,
  setHoveredCell,
  draggedOverCell,
  draggingTicket,
  handleDragOverCell,
  handleCellClick,
  today,
  renderTicket,
  handleTicketClick,
  setDraggingTicket,
  handleDragStart,
  handleDragEnd,
  currentCellTime
}) => {
  const renderAllDayTicket = (ticket, dia, mes, anio, index, totalTickets) => {
    const fechaInicio = moment(ticket.vencimiento, "DD-MM-YYYY");
    const fechaFin = moment(ticket.fechaFin || ticket.vencimiento, "DD-MM-YYYY");
    const fechaActual = moment([anio, mes, dia]);
    const isMultiDay = !fechaInicio.isSame(fechaFin, 'day');
    const isStart = fechaActual.isSame(fechaInicio, 'day');

    // Solo renderizar si es el día de inicio
    if (!isStart) return null;

    const duracionDias = fechaFin.diff(fechaInicio, 'days') + 1;
    const verticalOffset = index * 28; // 28px de espacio vertical por ticket
    
    const style = {
      backgroundColor: tinycolor(ticket.color).lighten(30).toString(),
      color: tinycolor(ticket.color).darken(20).toString(),
      borderLeft: `4px solid ${ticket.color}`,
      borderRadius: "4px",
      height: "24px",
      marginBottom: "2px",
      padding: "0 8px",
      display: "flex",
      alignItems: "center",
      position: "absolute",
      zIndex: 100,
      width: isMultiDay ? `calc(${duracionDias * 100}% + ${(duracionDias - 1) * 2}px)` : '100%',
      left: 0,
      right: 0,
      top: verticalOffset,
      boxSizing: 'border-box',
      transition: 'all 0.2s ease',
      cursor: 'pointer',
      whiteSpace: 'nowrap'
    };

    return (
      <DraggableTicketMulti
        key={`${ticket.id}-${dia}-${mes}-${anio}`}
        ticket={ticket}
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          handleTicketClick(ticket, e);
        }}
        setDraggingTicket={setDraggingTicket}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        currentCellTime={currentCellTime}
      >
        <span className="ticket-title">{ticket.title}</span>
        {ticket.horaInicio !== "Todo el día" && (
          <span className="ticket-time">
            {ticket.horaInicio} - {ticket.horaTermino}
          </span>
        )}
      </DraggableTicketMulti>
    );
  };

  return (
    <tr>
      <td style={{ transform: "translateY(0px)", position: 'relative' }}>Todo el día</td>
      {diasSemanaArr.map((fecha, index) => {
        const ticketsDia = obtenerTicketsDia(fecha);
        const ticketsTodoElDia = ticketsDia.filter(
          (ticket) => ticket.horaInicio === "Todo el día"
        ).slice(0, hoveredCell === index ? undefined : 3);

        const isCellHovered = hoveredCell === index;
        const isDraggingOverThisCell = draggedOverCell &&
          draggedOverCell.dia === fecha.getDate() &&
          draggedOverCell.mes === fecha.getMonth() &&
          draggedOverCell.anio === fecha.getFullYear();

        const totalTickets = ticketsDia.filter(t => t.horaInicio === "Todo el día").length;
        const cellHeight = Math.min(totalTickets, isCellHovered ? totalTickets : 3) * 28 + 4;

        return (
          <td
            key={`${fecha.getFullYear()}-${fecha.getMonth()}-${fecha.getDate()}`}
            className={`
              ${hoveredCell === index ? "calendarioSemanal__hovered-cell" : ""}
              ${isDraggingOverThisCell ? "calendarioSemanal__dragged-over-cell" : ""}
            `}
            onMouseEnter={() => setHoveredCell(index)}
            onMouseLeave={() => setHoveredCell(null)}
            onDragOver={(e) => {
              e.preventDefault();
              handleDragOverCell(
                e, 
                fecha.getDate(), 
                fecha.getMonth(), 
                fecha.getFullYear(), 
                null, 
                true
              );
            }}
            onClick={(e) =>
              handleCellClick(
                e, 
                null, 
                fecha.getDate(), 
                fecha.getMonth(), 
                fecha.getFullYear()
              )
            }
            style={{ 
              position: 'relative', 
              height: `${cellHeight}px`,
              minHeight: '30px',
              padding: '2px', 
              boxSizing: 'border-box',
              backgroundColor: today.isSame(fecha, 'day') ? '#f8f9fa' : 'transparent',
              overflow: 'visible',
              transition: 'height 0.2s ease-in-out'
            }}
          >
            <div 
              className="calendarioSemanal__ticket-container"
              style={{ 
                overflow: 'visible',
                height: '100%',
                position: 'relative'
              }}
            >
              {ticketsTodoElDia.map((ticket, idx) =>
                renderAllDayTicket(
                  ticket, 
                  fecha.getDate(), 
                  fecha.getMonth(), 
                  fecha.getFullYear(),
                  idx,
                  totalTickets
                )
              )}
              {!isCellHovered && totalTickets > 3 && (
                <div className="and-more">
                  y {totalTickets - 3} más
                </div>
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default AllDaySemanal; 