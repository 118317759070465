// src/components/GoogleVerify.js

import React, { useEffect, useRef, useState, useContext } from "react";
import { signInWithCustomToken } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { doc, getDoc, setDoc, updateDoc, arrayUnion, collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Ajusta la ruta según tu estructura
import { AuthContext } from "../../../context/AuthContext";
import logoLogin from "../../img/planiuslogin.png"; // Importa el logo

const GoogleVerify = () => {
  const isFetched = useRef(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [progressMessage, setProgressMessage] = useState("");
  const [loading, setLoading] = useState(true);

  // Usar AuthContext para obtener setCurrentUser, setBusinessEmail y setFirebaseAccessToken
  const { setCurrentUser, setBusinessEmail, setFirebaseAccessToken } = useContext(AuthContext);

  const createBusinessDocument = async (userEmail) => {
    try {
      const businessDocRef = await addDoc(collection(db, "business"), {
        businessName: userEmail,
        admin: userEmail,
      });

      const businessId = businessDocRef.id;
      sessionStorage.setItem("businessId", businessId);
      sessionStorage.setItem("nameBusiness", userEmail);

      return businessId;
    } catch (err) {
      console.error("Error creating business document: ", err);
      throw err;
    }
  };

  useEffect(() => {
    if (isFetched.current) return;

    const searchParams = new URLSearchParams(window.location.search);
    const state = searchParams.get("state");
    const code = searchParams.get("code");

    console.log("State:", state);
    console.log("Code:", code);

    if (code) {
      const redirectUri =
        process.env.REACT_APP_AMBIENTE === "dev"
          ? "http://localhost:8888/googleAuth"
          : "https://app.planius.ai/googleAuth";

      const sendAuthRequest = async () => {
        try {
          setProgressMessage("Iniciando sesión");
          console.log("Enviando solicitud a:", `https://us-central1-planiusai.cloudfunctions.net/api/googleAuth?state=${state}&code=${code}&redirectUri=${encodeURIComponent(redirectUri)}`);

          const response = await fetch(
            `https://us-central1-planiusai.cloudfunctions.net/api/googleAuth?state=${state}&code=${code}&redirectUri=${encodeURIComponent(redirectUri)}`,
            {
              method: "GET",
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("Respuesta del servidor:", data);

          const customToken = data.customToken;
          if (!customToken) {
            throw new Error("Custom token no recibido desde el servidor.");
          }

          sessionStorage.setItem("customToken", customToken);

          const userCredential = await signInWithCustomToken(auth, customToken);
          console.log("Usuario autenticado:", userCredential.user);

          setUser(userCredential.user);
          setCurrentUser(userCredential.user); // Actualizar el contexto

          const userEmail = userCredential.user.email;
          const userUID = userCredential.user.uid;

          const userDocRef = doc(db, "users", userEmail);
          const userDoc = await getDoc(userDocRef);
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          let businessIds = [];
          let activeBusinessId = "";
          let isFirstTimeUser = false;

          if (!userDoc.exists()) {
            setProgressMessage("Creando perfil de usuario...");
            await setDoc(userDocRef, {
              uid: userUID,
              displayName: userCredential.user.displayName || userEmail,
              email: userEmail,
              photoURL: userCredential.user.photoURL || "",
              userType: "standard",
              status: "active",
              date: new Date(),
              timezone,
              initView: "calendar",
              business: [],
            });

            activeBusinessId = await createBusinessDocument(userEmail);
            businessIds = [activeBusinessId];
            isFirstTimeUser = true;

            await updateDoc(userDocRef, {
              business: arrayUnion({
                id: activeBusinessId,
                userType: "admin",
                businessName: userEmail,
                status: "active",
              }),
            });
          } else {
            const userData = userDoc.data();
            if (!userData.business || userData.business.length === 0) {
              activeBusinessId = await createBusinessDocument(userEmail);
              businessIds = [activeBusinessId];
              isFirstTimeUser = true;

              await updateDoc(userDocRef, {
                business: arrayUnion({
                  id: activeBusinessId,
                  userType: "admin",
                  businessName: userEmail,
                  status: "active",
                }),
              });
            } else {
              businessIds = userData.business.map((b) => b.id);
              activeBusinessId = businessIds[0];
            }
          }

          sessionStorage.setItem("businessId", activeBusinessId);
          sessionStorage.setItem("nameBusiness", userEmail);
          setBusinessEmail(activeBusinessId); // Actualizar el contexto

          // Establecer el firebaseAccessToken en el AuthContext
          setFirebaseAccessToken(customToken);

          if (isFirstTimeUser) {
            navigate("/crearPerfil");
          } else {
            setProgressMessage("Despertando a Planibot... 🤖💤");
            setProgressMessage("Sincronizando eventos...📆");
            setProgressMessage("...");
            navigate("/gestionarTickets");
          }
        } catch (error) {
          console.error("Error durante el proceso de inicio de sesión:", error);
          setError(error.message);
        } finally {
          setLoading(false);
          setProgressMessage("");
        }
      };

      sendAuthRequest();
      isFetched.current = true;
    }
  }, [navigate, setCurrentUser, setBusinessEmail, setFirebaseAccessToken]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        textAlign: "center",
        background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        padding: "20px",
      }}
    >
      <div
        style={{
          background: "white",
          borderRadius: "20px",
          padding: "40px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
          width: "90%",
          maxWidth: "500px",
        }}
      >
        <img
          src={logoLogin}
          alt="Planius Logo"
          style={{
            maxWidth: "200px",
            height: "auto",
            marginBottom: "30px",
            animation: "float 3s ease-in-out infinite",
          }}
        />
        
        {loading && (
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                width: "40px",
                height: "40px",
                margin: "0 auto 20px",
                border: "3px solid #f3f3f3",
                borderTop: "3px solid #3498db",
                borderRadius: "50%",
                animation: "spin 1s linear infinite",
              }}
            />
            <div
              style={{
                fontSize: "1.2em",
                color: "#2c3e50",
                fontWeight: "500",
                marginBottom: "10px",
              }}
            >
              {progressMessage || "Iniciando sesión"}
            </div>
            <div
              style={{
                width: "80%",
                height: "4px",
                margin: "20px auto",
                background: "#f0f0f0",
                borderRadius: "2px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "30%",
                  height: "100%",
                  background: "linear-gradient(90deg, #3498db, #2ecc71)",
                  animation: "progress 1.5s ease-in-out infinite",
                }}
              />
            </div>
          </div>
        )}

        {error && (
          <div
            style={{
              color: "#e74c3c",
              fontSize: "1.1em",
              padding: "15px",
              background: "#fdeaea",
              borderRadius: "8px",
              marginTop: "20px",
            }}
          >
            <span role="img" aria-label="error">⚠️</span> {error}
          </div>
        )}

        {!loading && !error && (
          <div
            style={{
              color: "#27ae60",
              fontSize: "1.2em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <span role="img" aria-label="success">✅</span>
            Inicio de sesión exitoso
          </div>
        )}
      </div>

      <style>
        {`
          @keyframes float {
            0% {
              transform: translateY(0px);
            }
            50% {
              transform: translateY(-10px);
            }
            100% {
              transform: translateY(0px);
            }
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          @keyframes progress {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(400%); }
          }
        `}
      </style>
    </div>
  );
};

export default GoogleVerify;
