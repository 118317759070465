import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";

function AddEventSendGrid(props) {
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [evento, setEvento] = useState(props.evento);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirmModal = () => {
    setConfirmModalIsOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalIsOpen(false);
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    const checkGoogleUser = () => {
      if (user) {
        // Verifica si el usuario inició sesión usando Google
        setIsGoogleUser(
          user.providerData.some(
            (provider) => provider.providerId === "google.com"
          )
        );
      }
    };

    checkGoogleUser();
  }, []);

  const handleSendEmail = async () => {
    try {
      setIsLoading(true);
      handleCloseConfirmModal();

      const eventData = {
        evento: props.evento,
        descripcion: props.descripcion,
        fechaInicio: props.fechaVencimiento,
        fechaVencimiento: props.fechaVencimiento,
        uid: props.idTicket,
        sequence: 0,
        organizador: "contacto@planius.ai",
        participantes: props.participantes.split(","),
        colorEvento: "PURPLE",
        colorEventoGoogle: "#8640bf",
      };

      const response = await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventData), // Envía los datos del evento como JSON
      });

      const result = await response.json(); // Asume que la función devuelve JSON

      if (response.ok) {
        setModalIsOpen(true);
        console.log("Resultado:", result);
      } else {
        throw new Error(result.error || "Error desconocido al enviar el email");
      }
    } catch (error) {
      console.error("Error al enviar email:");
      alert("Error al enviar email. Ver consola para más detalles.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {!isGoogleUser && (
        <button className="buttonCalendar" onClick={handleConfirmModal}>
          Agregar al calendario
        </button>
      )}

      {/* Modal de confirmación */}
      <Modal
        overlayClassName={"modal-overlay"}
        className={"modal-content"}
        isOpen={confirmModalIsOpen}
        onRequestClose={handleCloseConfirmModal}
      >
        <h2 className="titleModal">Confirmar</h2>
        <div className="descriptionModal">
          <p>¿Seguro que desea agregar este evento a su calendario?</p>
        </div>
        <div className="row">
        <button className="btnCloseModal" onClick={handleCloseConfirmModal}>
          No
        </button>
        <button className="btnCloseModal" onClick={handleSendEmail}>
          Sí
        </button>
        </div>

      </Modal>

      {/* Modal de éxito */}
      <Modal
        overlayClassName={"modal-overlay"}
        className={"modal-content"}
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
      >
        <h2 className="titleModal">¡Tarea agendada!</h2>
        <div className="descriptionModal">
          <p>
            Se ha enviado una invitación a su correo electrónico personal, para
            agregar la tarea al calendario.
          </p>
        </div>
        <button className="btnCloseModal" onClick={handleCloseModal}>
          Cerrar
        </button>
      </Modal>

      {/* Spinner de carga */}
      {isLoading && <div className="Spinner"></div>}
    </div>
  );
}

export default AddEventSendGrid;