import React, { useState, useContext, useMemo, useCallback, useEffect, useRef } from "react";
import { useDrop } from "react-dnd";
import ChangeTimeModal from "../ModalInformativo/ChangeTimeModal";
import DetalleTarea from "./DetalleTarea";
import MenuAddCalendar from "./MenuAddCalendar";
import GoogleMeets from "../GoogleMeets/GoogleMeets";
import ResumeTarea from "../addTarea/ResumeTarea";
import "./CalendarMes.css";
import { AuthContext } from "../../context/AuthContext";
import DraggableTicket from "./DraggableTicket";
import DraggableTicketMulti from "./DraggableTicketMulti";
import { updateTicket } from "../Functions";
import { updateGoogleCalendarEvent, agregarEventoACalendario } from "./FunctionsCalendar";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import TabsContainer from "../ReorganizarPlanibot/TabsContainer";
import tinycolor from 'tinycolor2';
import { Tooltip } from '@mui/material';
import PlanibotDetail from "../Planibot/PlanibotDetail";

const MAX_TICKETS_VISIBLE = 3;

export const meses = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
];

const diasSemana = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];

const isTicketVencido = (ticket) => {
  const hoy = moment().startOf('day');
  const fechaVencimiento = moment(ticket.vencimiento, "DD-MM-YYYY").startOf('day');
  return fechaVencimiento.isBefore(hoy);
};

const getTicketStyle = (color, isVencido, isMultiDay, isStart, isEnd, width, index) => {
  const defaultColor = '#5d58d7';
  const ticketColor = color || defaultColor;
  const lightBackgroundColor = tinycolor(ticketColor).lighten(35).toString();
  const opacity = isVencido ? 0.65 : 1;
  const TICKET_HEIGHT = 22; // Altura del ticket
  const MULTIDAY_SPACING = 24; // Espaciado para tickets multi-día
  const SINGLE_DAY_SPACING = TICKET_HEIGHT -22; // Solo 2px de separación entre tickets

  const baseStyle = {
    backgroundColor: lightBackgroundColor,
    borderLeft: `4px solid ${ticketColor}`,
    color: tinycolor(ticketColor).lighten(-20).toString(),
    opacity: opacity,
    position: 'absolute',
    height: `${TICKET_HEIGHT}px`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    padding: '2px 6px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '4px',
  };

  if (isMultiDay) {
    return {
      ...baseStyle,
      width: width,
      zIndex: 10 + index,
      borderRadius: isStart ? '4px 0 0 4px' : (isEnd ? '0 4px 4px 0' : '0'),
      left: isStart ? '0' : '-100%',
      right: isEnd ? '0' : '100%',
      marginLeft: !isStart ? '-1px' : '0',
      marginRight: !isEnd ? '-1px' : '0',
      top: `${index * MULTIDAY_SPACING}px`,
      marginTop: '22px',

    };
  }

  return {
    ...baseStyle,
    top: `${index * SINGLE_DAY_SPACING}px`, // Ahora solo habrá 2px de separación
    zIndex: 10 + index,
  };
};

const isToday = (day, month, year) => {
  const today = new Date();
  return day === today.getDate() &&
    month === today.getMonth() &&
    year === today.getFullYear();
};

const filterTicketsForDay = (tickets, dia, mes, anio) => {
  const fechaActual = moment([anio, mes, dia]);
  
  return tickets
    .filter((ticket) => {
      if (ticket.isRoutine) return false;
      
      const inicio = moment(ticket.vencimiento, "DD-MM-YYYY");
      const fin = moment(ticket.fechaFin || ticket.vencimiento, "DD-MM-YYYY");
      
      return fechaActual.isBetween(inicio, fin, 'day', '[]');
    })
    .sort((a, b) => {
      const horaA = a.horaInicio || '00:00';
      const horaB = b.horaInicio || '00:00';
      
      if (horaA !== horaB) {
        return horaA.localeCompare(horaB);
      }
      
      const duracionA = moment(a.fechaFin || a.vencimiento, "DD-MM-YYYY")
        .diff(moment(a.vencimiento, "DD-MM-YYYY"), 'days') + 1;
      const duracionB = moment(b.fechaFin || b.vencimiento, "DD-MM-YYYY")
        .diff(moment(b.vencimiento, "DD-MM-YYYY"), 'days') + 1;
      
      return duracionB - duracionA;
    });
};

const obtenerDiaSemana = (dia, mes, anio) => {
  const diaSemana = new Date(anio, mes, dia).getDay();
  return diaSemana === 0 ? 6 : diaSemana - 1; // Convierte domingo (0) a 6, y resta 1 al resto
};

const getTicketPosition = (ticket, allTickets, dia, mes, anio) => {
  const fechaActual = moment([anio, mes, dia]);
  const posicionesOcupadas = new Set();

  // Ordenar todos los tickets por fecha de inicio y duración
  const ticketsOrdenados = allTickets
    .filter(t => t.id !== ticket.id) // Excluir el ticket actual
    .sort((a, b) => {
      const startA = moment(a.vencimiento, "DD-MM-YYYY");
      const startB = moment(b.vencimiento, "DD-MM-YYYY");
      const endA = moment(a.fechaFin || a.vencimiento, "DD-MM-YYYY");
      const endB = moment(b.fechaFin || b.vencimiento, "DD-MM-YYYY");
      
      // Primero ordenar por fecha de inicio
      if (!startA.isSame(startB)) {
        return startA.diff(startB);
      }
      
      // Si tienen la misma fecha de inicio, el más largo va primero
      return endB.diff(endA);
    });

  // Asignar posiciones a los tickets que ocupan este día
  ticketsOrdenados.forEach(t => {
    const inicio = moment(t.vencimiento, "DD-MM-YYYY");
    const fin = moment(t.fechaFin || t.vencimiento, "DD-MM-YYYY");
    
    // Verificar si el ticket ocupa este día
    if (fechaActual.isBetween(inicio, fin, 'day', '[]')) {
      // Si el ticket ya tiene una posición asignada, usarla
      if (t.verticalPosition !== undefined) {
        posicionesOcupadas.add(t.verticalPosition);
      }
    }
  });

  // Encontrar la primera posición libre
  let position = 0;
  while (posicionesOcupadas.has(position)) {
    position++;
  }

  // Asignar y guardar la posición
  ticket.verticalPosition = position;
  
  return position;
};

const CalendarMes = ({ mesActual, anioActual, tickets, coloresTicket, cambiarFecha, reordenarEventosConIA, timeZone, setTickets, isTabsVisible, manejarActualizacionTareasPendientes }) => {
  const { businessEmail, currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken } = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [hoveredDay, setHoveredDay] = useState(null);
  const [draggedOverCell, setDraggedOverCell] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showGoogleMeets, setShowGoogleMeets] = useState(false);
  const [tarea, setTarea] = useState();
  const [type, setType] = useState("tarea");
  const [showPlanibotDetail, setShowPlanibotDetail] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTickets, setSelectedDateTickets] = useState([]);
  const [tareasPendientes, setTareasPendientes] = useState([]);
  const planibotClickRef = useRef(false);
  const [isProcessingPlanibot, setIsProcessingPlanibot] = useState(false);

  // Nuevo useEffect para actualizar el número de tareas pendientes
  useEffect(() => {
    if (tareasPendientes.length > 0) {
      manejarActualizacionTareasPendientes(tareasPendientes.length);
    }
  }, [tareasPendientes, manejarActualizacionTareasPendientes]);

  useEffect(() => {
    console.log('Tickets cargados:', tickets);
  }, [tickets]);

  const obtenerDiasMes = (mes, anio) => new Date(anio, mes + 1, 0).getDate();

  const obtenerTicketsDia = useCallback((dia, mes, anio) => {
    return filterTicketsForDay(tickets, dia, mes, anio);
  }, [tickets]);

  const diasDelMes = useMemo(() => {
    const diasMes = obtenerDiasMes(mesActual, anioActual);
    let primerDiaMes = new Date(anioActual, mesActual, 1).getDay();
    // Ajustar para que lunes sea 0 y domingo sea 6
    primerDiaMes = primerDiaMes === 0 ? 6 : primerDiaMes - 1;
    
    const diasMesAnterior = obtenerDiasMes(mesActual - 1, anioActual);
    
    return {
      diasMes,
      primerDiaMes,
      diasMesAnterior
    };
  }, [mesActual, anioActual]);

  const onDropTicket = async (ticket, dia, mes, anio) => {
    const timezone = "America/Santiago";
    const fechaInicio = moment.tz(ticket.vencimiento, "DD-MM-YYYY", timezone);
    const fechaFin = moment.tz(ticket.fechaFin || ticket.vencimiento, "DD-MM-YYYY", timezone);
    const duracion = fechaFin.diff(fechaInicio, 'days');

    const nuevaFechaInicio = moment.tz({ year: anio, month: mes, day: dia }, timezone);
    const nuevaFechaFin = nuevaFechaInicio.clone().add(duracion, 'days');

    const updatedData = {
      creacion: nuevaFechaInicio.format("DD-MM-YYYY"),
      vencimiento: nuevaFechaInicio.format("DD-MM-YYYY"),
      fechaFin: nuevaFechaFin.format("DD-MM-YYYY"),
      horaInicio: ticket.horaInicio,
      horaTermino: ticket.horaTermino,
      status: "Proceso",
    };

    console.log("Datos actualizados del ticket:", updatedData);

    try {
      await updateTicket(businessEmail, ticket.id, updatedData);
      ticket.vencimiento = updatedData.vencimiento;
      ticket.fechaFin = updatedData.fechaFin;

      console.log("Estado del ticket después de actualizar:", ticket);

      if (ticket.status === "Proceso") {
        console.log("Actualizando evento en Google Calendar...");
        await updateGoogleCalendarEvent(ticket, updatedData, ticket.color,  currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
      } else {
        const fechaInicioISO = nuevaFechaInicio.toISOString();
        const fechaFinISO = nuevaFechaFin.toISOString();
        console.log("Agregando evento a Google Calendar...");
        await agregarEventoACalendario(businessEmail, ticket.id, ticket.title, ticket.descripcion, fechaInicioISO, fechaFinISO, updatedData.horaInicio, updatedData.horaTermino, ticket.color, 'primary', currentUser, accessToken, refreshToken, tokenExpiry, firebaseAccesToken);
      }

    } catch (error) {
      console.error("Error actualizando el ticket:", error);
      toast.error(`Error al actualizar el ticket: ${error.message}`);
    }
  };

  const [{ isOverDrop, canDrop }, drop] = useDrop({
    accept: "TICKET",
    drop: (item, monitor) => {
      if (draggedOverCell) {
        const { dia, mes, anio } = draggedOverCell;
        onDropTicket(item, dia, mes, anio);
        setDraggedOverCell(null);
      }
    },
    collect: (monitor) => ({
      isOverDrop: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  

  const handleTicketClick = (event, ticket) => {
    event.stopPropagation();
    setSelectedTicket(ticket);
    setMenuPosition(null);
  };
  

  const handleCloseDetalleTarea = () => {
    setSelectedTicket(null);
  };

  const handleClickCell = (event, dia, mes, anio) => {
    event.preventDefault();
    event.stopPropagation();
    
    const mesFormateado = String(mes + 1).padStart(2, '0');
    const diaFormateado = String(dia).padStart(2, '0');
    
    // Obtener la hora actual en formato 24 horas
    const horaActual = moment().format('HH:00');
    
    setMenuPosition({ x: event.clientX, y: event.clientY });
    setSelectedDateTime({
      date: `${anio}-${mesFormateado}-${diaFormateado}`,
      hour: horaActual // Establecer la hora actual como hora por defecto
    });
    setShowPopUp(false);
  };

  const handleAddItem = useCallback((item, dia, mes, anio) => {
    console.log(`handleAddItem llamado con: item=${item}, dia=${dia}, mes=${mes}, anio=${anio}`);
    console.log('Estado actual de isProcessingPlanibot:', isProcessingPlanibot);

    switch (item) {
      case "task":
        setShowPopUp(true);
        setType("tarea");
        break;
      case "meeting":
        setShowGoogleMeets(true);
        setType("reunion");
        break;
      case "event":
        setShowPopUp(true);
        setType("evento");
        break;
      case "planibot":
        console.log('Caso planibot seleccionado');
        if (!isProcessingPlanibot) {
          console.log('Iniciando procesamiento de Planibot');
          setIsProcessingPlanibot(true);
          const selectedDate = `${anio}-${String(mes + 1).padStart(2, '0')}-${String(dia).padStart(2, '0')}`;
          console.log('Fecha seleccionada:', selectedDate);
          const ticketsForSelectedDate = tickets.filter(ticket => {
            const ticketDate = moment(ticket.vencimiento, "DD-MM-YYYY").format("YYYY-MM-DD");
            return ticketDate === selectedDate;
          });
          console.log('Tickets para la fecha seleccionada:', ticketsForSelectedDate);
          const ticketsWithPriority = ticketsForSelectedDate.map(ticket => ({
            ...ticket,
            id: ticket.id,
            priority: ticket.priority || 'Media'
          }));
          console.log('Tickets con prioridad:', ticketsWithPriority);
          setSelectedDateTickets(ticketsWithPriority);
          setShowPlanibotDetail(true);
          setSelectedDate(selectedDate);
          
          setTimeout(() => {
            console.log('Reseteando isProcessingPlanibot');
            setIsProcessingPlanibot(false);
          }, 500);
        } else {
          console.log('Planibot ya está siendo procesado, ignorando la solicitud');
        }
        break;
      default:
        break;
    }
    setMenuPosition(null);
  }, [tickets, isProcessingPlanibot]);

  const memoizedPopUp = useMemo(() => {
    if (showPopUp) {
      return (
        <ResumeTarea
          onClose={() => setShowPopUp(false)}
          selectedDateTime={selectedDateTime}
          isOpen={true}
          fullScreen={true}
          type={type}
        />
      );
    }
    return null;
  }, [showPopUp, selectedDateTime, type]);

  const renderExtraTickets = (extraTickets) => (
    <div className="extra-tickets-tooltip">
      {extraTickets.map((ticket) => (
        <DraggableTicket
          key={ticket.id}
          currentUser={currentUser}
          ticket={ticket}
          style={{
            ...getTicketStyle(ticket.color, isTicketVencido(ticket)),
            marginBottom: "5px",
            whiteSpace: "normal",
            overflow: "visible",
            textOverflow: "clip",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleTicketClick(e, ticket);
          }}
        />
      ))}
    </div>
  );

  const renderTicket = useCallback((ticket, dia, mes, anio, allTickets) => {
    const fechaInicio = moment(ticket.vencimiento, "DD-MM-YYYY");
    const fechaFin = moment(ticket.fechaFin || ticket.vencimiento, "DD-MM-YYYY");
    const fechaActual = moment([anio, mes, dia]);
    const isMultiDay = !fechaInicio.isSame(fechaFin, 'day');
    const position = getTicketPosition(ticket, allTickets, dia, mes, anio);

    if (!isMultiDay) {
      return (
        <DraggableTicket
          key={ticket.id}
          currentUser={currentUser}
          ticket={ticket}
          style={getTicketStyle(ticket.color, isTicketVencido(ticket), false, false, false, null, position)}
          onClick={(e) => handleTicketClick(e, ticket)}
        />
      );
    }

    const diaSemana = obtenerDiaSemana(dia, mes, anio);
    const diasHastaFinSemana = 6 - diaSemana;
    
    const isStart = fechaActual.isSame(fechaInicio, 'day');
    const isNewWeekStart = diaSemana === 0 && fechaActual.isBetween(fechaInicio, fechaFin, 'day', '[]');
    
    if (!isStart && !isNewWeekStart) return null;

    let width;
    const fechaFinSemana = moment(fechaActual).add(diasHastaFinSemana, 'days');
    const isLastWeek = fechaFin.isBetween(fechaActual, fechaFinSemana, 'day', '[]');

    if (isNewWeekStart) {
      // Para el inicio de nueva semana
      const diasRestantes = fechaFin.diff(fechaActual, 'days') + 2;
      const diasEnEstaSemana = Math.min(diasRestantes, 7);
      width = `calc(${diasEnEstaSemana * 100}% + ${(diasEnEstaSemana - 1) * 2}px)`;
    } else {
      // Para el inicio del ticket o última semana
      const diasHastaFin = fechaFin.diff(fechaActual, 'days') + 1;
      const diasEnEstaSemana = isLastWeek ? 
        diasHastaFin : 
        Math.min(diasHastaFin, diasHastaFinSemana + 1);
      width = `calc(${diasEnEstaSemana * 100}% + ${(diasEnEstaSemana - 1) * 2}px)`;
    }

    const isEnd = fechaFin.isSame(fechaActual.add(width.split('calc(')[1].split('%')[0] - 100, 'days'), 'day');

    return (
      <div
        key={`${ticket.id}-${dia}-${mes}-${anio}`}
        className="calendario__ticket-mensual--multidia"
        style={getTicketStyle(ticket.color, isTicketVencido(ticket), true, isStart, isEnd, width, position)}
        onClick={(e) => handleTicketClick(e, ticket)}
      >
        <span className="ticket-title-mes">{ticket.title}</span>
      </div>
    );
  }, [currentUser, handleTicketClick]);

  const calendarTable = useMemo(() => {
    const { diasMes, primerDiaMes, diasMesAnterior } = diasDelMes;
    
    return (
      <table className="calendario__tabla">
        <thead>
          <tr>
            {diasSemana.map((dia, index) => (
              <th key={index}>{dia}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({
            length: Math.ceil((primerDiaMes + diasMes) / 7),
          }).map((_, weekIndex) => (
            <tr key={weekIndex}>
              {Array.from({ length: 7 }).map((_, dayIndex) => {
                const dia = weekIndex * 7 + dayIndex - primerDiaMes + 1;
                const diaAnterior = diasMesAnterior - (primerDiaMes - dayIndex) + 1;
                if (dia > 0 && dia <= diasMes) {
                  const ticketsDia = obtenerTicketsDia(dia, mesActual, anioActual);
                  const ticketsVisibles = ticketsDia.slice(0, MAX_TICKETS_VISIBLE);
                  const ticketsExtra = ticketsDia.slice(MAX_TICKETS_VISIBLE);
                  return (
                    <td
                      key={dayIndex}
                      className={`calendario__dia_mensual ${hoveredDay === dia ? "calendario__dia-hovered_mensual" : ""
                        }`}
                      onMouseEnter={() => setHoveredDay(dia)}
                      onMouseLeave={() => setHoveredDay(null)}
                      onClick={(e) => handleClickCell(e, dia, mesActual, anioActual)}
                      onDragOver={() => setDraggedOverCell({ dia, mes: mesActual, anio: anioActual })}
                    >
                      <div className={`calendario__dia-numero_mensual ${isToday(dia, mesActual, anioActual) ? 'calendario__dia-actual' : ''
                        }`}>
                        {dia}
                      </div>
                      <div className="calendario__tickets-container">
                        {ticketsVisibles.map((ticket) => 
                          renderTicket(ticket, dia, mesActual, anioActual, ticketsDia)
                        )}
                        {ticketsExtra.length > 0 && (
                          <Tooltip
                            title={renderExtraTickets(ticketsExtra)}
                            placement="bottom"
                            arrow
                          >
                            <div className="calendario__tickets-extra">
                              y {ticketsExtra.length} más
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  );
                } else if (dia <= 0) {
                  const ticketsDiaAnterior = obtenerTicketsDia(
                    diaAnterior,
                    mesActual - 1,
                    anioActual
                  );
                  const ticketsVisiblesAnterior = ticketsDiaAnterior.slice(
                    0,
                    MAX_TICKETS_VISIBLE
                  );
                  const ticketsExtraAnterior = ticketsDiaAnterior.slice(MAX_TICKETS_VISIBLE);
                  return (
                    <td
                      key={dayIndex}
                      className={`calendario__dia-anterior_mensual ${hoveredDay === diaAnterior ? "calendario__dia-hovered_mensual" : ""
                        }`}
                      onMouseEnter={() => setHoveredDay(diaAnterior)}
                      onMouseLeave={() => setHoveredDay(null)}
                      onClick={(e) => handleClickCell(e, diaAnterior, mesActual - 1, anioActual)}
                      onDragOver={() => setDraggedOverCell({
                        dia: diaAnterior,
                        mes: mesActual - 1,
                        anio: anioActual,
                      })}
                    >
                      <div className="calendario__dia-numero_mensual">
                        {diaAnterior}
                      </div>
                      <div className="calendario__tickets-container">
                        {ticketsVisiblesAnterior.map((ticket) => (
                          <DraggableTicket
                            key={ticket.id}
                            currentUser={currentUser}
                            ticket={ticket}
                            style={getTicketStyle(ticket.color, isTicketVencido(ticket))}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTicketClick(e, ticket);
                            }}
                          />
                        ))}
                        {ticketsExtraAnterior.length > 0 && (
                          <Tooltip
                            title={renderExtraTickets(ticketsExtraAnterior)}
                            placement="bottom"
                            arrow
                            
                          >
                            <div className="calendario__tickets-extra">
                              y {ticketsExtraAnterior.length} más
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={dayIndex}
                      className="calendario__dia-vacio_mensual"
                    ></td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [diasDelMes, hoveredDay, obtenerTicketsDia, renderTicket, mesActual, anioActual]);

  return (
    <div className="calendar-todo-container">
      <div className="calendario__mes_mensual" ref={drop}>
        <div className="calendario__dias_mensual">
          {calendarTable}
        </div>
        {selectedTicket && (
          <DetalleTarea
            tarea={selectedTicket}
            onClose={handleCloseDetalleTarea}
            onEdit={(updatedTask) => setSelectedTicket(updatedTask)}
          />
        )}
        {menuPosition && (
          <MenuAddCalendar
            position={menuPosition}
            onAddItem={(item) => handleAddItem(item, selectedDateTime.date.split('-')[2], mesActual, anioActual)}
            onClose={() => setMenuPosition(null)}
            emailBusiness={businessEmail}
            idTicket={selectedTicket?.id}
            evento={selectedTicket?.title}
            descripcion={selectedTicket?.descripcion}
            fechaInicio={selectedDateTime?.date}
            fechaVencimiento={selectedDateTime?.date}
            businessName={"Planius"}
            horaInicio={selectedDateTime?.time}
            horaTermino={selectedDateTime?.time}
          />
        )}
        {memoizedPopUp}
        {showGoogleMeets && (
          <GoogleMeets
            isOpen={showGoogleMeets}
            onClose={() => setShowGoogleMeets(false)}
            emailBusiness={businessEmail}
            idTicket={selectedTicket?.id}
            evento={selectedTicket?.title}
            descripcion={selectedTicket?.descripcion}
            fechaInicio={selectedDateTime?.date}
            fechaVencimiento={selectedDateTime?.date}
            businessName={"Planius"}
            horaInicio={selectedDateTime?.time}
            horaTermino={selectedDateTime?.time}
          />
        )}
      </div>

      <TabsContainer
        tickets={tickets}
        setTickets={setTickets}
        timeZone={timeZone}
        reordenarEventosConIA={reordenarEventosConIA}
        isVisible={isTabsVisible}
        manejarActualizacionTareasPendientes={setTareasPendientes}
      />

      {showPlanibotDetail && (
        <PlanibotDetail
          isOpen={showPlanibotDetail}
          onClose={() => {
            setShowPlanibotDetail(false);
            console.log('PlanibotDetail cerrado');
          }}
          tickets={selectedDateTickets}
          tareasPendientes={tareasPendientes}
          fecha={selectedDate}
          criterioUsuario={"ganar más dinero"}
          businessEmail={businessEmail}
          onTasksUpdated={(updatedTicketId) => {
            setTickets(prevTickets => prevTickets.filter(ticket => ticket.id !== updatedTicketId));
            console.log('Tarea actualizada:', updatedTicketId);
          }}
        />
      )}

      {showPlanibotDetail && console.log('Datos pasados a PlanibotDetail:', {
        tickets: selectedDateTickets,
        tareasPendientes,
        fecha: selectedDate,
        criterioUsuario: "ganar más dinero",
        businessEmail
      })}

    </div>
  );
};

export default React.memo(CalendarMes, (prevProps, nextProps) => {
  return (
    prevProps.mesActual === nextProps.mesActual &&
    prevProps.anioActual === nextProps.anioActual &&
    prevProps.tickets === nextProps.tickets &&
    prevProps.isTabsVisible === nextProps.isTabsVisible
  );
});
