// ImageBox.jsx
import React, { useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { uploadFile } from '../Functions'; // Importa la función de carga de archivos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './ImageBox.css';

const ItemType = 'ITEM';

function ImageBox({ item, index, setItems, items }) {
  const [url, setUrl] = useState(item.content);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        const newItems = Array.from(items);
        const [movedItem] = newItems.splice(draggedItem.index, 1);
        newItems.splice(index, 0, movedItem);
        setItems(newItems);
        draggedItem.index = index;
      }
    },
    drop: (draggedItem, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const newItems = Array.from(items);
      const movedItem = newItems.find((i) => i.id === draggedItem.id);
      if (movedItem) {
        movedItem.position = {
          x: Math.round(item.position.x + delta.x),
          y: Math.round(item.position.y + delta.y),
        };
        setItems(newItems);
      }
    },
  });

  drag(drop(ref));

  const handleChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > 3000000) {
      alert("La imagen no debe superar los 3 MB");
      return;
    }
    setIsUploading(true);
    const filePath = `images/${file.name}`;
    try {
      const imageUrl = await uploadFile(file, filePath);
      setUrl(imageUrl);
      setIsUploading(false);
      const newItems = Array.from(items);
      const currentItem = newItems.find((i) => i.id === item.id);
      if (currentItem) {
        currentItem.content = imageUrl;
        setItems(newItems);
      }
    } catch (error) {
      console.error("Error uploading file: ", error);
      setIsUploading(false);
      alert("Error uploading file");
    }
  };

  const handleEnlarge = () => {
    setIsEnlarged(true);
  };

  const handleCloseEnlarged = () => {
    setIsEnlarged(false);
  };

  return (
    <>
      <div
        ref={ref}
        className="element"
        style={{
          opacity: isDragging ? 0.5 : 1,
          left: item.position.x,
          top: item.position.y,
          position: 'absolute',
        }}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <button className="button" onClick={() => fileInputRef.current.click()}>
          <FontAwesomeIcon icon={faEdit} className="icon" />
          {url ? 'Cambiar Imagen' : 'Subir Imagen'}
        </button>
        {isUploading ? (
          <div className="uploading">Subiendo imagen...</div>
        ) : (
          url && (
            <div className="image-container">
              <img
                src={url}
                alt="Imagen"
                style={{ maxWidth: '220px', maxHeight: '220px', cursor: 'pointer', padding: '20px', backgroundColor: 'white', borderRadius: '8px' }}
                onClick={handleEnlarge}
              />
            </div>
          )
        )}
      </div>
      {isEnlarged && (
        <div className="enlarged-image-container" onClick={handleCloseEnlarged}>
          <img src={url} alt="Imagen Ampliada" className="enlarged-image" />
          <button className="close-button" onClick={handleCloseEnlarged}>X</button>
        </div>
      )}
    </>
  );
}

export default ImageBox;
