import React from "react";
import { useDrag } from "react-dnd";

const TaskItem = ({ task, onOpenMenu, position, onPositionChange, style, priorityColor }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "TICKET",
    item: task,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleClick = (event) => {
    event.stopPropagation();
    onOpenMenu(event, task);
  };

  return (
    <div
      ref={drag}
      className="todo-task-item"
      style={{
        ...style,
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move'
      }}
      onClick={handleClick}
    >
      <div 
        className="priority-dot"
        style={{
          backgroundColor: priorityColor
        }}
      />
      <div className="task-content">
        <h3>{task.title}</h3>
      </div>
    </div>
  );
};

export default TaskItem;