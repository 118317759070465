import React, { useContext, useState, useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { TextField } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { getAgents, queryAgents } from "../Functions";
import { onSnapshot, doc, deleteDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import "./RbAgents.css";
import Avatar from "react-avatar";
import AddAgentsModal from "../AddAgentsModal";

Modal.setAppElement("#root");

function RbAgents() {
  const { currentUser } = useContext(AuthContext);
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [busqueda, setBusqueda] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteAgent, setDeleteAgent] = useState("");
  const itemsPerPage = 5;
  const idBusiness = sessionStorage.getItem("businessId");
  const addAgentsModalRef = useRef(null);

  const handleOpenModalDelete = () => {
    setModalIsOpen(true);
  };

  const handleCloseModalDelete = () => {
    setModalIsOpen(false);
  };

  const handleDelete = async (agentEmail) => {
    try {
      await deleteDoc(doc(db, "business", idBusiness, "agents", agentEmail));
    } catch (error) {
      console.log(error);
    } finally {
      handleCloseModalDelete();
    }
  };

  const filteredAgents = agents.filter((agent) =>
    agent.email
      ? agent.email.toLowerCase().includes(busqueda.toLowerCase())
      : false
  );

  const currentItems = filteredAgents.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  useEffect(() => {
    if (idBusiness) {
      const q = queryAgents(idBusiness);
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const items = getAgents(querySnapshot);

        // Obtener la información de la foto para cada agente
        const agentsWithPhotos = await Promise.all(
          items.map(async (agent) => {
            const userDocRef = doc(db, "users", agent.email);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              return { ...agent, photoURL: userData.photoURL };
            }
            return agent;
          })
        );

        setAgents(agentsWithPhotos);
      });
      return unsubscribe;
    }
  }, [currentUser, idBusiness]);

  return (
    <div>
      <div className="bigCard2">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">Colaboradores</h1>
            </div>
            <div className="divTextAgents">
              <span className="textAgents">
                Identifica quiénes serán tus colaboradores de equipo y asígnales
                privilegios.
              </span>
            </div>
          </div>
          <input
            type="text"
            placeholder="Buscar Agente"
            onChange={(e) => setBusqueda(e.target.value)}
            onClick={() => setCurrentPage(0)}
            className="inputBusqueda2"
            autoComplete="off"
          />
          <div className="agents">
            {currentItems.map((agent, index) => (
              <div key={index} className="agentsGlobe">
                <div className="agentSection">
                  {agent.photoURL ? (
                    <img
                      src={agent.photoURL}
                      alt={agent.name}
                      className="agentPhoto"
                    />
                  ) : (
                    <Avatar name={agent.name} size="50" round={true} />
                  )}
                </div>
                <div className="agentSection agentInfo">
                  <h3 className="textGlobe">
                    <strong>Nombre:</strong> &nbsp; {agent.name} &nbsp;
                  </h3>
                  <h3 className="textGlobe">
                    <strong>Email:</strong> &nbsp; {agent.email} &nbsp;
                  </h3>
                  <h3 className="textGlobe">
                    <strong>Tipo:</strong>&nbsp;
                    {agent.userType === "agent" ? "Colaborador" : "Administrador"}
                    &nbsp;
                  </h3>
                </div>
                <div className="agentSection">
                  <button
                    onClick={() => {
                      handleOpenModalDelete();
                      setDeleteAgent(agent.email);
                    }}
                    className="deleteListArea"
                  >
                    <FontAwesomeIcon icon={faDeleteLeft} />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <button
            className="popupButton"
            onClick={() => addAgentsModalRef.current.handleOpen()}
          >
            Agregar
          </button>
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Siguiente"}
            breakLabel={""}
            pageCount={Math.ceil(filteredAgents.length / itemsPerPage)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={"ticketsNuevos__pagination"}
            activeClassName={"active"}
            previousClassName={"custom-previous-button"}
            nextClassName={"custom-next-button"}
            previousLinkClassName={"btnAnterior"}
            nextLinkClassName={"btnSiguiente"}
            pageClassName={"custom-page-number"}
            pageLinkClassName={"custom-page-link"}
          />
          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModalDelete}
          >
            <h2 className="titleModal">
              ¿Desea Eliminar Colaborador del proyecto?
            </h2>
            <div className="descriptionModal">
              <p>Se borrará del proyecto, aunque puede invitarlo nuevamente.</p>
            </div>
            <div className="row">
              <button
                className="btnCloseModal"
                onClick={() => handleDelete(deleteAgent)}
              >
                Sí
              </button>
              <button
                className="btnCloseModal"
                onClick={handleCloseModalDelete}
              >
                No
              </button>
            </div>
          </Modal>
        </div>
      </div>
      <AddAgentsModal ref={addAgentsModalRef} />
    </div>
  );
}

export default RbAgents;
