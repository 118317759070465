import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ImageUploader from "../ImageUploader";
import "../registerBusiness/InputRegister.css";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";

function EditBusiness({ board, isOpen, onClose, onUpdate }) {
  const [businessName, setBusinessName] = useState("");
  const [description, setDescription] = useState("");
  const [objetivo, setObjetivo] = useState("");
  const [color, setColor] = useState("#10439F");
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const colors = [
    "#C70039", // Morado
    "#10439F", // Azul
    "#E63946", // Rojo coral
    "#F4A261", // Naranja suave
    "#2A9D8F", // Verde esmeralda
    "#E9C46A", // Amarillo dorado
    "#9C27B0", // Púrpura vibrante
    "#00B4D8", // Cian claro
    "#6D6875", // Gris lavanda
    "#264653", // Verde azulado oscuro
    "#2C3E50", // Azul oscuro
    "#A8DADC", // Azul hielo
    "#FFB4A2", // Rosa salmón
    "#8D99AE", // Gris azulado
    "#FFD700", // Dorado
    "#FF6F61", // Coral
  ];

  useEffect(() => {
    if (board) {
      setBusinessName(board.businessName);
      setDescription(board.description);
      setObjetivo(board.objetivo);
      setColor(board.color);
    }
  }, [board]);

  const handleColorClick = () => {
    setShowColorPalette(!showColorPalette);
  };

  const handleColorSelect = (selectedColor) => {
    setColor(selectedColor);
    setShowColorPalette(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const boardRef = doc(db, "business", board.id);
      const updatedData = {
        businessName,
        description,
        objetivo,
        color,
      };

      if (file instanceof Blob) {
        const storageRef = ref(storage, `business/${board.id}/image`);
        await uploadBytesResumable(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        updatedData.img = downloadURL;
      }

      await updateDoc(boardRef, updatedData);
      onUpdate({ id: board.id, ...updatedData });
      onClose();
      window.location.reload(); // Recargar la página después de actualizar
    } catch (error) {
      console.error("Error al actualizar el tablero:", error);
    }
    setLoading(false);
  };

  return (
    <Modal
      overlayClassName={"popupBackground"}
      className={"popupContainer"}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Editar Proyecto</h2>
      </div>
      <div className="popupContent">
        <div style={{ maxHeight: "65vh", overflow: "scroll" }}>
          <input
            type="text"
            placeholder="Nombre del proyecto"
            className="inputRegister"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Descripción del proyecto"
            className="inputRegister"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            type="text"
            placeholder="Objetivo del proyecto"
            className="inputRegister"
            value={objetivo}
            onChange={(e) => setObjetivo(e.target.value)}
          />
          <div className="colorPickerContainer">
            <h3>Seleccione el color:</h3>
            <div
              className="colorBox"
              style={{ backgroundColor: color }}
              onClick={handleColorClick}
            ></div>
            {showColorPalette && (
              <div className="colorPalettePopup2">
                <div className="colorPaletteContainer">
                  {colors.map((colorOption, index) => (
                    <div
                      key={index}
                      className="colorOption"
                      style={{ backgroundColor: colorOption }}
                      onClick={() => handleColorSelect(colorOption)}
                    ></div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <ImageUploader onImageChange={setFile} type="1" />
        </div>
        <div className="popupActions">
          <div className="row" style={{ margin: "10px", padding: "10px" }}>
            <button className="popupButton" onClick={onClose}>
              Cancelar
            </button>
            <button
              className="popupButton"
              onClick={handleUpdate}
              disabled={loading}
            >
              {loading ? "Cargando..." : "Actualizar"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditBusiness;
