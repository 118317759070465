import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { doc, getDoc, setDoc, updateDoc, arrayUnion, collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import subscribeToCalendar from "./subscribeToCalendar";
import { cancelAllSubscriptions } from "./cancelAllSubscriptions";
import { sincronizarDesdeGoogleCalendar } from "./calendario/FunctionsCalendar";
import google from './img/google.png'; // Ajusta la ruta si es necesario
import { getAuth } from "firebase/auth";

const getAuthorizationCode = async () => {
  try {
    const response = await axios.get(
      "https://us-central1-planiusai.cloudfunctions.net/api"
    );
    window.location.href = response.data.authUrl;
  } catch (error) {
    console.error("Error getting authorization URL:", error);
  }
};

const createBusinessDocument = async (userEmail, userName) => {
  try {
    const businessDocRef = await addDoc(collection(db, "business"), {
      businessName: userEmail,
      admin: userEmail,
    });

    const businessId = businessDocRef.id;
    sessionStorage.setItem("businessId", businessId);
    sessionStorage.setItem("nameBusiness", userEmail);

    return businessId;
  } catch (err) {
    console.error("Error creating business document: ", err);
    throw err;
  }
};

const GoogleAuth = () => {
  const navigate = useNavigate();
  const { setCurrentUser, setBusinessEmail } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [progressMessage, setProgressMessage] = useState("");

  useEffect(() => {
    const handleSignIn = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const tokens = urlParams.get("tokens");
        if (!tokens) {
          console.error("No tokens found");
          navigate("/login");
          return;
        }

        const parsedTokens = JSON.parse(decodeURIComponent(tokens));

        if (!parsedTokens.access_token) {
          console.error("Access token not received");
          navigate("/login");
          return;
        }

        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v2/userinfo",
          {
            headers: {
              Authorization: `Bearer ${parsedTokens.access_token}`,
            },
          }
        );

        const user = userInfoResponse.data;
        console.log("User info received:", user);

        const userDocRef = doc(db, "users", user.email);
        const userDoc = await getDoc(userDocRef);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        let refreshToken = parsedTokens.refresh_token;
        if (!refreshToken && userDoc.exists()) {
          refreshToken = userDoc.data().refreshToken;
        }

        let businessIds = [];
        let activeBusinessId = "";
        let isFirstTimeUser = false;

        if (!userDoc.exists()) {
          setProgressMessage("Creando perfil de usuario...");
          await setDoc(userDocRef, {
            uid: user.id,
            displayName: user.name,
            email: user.email,
            photoURL: user.picture,
            userType: "standard",
            status: "activo",
            date: new Date(),
            timezone,
            initView: "calendario",
            accessToken: parsedTokens.access_token,
            refreshToken,
            business: [],
          });

          activeBusinessId = await createBusinessDocument(user.email, user.name);
          businessIds = [activeBusinessId];
          isFirstTimeUser = true;

          await updateDoc(userDocRef, {
            business: arrayUnion({
              id: activeBusinessId,
              userType: "admin",
              businessName: user.email,
              status: "activo",
            }),
          });
        } else {
          const userData = userDoc.data();
          if (!userData.business || userData.business.length === 0) {
            activeBusinessId = await createBusinessDocument(user.email, user.name);
            businessIds = [activeBusinessId];
            isFirstTimeUser = true;

            await updateDoc(userDocRef, {
              business: arrayUnion({
                id: activeBusinessId,
                userType: "admin",
                businessName: user.email,
                status: "activo",
              }),
            });
          } else {
            businessIds = userData.business.map((b) => b.id);
            activeBusinessId = businessIds[0];
          }
        }

        sessionStorage.setItem("businessId", activeBusinessId);
        setCurrentUser(user);
        setBusinessEmail(activeBusinessId);

        if (isFirstTimeUser) {
          navigate("/crearPerfil");
        } else {
          setProgressMessage("Despertando a Planibot... 🤖💤");
          await cancelAllSubscriptions(parsedTokens.access_token, user.id);

          setProgressMessage("Hablando con Google... 🤓");
          const subscribed = await subscribeToCalendar(
            parsedTokens.access_token,
            refreshToken,
            () => {},
            user.id,
            user.email
          );

          if (!subscribed.success) {
            console.error("El token de acceso ha expirado o no es válido. Por favor, vuelva a iniciar sesión.");
            sessionStorage.removeItem("accessToken");
            return;
          }

          sessionStorage.setItem("channelId", subscribed.channelId);

          setProgressMessage("Sincronizando eventos...📆");
          await sincronizarDesdeGoogleCalendar(activeBusinessId, user);

          setProgressMessage("...");
          navigate("/gestionarTickets");
        }
      } catch (error) {
        console.error("Error during sign in process:", error);
      } finally {
        setLoading(false);
        setProgressMessage("");
      }
    };

    handleSignIn();
  }, [navigate, setCurrentUser, setBusinessEmail]);

  return loading ? <div>{progressMessage || "Iniciando sesión..."}</div> : null;
};

const SignInWithGoogle2 = () => {
  useEffect(() => {
    getAuthorizationCode();
  }, []);

  return <div>Redireccionando a Google...</div>;
};
const GoogleLogin = () => {
  const [loading, setLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { setCurrentUser, setBusinessEmail } = useContext(AuthContext);

  const handleLogin = async () => {
    setLoading(true);
    setProgressMessage('Iniciando sesión con Google...');

    try {

      const redirectUri =
        process.env.REACT_APP_AMBIENTE === "dev"
          ? "http://localhost:8888/googleAuth"
          : "https://app.planius.ai/googleAuth";
    
      const getAuthURLFunction =
        'https://us-central1-planiusai.cloudfunctions.net/api/authUrl'; 
    
      // Incluye redirectUri dentro de params
      const response = await axios.get(getAuthURLFunction, {
        params: { 
          state: 'optional_secure_state', // Implementa un estado seguro
          redirectUri, // Pasa redirectUri correctamente
        },
      });

      const { authUrl } = response.data;

      if (!authUrl) {
        throw new Error("No authUrl returned from backend.");
      }

      // Redirige al usuario a la pantalla de consentimiento de Google OAuth
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error al obtener la URL de autenticación:', error);
      setErrorMessage('Error al iniciar sesión con Google');
      setLoading(false);
      setProgressMessage('');
    }
  };

  return (
    <div className="google-login-container">
      <button
        type="button"
        onClick={handleLogin}
        className="googleBtn-loginform"
        disabled={loading}
      >
        {loading ? (
          <div className="spinnerAcept"></div>
        ) : (
          <span className="btnContent-loginform">
            <img className="googleLogo-loginform" src={google} alt="Google" />
            <span>Continuar con Google</span>
          </span>
        )}
      </button>
      {loading && (
        <div className="progressMessage-loginform">{progressMessage}</div>
      )}
      {errorMessage && (
        <p className="errorMessage-loginform">{errorMessage}</p>
      )}
    </div>
  );
};

export default GoogleLogin;
export { GoogleAuth, SignInWithGoogle2 };
