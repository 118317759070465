import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import Modal from "react-modal";
import { TextField, Box } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { sendMail } from "./SendMail";
import { setDoc, doc, getDoc, arrayUnion } from "firebase/firestore";
import { db } from "./firebase";

const AddClientsModal = forwardRef((props, ref) => {
  const { businessName } = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [clientEmail, setClientEmail] = useState("");
  const [clientName, setClientName] = useState("");
  const [loading, setLoading] = useState(false);
  const idBusiness = sessionStorage.getItem("businessId");
  const nameBusiness = sessionStorage.getItem("nameBusiness");

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    cleanFields();
  };

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpenModal,
    };
  });

  function cleanFields() {
    setClientEmail("");
    setClientName("");
  }

  const handleClient = async () => {
    setLoading(true);
    try {
      const clientDocRef = doc(db, "business", idBusiness, "clients", clientEmail);
      const clientDocSnap = await getDoc(clientDocRef);

      if (clientDocSnap.exists()) {
        alert(
          "Este correo ya está registrado como cliente. Pruebe agregando un correo distinto."
        );
      } else {
        try {
          const newClientBusiness = {
            id: idBusiness,
            userType: "cliente",
            businessName: nameBusiness,
          };
          await setDoc(
            doc(db, "users", clientEmail),
            {
              business: arrayUnion(newClientBusiness),
            },
            { merge: true }
          );

          await setDoc(
            doc(db, "business", idBusiness, "clients", clientEmail),
            {
              email: clientEmail,
              userType: "cliente",
              name: clientName,
              status: "pendiente",
            },
            { merge: true }
          );

          // Enviar el correo de invitación después de actualizar Firestore exitosamente
          await sendMail(
            clientEmail,
            "Invitación de registro como cliente en Planius.ai",
            `Hola ${clientName}, te han invitado a registrarte en Planius.ai como Cliente en el proyecto ${businessName}. Para empezar a usar la plataforma debes registrarte en https://app.planius.ai/registrarusuarios.`
          );
          alert("¡Invitación enviada de forma satisfactoria!");
          handleCloseModal();
        } catch (err) {
          alert("Hubo un error al procesar su solicitud. Intente nuevamente.");
        }
      }
    } catch (err) {
      console.error("Error al verificar el usuario en Firestore:", err);
      alert("Hubo un error al verificar la información. Intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        overlayClassName={"modal-overlay"}
        className={"modal-content"}
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
      >
        <h2 className="titleModal">Agregar Cliente</h2>
        <div
          style={{
            maxHeight: "50vh",
            overflow: "scroll",
            margin: "auto",
            justifyContent: "center",
            textAlign: "center",
            padding: "20px",
          }}
        >
          <div className="descriptionModal">
            <p>
              Identifica quién será tu cliente y envíale una invitación. Recibirá un Email indicando que fue invitado a participar en tu proyecto.
            </p>
            <br />
          </div>
          <TextField
            id="outlined-basic"
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
              style: { fontFamily: "Poppins", fontSize: "calc(100vh/60)" },
            }}
            InputProps={{
              style: {
                fontFamily: "Poppins",
                fontSize: "calc(100vh/60)",
                height: "calc(100vh/20)",
              },
            }}
            label="Email"
            variant="outlined"
            value={clientEmail}
            onChange={(e) => {
              setClientEmail(e.target.value);
            }}
          />
          <p />
          <TextField
            id="outlined-basic2"
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
              style: { fontFamily: "Poppins", fontSize: "calc(100vh/60)" },
            }}
            InputProps={{
              style: {
                fontFamily: "Poppins",
                fontSize: "calc(100vh/60)",
                height: "calc(100vh/20)",
              },
            }}
            label="Nombre y Apellido"
            variant="outlined"
            value={clientName}
            onChange={(e) => {
              setClientName(e.target.value);
            }}
          />
        </div>

        <div className="buttonsModal">
          <button className="btnCloseModal" onClick={handleCloseModal}>
            Cancelar
          </button>
          <button
            className="btnCloseModal"
            onClick={handleClient}
            disabled={loading}
          >
            {loading ? "Cargando..." : "Agregar"}
          </button>
        </div>
      </Modal>
    </div>
  );
});

export default AddClientsModal;
