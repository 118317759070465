import React, { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import './CheckList.css';

const ItemType = 'ITEM';

function CheckList({ item, index, setItems, items }) {
  const [tasks, setTasks] = useState(Array.isArray(item.content) ? item.content : []);
  const [newTask, setNewTask] = useState('');
  const [isEditing, setIsEditing] = useState(null);
  const [editTask, setEditTask] = useState('');
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        const newItems = Array.from(items);
        const [movedItem] = newItems.splice(draggedItem.index, 1);
        newItems.splice(index, 0, movedItem);
        setItems(newItems);
        draggedItem.index = index;
      }
    },
    drop: (draggedItem, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const newItems = Array.from(items);
      const movedItem = newItems.find((i) => i.id === draggedItem.id);
      if (movedItem) {
        movedItem.position = {
          x: Math.round(item.position.x + delta.x),
          y: Math.round(item.position.y + delta.y),
        };
        setItems(newItems);
      }
    },
  });

  drag(drop(ref));

  const handleAddTask = () => {
    if (newTask.trim()) {
      const updatedTasks = [...tasks, { text: newTask, completed: false }];
      setTasks(updatedTasks);
      setNewTask('');
      updateItemContent(updatedTasks);
    }
  };

  const handleToggleTask = (index) => {
    const updatedTasks = tasks.map((task, idx) => 
      idx === index ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
    updateItemContent(updatedTasks);
  };

  const handleDeleteTask = (taskIndex) => {
    const updatedTasks = tasks.filter((_, idx) => idx !== taskIndex);
    setTasks(updatedTasks);
    updateItemContent(updatedTasks);
  };

  const handleEditTask = (taskIndex) => {
    setIsEditing(taskIndex);
    setEditTask(tasks[taskIndex].text);
  };

  const handleSaveEdit = (taskIndex) => {
    const updatedTasks = tasks.map((task, idx) => 
      idx === taskIndex ? { ...task, text: editTask } : task
    );
    setTasks(updatedTasks);
    setIsEditing(null);
    updateItemContent(updatedTasks);
  };

  const updateItemContent = (updatedTasks) => {
    const newItems = Array.from(items);
    const currentItem = newItems.find((i) => i.id === item.id);
    if (currentItem) {
      currentItem.content = updatedTasks;
      setItems(newItems);
    }
  };

  const completedTasks = tasks.filter(task => task.completed).length;

  return (
    <div
      ref={ref}
      className="element-ws"
      style={{
        opacity: isDragging ? 0.5 : 1,
        left: item.position.x,
        top: item.position.y,
        position: 'absolute',
      }}
    >
      <div className="checklist-container-ws">
        <div className="task-counter-ws">
          {completedTasks}/{tasks.length}
        </div>
        <input
          type="text"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          placeholder="Nueva tarea"
          onKeyDown={(e) => e.key === 'Enter' && handleAddTask()}
        />
        <button onClick={handleAddTask}>Agregar</button>
        <ul className="task-list-ws">
          {tasks.map((task, idx) => (
            <li
              key={idx}
              className={task.completed ? 'completed-ws' : ''}
            >
              {isEditing === idx ? (
                <div className="edit-container">
                  <input
                    type="text"
                    value={editTask}
                    onChange={(e) => setEditTask(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSaveEdit(idx)}
                  />
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => handleSaveEdit(idx)}
                  />
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={() => handleDeleteTask(idx)}
                  />
                </div>
              ) : (
                <>
                  <span
                    className="task-text"
                    onClick={() => handleToggleTask(idx)}
                  >
                    {task.text}
                  </span>
                  <div className="icons">
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => handleEditTask(idx)}
                    />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() => handleDeleteTask(idx)}
                    />
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CheckList;
