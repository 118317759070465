import React from 'react';

function TablaArchivados(props) {
  const { calificacion, title, creator, fechacierre, cerradopor, comentario, timertracker } = props;

  const timerMinutes = (time) => {
    if (time === 0 || time === undefined) {
      return "No hay registro";
    }
    time = time / 60;
    return parseFloat(time.toFixed(2)) + " minutos";
  };

  const getStarRating = (rating) => {
    const stars = "⭐".repeat(rating);
    return <span className="table-star-rating">{stars || "Sin Calificar"}</span>;
  };

  return (
    <tr>
      <td>{title}</td>
      <td>{creator}</td>
      <td>{fechacierre}</td>
      <td>{getStarRating(calificacion)}</td>
      <td>{cerradopor}</td>
      <td>{comentario || "Sin comentarios"}</td>
    </tr>
  );
}

export default TablaArchivados;