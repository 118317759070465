import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import Modal from "react-modal";
import { AuthContext } from "../context/AuthContext";
import { sendMail } from "./SendMail";
import { setDoc, doc, getDoc, arrayUnion } from "firebase/firestore";
import { db } from "./firebase";

const AddAgentsModal = forwardRef((props, ref) => {
  const { businessName } = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [privilegios, setPrivilegios] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [agentName, setAgentName] = useState("");
  const [loading, setLoading] = useState(false);
  const idBusiness = sessionStorage.getItem("businessId");
  const nameBusiness = sessionStorage.getItem("nameBusiness");

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    cleanEmail();
  };

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpenModal,
    };
  });

  function cleanEmail() {
    setAgentEmail("");
    setAgentName("");
    setPrivilegios("");
  }

  const handleTeam = async () => {
    setLoading(true);
    try {
      const userDocRef = doc(db, "business", idBusiness, "agents", agentEmail);
      const userDocSnap = await getDoc(userDocRef);

      // Verifica si el usuario existe en la colección "agents"
      if (userDocSnap.exists()) {
        alert(
          "Este correo ya está registrado como agente. Pruebe agregando un correo distinto."
        );
      } else {
        try {
          const newUserBusiness = {
            id: idBusiness,
            userType: privilegios,
            businessName: nameBusiness,
          };
          await setDoc(
            doc(db, "users", agentEmail),
            {
              business: arrayUnion(newUserBusiness),
            },
            { merge: true }
          );

          await setDoc(
            doc(db, "business", idBusiness, "agents", agentEmail),
            {
              email: agentEmail,
              userType: privilegios,
              name: agentName,
            },
            { merge: true }
          );

          // Enviar el correo de invitación después de actualizar Firestore exitosamente
          await sendMail(
            agentEmail,
            "Invitación de registro como colaborador en Planius.ai",
            `Has sido invitado a registrarte como colaborador en Planius.ai. En el proyecto ${businessName}. Para empezar a usar la plataforma debes registrarte en https://app.planius.ai/registrarusuarios si ya estás registrado inicia sesión en la plataforma y ya podrás empezar a gestionar tareas y ganar recompensas.`
          );
          alert("¡Invitación enviada de forma satisfactoria!");
          handleCloseModal();
        } catch (err) {
          alert("Hubo un error al procesar su solicitud. Intente nuevamente.");
        }
      }
    } catch (err) {
      console.error("Error al verificar el usuario en Firestore:", err);
      alert("Hubo un error al verificar la información. Intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        overlayClassName={"popupBackground"}
        className={"popupContainer"}
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
      >
        <div className="popupHeader">
          <h2 className="popupTitle">Agregar Colaborador</h2>
        </div>

        <div className="popupContent">
          <p>
            Identifica quién será tu colaborador de equipo y asígnale
            privilegios. Recibirá un Email indicando que fue invitado a
            participar en tu proyecto.
          </p>
          <br />

          <input
            type="email"
            className="popupInput"
            placeholder="Email"
            value={agentEmail}
            onChange={(e) => setAgentEmail(e.target.value)}
          />
          <input
            type="text"
            className="popupInput"
            placeholder="Nombre y Apellido"
            value={agentName}
            style={{marginTop:"20px", marginBottom:"20px"}}
            onChange={(e) => setAgentName(e.target.value)}
          />
          <select
            className="popupSelect"
            value={privilegios}
            onChange={(e) => setPrivilegios(e.target.value)}
          >
            <option value="" disabled>
              Selecciona Privilegios
            </option>
            <option value="admin">Administrador</option>
            <option value="agent">Colaborador</option>
          </select>
        </div>

        <div className="popUpRow">
          <button className="popupButton" onClick={handleCloseModal}>
            Cancelar
          </button>
          <button
            className="popupButton"
            onClick={handleTeam}
            disabled={loading}
          >
            {loading ? "Cargando..." : "Agregar"}
          </button>
        </div>
      </Modal>
    </div>
  );
});

export default AddAgentsModal;
