import React, { useState, useRef, useEffect, useContext } from "react";
import moment from "moment-timezone";
import AudioRecorder from "../voiceToText/AudioRecoder";
import './ChatStyles.css';
import { responderUsuario, massiveTaskJson, reordenarEventosConIA } from '../IA/Gpt';
import { Send, Mic, Trash2, Bot } from 'lucide-react';
import { AuthContext } from '../../context/AuthContext';
import ResumeMasiveTask from "../addTarea/ResumeMassiveTask";
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import { createTicketNew } from "../Functions";
import { agregarEventoACalendario } from "../calendario/FunctionsCalendar";
import EventosDisplay from "./EventosDisplay";

const ReorganizarConPlanibot = ({ tickets, timeZone, resetChat, setShowingModal, inputRef }) => {
  const { currentUser, businessEmail, accessToken, refreshToken, tokenExpiry, firebaseAccesToken } = useContext(AuthContext);
  const [mensaje, setMensaje] = useState("");
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [eventosModificados, setEventosModificados] = useState(null);
  const [currentTypingMessage, setCurrentTypingMessage] = useState(null);
  const chatEndRef = useRef(null);
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [jsonResult, setJsonResult] = useState([]);
  const [confirmedTasks, setConfirmedTasks] = useState({});
  const [areas, setAreas] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const audioRecorderRef = useRef(null);

  useEffect(() => {
    console.log("Tickets en ReorganizarConPlanibot:", tickets);
    if (resetChat) {
      setChatHistory([]);
      setEventosModificados(null);
      setCurrentTypingMessage(null);
      setMensaje("");
    }
  }, [resetChat]);

  useEffect(() => {
    const fetchAreas = async () => {
      if (businessEmail) {
        const q = query(collection(db, "business", businessEmail, "areas"));
        const querySnapshot = await getDocs(q);
        const items = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setAreas(items.map(item => item.id));
      }
    };
    fetchAreas();
  }, [businessEmail]);

  const handleInputChange = (e) => setMensaje(e.target.value);

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.target.style.height = '45px';
      handleEnviarMensaje();
    }
  };

  const obtenerEventosSemana = () => {
    const hoy = moment().startOf('day');
    const inicioSemana = hoy.clone().startOf('week');
    const finSemana = hoy.clone().endOf('week');

    const eventos = tickets.filter(ticket => {
      const fechaVencimiento = moment(ticket.vencimiento, "DD-MM-YYYY");
      console.log("Fecha de vencimiento del ticket:", fechaVencimiento.format("DD-MM-YYYY"));
      return fechaVencimiento.isBetween(inicioSemana, finSemana, null, '[]') && 
             (ticket.status === "Pendiente" || ticket.status === "Proceso");
    });

    console.log("Eventos de la semana:", eventos);
    return eventos;
  };

  const typeMessage = (message, type, isLoading = false) => {
    return new Promise((resolve) => {
      if (isLoading) {
        setCurrentTypingMessage({ content: "Cargando solicitud", type, isLoading: true });
        setTimeout(() => {
          setCurrentTypingMessage({ content: "", type, isLoading: false });
          let i = 0;
          const intervalId = setInterval(() => {
            setCurrentTypingMessage(prev => ({ ...prev, content: message.slice(0, i) }));
            i++;
            if (i > message.length) {
              clearInterval(intervalId);
              setCurrentTypingMessage(null);
              setChatHistory(prev => [...prev, { type, content: message }]);
              resolve();
            }
          }, 20);
        }, 1500);
      } else {
        setCurrentTypingMessage({ content: "", type });
        let i = 0;
        const intervalId = setInterval(() => {
          setCurrentTypingMessage(prev => ({ ...prev, content: message.slice(0, i) }));
          i++;
          if (i > message.length) {
            clearInterval(intervalId);
            setCurrentTypingMessage(null);
            setChatHistory(prev => [...prev, { type, content: message }]);
            resolve();
          }
        }, 20);
      }
    });
  };

  const handleEnviarMensaje = async () => {
    if (!mensaje.trim()) return;

    const textarea = document.querySelector('.planichat-input');
    if (textarea) {
      textarea.style.height = '45px';
    }

    const userMessage = { type: 'user', content: mensaje };
    setChatHistory(prev => [...prev, userMessage]);
    setMensaje("");
    setLoading(true);

    try {
      const contextoCompleto = [...chatHistory, userMessage]
        .map(msg => `${msg.type === 'user' ? 'Usuario' : 'Planibot'}: ${msg.content}`)
        .join('\n');

      const eventosSemana = obtenerEventosSemana();
      console.log("Eventos antes de JSON.stringify:", eventosSemana);

      const eventosSimplificados = eventosSemana.map(ticket => ({
        id: ticket.id,
        title: ticket.title,
        vencimiento: ticket.vencimiento,
        horaInicio: ticket.horaInicio,
        horaTermino: ticket.horaTermino,
        status: ticket.status
      }));
      console.log("Eventos simplificados:", eventosSimplificados);

      const jsonEventosSemana = JSON.stringify(eventosSimplificados);
      console.log("Eventos en formato JSON:", jsonEventosSemana);

      const fechaHoraActual = moment().tz(timeZone).format("YYYY-MM-DD HH:mm:ss");

      const respuestaIA = await responderUsuario(mensaje, contextoCompleto, jsonEventosSemana, fechaHoraActual);
      
      // Si la respuesta incluye eventos para mostrar, los mostramos en el formato especial
      if (respuestaIA.eventos && respuestaIA.eventos.length > 0) {
        await typeMessage(respuestaIA.respuesta, 'ai');
        setChatHistory(prev => [...prev, { 
          type: 'eventos', 
          content: respuestaIA.eventos 
        }]);
      } else {
        await typeMessage(respuestaIA.respuesta, 'ai');
      }

      if (respuestaIA.requiereConfirmacion && respuestaIA.solicitud_identificada) {
        setChatHistory(prev => {
          const newHistory = [...prev];
          newHistory[newHistory.length - 1].showActions = true;
          newHistory[newHistory.length - 1].solicitud = respuestaIA.solicitud_identificada;
          return newHistory;
        });
      }
    } catch (error) {
      console.error("Error al procesar el mensaje:", error);
      await typeMessage("Lo siento, hubo un error al procesar tu mensaje.", 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseResumeModal = () => setShowResumeModal(false);

  const procesarSolicitud = async (solicitud) => {
    await typeMessage("Procesando tu solicitud...", 'system');

    const eventosSemana = obtenerEventosSemana();
    const jsonEventos = JSON.stringify(eventosSemana, null, 2);
    const hoy = moment().tz(timeZone).format("YYYY-MM-DD HH:mm:ss");

    try {
      const respuestaModificacion = await reordenarEventosConIA(jsonEventos, solicitud.solicitud_identificada, hoy, solicitud.usar_workspace);
      
      setEventosModificados(respuestaModificacion.eventos);
      await typeMessage(respuestaModificacion.respuesta, 'ai', true);
      setChatHistory(prev => {
        const newHistory = [...prev];
        newHistory[newHistory.length - 1].showActions = true;
        return newHistory;
      });
    } catch (error) {
      console.error("Error al procesar la solicitud:", error);
      let errorMessage = "Lo siento, tuve problemas al procesar tu solicitud. ";
      if (error.message.includes("no es un JSON válido")) {
        errorMessage += "Hubo un problema técnico. Por favor, intenta de nuevo más tarde.";
      } else if (error.message.includes("No se encontraron eventos")) {
        errorMessage += "No pude identificar los cambios necesarios en tu calendario. ¿Podrías ser más específico?";
      } else {
        errorMessage += "¿Podrías reformular tu solicitud de otra manera?";
      }
      await typeMessage(errorMessage, 'error');
    }
  };

  const handleAceptarCambios = async (solicitud) => {
    if (loadingAccept) return;
    setLoadingAccept(true);

    try {
      const tasks = await massiveTaskJson(solicitud.join('\n'), businessEmail);
      setJsonResult(tasks);
      setShowResumeModal(true);
      setShowingModal(true);

      // Eliminar los botones de acción después de aceptar
      setChatHistory(prev => {
        const newHistory = [...prev];
        newHistory[newHistory.length - 1].showActions = false;
        return newHistory;
      });
    } catch (error) {
      console.error("Error al procesar las tareas masivas:", error);
      await typeMessage("Hubo un error al procesar las tareas.", 'error');
    } finally {
      setLoadingAccept(false);
    }
  };

  const handleCancelarCambios = () => {
    setChatHistory(prev => {
      const newHistory = [...prev];
      newHistory[newHistory.length - 1].showActions = false;
      return newHistory;
    });
    typeMessage("De acuerdo, no realizaré ningún cambio. ¿Hay algo más en lo que pueda ayudarte?", 'ai');
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory, currentTypingMessage]);

  const getAreaDetails = async (areaId) => {
    try {
      const areaRef = collection(db, "business", businessEmail, "areas");
      const q = query(areaRef, where("name", "==", areaId));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data();
      } else {
        console.warn("Área no encontrada:", areaId);
        return null;
      }
    } catch (error) {
      console.error("Error al obtener los detalles del área:", error);
      return null;
    }
  };

  const autoResize = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  return (
    <>
      <div className="planichat-container">
        <div className="planichat-history">
          {chatHistory.map((msg, index) => (
            <div key={index} className="planichat-message-container">
              <div className="planichat-message-content">
                {msg.type === 'user' && (
                  <img 
                    src={currentUser?.photoURL || 'ruta/a/imagen/por/defecto.png'} 
                    alt="User" 
                    className="planichat-user-avatar"
                  />
                )}
                {msg.type === 'ai' && (
                  <span className="planichat-ai-avatar">
                    <Bot size={20} />
                  </span>
                )}
                {msg.type === 'eventos' ? (
                  <EventosDisplay eventos={msg.content} />
                ) : (
                  <div className={`planichat-message ${msg.type}`}>
                    {msg.type === 'user' ? 'Tú: ' : msg.type === 'ai' ? ' Planibot: ' : '🔔 Sistema: '}
                    {msg.content}
                  </div>
                )}
              </div>
              {msg.showActions && (
                <div className="planichat-actions">
                  <button 
                    onClick={() => handleAceptarCambios(msg.solicitud)} 
                    className="planichat-action-button accept"
                    disabled={loadingAccept}
                  >
                    {loadingAccept ? (
                      <div className="planichat-button-loading">
                        <div className="planichat-loading-spinner"></div>
                        <span>Procesando...</span>
                      </div>
                    ) : (
                      'Aceptar'
                    )}
                  </button>
                  <button 
                    onClick={handleCancelarCambios} 
                    className="planichat-action-button cancel"
                    disabled={loadingAccept}
                  >
                    Cancelar
                  </button>
                </div>
              )}
            </div>
          ))}
          {currentTypingMessage && (
            <div className="planichat-message-container">
              <div className="planichat-message-content">
                {currentTypingMessage.type === 'ai' && (
                  <span className="planichat-ai-avatar">
                    <Bot size={20} />
                  </span>
                )}
                <div className={`planichat-message ${currentTypingMessage.type}`}>
                  {currentTypingMessage.type === 'user' ? 'Tú: ' : currentTypingMessage.type === 'ai' ? ' Planibot: ' : '🔔 Sistema: '}
                  {currentTypingMessage.isLoading ? (
                    <>
                      {currentTypingMessage.content}
                      <span className="planichat-loading-spinner"></span>
                    </>
                  ) : (
                    <>
                      {currentTypingMessage.content}
                      <span className="planichat-typing-cursor">|</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          <div ref={chatEndRef} />
        </div>
        <div className={`planichat-input-container ${isRecording ? 'recording' : ''}`}>
          {isRecording ? (
            <div className="planichat-recording-controls">
              <div className="planichat-recording-message">
                <span className="recording-dot"></span>
                {loading ? 'Procesando audio...' : 'Grabando...'}
              </div>
              <div className="planichat-recording-buttons">
                <button 
                  className="planichat-cancel-button"
                  onClick={() => {
                    if (audioRecorderRef.current) {
                      audioRecorderRef.current.cancelRecording();
                      setIsRecording(false);
                      setLoading(false);
                    }
                  }}
                  disabled={loading}
                  aria-label="Cancelar grabación"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          ) : (
            <textarea
              ref={inputRef}
              className="planichat-input"
              value={mensaje}
              onChange={handleInputChange}
              onKeyPress={handleInputKeyPress}
              placeholder="Escribe tu mensaje..."
              rows="1"
              onInput={autoResize}
            />
          )}
          <div className="planichat-input-buttons">
            <AudioRecorder 
              ref={audioRecorderRef}
              onTranscription={(text) => {
                if (text) {
                  setMensaje(text);
                  setIsRecording(false);
                  setLoading(false);
                }
              }}
              customButton={(isRecording, toggleRecording) => (
                <button 
                  className={`planichat-record-button ${isRecording ? 'recording' : ''}`}
                  onClick={() => {
                    if (!isRecording) {
                      setIsRecording(true);
                      toggleRecording();
                    } else {
                      setLoading(true);
                      toggleRecording();
                    }
                  }}
                  disabled={loading}
                  aria-label={isRecording ? "Detener grabación" : "Iniciar grabación"}
                >
                  <Mic size={20} />
                  {isRecording && <span className="recording-text">REC</span>}
                </button>
              )}
            />
            {!isRecording && (
              <button 
                onClick={handleEnviarMensaje} 
                disabled={loading || !mensaje.trim()} 
                className="planichat-button"
              >
                {loading ? <div className="planichat-loading-spinner"></div> : <Send size={20} />}
              </button>
            )}
          </div>
        </div>
      </div>
      
      <ResumeMasiveTask
        isOpen={showResumeModal}
        onClose={() => {
          setShowResumeModal(false);
          setShowingModal(false);
          typeMessage("¿Hay algo más en lo que pueda ayudarte?", 'ai');
        }}
        tasks={jsonResult}
        confirmedTasks={confirmedTasks}
        onCheckboxChange={(index) => {
          setConfirmedTasks(prev => ({
            ...prev,
            [index]: !prev[index]
          }));
        }}
        onTimeChange={(index, key, value) => {
          setJsonResult(prev => {
            const newTasks = [...prev];
            newTasks[index][key] = value;
            return newTasks;
          });
        }}
        onDateChange={(index, value) => {
          setJsonResult(prev => {
            const newTasks = [...prev];
            newTasks[index].fecha = value;
            return newTasks;
          });
        }}
        onTypeChange={(index, value) => {
          setJsonResult(prev => {
            const newTasks = [...prev];
            newTasks[index].tipo = value;
            return newTasks;
          });
        }}
        areas={areas}
        onAreaChange={(index, value) => {
          setJsonResult(prev => {
            const newTasks = [...prev];
            newTasks[index].area = value;
            return newTasks;
          });
        }}
        handleCreateTasks={async () => {
          const selectedTasks = jsonResult.filter((task, index) => confirmedTasks[index]);
          if (selectedTasks.length === 0) {
            await typeMessage("No se ha seleccionado ninguna tarea.", 'system');
            return;
          }

          setSuccessMessage(`Agregando 0 de ${selectedTasks.length} tareas seleccionadas...`);

          try {
            let addedTasksCount = 0;
            for (const task of selectedTasks) {
              const {
                fecha,
                horaInicio,
                horaFin,
                título,
                tipo,
                area,
                todoElDia,
                priority,
              } = task;

              const status = fecha ? "Proceso" : "Pendiente";

              const areaDetails = await getAreaDetails(area);
              const color = areaDetails?.color || "#000000";
              const icon = areaDetails?.icon || "📌";

              const formattedFechaCreacion = moment().format("DD-MM-YYYY");
              const formattedFechaVencimiento = fecha ? moment(fecha, "YYYY-MM-DD").format("DD-MM-YYYY") : null;
              const horaCreacion = new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              });
              const fromEmail = currentUser.email;
              const descripcion = título;
              const tituloConIcono = `${icon} ${título}`;
              const businessName = "Proyecto de pruebas";

              const horaInicioFinal = todoElDia ? "Todo el día" : (horaInicio || "09:00");
              const horaFinFinal = todoElDia ? "Todo el día" : (horaFin || "10:00");

              const refDoc = await createTicketNew(
                tituloConIcono,
                businessName,
                priority,
                formattedFechaVencimiento,
                formattedFechaCreacion,
                area,
                status,
                currentUser.email,
                businessEmail,
                descripcion,
                descripcion,
                "No aplica",
                descripcion,
                currentUser.displayName,
                [currentUser.email],
                fromEmail,
                formattedFechaCreacion,
                horaInicioFinal,
                horaFinFinal,
                tipo,
                color,
                todoElDia,
                formattedFechaVencimiento,
              );

              if (status === "Proceso") {
                const fechaInicioISO = moment.tz({ 
                  year: moment(fecha).year(), 
                  month: moment(fecha).month(), 
                  day: moment(fecha).date() 
                }, "America/Santiago").toISOString();
                
                await agregarEventoACalendario(
                  businessEmail,
                  refDoc.id,
                  tituloConIcono,
                  descripcion,
                  fechaInicioISO,
                  fechaInicioISO,
                  horaInicioFinal,
                  horaFinFinal,
                  color,
                  'primary',
                  currentUser,
                  accessToken,
                  refreshToken,
                  tokenExpiry,
                  firebaseAccesToken
                );
              }

              addedTasksCount++;
              setSuccessMessage(`Agregando ${addedTasksCount} de ${selectedTasks.length} tareas seleccionadas...`);
            }

            setSuccessMessage("¡Agregadas todas las tareas y eventos satisfactoriamente!");
            setTimeout(() => {
              setShowResumeModal(false);
              setShowingModal(false);
              typeMessage("¡He agregado todas las tareas seleccionadas! ¿Hay algo más en lo que pueda ayudarte?", 'ai');
            }, 2000);
          } catch (error) {
            console.error("Error al crear los tickets:", error);
            typeMessage("Lo siento, hubo un error al crear las tareas.", 'error');
          }
        }}
        successMessage={successMessage}
        setJsonResult={setJsonResult}
      />
    </>
  );
};

const sortTasksByDateTime = (tasks) => {
  return tasks.sort((a, b) => {
    const dateA = new Date(`${a.fecha}T${a.horaInicio !== "todoElDía" ? a.horaInicio : "00:00"}`);
    const dateB = new Date(`${b.fecha}T${b.horaInicio !== "todoElDía" ? b.horaInicio : "00:00"}`);
    return dateA - dateB;
  });
};

export default ReorganizarConPlanibot;
