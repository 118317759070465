import React, { useEffect, useState } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import './ToWorkSpace.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function ToWorkSpace({ to }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUserPhotos = async () => {
      const usersData = await Promise.all(
        to.map(async (email) => {
          const userDocRef = doc(db, 'users', email);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            return { email, photoURL: userData.photoURL };
          }
          return { email, photoURL: '' };
        })
      );
      setUsers(usersData);
    };

    fetchUserPhotos();
  }, [to]);

  const handleAddUser = () => {
    // Función para manejar la adición de nuevos usuarios
    alert('Agregar nuevo usuario');
  };

  return (
    <div className="to-workspace">
      <div className="add-user-circle" onClick={handleAddUser}>
        <FontAwesomeIcon icon={faPlus} size="lg" />
      </div>
      {users.map((user, index) => (
        <div key={index} className="user-circle">
          {user.photoURL ? (
            <img src={user.photoURL} alt={user.email} className="user-photo" />
          ) : (
            <div className="user-photo-placeholder">{user.email.charAt(0).toUpperCase()}</div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ToWorkSpace;
