import React, { useRef, useContext, useEffect, useState } from "react";
import { doc, deleteDoc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import AddClientsModal from "../AddClientsModal";
import { AuthContext } from "../../context/AuthContext";
import { getClients, queryClients } from "../Functions";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import { sendMail } from "../SendMail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import Avatar from "react-avatar"; // Añadido para la imagen de usuario
import "./RbClients.css";

Modal.setAppElement("#root");

function RbClients() {
  const { currentUser } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [busqueda, setBusqueda] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteClient, setDeleteClient] = useState("");
  const itemsPerPage = 5;
  const idBusiness = sessionStorage.getItem("businessId");
  const addClientsModalRef = useRef(null);

  const handleOpenModalDelete = () => {
    setModalIsOpen(true);
  };

  const handleCloseModalDelete = () => {
    setModalIsOpen(false);
  };

  const handleDeleteClient = async (email) => {
    try {
      await deleteDoc(doc(db, "business", idBusiness, "clients", email));
      await deleteDoc(doc(db, "clients", email, "invitaciones", idBusiness));
      await deleteDoc(doc(db, "clients", email));
    } catch (error) {
      console.log(error);
    } finally {
      handleCloseModalDelete();
    }
  };

  const handleReenviarInvitacion = async (idBusiness, email, name) => {
    let userResponse = window.confirm(
      "¿Desea reenviar la invitación a " + email + "?"
    );

    if (userResponse) {
      try {
        sendMail(
          email,
          "Nueva Invitación en Planius.ai",
          `Hola ${email} te han invitado a ser cliente en Planius, para registrarte ingresa a: https://app.planius.ai/registrarusuarios y crea tu cuenta con el correo: ${email} y asigna una contraseña. Una vez creada tu cuenta, ve al menú de invitaciones y acepta la misma. Una vez aceptada, podrás acceder a tu panel de cliente y crear nuevas solicitudes😃. `
        );
      } catch (error) {
        console.log(error);
      } finally {
        alert("Invitación enviada de forma satisfactoria");
      }
    } else {
    }
  };

  useEffect(() => {
    if (idBusiness) {
      const q = queryClients(idBusiness);
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const items = getClients(querySnapshot);

        // Obtener la información de la foto para cada cliente
        const clientsWithPhotos = await Promise.all(
          items.map(async (client) => {
            const userDocRef = doc(db, "users", client.email);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              return { ...client, photoURL: userData.photoURL };
            }
            return client;
          })
        );

        setClients(clientsWithPhotos);
      });
      return unsubscribe;
    }
  }, [currentUser, idBusiness]);

  const filteredClients = clients.filter((client) =>
    client.email.toLowerCase().includes(busqueda.toLowerCase())
  );

  const currentItems = filteredClients.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  return (
    <div>
      <div className="bigCard2">
        <div className="bigCardContent">
          <div className="columBigcard">
            <div className="centerDivBigCard">
              <h1 className="titleBigCard">Clientes</h1>
            </div>
            <div className="divTextAgents">
              <span className="textAgents">
                Puedes gestionar las tareas de tus clientes de manera
                individual, es decir, puedes invitar a tus clientes a tu
                proyecto y gestionar las tareas que ellos te asignen.
              </span>
              <hr className="hrAgents" />
            </div>
          </div>
          <input
            type="text"
            placeholder="Buscar Cliente"
            onChange={(e) => setBusqueda(e.target.value)}
            onClick={() => setCurrentPage(0)}
            className="inputBusqueda2"
            autoComplete="off"
          />
          <div className="agents">
            {currentItems.map((client, index) => (
              <div key={index} className="clientsGlobe">
                <div className="agentSection">
                  {client.photoURL ? (
                    <img
                      src={client.photoURL}
                      alt={client.name}
                      className="agentPhoto"
                    />
                  ) : (
                    <Avatar name={client.name} size="50" round={true} />
                  )}
                </div>
                <div className="agentSection clientInfo">
                  <h3 className="textGlobe">
                    <strong>Nombre:</strong> &nbsp; {client.name} &nbsp;
                  </h3>
                  <h3 className="textGlobe">
                    <strong>Email:</strong> &nbsp; {client.email}
                  </h3>
                </div>
                <div className="agentSection">
                  <button
                    onClick={() => {
                      handleOpenModalDelete();
                      setDeleteClient(client.email);
                    }}
                    className="deleteListArea"
                  >
                    <FontAwesomeIcon icon={faDeleteLeft} />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button
            className="AddItemList"
            onClick={() => addClientsModalRef.current.handleOpen()}
          >
            Agregar
          </button>
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Siguiente"}
            breakLabel={""}
            pageCount={Math.ceil(filteredClients.length / itemsPerPage)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={"ticketsNuevos__pagination"}
            activeClassName={"active"}
            previousClassName={"custom-previous-button"}
            nextClassName={"custom-next-button"}
            previousLinkClassName={"btnAnterior"}
            nextLinkClassName={"btnSiguiente"}
            pageClassName={"custom-page-number"}
            pageLinkClassName={"custom-page-link"}
          />
          <Modal
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModalDelete}
          >
            <h2 className="titleModal">¿Desea Eliminar Cliente?</h2>
            <div className="descriptionModal">
              <p>
                Se borrará de todos los registros, aunque puede agregarlo
                nuevamente y enviar una invitación.
              </p>
            </div>
            <div className="row">
              <button
                className="btnCloseModal"
                onClick={() => handleDeleteClient(deleteClient)}
              >
                Sí
              </button>
              <button
                className="btnCloseModal"
                onClick={handleCloseModalDelete}
              >
                No
              </button>
            </div>
          </Modal>
        </div>
      </div>
      <AddClientsModal ref={addClientsModalRef} />
    </div>
  );
}

export default RbClients;
