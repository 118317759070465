import React, { useRef, useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import "../calendario/DetalleTarea.css";
import { Save, Edit, Trash2, X, User, Briefcase, StickyNote, Settings, CheckCircle, Loader, CheckSquare } from "lucide-react";
import { updateTicketDetalle } from "../Functions";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment-timezone";
import AddEventSendGrid from "../addEventoSendGrid/AddEventSendGrid";
import DeleteTarea from "../DeleteTarea";
import ModalColorTarea from "../calendario/ModalColorTarea";
import { toast } from 'react-toastify';
import Confetti from "react-confetti";
import DeleteTareaPending from "../DeleteTareaPending";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

const DetalleTask = ({
  tarea,
  onClose,
  onEdit,
  businessName,
  context,
  horaInicio,
  horaTermino,
  style,
}) => {
  const ref = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTask, setEditedTask] = useState({
    ...tarea,
    vencimiento: moment(tarea.vencimiento, "DD-MM-YYYY").format("YYYY-MM-DD"),
    creacion: moment(tarea.creacion, "DD-MM-YYYY").format("YYYY-MM-DD"),
    title: tarea.title || "",
    descripcion: tarea.descripcion || "",
    priority: tarea.priority || "Media"
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isColorModalOpen, setIsColorModalOpen] = useState(false);
  const { currentUser, accessToken, refreshToken, tokenExpiry } = useContext(AuthContext);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
  
    const updatedData = {
      ...editedTask,
      creacion: moment(editedTask.creacion, "YYYY-MM-DD").format("DD-MM-YYYY"),
      vencimiento: moment(editedTask.vencimiento, "YYYY-MM-DD").format("DD-MM-YYYY"),
    };
  
    try {
      await updateTicketDetalle(tarea.emailBusiness, tarea.id, updatedData);
  
      onEdit(updatedData);
      setIsEditing(false);
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);
  
      toast.success('¡Tarea sincronizada!');
    } catch (error) {
      console.error("Error actualizando la tarea:", error);
      toast.error("Error actualizando la tarea");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleDeleteClick = async () => {
    try {
      if (tarea.idEventoCalendario) {
        setIsDeleteModalOpen(true);
        return;
      }

      await deleteDoc(doc(db, "business", tarea.emailBusiness, "tickets", tarea.id));
      toast.success('Tarea eliminada exitosamente');
      onEdit(null);
      onClose();
    } catch (error) {
      console.error("Error al eliminar la tarea:", error);
      toast.error(`Error al eliminar la tarea: ${error.message}`);
    }
  };

  const handleDeleteTarea = () => {
    onEdit(null);
    onClose();
  };

  const handleColorChange = (newColor) => {
    setEditedTask((prevTask) => ({
      ...prevTask,
      colorTarea: newColor,
    }));
  };

  const handleMarkAsCompleted = async () => {
    setIsLoading(true);
    const updatedTitle = tarea.title.startsWith('✅') ? tarea.title : `✅ ${tarea.title}`;
    
    const updatedData = {
      title: updatedTitle,
      status: "Terminado",
      creacion: tarea.creacion,
      vencimiento: tarea.vencimiento,
      fechaFin: moment().format("DD-MM-YYYY")
    };

    try {
      await updateTicketDetalle(tarea.emailBusiness, tarea.id, updatedData);

      onEdit({ ...tarea, ...updatedData });
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);

      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);

      toast.success('¡Tarea marcada como terminada y sincronizada!');
    } catch (error) {
      console.error("Error actualizando la tarea:", error);
      toast.error("Error al marcar la tarea como terminada");
    } finally {
      setIsLoading(false);
    }
  };

  if (!tarea) return null;

  const {
    title,
    fecha,
    department,
    descripcion,
    vencimiento,
    skill,
    status,
    priority
  } = isEditing ? editedTask : tarea;

  const elaboracionISO = moment(fecha, "DD-MM-YYYY").toISOString();
  const vencimientoISO = moment(vencimiento, "DD-MM-YYYY").toISOString();

  return ReactDOM.createPortal(
    <div ref={ref} className="detalleTarea" style={{ ...style, zIndex: 43000 }}>
      <div className="detalleTarea__header">
        <X className="icon" onClick={onClose} />
      </div>
      {isEditing ? (
        <label>
          Título:
          <input
            type="text"
            name="title"
            value={editedTask.title}
            onChange={handleChange}
            className="detalleTarea__input detalleTarea__input--text"
            style={{ textAlign: "center", width: "100%" }}
          />
        </label>
      ) : (
        title || "(Sin título)"
      )}
      <div className="detalleTarea__body">
        {isEditing ? (
          <>
            <label>
              Fecha de vencimiento:
              <input
                type="date"
                name="vencimiento"
                value={editedTask.vencimiento}
                onChange={handleChange}
                className="detalleTarea__input detalleTarea__input--text"
              />
            </label>
            <label>
              Descripción:
              <textarea
                name="descripcion"
                value={editedTask.descripcion}
                onChange={handleChange}
                className="detalleTarea__input"
              />
            </label>
            <label>
              Prioridad:
              <select
                name="priority"
                value={editedTask.priority}
                onChange={handleChange}
                className="detalleTarea__input"
              >
                <option value="Alta">Alta</option>
                <option value="Media">Media</option>
                <option value="Baja">Baja</option>
              </select>
            </label>
          </>
        ) : (
          <p>{vencimiento}</p>
        )}

        <div className="detalleTarea__info">
          <StickyNote className="icon-margin" />
          <div className="detalleTarea__description" dangerouslySetInnerHTML={{ __html: descripcion }} />
        </div>
        <hr />
        <div className="detalleTarea__info">
          <Briefcase className="icon-margin" />
          <span>{department || "Sin Área"}</span>
        </div>
        <div className="detalleTarea__info">
          <User className="icon-margin" />
          <span>{tarea.to}</span>
        </div>
        <div className="detalleTarea__info">
          <Settings className="icon-margin" />
          <span>{skill || "Sin Área"}</span>
        </div>
        <div className="detalleTarea__info">
          <Settings className="icon-margin" />
          <span>Prioridad: {priority}</span>
        </div>
      </div>
      {isLoading && (
        <div className="detalleTarea__loading">
          <Loader className="icon" />
          <span>Actualizando...</span>
        </div>
      )}
      {showConfirmation && (
        <div className="detalleTarea__confirmation">
          <CheckCircle className="icon" />
          <span>La tarea ha sido actualizada</span>
        </div>
      )}
      <div className="detalleTarea__actions">
        {isEditing ? (
          <Save
            className="icon"
            onClick={handleSaveClick}
          />
        ) : (
          <>
            <div className="detalleTarea__edit-delete-icons">
              <Edit
                className="icon"
                onClick={handleEditClick}
              />
              <Trash2
                className="icon"
                onClick={handleDeleteClick}
              />
            </div>
            <div className="detalleTarea__complete-button-container">
              <button
                className="detalleTarea__complete-button"
                onClick={handleMarkAsCompleted}
                disabled={status === "Terminado"}
              >
                <CheckSquare className="icon" />
                {status === "Terminado" ? "Tarea completada" : "Marcar como terminado"}
              </button>
            </div>
          </>
        )}
      </div>

      {tarea.idEventoCalendario && (
        <DeleteTarea
          tarea={tarea}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDeleteTarea}
          currentUser={currentUser}
          accessToken={accessToken}
          refreshToken={refreshToken}
          tokenExpiry={tokenExpiry}      
        />
      )}
      <ModalColorTarea
        tarea={tarea}
        isOpen={isColorModalOpen}
        onClose={() => setIsColorModalOpen(false)}
        onColorChange={handleColorChange}
      />
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={300}
          recycle={false}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
    </div>,
    document.body
  );
};

export default DetalleTask;