import React, { useEffect, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../../context/AuthContext";
import { onSnapshot } from "firebase/firestore";
import { doc, setDoc, getDoc, deleteDoc, writeBatch } from "firebase/firestore";
import { db } from "../firebase";
import {
  getAgents,
  queryAgents,
  getClients,
  getCompetencias,
  getUserArea,
  queryCompetencias,
  getBusinessPartner,
  queryAreas,
  queryBusinessPartner,
  queryAgentCompetencias,
  getAgentCompetencias,
  queryClients,
  queryAreasAgent,
  getAreaAgent,
} from "../Functions";
import Autocomplete from "@mui/material/Autocomplete";
import { act } from "react-dom/test-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

function RbClientsAgents(props) {
  const { currentUser } = useContext(AuthContext);
  const [cargandoEmpresa, setCargandoEmpresa] = useState(false);
  const [cargandoAgente, setCargandoAgente] = useState(false);
  const [cargandoCompetencia, setCargandoCompetencia] = useState(false);
  const [cargandoAgregar, setCargandoAgregar] = useState(false);
  const [cargandoArea, setCargandoArea] = useState(false);
  const [agents, setAgents] = useState([]);
  const [areas, setAreas] = useState([]);
  const [businessPartner, setBusinessPartner] = useState([]);
  const [areasAgent, setAreasAgent] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [BusinessActive, setBusinessActive] = useState("General");
  const [cilenteActive, setClienteActive] = useState("General");
  const [AgentActive, setAgentActive] = useState("");
  const [areaActive, setAreaActive] = useState("");
  const [agentArea, setAgentArea] = useState("");
  const [competencias, setCompetencias] = useState([]);
  const [agentCompetencias, setAgentCompetencias] = useState([]);
  const { businessEmail } = useContext(AuthContext);
  const [selectedCompetencia, setSelectedCompetencia] = useState(null);
  const idBusiness = sessionStorage.getItem("businessId");

  //manejadores del cambio del autocomplete.

  const handleAreaChange = (event, newValue) => {
    setSelectedArea(newValue); // Actualiza manualmente el valor del estado
  };

  const handleCompetenciaChange = (event, newValue) => {
    setSelectedCompetencia(newValue); // Actualiza manualmente el valor del estado
  };

  const handleClienteChange = (event, newValue) => {
    setSelectedEmpresa(newValue); // Actualiza manualmente el valor del estado
  };

  // agrego agentes
  const handleAddCliente = async () => {
    setCargandoAgregar(true);
    try {
      /*  */

      await setDoc(
        doc(
          db,
          "business",
          idBusiness,
          "agents",
          selectedAgent,
          "businessPartner",
          selectedEmpresa.email
        ),
        {
          name: selectedEmpresa.name,
          email: selectedEmpresa.email,
        }
      );
      setCargandoAgregar(false);
    } catch (error) {
      console.log(error);
    }
  };

  // agregar todos los clientes al mismo tiempo

  const handleAddAllClients = async () => {
    setCargandoAgregar(true); // Comienza mostrando el indicador de carga
    try {
      const batch = writeBatch(db); // Usamos un batch para optimizar las escrituras en Firebase

      clientes.forEach((empresa) => {
        const businessDocRef = doc(
          db,
          "business",
          idBusiness,
          "agents",
          selectedAgent,
          "businessPartner",
          empresa.email
        );
        batch.set(businessDocRef, {
          name: empresa.name,
          email: empresa.email,
        });
      });

      await batch.commit(); // Ejecuta todas las operaciones del batch
      setCargandoAgregar(false); // Oculta el indicador de carga una vez completado
    } catch (error) {
      console.error("Error al agregar todos los clientes:", error);
      setCargandoAgregar(false); // Asegúrate de ocultar el indicador de carga incluso si hay un error
    }
  };

  //agrego las áreas asociadas al agente:

  const handleAddArea = async () => {
    setCargandoAgregar(true);
    try {
      await setDoc(
        doc(
          db,
          "business",
          idBusiness,
          "agents",
          AgentActive,
          "areas",
          selectedArea.id // area
        ),
        {
          area: selectedArea.name,
          name: selectedArea.name,
          icon: selectedArea.icon,

        }
      );
      setCargandoAgregar(false);
    } catch (error) {}
  };

  //agrego todas las áreas al mismo tiempo:

  const handleAddAllAreas = async () => {
    setCargandoAgregar(true); // Indicador de carga

    const batch = writeBatch(db); // Usamos un batch para optimizar las escrituras en Firebase

    areas.forEach((area) => {
      const businessDocRef = doc(
        db,
        "business",
        idBusiness,
        "agents",
        AgentActive,
        "areas",
        area.id
      );
      batch.set(businessDocRef, {
        area: area.name,
        name: area.name,
        icon: area.icon,
      });
    });

    try {
      await batch.commit(); // Ejecuta todas las operaciones del batch
      setCargandoAgregar(false); // Oculta el indicador de carga
    } catch (error) {
      console.error("Error al agregar todas las áreas:", error);
      setCargandoAgregar(false); // Manejo de errores
    }
  };

  const handleAddCompetencia = async () => {
    setCargandoCompetencia(true);
    //agrego el agente a la empresa del cliente...
    try {
      await setDoc(
        doc(
          db,
          "business",
          idBusiness,
          "agents",
          selectedAgent,
          "businessPartner",
          BusinessActive,
          "competencias",
          selectedCompetencia.competencia
        ),
        {
          competencia: selectedCompetencia.competencia,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddAllCompetencias = async () => {
    if (!AgentActive || !BusinessActive || !areaActive) {
      return;
    }
  
    setCargandoCompetencia(true);
    const batch = writeBatch(db);
  
    competencias.forEach((competencia) => {
      const businessCompetenciaRef = doc(
        db,
        "business",
        idBusiness,
        "agents",
        AgentActive,
        "businessPartner",
        BusinessActive,
        "competencias",
        competencia.id
      );
      batch.set(businessCompetenciaRef, {
        competencia: competencia.competencia,
      });
    });
  
    try {
      await batch.commit();
    } catch (error) {
      console.error("Error al agregar todas las competencias:", error);
    } finally {
      setCargandoCompetencia(false);
    }
  };

  // borro el cliente asociado al agente
  const handleDeleteCliente = async (idBusiness, agentActive, item) => {
    let userResponse = window.confirm(
      "¿Deseas devincular el cliente del agente?"
    );

    if (userResponse) {
      try {
        setCargandoEmpresa(true);
        deleteDoc(
          doc(
            db,
            "business",
            idBusiness,
            "agents",
            agentActive,
            "businessPartner",
            item
          )
        );
        setCargandoEmpresa(false);
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  // borro el cliente asociado al agente
  const handleDeleteArea = async (idBusiness, AgentActive, item) => {
    let userResponse = window.confirm(
      "¿Deseas desvincular el agente de esta área?"
    );

    if (userResponse) {
      try {
        deleteDoc(
          doc(db, "business", idBusiness, "agents", AgentActive, "areas", item)
        );
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  // borro la competencia asociada al agente
  const handleDeleteCompetencias = async (
    idBusiness,
    AgentActive,
    clienteActive,
    item
  ) => {
    let userResponse = window.confirm(
      "¿Deseas desvincular el agente de esta competencia?"
    );

    if (userResponse) {
      try {
        await deleteDoc(
          doc(
            db,
            "business",
            idBusiness,
            "agents",
            AgentActive,
            "businessPartner",
            clienteActive,
            "competencias",
            item
          )
        );
      } catch (error) {
        console.error("Error al eliminar la competencia:", error);
      }
    }
  };

  const areaName = selectedEmpresa ? selectedEmpresa.name : "";

  // cargo clientes en el select ---------------------

  useEffect(() => {
    if (idBusiness) {
      setCargandoEmpresa(true);
      const q = queryClients(idBusiness);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getClients(querySnapshot);
        setClientes(items);
        setCargandoEmpresa(false);
      });
      setSelectedEmpresa(clientes[1]);
      return unsubscribe;
    }
  }, [idBusiness, currentUser]);

  //cargo competencias en el select...... .

  useEffect(() => {
    if (idBusiness && areaActive) {
      setCargandoCompetencia(true);
      const q = queryCompetencias(idBusiness, areaActive);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getCompetencias(querySnapshot);
        setCompetencias(items);
        setCargandoCompetencia(false);
      });
      return unsubscribe;
    }
  }, [idBusiness, areaActive]);

  // cargo áreas en el select ---------------------

  useEffect(() => {
    if (idBusiness) {
      setCargandoArea(true);
      const q = queryAreas(idBusiness);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getUserArea(querySnapshot);
        setAreas(items);
        setCargandoArea(false);
      });
      return unsubscribe;
    }
  }, [idBusiness, currentUser]);

  // cargo el área del agente

  const getAgentArea = async (email) => {
    const docRef = doc(db, "users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setAgentArea(docSnap.data().department);
    } else {
    }
  };

  // 1 ......Busco los agentes registrados

  useEffect(() => {
    if (idBusiness) {
      const q = queryAgents(idBusiness);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAgents(querySnapshot);
        setAgents(items);
      });

      return unsubscribe;
    }
  }, [idBusiness, currentUser]);

  // 2 ......Busco las áreas a asignadas a los agentes

  useEffect(() => {
    // Asegurarse de que el efecto se ejecute solo cuando selectedAgent cambie y no esté vacío
    if (selectedAgent) {
      setCargandoArea(true); // Indicar inicio de carga
      const q = queryAreasAgent(selectedAgent, idBusiness); // Obtener la query basada en el agente seleccionado
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAreaAgent(querySnapshot); // Obtener áreas del agente
        setAreasAgent(items); // Actualizar el estado con las áreas del agente seleccionado
        setCargandoArea(false); // Indicar fin de carga
      });
      return () => unsubscribe(); // Limpiar el observer al desmontar o cambiar el agente
    }
  }, [selectedAgent]); // Dependencia: selectedAgent

  // 3 ......busco los clientes asociados al agente *****

  useEffect(() => {
    if (selectedAgent !== "") {
      setCargandoEmpresa(true);
      setBusinessActive("General");
      const q = queryBusinessPartner(selectedAgent, idBusiness);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getBusinessPartner(querySnapshot);
        setBusinessPartner(items);
        setCargandoEmpresa(false);
      });
      return unsubscribe;
    }
  }, [AgentActive]);

  // 4 ......busco las competencias asociadas al agente *****

  useEffect(() => {
    if (selectedEmpresa !== "" && BusinessActive !== "" && areaActive !== "") {
      setCargandoCompetencia(true);
      
      const qCompetenciasDisponibles = queryCompetencias(idBusiness, areaActive);
      const qCompetenciasAsignadas = queryAgentCompetencias(BusinessActive, idBusiness, AgentActive, areaActive);
  
      const unsubscribeCompetenciasDisponibles = onSnapshot(qCompetenciasDisponibles, (querySnapshot) => {
        const competenciasDisponibles = getCompetencias(querySnapshot);
        
        const unsubscribeCompetenciasAsignadas = onSnapshot(qCompetenciasAsignadas, (querySnapshot) => {
          const competenciasAsignadas = getAgentCompetencias(querySnapshot);
  
          // Filtrar las competencias asignadas para mostrar solo las que están disponibles para el área seleccionada
          const competenciasFiltradas = competenciasAsignadas.filter(competencia => 
            competenciasDisponibles.some(disponible => disponible.competencia === competencia.competencia)
          );
  
          setAgentCompetencias(competenciasFiltradas);
          setCargandoCompetencia(false);
        });
  
        return () => {
          unsubscribeCompetenciasAsignadas();
        };
      });
  
      return () => {
        unsubscribeCompetenciasDisponibles();
      };
    } else {
      setAgentCompetencias([]);
    }
  }, [BusinessActive, areaActive, selectedEmpresa]); // Agrega areaActive y selectedEmpresa a las dependencias
  

  return (
    <div>
      <div className="bigCard2">
        <div className="bigCardContent">
          <div className="centerDivBigCard">
            <h1 className="titleBigCard">Parametrización de usuarios</h1>
            <div className="divTextAgents">
              <span className="textAgents">
                Asigna de manera meticulosa a cada colagorador los proyectos y
                actividaes que gestionará en tu proyecto. Esto es crucial,
                puesto que estos parámetros serán empleados por la IA
                para identificar al agente más apto para atender cada
                solicitud.
              </span>
              <hr className="hrAgents" />
            </div>
            <div className="threeColumns">
              <div className="columnsDiv">
                <h3 className="titleColumns">Colaboradores</h3>
                {cargandoAgente ? (
                  <p>Cargando...</p>
                ) : (
                  <div className="businessPartners">

                    {agents.map((agent, index) => (
                      <div key={index}>
                        <li
                          onClick={(e) => {
                            setBusinessActive("General");
                            setSelectedAgent(agent.email);
                            setAgentActive(agent.email);
                            getAgentArea(agent.email);
                          }}
                          className={
                            AgentActive === agent.email
                              ? "liAgent2Active"
                              : "liAgent2"
                          }
                        >
                          {" "}
                          🤖 {agent.name}
                        </li>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="columnsDiv">
                <h3 className="titleColumns">Áreas</h3>
                {selectedAgent === "" ? (
                  <p className="textAgents">
                    Selecciona un colaborador para asingarle las áreas que
                    desees
                  </p>
                ) : (
                  <div>
                    <Autocomplete
                      disablePortal
                      id="combo-box-areas"
                      options={areas} // Asegúrate de que este estado contenga las áreas a mostrar
                      getOptionLabel={(option) => option.name} // Asumiendo que cada área tiene una propiedad 'name'
                      sx={{ width: "100%", fontFamily: "Poppins" }}
                      onChange={handleAreaChange} // Función para manejar el cambio de selección
                      renderInput={(params) => (
                        <TextField {...params} label="Áreas" />
                      )}
                    />

                    {selectedArea && selectedArea !== "" && (
                      <button className="AddItemList" onClick={handleAddArea}>
                        Agregar
                      </button>
                    )}

                    <button className="AddAllItems" onClick={handleAddAllAreas}>
                      Asignar todas las areas
                    </button>

                    <div className="businessPartners">
                      {cargandoArea || cargandoAgregar ? (
                        <p>Cargando...</p>
                      ) : areasAgent.length > 0 ? (
                        areasAgent.map((bpartner, index) => (
                          <div key={index}>
                            <li
                              className={
                                areaActive === bpartner.area
                                  ? "liAreaActive"
                                  : "liArea"
                              }
                              onClick={() => setAreaActive(bpartner.area)}
                            >
                              🏛️ {bpartner.area}
                              <button
                                onClick={(e) =>
                                  handleDeleteArea(
                                    idBusiness,
                                    AgentActive,
                                    bpartner.area
                                  )
                                }
                                className="deleteListArea"
                              >
                                <FontAwesomeIcon icon={faDeleteLeft} />
                              </button>
                            </li>
                          </div>
                        ))
                      ) : (
                        <p>No hay áreas asignadas</p> // Mensaje cuando no hay áreas
                      )}
                    </div>
                  </div>
                )}
              </div>
            
              <div className="columnsDiv">
                <h3 className="titleColumns">Actividades</h3>
                {cilenteActive === "" ? (
                  <p className="textAgents">Selecciona un proyecto y un área</p>
                ) : (
                  <div>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={competencias}
                      getOptionLabel={(option) => option.competencia}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      sx={{ width: "100%", fontFamily: "Poppins" }}
                      onChange={handleCompetenciaChange}
                      renderInput={(params) => (
                        <TextField {...params} label="Competencias" />
                      )}
                    />

                    {selectedCompetencia && selectedCompetencia !== "" && (
                      <button
                        className="AddItemList"
                        onClick={handleAddCompetencia}
                      >
                        Agregar
                      </button>
                    )}

                    <button
                      className="AddAllItems"
                      onClick={handleAddAllCompetencias}
                    >
                      Asignar todas las actividades de {areaActive}
                    </button>

                    <p></p>
                    <p />
                    <div className="businessPartners">
                      {cargandoCompetencia ? (
                        <p>Cargando...</p>
                      ) : agentCompetencias.length > 0 ? (
                        agentCompetencias.map((competencia, index) => (
                          <div key={index}>
                            <li className="liAgent3">
                              ⭐ {competencia.competencia}
                              <button
                                onClick={(e) => {
                                  handleDeleteCompetencias(
                                    idBusiness,
                                    AgentActive,
                                    BusinessActive,
                                    competencia.competencia
                                  );
                                }}
                                className="deleteListArea"
                              >
                                <FontAwesomeIcon icon={faDeleteLeft} />
                              </button>
                            </li>
                          </div>
                        ))
                      ) : (
                        <p>No hay actividades asignadas a {cilenteActive}</p> // Mensaje cuando no hay competencias
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default RbClientsAgents;
