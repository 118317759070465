import React, { useState } from "react";
import LogoLogin from "../img/logo-login.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function StartNow() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const navigate = useNavigate();


  const handleEmpresaClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleEmpresaClick2 = () => {
    setShowDropdown2(!showDropdown2);
  };

  const handleAceptarEmpresa = () => {
    navigate("/registro");
    };

    const handleAceptarUsuario = () => {
      navigate("/registrarusuarios");
      };

  return (
    <div>
      <div className="container">
        <img className="logo" src={LogoLogin} alt="" />
        <h3 className="titleStart">
          Para empezar selecciona: ¿Qué deseas registrar?
        </h3>
        <div className="buttonContainerStart">
          <div className="empresaContainer">
            <button className="standardButton" onClick={handleEmpresaClick}>
              <FontAwesomeIcon
                icon={faBuilding}
                style={{ marginRight: "10px" }}
              />
              Empresa
            </button>
            {showDropdown && (
              <div onMouseLeave={handleEmpresaClick} className="dropdownButton">
                <p>
                  Ecosistema que parametrizará y <strong>ofrecerá</strong> el
                  servicio de gestión empresarial.
                </p>
                <button onClick={handleAceptarEmpresa} className="okButton">Aceptar</button>
              </div>
            )}
          </div>
          <div className="usuarioContainer">
            <button className="okButton" onClick={handleEmpresaClick2}>
              <FontAwesomeIcon
                icon={faUser}
                style={{ marginRight: "10px" }}
              />
              Usuario
            </button>
            {showDropdown2 && (
              <div
                className="dropdownButton"
                onMouseLeave={handleEmpresaClick2}
              >
                <p>
                  Persona o empresa que <strong>creará o gestionará</strong> el
                  servicio de gestión empresarial, pueden ser clientes o
                  usuarios. 
                  
                </p>
                <button className="okButton" onClick={handleAceptarUsuario}  id= "1">Aceptar</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartNow;
