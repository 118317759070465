// src/context/AuthContext.js
import React, { createContext, useMemo, useState, useEffect, useCallback } from "react";
import { auth, db } from "../components/firebase";
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getDoc, doc, getDocs, query, where, collection } from "firebase/firestore";
import { getPuntosSoportick } from "../components/Functions";
import { CircularProgress } from "@mui/material";
// Importa la función de sincronización
import { sincronizarDesdeGoogleCalendar } from "../components/calendario/FunctionsCalendar";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  // Separar el estado en partes más pequeñas y específicas
  const [user, setUser] = useState({
    currentUser: null,
    name: "",
    category: "admin",
    userType: "admin",
    status: "",
    department: "General",
  });

  const [business, setBusiness] = useState({
    businessEmail: null,
    businessName: "",
    businessAdminEmail: "",
    businessPhoto: "",
    businessDescription: "",
    numberOfAgents: 0,
    numberOfTickets: 0,
    averageRating: 5,
    objetivos: "",
  });

  const [userPreferences, setUserPreferences] = useState({
    timeZone: "America/Santiago",
    initView: "calendario",
    miPorque: [],
    planDay: "",
    planTime: "",
    weeklyReviewDay: "",
    weeklyReviewTime: "",
    dailyReviewTime: "",
  });

  const [tokens, setTokens] = useState({
    accessToken: null,
    firebaseAccessToken: null,
    refreshToken: null,
    tokenExpiry: null,
  });

  const [loading, setLoading] = useState(true);
  const [puntosSoportick2, setPuntosSoportick2] = useState(0);

  // Memoizar las funciones de actualización
  const updateBusinessContext = useCallback((businessId, userType) => {
    setBusiness(prev => ({ ...prev, businessEmail: businessId }));
    setUser(prev => ({ ...prev, userType }));
  }, []);

  const setCurrentUser = useCallback((newUser) => {
    setUser(prev => ({ ...prev, currentUser: newUser }));
  }, []);

  const setBusinessEmail = useCallback((email) => {
    setBusiness(prev => ({ ...prev, businessEmail: email }));
  }, []);

  const setFirebaseAccessToken = useCallback((token) => {
    setTokens(prev => ({ ...prev, firebaseAccessToken: token }));
  }, []);

  // Memoizar la función de fetchBusinessInfo
  const fetchBusinessInfo = useCallback(async (businessEmail) => {
    try {
      const businessDoc = await getDoc(doc(db, "business", businessEmail));
      if (businessDoc.exists()) {
        const businessData = businessDoc.data();
        setBusiness(prev => ({
          ...prev,
          businessName: businessData.businessName,
          businessAdminEmail: businessData.businessEmail,
          businessPhoto: businessData.photoBusiness,
          businessDescription: businessData.description,
          objetivos: businessData.objetivo
        }));

        const [agentsSnapshot, ticketsSnapshot] = await Promise.all([
          getDocs(query(collection(db, "users"), where("category", "==", "agent"))),
          getDocs(query(collection(db, "business", businessEmail, "tickets"), 
                      where("status", "==", "Terminado")))
        ]);

        setBusiness(prev => ({
          ...prev,
          numberOfAgents: agentsSnapshot.size,
          numberOfTickets: ticketsSnapshot.size
        }));
      }
    } catch (error) {
      console.error("Error fetching business info:", error);
    }
  }, []);

  // Efecto para la autenticación inicial
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(prev => ({ ...prev, currentUser: user }));
      
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.email));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const storedCustomToken = sessionStorage.getItem("customToken");

            setUser(prev => ({
              ...prev,
              ...userData,
              name: user.displayName,
            }));

            setTokens(prev => ({
              ...prev,
              accessToken: userData.googleAccessToken,
              refreshToken: userData.googleRefreshToken,
              tokenExpiry: userData.tokenExpiry,
              firebaseAccessToken: storedCustomToken || userData.accessToken,
            }));

            setUserPreferences(prev => ({
              ...prev,
              miPorque: Array.isArray(userData.whyPlansObjective) 
                ? userData.whyPlansObjective 
                : [userData.whyPlansObjective],
            }));
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence).catch(console.error);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(prev => ({ ...prev, currentUser: user }));
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.email));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const storedCustomToken = sessionStorage.getItem("customToken");

            setUser(prev => ({
              ...prev,
              ...userData,
              accessToken: userData.googleAccessToken,
              refreshToken: userData.googleRefreshToken,
              tokenExpiry: userData.tokenExpiry,
              firebaseAccessToken: storedCustomToken || userData.accessToken,
              miPorque: Array.isArray(userData.whyPlansObjective) ? userData.whyPlansObjective : [userData.whyPlansObjective],
              name: user.displayName,
              loading: false
            }));

            const storedBusinessId = sessionStorage.getItem("businessId");
            if (storedBusinessId) {
              setBusiness(prev => ({ ...prev, businessEmail: storedBusinessId }));
            } else {
              fetchBusinessIdForUser(user.email);
            }
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchBusinessIdForUser = async (userEmail) => {
    try {
      const userDoc = await getDoc(doc(db, "users", userEmail));
      if (userDoc.exists()) {
        const activeBusinessId = userDoc.data().business?.[0]?.id;
        if (activeBusinessId) {
          setBusiness(prev => ({ ...prev, businessEmail: activeBusinessId }));
          sessionStorage.setItem("businessId", activeBusinessId);
        }
      }
    } catch (error) {
      console.error("Error fetching business ID:", error);
    }
  };

  useEffect(() => {
    if (!business.businessEmail) return;

    fetchBusinessInfo(business.businessEmail);
  }, [business.businessEmail, fetchBusinessInfo]);

  useEffect(() => {
    if (!user.currentUser?.email || !business.businessEmail || !user.category) return;

    const fetchPuntosSoportick = async () => {
      try {
        const puntos = await getPuntosSoportick(business.businessEmail, user.currentUser.email, user.category);
        setPuntosSoportick2(puntos);
      } catch (error) {
        console.error("Error fetching puntos soportick:", error);
      }
    };

    fetchPuntosSoportick();
  }, [user.currentUser, business.businessEmail, user.category, fetchBusinessInfo]);

  // Memoizar el valor del contexto
  const contextValue = useMemo(() => ({
    ...user,
    ...business,
    ...userPreferences,
    ...tokens,
    loading,
    puntosSoportick2,
    updateBusinessContext,
    setCurrentUser,
    setBusinessEmail,
    setFirebaseAccessToken
  }), [
    user,
    business,
    userPreferences,
    tokens,
    loading,
    puntosSoportick2,
    updateBusinessContext,
    setCurrentUser,
    setBusinessEmail,
    setFirebaseAccessToken
  ]);

  // Nuevo efecto para la sincronización
  useEffect(() => {
    const syncCalendar = async () => {
      if (
        user.currentUser &&
        business.businessEmail &&
        tokens.accessToken &&
        tokens.refreshToken &&
        tokens.tokenExpiry &&
        tokens.firebaseAccessToken &&
        !loading
      ) {
        try {
          await sincronizarDesdeGoogleCalendar(
            business.businessEmail,
            user.currentUser,
            tokens.accessToken,
            tokens.refreshToken,
            tokens.tokenExpiry,
            tokens.firebaseAccessToken
          );
        } catch (error) {
          console.error("Error al sincronizar el calendario:", error);
        }
      }
    };

    syncCalendar();
  }, [
    user.currentUser,
    business.businessEmail,
    tokens.accessToken,
    tokens.refreshToken,
    tokens.tokenExpiry,
    tokens.firebaseAccessToken,
    loading
  ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading ? children : (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f5f5f5'
        }}>
          <CircularProgress size={60} thickness={4} style={{ color: '#4a90e2' }} />
          <p style={{
            marginTop: '20px',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#333'
          }}>
            Cargando...
          </p>
        </div>
      )}
    </AuthContext.Provider>
  );
};
