import React from 'react';
import './EventosDisplay.css';
import { Calendar, Clock } from 'lucide-react';
import moment from 'moment';

const EventosDisplay = ({ eventos }) => {
  // Ordenar eventos por fecha y hora
  const eventosOrdenados = [...eventos].sort((a, b) => {
    const fechaA = moment(a.vencimiento, "DD-MM-YYYY");
    const fechaB = moment(b.vencimiento, "DD-MM-YYYY");
    if (fechaA.isSame(fechaB)) {
      return a.horaInicio.localeCompare(b.horaInicio);
    }
    return fechaA.diff(fechaB);
  });

  // Agrupar eventos por fecha
  const eventosPorFecha = eventosOrdenados.reduce((acc, evento) => {
    const fecha = evento.vencimiento;
    if (!acc[fecha]) {
      acc[fecha] = [];
    }
    acc[fecha].push(evento);
    return acc;
  }, {});

  const truncateText = (text, maxLength = 40) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
    <div className="eventos-display">
      <div className="eventos-header">
        <Calendar size={18} />
        <span>Eventos programados</span>
      </div>
      {Object.entries(eventosPorFecha).map(([fecha, eventosDelDia]) => (
        <div key={fecha} className="eventos-dia">
          <div className="fecha-header">
            {moment(fecha, "DD-MM-YYYY").format("DD MMM YYYY")}
          </div>
          {eventosDelDia.map((evento) => (
            <div key={evento.id || Math.random()} className="evento-card">
              <div className="evento-titulo" title={evento.title}>
                {truncateText(evento.title)}
              </div>
              <div className="evento-horario">
                <Clock size={14} />
                <span className="horario-inicio">
                  {evento.horaInicio}
                </span>
                {evento.horaTermino && (
                  <>
                    <span className="horario-separador">-</span>
                    <span className="horario-fin">
                      {evento.horaTermino}
                    </span>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default EventosDisplay; 