import { v4 as uuidv4 } from "uuid";
import { db } from "./firebase";
import { doc, setDoc, updateDoc, getDocs, collection, query, where } from "firebase/firestore";

// Define the cancelChannel function or import it if defined in another module
const cancelChannel = async (accessToken, channelId, resourceId) => {
  // Add your cancelChannel implementation here
};

const subscribeToCalendar = async (accessToken, refreshToken, handleShowSignIn, userId, email) => {
  const now = Date.now();
  const channelsRef = collection(db, 'googleCalendarChannels');
  const q = query(channelsRef, where('userId', '==', userId));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const channelDoc = querySnapshot.docs[0];
    const channelData = channelDoc.data();
    if (channelData.expiration > now + 7 * 24 * 60 * 60 * 1000) { // Si el canal expira en más de 7 días
      console.log("Canal existente encontrado, no es necesario crear uno nuevo");
      return {
        success: true,
        message: "Canal existente todavía activo",
        channelId: channelData.channelId, // Asegúrate de que esta línea existe y sea válida
        resourceId: channelData.resourceId,
        syncToken: channelData.syncToken
      };
    } else {
      console.log("Renovando el canal existente");
      await cancelChannel(accessToken, channelData.channelId, channelData.resourceId); // Cancelar el canal antiguo
      return await renewChannel(channelDoc.id, accessToken, refreshToken, handleShowSignIn, userId, email);
    }
  } else {
    return await createNewChannel(accessToken, refreshToken, handleShowSignIn, userId, email);
  }
};

const renewChannel = async (channelId, accessToken, refreshToken, handleShowSignIn, userId, email) => {
  const watchUrl = `https://www.googleapis.com/calendar/v3/calendars/primary/events/watch`;
  const newExpirationDate = Date.now() + 30 * 24 * 60 * 60 * 1000; // Expiración en 30 días

  const body = {
    id: channelId,
    type: "web_hook",
    address: "https://us-central1-planiusai.cloudfunctions.net/googleCalendarWebhook",
    payload: true,
    expiration: newExpirationDate,
  };

  try {
    const watchResponse = await fetch(watchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    });

    if (!watchResponse.ok) {
      const watchError = await watchResponse.json();
      throw new Error(watchError.error.message);
    }

    const eventData = await watchResponse.json();

    await updateDoc(doc(db, 'googleCalendarChannels', channelId), {
      accessToken: accessToken, // Actualizar el accessToken
      refreshToken: refreshToken, // Actualizar el refreshToken
      expiration: newExpirationDate,
      syncToken: eventData.nextSyncToken || "",
      resourceId: eventData.resourceId,
      channelId: channelId // Agregar el campo channelId
    });

    await performInitialSync(accessToken, channelId);

    handleShowSignIn(false);
    return {
      success: true,
      message: "Canal renovado",
      channelId: channelId,
      resourceId: eventData.resourceId,
      syncToken: eventData.nextSyncToken
    };
  } catch (error) {
    console.error("Error al renovar el canal de Google Calendar:", error);
    handleShowSignIn(true);
    return {
      success: false,
      error: error.message,
    };
  }
};

const createNewChannel = async (accessToken, refreshToken, handleShowSignIn, userId, email) => {
  const watchUrl = `https://www.googleapis.com/calendar/v3/calendars/primary/events/watch`;

  const channelId = uuidv4();
  const expirationDate = Date.now() + 30 * 24 * 60 * 60 * 1000; // Expiración en 30 días

  const body = {
    id: channelId,
    type: "web_hook",
    address: "https://us-central1-planiusai.cloudfunctions.net/googleCalendarWebhook",
    payload: true,
    expiration: expirationDate,
  };

  try {
    const watchResponse = await fetch(watchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    });

    if (!watchResponse.ok) {
      const watchError = await watchResponse.json();
      throw new Error(watchError.error.message);
    }

    const eventData = await watchResponse.json();

    const syncToken = eventData.nextSyncToken || "";

    await setDoc(doc(db, 'googleCalendarChannels', channelId), {
      accessToken: accessToken,
      refreshToken: refreshToken, // Almacenar el refreshToken
      userId: userId,
      email: email,
      idBusiness: "123",
      expiration: expirationDate,
      syncToken: syncToken,
      resourceId: eventData.resourceId,
      channelId: channelId // Almacenar el channelId correctamente
    });

    await performInitialSync(accessToken, channelId);

    handleShowSignIn(false);
    return {
      success: true,
      message: "Nuevo canal creado",
      channelId: channelId,
      resourceId: eventData.resourceId,
      syncToken: syncToken
    };
  } catch (error) {
    console.error("Error al suscribirse a Google Calendar:", error);
    handleShowSignIn(true);
    return {
      success: false,
      error: error.message,
    };
  }
};

const performInitialSync = async (accessToken, channelId) => {
  const calendarUrl = 'https://www.googleapis.com/calendar/v3/calendars/primary/events';

  try {
    const calendarResponse = await fetch(calendarUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!calendarResponse.ok) {
      const error = await calendarResponse.json();
      throw new Error(error.error.message);
    }

    const eventData = await calendarResponse.json();
    console.log("Initial sync details:", eventData);

    // Guardar el nuevo syncToken para futuras sincronizaciones
    if (eventData.nextSyncToken) {
      await updateDoc(doc(db, 'googleCalendarChannels', channelId), { syncToken: eventData.nextSyncToken });
    }
  } catch (error) {
    console.error("Error during initial sync:", error);
  }
};

export default subscribeToCalendar;
