import React from 'react';
import Modal from 'react-modal';

const ModalErrorCalendar = ({ isOpen, onRequestClose, errorMessage }) => (
    <Modal
        overlayClassName={'popupBackground'}
        className={'popupContainer'}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
    >
        <div className="popupHeader">
            <h2 className="popupTitle">Error</h2>
        </div>
        <div className="popupContent">
            <p>{errorMessage}</p>
        </div>
        <div className="popupActions">
            <button className="popupButton" onClick={onRequestClose}>Cerrar</button>
        </div>
    </Modal>
);

export default ModalErrorCalendar;
