import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import styled from "styled-components";
import Default from "./img/default_user.jpg";
import Business from "./img/business.png";

const ImageUploaderContainer = styled.div`
  text-align: center;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed #ccc;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: ${({ isDragActive }) => (isDragActive ? "#e6f7ff" : "transparent")};
  transition: background-color 0.3s;
`;

const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: #333;
`;

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ControlButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #19256b;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: #151e55;
  }
`;

const ChangeImageButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #6b1925;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: #551519;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const CenterImage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CropButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #19256b;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: #151e55;
  }
  display: block;
  margin: 10px auto;
`;

function ImageUploader({ onImageChange, type }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropTools, setShowCropTools] = useState(false);
  const [error, setError] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setError(null);
    const file = acceptedFiles[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
        setShowCropTools(true);
      };
      reader.readAsDataURL(file);
    } else {
      setError("Solo se permiten archivos PNG y JPG.");
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    accept: "image/png, image/jpeg",
  });

  const handleDefaultImage = () => {
    const defaultImageUrl = type === "1" ? Business : Default;
    setCroppedImage(defaultImageUrl);
    onImageChange(defaultImageUrl);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = async (quality = 0.8) => {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("El canvas está vacío"));
          }
        },
        "image/jpeg",
        quality
      );
    });
  };

  const handleCropImage = async () => {
    try {
      if (croppedAreaPixels) {
        const croppedImageBlob = await getCroppedImg(0.8);
        const croppedImageFile = new File([croppedImageBlob], "croppedImage.jpg", {
          type: "image/jpeg",
        });
        setCroppedImage(URL.createObjectURL(croppedImageFile));
        setShowCropTools(false);
        onImageChange(croppedImageFile);
      }
    } catch (error) {
      setError("Error al recortar la imagen. Inténtalo de nuevo.");
      console.error("Error cropping the image", error);
    }
  };

  const handleRotateImage = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  return (
    <ImageUploaderContainer>
      <DropzoneContainer {...getRootProps({ isDragActive })} onClick={open}>
        <input {...getInputProps({ accept: "image/png, image/jpeg" })} />
        <p className="imageUp">
          Arrastra y suelta una imagen aquí o haz clic para seleccionar una
        </p>
      </DropzoneContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {imageSrc && showCropTools && (
        <>
          <CropContainer>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              cropSize={{ width: 200, height: 200 }}
              cropShape="round"
              onCropComplete={onCropComplete}
              minZoom={0.5} // Permitir zoom inverso
            />
            <ControlsContainer>
              <input
                type="range"
                min={0.5}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(parseFloat(e.target.value))}
                style={{ marginBottom: "10px" }}
              />
              <div className="row">
              <ControlButton type="button" onClick={handleRotateImage}>
                Rotar
              </ControlButton>
              <ChangeImageButton type="button" onClick={open}>
                Cambiar imagen
              </ChangeImageButton>
              </div>

            </ControlsContainer>
          </CropContainer>
          <CropButton type="button" onClick={handleCropImage}>
            Aceptar imagen recortada
          </CropButton>
        </>
      )}
      <CenterImage>
        <img
          onClick={open}
          src={croppedImage || (type === "1" ? Business : Default)}
          alt="perfil"
          className="imgRegister"
          style={{ cursor: "pointer" }}
        />
      </CenterImage>
    </ImageUploaderContainer>
  );
}

export default ImageUploader;
