import React from "react";
import Navbar from "./../Navbar";
import Sidebar from "./../Sidebar";
import Footer from "./../Footer";
import TablaArchivados from "./TablaArchivados";
import ConsultarTicketsArchivados from "../ConsultarTicketsArchivados";

function Archivados() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="statsDiv" style={{width:"80%", margin:"auto", marginTop:"20px"}}>
      <ConsultarTicketsArchivados />

      </div>
     
      <Footer />
    </div>
  );
}

export default Archivados;
