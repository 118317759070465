import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import WelcomeScreen from './WelcomeScreen';
import WhyPlan from './WhyPlan';
import CreateProject from './CreateProject';
import CustomizeProject from './CustomizeProject';
import Manifest from './Manifest';
import SyncCalendar from './SyncCalendar';
import Planes from './Planes';
import FinishRegister from './FinishRegister';

import './CrearPerfil.css';

const CrearPerfil = () => {
  const { currentUser } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState('forward');
  const [formData, setFormData] = useState({
    reason: '',
    projectName: '',
    projectGoal: '',
    areas: [],
    areaGoals: {},
    plan: '',
    userEmail: '',
    idBusiness: '',
    projectColor: '',
    projectIcon: '',
  });

  useEffect(() => {
    const idBusiness = sessionStorage.getItem('businessId');
    const userEmail = currentUser ? currentUser.email : '';
    if (idBusiness && userEmail) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userEmail,
        idBusiness,
      }));
    }
  }, [currentUser]);

  const nextStep = () => {
    setDirection('forward');
    setStep(step + 1);
  };
  
  const prevStep = () => {
    setDirection('backward');
    setStep(step - 1);
  };

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <WelcomeScreen nextStep={nextStep} currentUser={currentUser} />;
      case 2:
        return <WhyPlan nextStep={nextStep} handleChange={handleChange} formData={formData} currentUser={currentUser} />;
      case 3:
        return <CreateProject nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} currentUser={currentUser} />;
      case 4:
        return <Manifest nextStep={nextStep} prevStep={prevStep} currentUser={currentUser} />;
      case 5:
        return <SyncCalendar prevStep={prevStep} nextStep={nextStep} />;
      case 6:
        return <Planes 
        prevStep={prevStep} 
        nextStep={nextStep} 
        handleChange={handleChange} 
        formData={formData} 
      />
      case 7:
          return <FinishRegister />;
      default:
        return <WelcomeScreen nextStep={nextStep} currentUser={currentUser} />;
    }
  };

  return (
    <div className="crearPerfilContainer">
      <div className="progressCircles-loginform desktop-only">
        {[1, 2, 3, 4, 5, 6, 7].map((circle, index) => (
          <React.Fragment key={index}>
            <div className={`circle-loginform ${step === circle ? 'active' : step > circle ? 'completed' : ''}`}>
              {circle}
            </div>
            {index < 6 && (
              <div className={`bar-loginform ${step > circle ? 'active' : ''}`}></div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className={`formStepContainer ${direction}`}>
        {renderStep()}
      </div>
    </div>
  );
};

export default CrearPerfil;