import React, { useState, useRef, useEffect } from "react";
import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import TooltipPortal from './TooltipPortal';
import moment from "moment";

const DraggableTicketMulti = ({ ticket, onClick, style, currentUser, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const timeoutRef = useRef(null);
    const ticketRef = useRef(null);

    const [{ isDraggingItem }, drag, preview] = useDrag({
        type: "TICKET",
        item: { ...ticket },
        canDrag: ticket.canEdit,
        collect: (monitor) => ({
          isDraggingItem: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          if (monitor.didDrop()) {
            const newStartDate = monitor.getDropResult().date;
            const duration = moment(ticket.fechaFin, "DD-MM-YYYY").diff(moment(ticket.vencimiento, "DD-MM-YYYY"), 'days');
            const newEndDate = moment(newStartDate).add(duration, 'days').format("DD-MM-YYYY");
      
            ticket.vencimiento = newStartDate;
            ticket.fechaFin = newEndDate;
      
            ReactGA.event({
              category: "Interacción",
              action: "Arrastre Ticket Mensual",
              label: "Arrastre Ticket Mensual",
              value: 1,
            });
          }
        },
    });

    const handleMouseEnter = () => {
        timeoutRef.current = setTimeout(() => {
            const rect = ticketRef.current.getBoundingClientRect();
            setTooltipPosition({
                top: rect.top,
                left: rect.left + rect.width / 2,
            });
            setShowTooltip(true);
        }, 500);
    };

    const handleMouseLeave = () => {
        clearTimeout(timeoutRef.current);
        setShowTooltip(false);
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <div
            ref={drag}
            style={style}
            onClick={onClick}
            className={`calendario__ticket-multidia ${isDraggingItem ? 'dragging' : ''}`}
        >
            {children}
            {showTooltip && (
                <TooltipPortal position={tooltipPosition}>
                    <p><strong>Título:</strong> {ticket.title}</p>
                    <p><strong>Hora:</strong>
                        {ticket.horaInicio === "Todo el día"
                            ? ticket.horaInicio
                            : `${ticket.horaInicio} - ${ticket.horaTermino}`}
                    </p>
                    <p><strong>Editable:</strong> {ticket.canEdit ? "Sí" : "No"}</p>
                </TooltipPortal>
            )}
        </div>
    );
};

export default DraggableTicketMulti;