import React from "react";
import "./TutorialCard.css"

function TutorialCard() {
  return (
    <div className="card">
      <div className="cardContent">
        <div className="videoContainer">
          <iframe
            width="100%"
            height="200"
            src="https://www.youtube.com/embed/ZlsQdvM4U4&t=11s"  // Reemplaza con tu video
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="cardText">
          <span className="cardTitle">Tutorial de creación de proyectos</span>
        </div>
      </div>
    </div>
  );
}

export default TutorialCard;
