import React from "react";
import { AuthContext } from "../context/AuthContext";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useContext } from "react";
import Chat from "./Chat";
import { useLocation } from "react-router-dom";

const TicketDetails = (props) => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  const title = location.state?.title;
  const businessName = location.state?.businessName;
  const priority = location.state?.priority;
  const vencimiento = location.state?.vencimiento;
  const creacion = location.state?.creacion;
  const department = location.state?.department;
  const status = location.state?.status;
  const emailClient = location.state?.emailClient;
  const emailBusiness = location.state?.emailBusiness;
  const descripcion = location.state?.descripcion;
  const context = location.state?.context;
  const skill = location.state?.skill;
  const plan = location.state?.plan;
  const creator = location.state?.creator;
  const elaboracion = location.state?.elaboracion;
  const id = location.state?.id;
  const uid = location.state?.uid;
  const to = location.state?.to;
  const from = location.state?.from;
  const horaCreacion = location.state?.horaCreacion;

  
  return (
    <div>
      <Navbar
        name={currentUser.displayName}
        photo={currentUser.photoURL}
        caption={currentUser.displayName}
      />

      <Sidebar />

      <Chat
        title={title}
        businessName={businessName}
        priority={priority}
        vencimiento={vencimiento}
        creacion={creacion}
        department={department}
        status={status}
        emailClient={emailClient}
        emailBusiness={emailBusiness}
        descripcion={descripcion}
        context={context}
        skill={skill}
        plan={plan}
        creator={creator}
        elaboracion={elaboracion}
        id={id}
        uid={uid}
        to={to}
        from={from}
        horaCreacion={horaCreacion}
      />
    </div>
  );
};

export default TicketDetails;
