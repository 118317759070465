import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import RbAreas from './registerBusiness/RbAreas'

function Areas() {

  return (
    <div>
    <Navbar/>
    <Sidebar />
    <RbAreas />
    <Footer />
  </div>
  )
}

export default Areas