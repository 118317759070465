// WaveBackgroundLogin.jsx
import React, { useEffect, useState, useRef } from 'react';

const WaveBackground = () => {
  const svgRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Efecto para capturar el movimiento del ratón y ajustar la animación de la onda
  useEffect(() => {
    const handleMouseMove = (event) => {
      if (svgRef.current) {
        const { left, top, width, height } = svgRef.current.getBoundingClientRect();
        const x = (event.clientX - left) / width;
        const y = (event.clientY - top) / height;
        setMousePosition({ x, y });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <svg
      ref={svgRef}
      className="wave-background"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
      preserveAspectRatio="none"
    >
      {/* Definición de gradiente para el fondo de la onda */}
      <defs>
        <linearGradient id="waveGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#e0e7ff" stopOpacity="0.4" />
          <stop offset="50%" stopColor="#d8b4fe" stopOpacity="0.3" />
          <stop offset="100%" stopColor="#bfdbfe" stopOpacity="0.4" />
        </linearGradient>
      </defs>
      {/* Path de la onda, que cambia según la posición del mouse */}
      <path
        fill="url(#waveGradient)"
        d={`
          M0,${160 + mousePosition.y * 50}
          C320,${180 + mousePosition.x * 40},
          720,${140 + mousePosition.y * 60},
          1440,${160 + mousePosition.x * 50}
          V320H0Z
        `}
      >
        {/* Animación del movimiento de la onda */}
        <animate
          attributeName="d"
          dur="5s"
          repeatCount="indefinite"
          values={`
            M0,${160 + mousePosition.y * 50}C320,${180 + mousePosition.x * 40},720,${140 + mousePosition.y * 60},1440,${160 + mousePosition.x * 50}V320H0Z;
            M0,${170 + mousePosition.y * 40}C320,${190 + mousePosition.x * 50},720,${150 + mousePosition.y * 50},1440,${170 + mousePosition.x * 40}V320H0Z;
            M0,${160 + mousePosition.y * 50}C320,${180 + mousePosition.x * 40},720,${140 + mousePosition.y * 60},1440,${160 + mousePosition.x * 50}V320H0Z
          `}
        />
      </path>
    </svg>
  );
};

export default WaveBackground;
