import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './Manifest.css';

const Manifest = ({ nextStep, prevStep, currentUser }) => {
  const [planDay, setPlanDay] = useState('Lunes');
  const [planTime, setPlanTime] = useState('07:00');
  const [dailyReviewTime, setDailyReviewTime] = useState('08:00');
  const [weeklyReviewDay, setWeeklyReviewDay] = useState('Viernes');
  const [weeklyReviewTime, setWeeklyReviewTime] = useState('07:00');
  const [isSigned, setIsSigned] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  const handleSign = async () => {
    setIsSigned(true);
    setErrorMessage('');
    try {
      // Actualiza los datos del manifiesto en Firestore
      const userRef = doc(db, 'users', currentUser.email);
      await updateDoc(userRef, {
        planDay,
        planTime,
        dailyReviewTime,
        weeklyReviewDay,
        weeklyReviewTime
      });

      // Llama a la función de envío de email de bienvenida
      const response = await fetch('https://sendwelcomeemail-vk3cqh4g7q-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: currentUser.displayName,
          email: currentUser.email,
          timeReview: `${weeklyReviewDay} a las ${weeklyReviewTime}`,
        }),
      });

      if (response.ok) {
        console.log('Email de bienvenida enviado con éxito');
      } else {
        console.error('Error al enviar el email de bienvenida');
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      alert('Hubo un error al guardar tu manifiesto o enviar el email. Por favor, intenta de nuevo.');
    }
  };

  const handleNextStep = () => {
    if (!isSigned) {
      setErrorMessage('Por favor, firma el manifiesto antes de continuar.');
    } else {
      nextStep();
    }
  };

  return (
    <div className="manifest-container">
      <div className="manifest-paper">
        <h1 className="manifest-title">Manifiesto de Planificación</h1>
        
        <p className="manifest-intro">
          Yo, <span className="manifest-name">{currentUser.displayName}</span>, me comprometo a lo siguiente:
        </p>

        <ol className="manifest-list">
          <li className="manifest-item">
            Planificaré mi semana cada 
            <select className="manifest-select" value={planDay} onChange={(e) => setPlanDay(e.target.value)}>
              {days.map(day => <option key={day} value={day}>{day}</option>)}
            </select>
            a las
            <input className="manifest-input" type="time" value={planTime} onChange={(e) => setPlanTime(e.target.value)} />
          </li>

          <li className="manifest-item">
            Realizaré la revisión diaria de mi planificación a las
            <input className="manifest-input" type="time" value={dailyReviewTime} onChange={(e) => setDailyReviewTime(e.target.value)} />
          </li>

          <li className="manifest-item">
            Llevaré a cabo la revisión semanal de mi planificación cada
            <select className="manifest-select" value={weeklyReviewDay} onChange={(e) => setWeeklyReviewDay(e.target.value)}>
              {days.map(day => <option key={day} value={day}>{day}</option>)}
            </select>
            a las
            <input className="manifest-input" type="time" value={weeklyReviewTime} onChange={(e) => setWeeklyReviewTime(e.target.value)} />
          </li>
        </ol>

        <p className="manifest-closing">
          Me comprometo a cumplir con este manifiesto para mejorar mi productividad y alcanzar mis metas.
        </p>

        <div className="manifest-signature">
          <button className="manifest-sign-button" onClick={handleSign} disabled={isSigned}>
            {isSigned ? 'Manifiesto Firmado' : 'Firmar Manifiesto'}
          </button>
          {isSigned && <p className="manifest-signed-text">Firmado por: {currentUser.displayName}</p>}
        </div>
      </div>

      {errorMessage && <p className="manifest-error-message">{errorMessage}</p>}

      <div className="form-actions">
        <button className="formButton" onClick={prevStep}>Volver</button>
        <button className="formButton" onClick={handleNextStep} disabled={!isSigned}>
          Avanzar
        </button>
      </div>
    </div>
  );
};

export default Manifest;
