import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { db } from '../firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import Modal from 'react-modal';
import './PopUp.css';
import moment from 'moment-timezone';
import { createTicketNew } from '../Functions';
import { agregarEventoACalendario } from '../calendario/FunctionsCalendar';
import { RefreshCcw } from 'lucide-react';

const AddFromPlani = ({ isOpen, onClose, detalle, businessEmail, fecha, onTaskCreated }) => {
  const { currentUser, businessName, accessToken, refreshToken, tokenExpiry } = useContext(AuthContext);

  const [titulo, setTitulo] = useState('');
  const [area, setArea] = useState('');
  const [areas, setAreas] = useState([]);
  const [prioridad, setPrioridad] = useState('media');
  const [todoElDia, setTodoElDia] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [horaInicio, setHoraInicio] = useState('');
  const [horaTermino, setHoraTermino] = useState('');
  const [fechaVencimiento, setFechaVencimiento] = useState('');

  useEffect(() => {
    if (detalle) {
      setTitulo(detalle.evento || '');
      setPrioridad(detalle.priority?.toLowerCase() || 'media');
      setTodoElDia(detalle.horaInicio === 'Todo el día');
      setHoraInicio(detalle.horaInicio === 'Todo el día' ? '' : detalle.horaInicio);
      setHoraTermino(detalle.horaTermino === 'Todo el día' ? '' : detalle.horaTermino);
      setFechaVencimiento(moment(fecha).format('YYYY-MM-DD'));
    }
  }, [detalle, fecha]);

  useEffect(() => {
    const fetchAreas = async () => {
      if (businessEmail) {
        const q = query(collection(db, "business", businessEmail, "areas"));
        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
        });
        setAreas(items);
      }
    };
    fetchAreas();
  }, [businessEmail]);

  const handleCreateTask = async () => {
    if (!titulo || !area) {
      setErrorMessage('Por favor complete todos los campos requeridos');
      return;
    }

    setLoadingTasks(true);
    setSuccessMessage('Agregando tarea');
    setErrorMessage('');

    try {
      const selectedArea = areas.find(a => a.id === area);
      const color = selectedArea?.color || "#000000";
      const icon = selectedArea?.icon || "📌";
      const hoy = moment().tz("America/Santiago").format('DD-MM-YYYY');

      const tituloConIcono = `${icon} ${titulo}`;
      const formattedFechaVencimiento = moment(fechaVencimiento).format("DD-MM-YYYY");
      const horaInicioFinal = todoElDia ? "Todo el día" : horaInicio;
      const horaTerminoFinal = todoElDia ? "Todo el día" : horaTermino;

      // Crear ticket
      const refDoc = await createTicketNew(
        tituloConIcono,
        businessName,
        prioridad,
        formattedFechaVencimiento,
        hoy,
        area,
        'Proceso',
        currentUser.email,
        businessEmail,
        titulo,
        titulo,
        "No aplica",
        titulo,
        currentUser.displayName,
        [currentUser.email],
        currentUser.email,
        hoy,
        horaInicioFinal,
        horaTerminoFinal,
        'tarea',
        color,
        true,
        formattedFechaVencimiento
      );

      // Preparar fechas para Google Calendar
      let fechaInicioISO, fechaVencimientoISO;
      if (todoElDia) {
        fechaInicioISO = moment.tz(fechaVencimiento, "America/Santiago").format('YYYY-MM-DD');
        fechaVencimientoISO = moment.tz(fechaVencimiento, "America/Santiago").add(1, 'days').format('YYYY-MM-DD');
      } else {
        fechaInicioISO = moment.tz(`${fechaVencimiento} ${horaInicioFinal}`, "YYYY-MM-DD HH:mm", "America/Santiago").toISOString();
        fechaVencimientoISO = moment.tz(`${fechaVencimiento} ${horaTerminoFinal}`, "YYYY-MM-DD HH:mm", "America/Santiago").toISOString();
      }

      // Agregar al calendario
      await agregarEventoACalendario(
        businessEmail,
        refDoc.id,
        tituloConIcono,
        titulo,
        fechaInicioISO,
        fechaVencimientoISO,
        todoElDia ? null : horaInicioFinal,
        todoElDia ? null : horaTerminoFinal,
        color,
        'primary',
        currentUser,
        accessToken,
        refreshToken,
        tokenExpiry
      );

      setLoadingTasks(false);
      if (onTaskCreated) onTaskCreated();
      onClose();

    } catch (error) {
      console.error('Error al crear la tarea:', error);
      setErrorMessage('Error al crear la tarea');
      setLoadingTasks(false);
    }
  };

  return (
    <Modal
      overlayClassName="popupBackground"
      className="popupContainer"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">Agregar Tarea Sugerida</h2>
      </div>

      <div className="popupContent">
        <div className="popupField">
          <label className="popupLabel">Título</label>
          <input
            type="text"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            className="popupInput"
          />
        </div>

        <div className="popupField">
          <label className="popupLabel">Fecha</label>
          <input
            type="date"
            value={fechaVencimiento}
            onChange={(e) => setFechaVencimiento(e.target.value)}
            className="popupInput"
          />
        </div>

        <div className="popupField">
          <label>
            <input
              type="checkbox"
              checked={todoElDia}
              onChange={(e) => setTodoElDia(e.target.checked)}
            />
            ¿Todo el día?
          </label>
        </div>

        {!todoElDia && (
          <>
            <div className="popupField">
              <label className="popupLabel">Hora de Inicio</label>
              <input
                type="time"
                value={horaInicio}
                onChange={(e) => setHoraInicio(e.target.value)}
                className="popupInput"
              />
            </div>
            <div className="popupField">
              <label className="popupLabel">Hora de Término</label>
              <input
                type="time"
                value={horaTermino}
                onChange={(e) => setHoraTermino(e.target.value)}
                className="popupInput"
              />
            </div>
          </>
        )}

        <div className="popupField">
          <label className="popupLabel">Área</label>
          <select
            value={area}
            onChange={(e) => setArea(e.target.value)}
            className="popupInput"
          >
            <option value="">Seleccionar área</option>
            {areas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.id}
              </option>
            ))}
          </select>
        </div>

        <div className="popupField">
          <label className="popupLabel">Prioridad</label>
          <select
            value={prioridad}
            onChange={(e) => setPrioridad(e.target.value)}
            className="popupInput"
          >
            <option value="alta">Alta</option>
            <option value="media">Media</option>
            <option value="baja">Baja</option>
          </select>
        </div>

        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}

        {successMessage && (
          <div className="success-message">
            {successMessage}
            {loadingTasks && (
              <div className="loadingSpinner">
                <RefreshCcw size={24} className="spinner" />
              </div>
            )}
          </div>
        )}

        <div className="center" style={{display:"flex", flexDirection:"row", margin:"auto", padding:"10px", alignContent:"center"}}>
          <button 
            className="popupButton" 
            style={{marginRight:"10px"}} 
            onClick={handleCreateTask} 
            disabled={loadingTasks}
          >
            Agregar Tarea
          </button>
          <button className="popupButton" onClick={onClose}>
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddFromPlani; 