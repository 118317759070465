import React from 'react';
import TimeCell from './TimeCell';
import CalendarCell from './CalendarCell';

const CalendarRow = ({
  hour,
  hoveredRow,
  diasSemanaArr,
  obtenerTicketsDia,
  agruparTicketsPorSolapamiento,
  ...props
}) => {
  return (
    <tr
      key={hour}
      onMouseEnter={() => props.setHoveredRow(hour)}
      onMouseLeave={() => props.setHoveredRow(null)}
      className={hoveredRow === hour ? "calendarioSemanal__hovered-row" : ""}
    >
      <TimeCell hour={hour} />
      {diasSemanaArr.map((fecha, index) => {
        const ticketsDia = obtenerTicketsDia(fecha);
        const gruposTickets = agruparTicketsPorSolapamiento(ticketsDia);

        return (
          <CalendarCell
            key={index}
            index={index}
            fecha={fecha}
            hour={hour}
            gruposTickets={gruposTickets}
            {...props}
          />
        );
      })}
    </tr>
  );
};

export default CalendarRow; 