import React, { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import './VideoBox.css';

const ItemType = 'ITEM';

function VideoBox({ item, index, setItems, items }) {
  const [url, setUrl] = useState(item.content);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(item.content || '');
  const ref = useRef(null);
  const inputRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        const newItems = Array.from(items);
        const [movedItem] = newItems.splice(draggedItem.index, 1);
        newItems.splice(index, 0, movedItem);
        setItems(newItems);
        draggedItem.index = index;
      }
    },
    drop: (draggedItem, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const newItems = Array.from(items);
      const movedItem = newItems.find((i) => i.id === draggedItem.id);
      if (movedItem) {
        movedItem.position = {
          x: Math.round(item.position.x + delta.x),
          y: Math.round(item.position.y + delta.y),
        };
        setItems(newItems);
      }
    },
  });

  drag(drop(ref));

  const handleSave = () => {
    const videoId = getYouTubeVideoId(inputValue);
    if (videoId) {
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      setUrl(embedUrl);
      const newItems = Array.from(items);
      const currentItem = newItems.find((i) => i.id === item.id);
      if (currentItem) {
        currentItem.content = embedUrl;
        setItems(newItems);
      }
      setIsEditing(false);
    } else {
      alert('Por favor, ingresa un enlace de YouTube válido.');
    }
  };

  const handlePaste = (e) => {
    if (e.clipboardData) {
      const pasteText = e.clipboardData.getData('text');
      setInputValue(pasteText);
    }
  };

  const getYouTubeVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <div
      ref={ref}
      className="video-box"
      style={{
        opacity: isDragging ? 0.5 : 1,
        left: item.position.x,
        top: item.position.y,
        position: 'absolute',
      }}
    >
      {isEditing ? (
        <div className="video-box-edit">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onPaste={handlePaste}
            placeholder="Pega el enlace de YouTube"
            ref={inputRef}
          />
          <button className="save-button" onClick={handleSave}>
            <FontAwesomeIcon icon={faEdit} /> 
          </button>
        </div>
      ) : (
        <>
          {url ? (
            <div className="video-container">
              <iframe
                width="200"
                height="150"
                src={url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <button className="edit-button" onClick={() => setIsEditing(true)}>
              <FontAwesomeIcon icon={faEdit} /> Agregar Video
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default VideoBox;
