import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { updateTicket } from "../Functions";
import moment from "moment";
import "moment-timezone";


function ChangeTimeModal({
  isOpen,
  onClose,
  title,
  fechaInicio,
  fechaTermino,
  horaInicio,
  horaTermino,
  onSave,
  businessEmail,
  ticketId,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(horaInicio || "00:00");
  const [endTime, setEndTime] = useState(horaTermino || "00:00");
  const [error, setError] = useState("");

  useEffect(() => {
    if (fechaInicio) {
      const parsedStartDate = new Date(fechaInicio);
      if (!isNaN(parsedStartDate)) {
        setStartDate(parsedStartDate);
      }
    }

    if (fechaTermino) {
      const parsedEndDate = new Date(fechaTermino);
      if (!isNaN(parsedEndDate)) {
        setEndDate(parsedEndDate);
      }
    }

    setStartTime(horaInicio || "00:00");
    setEndTime(horaTermino || "00:00");
  }, [fechaInicio, fechaTermino, horaInicio, horaTermino]);

  const handleSave = async () => {
    setError(""); // Clear previous errors

    if (!startDate || isNaN(startDate.getTime())) {
      setError("Debe seleccionar una fecha de inicio válida.");
      return;
    }

    if (!endDate || isNaN(endDate.getTime())) {
      setError("Debe seleccionar una fecha de término válida.");
      return;
    }

    if (!startTime || !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(startTime)) {
      setError("Debe seleccionar una hora de inicio válida.");
      return;
    }

    if (!endTime || !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(endTime)) {
      setError("Debe seleccionar una hora de término válida.");
      return;
    }

    const startDateTime = moment.tz(
      `${startDate.toISOString().split("T")[0]} ${startTime}`,
      "YYYY-MM-DD HH:mm",
      "America/Santiago"
    );
    const endDateTime = moment.tz(
      `${endDate.toISOString().split("T")[0]} ${endTime}`,
      "YYYY-MM-DD HH:mm",
      "America/Santiago"
    );

    if (endDateTime.isBefore(startDateTime)) {
      setError("La fecha de término no puede ser menor que la fecha de inicio.");
      return;
    }

    const updatedData = {
      creacion: startDateTime.format("DD-MM-YYYY"),
      vencimiento: endDateTime.format("DD-MM-YYYY"),
      horaInicio: startTime,
      horaTermino: endTime,
    };

    try {
      await updateTicket(businessEmail, ticketId, updatedData);
      onSave(updatedData);
      onClose();
    } catch (error) {
      console.error("Error al guardar los cambios: ", error);
      setError("Error al guardar los cambios. Inténtalo de nuevo.");
    }
  };

  return (
    <Modal
      overlayClassName="popupBackground"
      className="popupContainer"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="popupHeader">
        <h2 className="popupTitle">¿Con qué fecha desea iniciar la tarea?</h2>
      </div>
      <div className="popupContent">
        <div className="popupField">
          <label className="popupLabel">Fecha de Inicio:</label>
          <input
            type="date"
            value={startDate ? startDate.toISOString().split("T")[0] : ""}
            onChange={(e) => setStartDate(new Date(e.target.value))}
            min={new Date().toISOString().split("T")[0]}
            className="popupInput"
          />
        </div>
        <div className="popupField">
          <label className="popupLabel">Hora de Inicio:</label>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="popupInput"
          />
        </div>
        <div className="popupField">
          <label className="popupLabel">Fecha de Término:</label>
          <input
            type="date"
            value={endDate ? endDate.toISOString().split("T")[0] : ""}
            onChange={(e) => setEndDate(new Date(e.target.value))}
            min={new Date().toISOString().split("T")[0]}
            className="popupInput"
          />
        </div>
        <div className="popupField">
          <label className="popupLabel">Hora de Término:</label>
          <input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className="popupInput"
          />
        </div>
        {error && <p className="error-message">{error}</p>}
      </div>
      <div style={{display:"flex", flexDirection:"row", margin:"auto", gap:"10px"}}>
        <button className="popupButton" onClick={handleSave}>
          Guardar
        </button>
        <button className="popupButton" onClick={onClose}>
          Cancelar
        </button>
      </div>
    </Modal>
  );
}

export default ChangeTimeModal;
