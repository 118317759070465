import React from "react";

function Condiciones() {
  return (
    <div className="cardPolitics">
      <h1>Condiciones de Servicio de Planius</h1>
      <h1>Introducción</h1>
      <p>
        Bienvenido a Planius. Estos términos y condiciones ("Condiciones") rigen
        tu uso de nuestro sitio web, aplicaciones y otros productos y servicios
        ("Servicios"). Al acceder o usar los Servicios, aceptas estar sujeto a
        estas Condiciones. Si no estás de acuerdo con alguna parte de las
        Condiciones, no puedes acceder a los Servicios.
      </p>
      <h1>Uso de los Servicios</h1>
      <p>
        Planius te otorga un permiso limitado, no exclusivo y revocable para
        hacer uso personal de los Servicios. Este permiso no incluye ningún tipo
        de reventa o uso comercial de los Servicios ni de sus contenidos;
        cualquier recopilación y uso de listados, descripciones o precios de
        productos; cualquier uso derivado de los Servicios o sus contenidos;
        cualquier descarga o copia de información de cuenta para el beneficio de
        otro comerciante; o cualquier uso de minería de datos, robots o
        herramientas similares de recolección y extracción de datos.
      </p>
      <h1>Cuentas de Usuario</h1>
      <p>
        Para acceder a algunas funciones de los Servicios, es posible que debas
        crear una cuenta. Eres responsable de mantener la confidencialidad de tu
        cuenta, contraseña y restricción de acceso a tu computadora y/o cuenta.
        Aceptas aceptar la responsabilidad de todas las actividades que ocurran
        bajo tu cuenta o contraseña.
      </p>
      <h1>Privacidad y Uso de Datos</h1>
      <p>
        Nuestra Política de Privacidad describe cómo manejamos la información
        que nos proporcionas cuando utilizas nuestros Servicios. Al utilizar
        nuestros Servicios, consientes el uso de dicha información tal como se
        describe en nuestra Política de Privacidad.
      </p>
      <p>
        En particular, al aceptar estas Condiciones, consientes expresamente que
        Planius acceda a los datos de tu Calendario de Google, Google Tasks y la
        información básica del perfil de Microsoft (nombre y dirección de correo
        electrónico) cuando elijas iniciar sesión con una cuenta de Microsoft.
        Estos datos se utilizarán únicamente para proporcionar las
        funcionalidades centrales de Planius como se describe en nuestra
        Política de Privacidad y no se compartirán ni utilizarán para ningún
        otro propósito.
      </p>
      <p>
        Puedes revocar el consentimiento para acceder a estos datos en cualquier
        momento desde tu configuración de cuenta, sin embargo, ten en cuenta que
        esto puede afectar la funcionalidad de Planius.
      </p>
      <h1>Propiedad Intelectual</h1>
      <p>
        Los Servicios y su contenido original (excluyendo el Contenido
        proporcionado por los usuarios), características y funcionalidad son y
        seguirán siendo propiedad exclusiva de Planius y sus licenciantes. Los
        Servicios están protegidos por derechos de autor, marcas registradas y
        otras leyes tanto de Chile como de países extranjeros. Nuestras marcas y
        elementos de imagen comercial no podrán ser utilizados en relación con
        ningún producto o servicio sin el previo consentimiento por escrito de
        Planius.
      </p>
      <h1>Modificaciones a las Condiciones</h1>
      <p>
        Planius se reserva el derecho, a su entera discreción, de modificar o
        reemplazar cualquier parte de estas Condiciones publicando
        actualizaciones y cambios en nuestro sitio web. Es tu responsabilidad
        revisar nuestro sitio web periódicamente para ver los cambios.
      </p>
      <h1>Contacto</h1>
      <p>
        Si tienes preguntas sobre estas Condiciones, por favor contáctanos a
        través de [contacto@planius.com].
      </p>
    </div>
  );
}

export default Condiciones;
