// Login.jsx
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import WaveBackgroundLogin from "./WaveBackgroundLogin";
import logoLogin from "./img/planiuslogin.png";
import "./Login.css";
import GoogleLogin from "./GoogleAuth";

export const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const navigate = useNavigate();
  const { setCurrentUser, setBusinessEmail } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Planius AI - Login";
    return () => {
      document.title = "Planius AI";
    };
  }, []);

  return (
    <div className="formContainer-loginform">
      <WaveBackgroundLogin />
      <div className="formWrapper-loginform">
        <div className="betaBanner-loginform">Beta</div>
        <img className="logoLogin-loginform" src={logoLogin} alt="Planius AI" />
        <span className="titleLogin-loginform">Iniciar Sesión</span>
        <GoogleLogin />
        {loading && (
          <div className="progressMessage-loginform">{progressMessage}</div>
        )}
        <p className="errorMessage-loginform">{errorMessage}</p>
        <div className="privacyPolicy-loginform">
          <span>
            Al usar nuestra plataforma aceptas nuestra{" "}
            <span
              onClick={() => navigate("/politicas")}
              className="privacyPolicyLink-loginform"
            >
              Política de Privacidad
            </span>
          </span>
        </div>
      </div>
      <div className="version-loginform">versión 4.26.8</div>
    </div>
  );
};

export default Login;
